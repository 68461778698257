$animation-time: 0.4s;

.preview_wrapper {
}
@keyframes appear {
  0% {
    display: flex;
  }
  10% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
}
.display_element_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 102;
  height: 120px;
  width: auto;
  max-width: 200px;
  background-color: white;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  display: flex;
  transition: linear opacity $animation-time;
  opacity: 0;
  overflow: hidden;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  &.page_type {
    width: 185px;
    height: 247px;
  }
  &.component_type {
    width: 172px;
    height: 172px;
  }
  &.pdf_type {
    width: 200px;
    height: 260px;
  }
  &.link_type,
  &.img_type {
    height: auto;
    width: auto;
    max-width: 360px;
  }
  &.audio_type,
  &.video_type_svg,
  &.broken_file {
    width: 120px;
  }
 &.another_type {
   width: 140px;
   border-radius: 10px;
   height: 137px;
   & .image, img {
     height: 140px;
    }
 }
  &.presentation_type {
    height: 200px;
    width: 200px;
  }
  &.isKanban {
    height: 120px;
    width: 120px;
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  max-width: 200px;
  //max-height: 230px;
  overflow: hidden;
  border-radius: 7px;
  &.page_type {
    height: 240px;
    & img {
      height: 100%;
      width: 100%;
    }
  }
  &.component_type {
    display: block;
    height: 100%;
    width: 100%;
    & img {
      height: 100%;
      width: 100%;
    }
  }
  &.link_type {
    width: 100%;
  }
  &.pdf_type {
    height: 240px;
  }
  &.presentation_type {
    height: 200px;
    width: 200px;
  }
  & img {
    height: 120px;
    width: auto;
    border-radius: 7px;
  }
  &.broken_file {
    width: 120px;
  }
}
