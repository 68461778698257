.users_count {
  color: #939393;
  margin-left: 4px;
  &.hide {
    opacity: 0;
    width: 14px;
  }
  &.active {
    color: #484343;
  }
  &.disabled {
    color: lightgrey;
    cursor: not-allowed;
    &:hover {
      background-color: initial;
    }
  }
}

.nav_bar {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  height: 20px;
  &.customTopPadding {
    padding-top: 15px;
  }
}
.nav_bar.isDescriptionOpened {
  @media (max-width: 1730px) {
    padding-left: 32px;
  }
}
.nav_item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  height: 36px;
  box-sizing: content-box;
  cursor: pointer;

  &.disabled {
    color: lightgrey;
    cursor: not-allowed;
    &:hover {
      background-color: initial;
    }
  }

  &.first {
    //margin-right: 16px;
  }
}

.nav_slider {
  position: relative;
  padding-top: 5px;
}

.nav_underlay {
  position: absolute;
  width: 99.5%;
  height: 1px;
  bottom: -1px;
  background: #e4e7f7;
}
.all_separator {
  position: absolute;
  right: -8.5px;
  width: 1px;
  height: 22px;
  background-color: #e4e7f7;
}

.displayFlex {
  display: flex;
}

@media (max-width: 1430px) {
  .isNavVisible {
    display: none;
  }
}

@media (max-width: 1550px) {
  .isNavVisible.isDescriptionOpened {
    display: none;
  }
}
