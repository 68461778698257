@import "./src/_mixins";


.upload_wrapper {
  width: 696px;
  min-height: 120px;
  background: #f5f6ff;
  border-radius: 12px;
  display: flex;
}


.upload_component {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle_loader_wrapper {
  z-index: 15;
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.uploading_text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  font-size: 10px;
}

.circle_loader {
  position: relative;
  width: 60px;
  height: 60px;
  //background: #F5F6FF;
  /*top: 38px;*/
  /*left: 46px;*/
  text-indent: -9999em;
  border-radius: 50%;
  border-top: 1px solid #0026e5;
  border-right: 1px solid #0026e5;
  border-bottom: 1px solid #d7ddf7;
  border-left: 1px solid #0026e5;
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite ease;
  animation: load8 1.1s infinite ease;
  /*animation:  1.1s infinite linear;*/
}
.menu_option {
  padding-left: 9px;
  display: flex;
  justify-content: space-between;
  color: #596080;
  width: calc(100% - 104px);
  .name {
    padding-top: 39px;
    font-size: 16px;
  }
  .description {
    padding-top: 5px;
    font-size: 12px;
  }
  & .cancel {
    height: 120px;
    width: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    & .cancel_image_wrapper {
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      &:hover {
        background-color: #e8ebfa;
      }
    }
  }
}
.approve_button {
  align-self: center;
  padding: 8px 9px;
  border-radius: 16px;
  width: fit-content;
  text-transform: uppercase;
  height: 32px;
  cursor: pointer;
  max-width: 500px;
  @include ellipsis;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-top: 15px;
  margin-left: 2px;

  & svg {
    margin-left: -4px;
    margin-right: 6px;
  }

  &.clicked.clicked {
    border: 1px solid #d7ddf7;
    display: flex;
    align-items: center;
    background: white;
    color: #242633;
    margin-top: 15px;
    &:hover {
      background: #e8ebfa;
    }
  }

  &.green {
    background: rgba(67, 175, 65, 0.15);
    color: #1E9001;

    & svg path {
      stroke: #1E9001;
    }
    &:hover {
      background: rgba(67, 175, 65, 0.3);
    }
  }
  &.red {
    background:  #D33F49;
    color: #D33F49;

    & svg path {
      stroke: #D33F49;
    }
    &:hover {
      background: rgba(67, 175, 65, 0.3);
    }
  }

  &.orange {
    background: #E56E00;
    color: #E56E00;

    & svg path {
      stroke: #E56E00;
    }
    &:hover {
      background: rgba(219, 135, 74, 0.3);
    }
  }

  &.purple {
    background: rgba(174, 69, 202, 0.15);
    color: #9F4C9B;

    & svg path {
      stroke: #9F4C9B;
    }
    &:hover {
      background: rgba(174, 69, 202, 0.3);
    }
  }

  &.black {
    background: rgba(104, 38, 188, 0.15);
    color: #484343;

    & svg path {
      stroke: #484343;
    }
    &:hover {
      background: rgba(104, 38, 188, 0.3);
    }
  }

  &.blue {
    background: rgba(0, 38, 229, 0.15);
    color: #204EC3;

    & svg path {
      stroke: #204EC3;
    }
    &:hover {
      background: rgba(0, 38, 229, 0.3);
    }
  }

  & svg {
    width: 20px;
    height: 20px;
  }
}

.question_wrapper {
  z-index: -1;

  & .question {
    padding: 1px 5px 1px 4px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: fit-content;
    min-height: 32px;
    cursor: auto;
    max-width: 500px;
    //overflow: hidden;
    margin-left: 1px;
    text-overflow: ellipsis;
    font-size: 16px;
    margin-top: 7px;
    position: relative;
    & .question_text {
      padding-left: 2px;
      font-weight: 500;
      max-width: 520px;
      overflow: hidden;
      word-wrap: break-word;
    }
    & input {
      border: none;
      width: 100%;
      height: 21px;
      padding-left: 2px;
      min-width: 100px;
      color: #242633;
      font-weight: 500;
      font-size: 16px;
      font-family: "RobotoFlex", sans-serif;
    }
    & .hidden {
      opacity: 0;
      position: absolute;
      z-index: -1;
      white-space: nowrap;
    }
    & .required {
      position: absolute;
      right: -19px;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      line-height: 24px;
      color: #c21c44;
      margin-top: 3px;
    }
    &.hoverable {
      cursor: pointer;
    }
    &.hoverable:hover {
      border: 1px solid #d7ddf7;
      border-radius: 6px;
      padding: 0 3px 0 3px;
      //margin-left: 0;
    }
    &.rename {
      border: 1px solid #d7ddf7;
      border-radius: 6px;
      padding: 0 3px 0 3px;
      //margin-left: 0;
    }
  }
}

.short_text {
  & .question {
    padding: 1px 5px 1px 4px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    min-height: 28px;
    cursor: auto;
    max-width: 500px;
    margin-left: 5px;
    @include ellipsis;
    font-size: 16px;
    margin-top: 7px;
    position: relative;
    left: -5px;
    top: -2px;
    & .question_text {
      padding-left: 2px;
      font-weight: 500;
      max-width: 520px;
      overflow: hidden;
      word-wrap: break-word;
    }
    & input {
      border: none;
      width: 100%;
      height: 21px;
      min-width: 100px;
      color: #242633;
      font-weight: 500;
      font-size: 16px;
      font-family: "RobotoFlex", sans-serif;
      padding-left: 2px;
    }
    & .hidden {
      opacity: 0;
      position: absolute;
      z-index: -1;
      white-space: nowrap;
    }
    & .required {
      position: absolute;
      right: -19px;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      line-height: 24px;
      color: #c21c44;
      margin-top: 3px;
    }
    &.hoverable {
      cursor: pointer;
    }
    &.hoverable:hover {
      border: 1px solid #d7ddf7;
      border-radius: 6px;
      padding: 0 3px 0 3px;
      //margin-left: 0;
    }
    &.rename {
      border: 1px solid #d7ddf7;
      border-radius: 6px;
      padding: 0 3px 0 3px;
      //margin-left: 0;
    }
  }

  & .answer {
    position: relative;
    width: 321px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d7ddf7;
    border-radius: 6px;
    color: #a3aacc;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 5px 8px 6px 8px;
    margin-right: 10px;
    margin-top: 16px;
    & .limit {
      position: absolute;
      font-size: 12px;
      text-align: right;
      color: #a3aacc;
      top: -18px;
      right: -1px;
    }
    & input {
      border: none;
      width: 100%;
      height: 21px;
      font-size: 14px;
      color: #242633;
    }
  }

  & .hint {
    margin-top: 8px;
    margin-left: 2px;
    width: 320px;
    color: #596080;
    font-size: 12px;
    line-height: 18px;
    word-wrap: break-word;
  }
}

.button_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
  user-select: none;
}

.button_wrapper {
  & .action_button {
    padding: 8px 9px;
    border-radius: 16px;
    width: fit-content;
    text-transform: uppercase;
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 500px;
    @include ellipsis;

    & svg {
      margin-left: 6px;
      margin-right: -4px;
    }

    &.green {
      border: 1px solid #1E9001;
      color: #1E9001;

      & svg path {
        stroke: #1E9001;
      }
    }

    &.red {
      color: #D33F49;
      border: 1px solid #D33F49;

      & svg path {
        stroke: #D33F49;
      }
    }

    &.orange {
      color: #E56E00;
      border: 1px solid #E56E00;

      & svg path {
        stroke: #E56E00;
      }
    }

    &.purple {
      color: #9F4C9B;
      border: 1px solid #9F4C9B;

      & svg path {
        stroke: #9F4C9B;
      }
    }

    &.black {
      color: #484343;
      border: 1px solid #484343;

      & svg path {
        stroke: #484343;
      }
    }

    &.blue {
      border: 1px solid #204EC3;
      color: #204EC3;

      & svg path {
        stroke: #204EC3;
      }
    }

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  & .button_settings {
    height: 42px;
    width: 136px;
    font-size: 12px;
    color: #a3aacc;
    font-family: "RobotoFlex", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: default;

    .svg_wrapper {
      width: 32px;
      height: 32px;
      padding: 6px;
      cursor: pointer;
      border-radius: 6px;
      margin-left: 12px;

      &:hover {
        background: #d7ddf7;
      }

      &.active_item {
        background: #d7ddf7;
      }
    }

    & .settings_menu {
      position: absolute;
      top: 37px;
      height: auto;
      width: 291px;
      padding: 1px 7px 25px 15px;
      right: 0;
      background: #ffffff;
      box-shadow:
        0 1px 4px rgba(64, 77, 140, 0.15),
        0 4px 16px rgba(64, 77, 140, 0.09);
      border-radius: 8px;
      z-index: 100;
      .item_wrapper {
        margin-top: 16px;
        &_limit {
          width: 279px;
        }
        & .text {
          font-size: 12px;
          color: #596080;
          margin-top: 16px;
          margin-bottom: 6px;
          &.active {
            color: #0026e5;
          }
        }
        & .text:first-child {
          margin-top: 0;
        }

        &:focus-within .input_wrapper {
          border: 1px solid #0026e5;
        }

        & .field {
          display: flex;
          align-items: center;
          margin-top: 9px;
          margin-left: 9px;
          height: 32px;
          margin-bottom: 6px;
          font-size: 14px;
          line-height: 21px;
          color: #242633;
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        & .input_wrapper {
          //margin-top: 4px;
          background: #ffffff;
          border: 1px solid #d7ddf7;
          border-radius: 6px;
          //width: 375px;
          height: 32px;
          padding: 5px 8px 6px 8px;
          margin-right: 10px;
          &.input_wrapper_small {
            width: 70px;
            padding-left: 3px;
            position: relative;
            //top: -14px;
            margin-left: 14px;
            input {
              text-align: right;
            }
          }
          input {
            border: none;
            width: 100%;
            height: 21px;
            font-size: 14px;
            color: #242633;

            &::placeholder {
              color: #a3aacc;
            }
          }
        }

        & .select_color {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
          margin-right: 17px;

          & .picker_wrapper {
            border: 1px solid #d7ddf7;
            box-sizing: border-box;
            border-radius: 17px;
            width: 32px;
            height: 32px;
            padding: 2px;

            &.active {
              border: 1px solid #0026e5;
            }

            & .picker {
              width: 26px;
              height: 26px;
              border-radius: 13px;

              &.green {
                background: #43af41;
              }

              &.red {
                background: #D33F49;
              }

              &.orange {
                background: #E56E00;
              }

              &.purple {
                background: #9F4C9B;
              }

              &.black {
                background: #484343;
              }

              &.blue {
                background: #204EC3;
              }
            }
          }
        }
      }
    }
  }
}
.submit {
  margin-top: 65px;
  margin-bottom: 16px;
  position: relative;
  font-family: "RobotoFlex", sans-serif;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: 32px;
  color: #0026e5;
  padding: 0 7px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  letter-spacing: 0.5px;
  margin-left: 8px;
  &:hover {
    background: #e8ebfa;
  }
}

.share_menu_toggler {
  margin-right: 8px;
}

.in_folder_toggler_img {
  /*margin-right: 9px;*/
  position: relative;
  display: flex;
  align-items: center;
  width: 24px;
  height: 11.5px;

  /*background: #F5F6FF;*/
  border: 1px solid #bbc1e1;
  border-radius: 12px;
  cursor: pointer;
  -webkit-transition-property: border;
  -moz-transition-property: border;
  -o-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  left: -1px;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.in_folder_toggler:hover .in_folder_toggler_img {
  background: #dde2f9;
}

.in_folder_toggler_img_right {
  border: 1px solid #0026e5;
}

.in_folder_toggler:hover .in_folder_toggler_knob {
  background: #dde2f9;
}

.in_folder_toggler_knob {
  position: absolute;
  width: 11.5px;
  height: 11.5px;
  border: 1px solid #bbc1e1;
  border-radius: 50%;
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  left: -1px;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.in_folder_knob_left {
  left: -1px;
}

.in_folder_knob_right {
  border: 1px solid #0026e5;
  left: 12px;
}

.page_link {
  &_wrapper {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 56px;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px 16px;
    cursor: pointer;
    min-width: 600px;
    &:hover {
      background-color: #e8ebfa;
      & svg path {
        stroke: #242633;
      }
    }
  }
  &_container {
    display: flex;
    align-items: center;
    & .hint {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 7px;
      color: #596080;
    }
    & .page_name {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #242633;
      margin-right: 8px;
    }
    & .icon {
      margin-right: 8px;
      width: 32px;
      height: 32px;
    }
  }
}

.EmbedBlock {
  //padding: 8px 0;
  height: 470px;
  overflow: hidden;
  position: relative;
  border: 1px solid #e8ebfa;
  cursor: pointer;
  border-radius: 12px;
  &:hover {
    border: 1px solid #d7ddf7;
  }
}
.embedPreview {
  height: 400px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  &.sideBar {
    width: 630px;
  }

  & iframe {
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
  }
}

.EmbedBlock.google {
  padding: 0;
}

.EmbedBlock.mini .embedPreview {
  overflow: hidden;
  height: 450px;
  position: relative;
  z-index: 1;
  width: 600px;
}
.EmbedBlock.mini.isSideBar .embedPreview {
  overflow: hidden;
  height: 400px;
  position: relative;
  z-index: 1;
  width: 530px;
}

.blocked {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #aacc3388;
}
