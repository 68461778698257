@import "./src/_mixins";

.item {
  display: flex;
  align-items: center;
  margin: 3px 9px 2px 9px;
  border-radius: 4px;
  height: 30px;
  padding-left: 6px;
  padding-right: 6px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: #FFE9AD;
  }
  &.selected {
    background-color: #FFE9AD;
    border: 1px solid #8697f0;
  }
  &.hidden {
    display: none;
  }
  &.leftShift {
    padding-left: 17px;
  }
}
.title {
  font-size: 13px;
  line-height: 21px;
  color: #0e0e0e;
  @include ellipsis;
  width: 220px;
}
.icon {
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
}

.greyElement {
  & .title {
    color: #a3aacc;
  }
}

.upload_20 path {
  stroke: #a3aacc;
}
.icon_container {
  display: flex;
  flex-basis: 20px;
  align-items: center;
}
.no_icon_placeholder {
  width: 14px;
  height: 14px;
}
.circle {
  margin-left: 5px;
  width: 10px;
  height: 10px;
  border: 1px solid #a3aacc;
  border-radius: 50%;
  transition: background-color 0.2s linear;
  &.read {
    border-color: #1a0dab;
    background-color: #1a0dab;
  }
}

.button_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1a0dab;
    width: 60px;
    border-radius: 4px;
    .title {
      color: #1a0dab;
      width: initial;
    }
  }
  &:hover {
    background-color: #d7ddf7;
  }
}

.TOCPlayerPlaylistCardView {
  display: flex;
  position: relative;
  //padding-bottom: 12px;
  //height: 133px;
  transition: all ease-in-out 0.25s;
  margin-bottom: 50px;
  &.row_view {
    margin-bottom: 28px;
    height: 77px;
    align-items: center;
    padding-bottom: unset;
  }
}
.number {
  z-index: 1;
  margin-right: 20px;

  position: relative;
  width: 32px;
  height: 32px;
  background: #FFE9AD;
  border-radius: 6px;
  margin-top: 40px;
  margin-bottom: 34px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #596080;
  transition: all ease-in-out 0.25s;

  &.row_view {
    margin-right: 14px;
    margin-top: 13px;
    margin-bottom: 0;
    align-self: flex-start;
  }
}
.line {
  background: #FFE9AD;
  width: 2px;
  height: 120px;
  position: absolute;
  top: -68px;
  left: 15px;
  &.row_view {
    top: -55px;
  }
}
.number_placeholder {
  margin-right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  margin-top: 34px;
  margin-bottom: 34px;
  transition: all ease-in-out 0.25s;

  &.row_view {
    margin-right: 14px;
    margin-top: 5px;
    margin-bottom: 0;
    align-self: flex-start;
  }
}
.preview_container {
  display: flex;
  width: 175px;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  & .title {
    width: fit-content;
    max-width: 170px;
    align-self: flex-start;
    color: #484343;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 9px;
    max-height:40px;
    overflow: hidden;
    display: -webkit-box;

    -webkit-line-clamp: 2; /* This limits the text to 2 lines */
    -webkit-box-orient: vertical;
    white-space: normal;
    &.row_view {
    @include ellipsis();
      margin: 0;
      max-width: 200px;
      position: relative;
      top: 9px;
      left: -1px;
    }
  }
  transition: all ease-in-out 0.25s;
  &.row_view {
    //flex-direction: column-reverse;
    //height: 56px;
    width: 200px;
    padding-left: 9px;
  }
}
.preview {
  position: relative;
  width: 167px;
  height: 112px;
  //border: 1px solid #242633;
  border-radius: 8px;
  align-self: flex-start;
  transition: all ease-in-out 0.25s;
  overflow: hidden;

  &.active {
    //border: 2px solid #242633;
    box-shadow: 0px 0px 0px 4px #FFC72C;
  }

  &.isRead {
    opacity: 0.7;
  }

  &.row_view {
    width: 69px;
    height: 47px;
  }
}
.icons {
  padding-top: 7px;
  padding-bottom: 1px;
  margin-left: 5px;
  width: 19px;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-between;
  transition: all ease-in-out 0.25s;
  opacity: 1;

  &.row_view {
    opacity: 0;
    width: 0;
  }
}

.button {
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all ease-in-out 0.25s;

  &.types {
    opacity: 0.3;
  }
  &.comments {
    cursor: pointer;
    &:hover {
      background: #FFE9AD;
    }
  }
  &.action {
    cursor: pointer;
    &:hover {
      background: #FFE9AD;
    }
  }

  &.isLocked {
    color: lightgrey;
    & svg path {
      stroke: lightgrey;
    }
    &:hover {
      background: initial;
    }
  }
}

.six_dot_permanent_line {
  display: flex;
  .line {
    width: 2px;
    background-color: #FFE9AD;
    position: absolute;
    &.regular {
      height: 138%;
    }
    &.first {
      height: 87%;
      transform: translate(0%, 100%);
    }
    &.last {
      height: 50%;
    }
    &.no_line {
      display: none;
    }
  }
}

.duration_label {
  padding-right: 2px;
  padding-left: 2px;
  width: fit-content;
  position: absolute;
  left: 10px;
  top: 85px;
  background: rgba(217, 216, 216, 0.4);
  //background: #ffffffaa;
  border-radius: 5px;
  opacity: 1;

  &.row_view {
    opacity: 0;
  }
}
.duration_text {
  display: flex;
  gap:1.5px;

}

.digit {
  color: #343434;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  font-family: RobotoFlex;
  width: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dots {
  width: 2px;
  display: flex;

  align-items: center;
  color: #343434;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  font-family: RobotoFlex;
  justify-content: center;
}

.isRead_circle {
  position: absolute;
  top: 7px;
  right: 14px;
  transition: all ease-in-out 0.25s;
  &.row_view {
    top: 13px;
    right: 149px;
  }
}

.grey {
  top: 0;
  position: absolute;
  width: 167px;
  opacity: 0.2;
  height: 112px;
  background-color: grey;
  &.row_view {
    width: 69px;
    height: 47px;
  }
}

.row_info_container {
  left: 79px;
  top: 11px;
  margin-left: 8px;
  margin-right: 16px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 0;
  height: 24px;
  display: flex;
  transition: all ease-in-out 0.25s;
  overflow: hidden;
  opacity: 0;

  &.row_view {
    opacity: 1;
    width: 132px;
  }
}
