.recent_wrapper_grid {
  padding-top: 2px;
  height: 100%;
  width: 100%;
  /*z-index: 100;*/
}
.recent_wrapper_list {
  padding-top: 2px;
  height: 100%;
  min-width: 982px;
  max-width: 1160px;
  margin: 0 auto;
}
.recent_grid_row_container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 26px;
  width: calc(100% + 5px);
  margin: 0 0 40px 0;
  /*z-index: 100;*/
  scroll-snap-type: inline;
  scroll-behavior: smooth;
  gap: 40px;
}

  @media (min-width: 900px) {
    .recent_grid_row_container {
      width: 508px;
      margin: 0 auto;
    }
    .recent_grid_row_container.smartfiles_tab {
      width: 508px;
      margin: 0 auto;
    }
  }
  @media (min-width: 991.8px) {
    .recent_grid_row_container {
      width: 593px;
    }
  }
  @media (min-width: 1024px) {
    .recent_grid_row_container.smartfiles_tab {
      width: 688px;
    }
  }
  @media (min-width: 1199.8px) {
    .recent_grid_row_container {
      width: 802px;
    }
  }
  @media (min-width: 1300px) {
    .recent_grid_row_container.smartfiles_tab {
      width: 871px;
    }
  } 
  @media (min-width: 1430px) {
    .recent_grid_row_container {
      width: 1011px;
    }
    .recent_grid_row_container.smartfiles_tab {
      width: 1054px;
    }
  }
  @media (min-width: 1600px) {
    .recent_grid_row_container {
      width: 1222px;
    }
    .recent_grid_row_container.smartfiles_tab {
      width: 1240px;
    }
  }
  @media (min-width: 1850px) {
    .recent_grid_row_container.smartfiles_tab {
      width: 1417px;
    }
  }
  @media (min-width: 1920px) {
    .recent_grid_row_container {
      width: 1434px;
    }
  }
  @media (min-width: 2050px) {
    .recent_grid_row_container.smartfiles_tab {
      width: 1600px;
    }
  }
.sideBarLeftSpacing {
  padding-left: 16px;
  margin: 0 0 20px 0;
}

.sideBarRecentFirst {
  padding-top: 16px;
}

.recent_list_row_container.recent_list_row_container {
  overflow-x: visible;
  margin: 0 0 40px -5px;
}
.row_grid_spacing {
  padding-left: 0;
}
.row_list_spacing {
  padding-left: 0;
}
.isSideBar {
  position: relative;
  height: calc(100vh - 166px);
}
.title_grid_spacing {
}
.title_list_spacing.title_list_spacing {
  margin: 24px 0 16px 44px;
}
.recent_title_wrapper {
  margin: 0 0 24px 0;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #596080;
}

.recent_list_view_block {
  display: block;
}
.fullParentWidth {
  width: 100%;
}

.emptyRecent {
  display: flex;
  flex-direction: column;
  margin: 35vh auto 0 auto;
  align-items: center;
}
.emptyRecent img {
  width: 48px;
  height: 58.67px;
}

.emptyRecent_mainText {
  margin-top: 18px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #242633;
  text-align: center;
}

.emptyRecent_infoText {
  margin-top: 8px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  text-align: center;
}

.emptyNoResults {
  display: flex;
  flex-direction: column;
  margin: 25vh auto 0 auto;
  align-items: center;
}
.emptyNoResults img {
  width: 64px;
  height: 64px;
}

.emptyNoResults_mainText {
  margin-top: 23px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #242633;
  text-align: center;
}

.emptyNoResults_infoText {
  margin-top: 8px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  text-align: center;
}

.emptyNoResults_sideBar {
  margin: 4.6vh auto 0 auto;
}

.add_page_wrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 22px;
}
.add_page_wrapper.component {
  margin-right: 13px;
}
.add_page_wrapper.pages {
  margin-right: 19px;
}
.add_page_wrapper.isSideBarNew {
  margin-right: 10px;
}

.add_page_wrapper svg {
  /*height: 100%;*/
  /*width: 100%;*/
}
.add_page_wrapper:hover svg,
.add_page_wrapper:hover svg rect {
  /*fill: #E4E7F7;*/
}

.upload_library_placeholder {
  height: 162px;
  background: #ffffff;
  border: 1px solid #d7ddf7;
  border-radius: 8px;
  width: 160px;
  margin-right: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  cursor: pointer;
}
.upload_library_placeholder:hover {
  background-color: #e8ebfa;
}
.upload_inner_container {
  width: 82px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.upload_text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #596080;
  white-space: nowrap;
}
