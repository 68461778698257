.button {
  font-family: 'RobotoFlex', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  height: 36px;
  gap: 10px;
  border-radius: 6px;
  cursor: pointer;

  & .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  &.grey {
    border: 1px solid #d7ddf7;
    margin-right: 17px;
    & .text {
      color: #0026e5;
    }
    &:hover {
      background-color: #e8ebfa;
      border-color: #e8ebfa;
    }
    &.active {
      background-color: #e8ebfa;
      border-color: #e8ebfa;
    }
  }
  &.blue {
    background: #0026e5;
    border: 1px solid #0026e5;
    & .text {
      color: #fcfdff;
    }
    &:hover {
      background-color: #021a9f;
    }
  }
  &.primaryGrey {
    margin-left: 4px;
    height: 32px;
    background-color: #534B4F;
    border: 1px solid #534B4F;

    &:last-child {
        margin: 0;
    }

    &:hover {
      background-color: #6b6767;
    }

    & .text {
        color: #FFFFFF;
      }
  }

  &.secondaryGrey {
    background-color: #fff;
    border: 1px solid #534B4F;
    height: 32px;
    
    &:hover {
      border-color: #6b6767;
      background-color: #fff;
    }

    & .text {
      color: #534B4F;
      &:hover {
        color: #6b6767;
      }
    }
  }
  
  &.disabled{
    cursor:not-allowed;
    border: 0.6px solid #484343;
    opacity: 0.25;
    background: #534B4F;

    &:hover {
      background: #534B4F;
      border: 0.6px solid #484343;
    }

  };
}
