.input_title {
  height: auto;
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  padding: 0;
  margin-bottom: 13px;
  resize: none;
  min-height: 40px;
  border: none;
  background: none;
  line-height: 58px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  color: #343434;

  &::placeholder {
    padding-top: 15px;
    font-style: normal;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 36px;
    line-height: 40px;
    color: #343434;
  }
}

.page {
  transition: transform 0.5s ease;
  /*transform: scale(1) ;*/
  transform-origin: top left;
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.info_wrapper_small {
  position: relative;
  pointer-events: none;
  display: block;
}

.small_page .space_separator3 {
  height: 100px;
}

.noSelect {
  & ::selection {
    user-select: none;
    background: none;
  }
}

.small_page {
  transform: scale(0.5);
  /*position: absolute;*/
  /*top: 0;*/
  transform-origin: top left;
  width: 400px;
}

.input_title::placeholder {
  color: #939393;
}

.testStart.testStart {
  background-color: red;
}

.testEnd.testEnd {
  background-color: blue;
}

.has {
  background-color: salmon;
}

.counter {
  opacity: 0;
  position: absolute;
  right: -28px;
  top: 30px;
  font-size: 12px;
  font-family: "RobotoFlex", sans-serif;
}

.counter_shell:focus-within .counter {
  opacity: 1;
}

.counter_grey.counter_grey {
  color: #a3aacc;
}

.counter_red {
  color: #c21c44;
}

.counter_yellow {
  color: #ed9716;
}

.counter_visible {
  opacity: 1;
}

.description {
  display: block;
  width: 100%;
  padding: 8px 0 1px 0;
  margin-bottom: 5px;
  max-width: 100%;
  min-width: 100%;
  resize: none;
  border: none;
  overflow: auto;
  height: auto;
  font-size: 14px;
  color: #242633;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  line-height: 21px;
}

.description::placeholder {
  color: #a3aacc;
}

.display_none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.edit_content {
  /*padding-left: 5px;*/
  max-width: 970px;
  margin: 0 auto;
}

.chevron {
  padding-left: 15px;
}

.wrapper {
  min-height: calc(100vh - 130px);
  font-size: 12px;
  padding-top: 24px;
  display: block;
  transition: transform 0.5s ease;
}

.wrapper_small {
  min-height: calc(100vh);
  /*font-size: 12px*/
  height: 0;
  padding-bottom: 50%;
}

.info_block_add {
  display: flex;
  margin-right: 13px;
  padding: 6px 8px 6px 0;
  color: #596080;
}

.info_block_add:hover {
  background: #e8ebfa;
  border-radius: 18px;
}

.info_block_hide {
  display: flex;
  cursor: pointer;
  padding: 6px 8px 6px 0;
  color: #596080;
}

.info_block_hide div {
  /*padding-right: 8px;*/
}

.info_block_hide:hover {
  background: #e8ebfa;
  border-radius: 18px;
}

.info_block_add img {
  padding: 0 8px 0;
}

.info_block_hide img {
  padding: 0 8px 0;
}

.info_block {
  opacity: 0;
  display: flex;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.info_wrapper_small.info_wrapper_small .info_block.info_block {
  opacity: 0;
  cursor: default;
}

.info {
  max-width: 1000px;
  margin: 0 auto 0 auto;
  position: relative;
  padding-left: 124px;
}

.info_wrapper {
  width: 100%;
  display: block;
}

.info_wrapper:hover .info_block {
  opacity: 1;
}

.selection_border {
  background: rgba(0, 162, 255, 0.4);
  position: fixed;
  z-index: 99;
}

.input_description {
  border: none;
  background: none;
  width: 100%;
  display: block;
  resize: none;
  padding: 35px 0 0 0;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-size: 20px;
}

.add_image {
  opacity: 0;
  display: flex;
  width: fit-content;
  cursor: pointer;
}

.add_image:hover {
  background: #b5b6ba;
}

.add_image__text {
  padding-left: 5px;
}

.add_image__icon {
  width: 15px;
}

.title:hover .add_image {
  opacity: 0.2;
}

.image {
  width: 100%;
}

.provisional_button {
  margin-top: 100px;
}

.add_block {
  margin-top: 24px;
  display: flex;
  line-height: 24px;
  color: #0026e5;
  font-size: 12px;
  width: 114px;
  height: 32px;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-left: 160px;
}

.marginCompensation {
  margin-top: 4px;
}

.space_separator3 {
  height: 230px;
  width: 100px;
}

.add_block:hover {
  background: #e8ebfa;
  border-radius: 18px;
}

.add_block__on_end {
  margin-top: 10px;
}

.add_block_icon {
  padding: 8px;
}

.wrapper_add_block {
  width: 880px;
  position: relative;
}

.center_add_block {
  left: -51px;
}

.dragHandler {
  position: absolute;
  width: 1000px;
  height: 50px;
  /*padding-left: 180px;*/
  z-index: 100;
}

.dragHandler_last {
  position: absolute;
  width: 1000px;
  height: 40px;
  padding-left: 160px;
  z-index: 100;
}

.dragLine {
  margin-top: 10px;
  position: absolute;
  background: #8697f0;
  width: 694px;
  height: 4px;
  border-radius: 36px;
  z-index: 100;
  margin-left: 160px;
}

.dragLine_wrap {
  /*position: relative;*/
  position: sticky;
  height: 0;
}

.dragLine_wrap.first .dragLineSvg {
  margin-left: -9px;
}

.dragLine_wrap.last .dragLineSvg {
  margin-left: 42px;
}

.dragLineSvg {
  position: absolute;
  top: -10px;
  width: 743px;
  margin-left: 41px;
  left: 90px;
}

.dragLineSvg_isLargeRightBar.dragLineSvg_isLargeRightBar {
  left: 0;
  top: -25px;
}

.dragLineLast_isLargeRightBar {
  position: absolute;
  z-index: 1000;
  width: 743px;
  margin-left: 41px;
}

.dragLineLast {
  position: relative;
  width: 694px;
  height: 4px;
  border-radius: 36px;
  z-index: 100;
  margin-left: 160px;
  background: #8697f0;
}

.dragLineLast_hidden {
  position: relative;
  width: 694px;
  height: 4px;
  background: #fff;
  z-index: -1;
}

.dragLine_exist {
  position: absolute;
  width: 694px;
  height: 4px;
  border-radius: 36px;
  z-index: 100;
  margin-left: 160px;
}

.dragLine_new {
  background: #8697f0;
}

.dragHandler_wrapper {
  position: relative;
  top: -10px;
  z-index: 100;
}

.dashed {
  position: absolute;
  top: 0;
  left: 156px;
  right: 20px;
  bottom: 0;
  z-index: -1;
}

.dashed_first.dashed_first:after {
  width: 701px;
  right: 3px;
  content: "";
  border-top: 1px dashed #596080;
  border-left: 1px dashed #596080;
  border-right: 1px dashed #596080;
  border-radius: 5px 5px 0 0;
  display: block;
  position: relative;
  height: 100%;
  top: 0;
}

.dashed_last.dashed_last.dashed_first.dashed_first:after {
  border-radius: 5px;
}

.dashed_last.dashed_last:after {
  width: 701px;
  right: 3px;
  content: "";
  border-bottom: 1px dashed #596080;
  border-left: 1px dashed #596080;
  border-right: 1px dashed #596080;
  border-radius: 0 0 5px 5px;
  display: block;
  position: relative;
  height: 100%;
  top: 0;
}

.dashed_middle:after {
  width: 701px;
  right: 3px;
  content: "";
  border-left: 1px dashed #596080;
  border-right: 1px dashed #596080;
  border-radius: 0;
  display: block;
  position: relative;
  height: 100%;
  top: 0;
}

.relative {
  position: relative;
}

.center_valuable_content {
  left: -50px;
}

.center_info {
  margin: 0;
  padding: 0 0 0 40px;
  min-width: 708px;
}

.warehouse_block {
  position: absolute;
  top: 0;
  left: 150px;
  right: 20px;
  bottom: 0;
  z-index: -1;
}

.green {
  background: green;
}

.createComponentButton {
  position: absolute;
  right: -67px;
  bottom: 3px;
  user-select: none;
  cursor: default;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: -20;
}

.createComponentButton.show {
  cursor: pointer;
  opacity: 1;
  z-index: auto;
}

.editComponentWrapper {
}

.editComponentButton {
  position: absolute;
  right: -68px;
  bottom: 3px;
  user-select: none;
  cursor: default;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.usedInPage {
  position: absolute;
  right: 120px;
  top: 3px;
  user-select: none;
  cursor: default;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.relative:hover .usedInPage {
  cursor: pointer;
  opacity: 1;
}

.usedInPage:hover {
  cursor: pointer;
  opacity: 1;
}

.relative:hover .editComponentButton.show {
  cursor: pointer;
  opacity: 1;
}
