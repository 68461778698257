.notistack-SnackbarContainer.notistack-SnackbarContainer {
  width: 300px;
  div {
    width: 100%;
  }
}

.notistack-MuiContent span {
  word-break: break-word;
}

.notistack-MuiContent.notistack-MuiContent {
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  padding: 0 14px 0 8px;
  background: #fff;
  border-radius: 6px;
  color: #242633;
  line-height: 16px;
  z-index: 1500;
  letter-spacing: 0.02px;
  div:first-child {
    padding: 0 43px 0px 24px;
  }
  div:first-child.informer_loader {
    padding: 0 ;
    span {
      padding-left: 43px;
    }
  }
}

.notistack-MuiContent-success.notistack-MuiContent-success {
  border: 1px solid #247728;
  background: #2e7d32;
  color: #ffffff;
  box-shadow: 0 1px 4px rgba(52, 52, 52, 0.25);
  border-radius: 8px;
  svg path {
    stroke: #2e7d32;
    fill: white;
  }
  .informer__close:hover {
    background-color: #1a6d1e;
    border-radius: 12px;
  }
}

.notistack-MuiContent-error.notistack-MuiContent-error {
  background: #d32f2f;
  border: 1px solid #c20f0f;
  box-shadow: 0 1px 4px rgba(52, 52, 52, 0.25);
  border-radius: 8px;
  color: white;
  svg path {
    stroke: #d32f2f;
    fill: white;
  }
  .informer__close:hover {
    background-color: #cb4848;
    border-radius: 12px;
  }
}

.notistack-MuiContent-warning.notistack-MuiContent-warning.notistack-MuiContent-warning {
  background: #d99517;
  border: 1px solid #a46c04;
  box-shadow: 0 1px 4px rgba(52, 52, 52, 0.25);
  border-radius: 8px;
  color: white;
  svg path {
    stroke: #eaa371;
    fill: white;
  }
  .informer__close:hover {
    background-color: #a63232;
    border-radius: 12px;
  }
}
.notistack-MuiContent-default.notistack-MuiContent-default.notistack-MuiContent-default {
  background: #0288d1;
  border: 1px solid #007cbf;
  box-shadow: 0 1px 4px rgba(52, 52, 52, 0.25);
  border-radius: 8px;
  color: white;
  svg path {
    stroke: #0288d1;
    fill: white;
  }
  .informer__close:hover {
    background-color: #4182a6;
    border-radius: 12px;
  }
}

.informer__close.informer__close {
  width: 24px !important;
  height: 24px;
  margin-left: 28px;
  padding-top: 6px;
  display: flex;
  vertical-align: middle;
  justify-content: space-around;
  cursor: pointer;
  right: 17px;
  top: 13px;
  position: absolute;
  svg path {
    stroke: white !important;
  }
}

.informer__icon.informer__icon {
  width: 24px !important;
  height: 24px;
  position: absolute;
  left: 15px;
  top: 11px;
  padding: 0 !important;
  svg {
    width: 26px;
    height: 26px;
  }
  &.clickable{
    cursor: pointer;
  }
}

div.informer_loader.informer_loader.informer_loader.informer_loader {
  width: 22px;
  border-radius: 50%;
  height: 22px;
  margin: 60px auto;
}
.informer_loader.informer_loader.informer_loader {
  height: 22px;
  font-size: 10px;
  position: absolute;
  text-indent: -9999em;
  border-top: 2px solid #ffc72c;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  top: -47px;
  left: 18px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.notificationText {
  width: 200px;
}
