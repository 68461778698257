@import "./src/_mixins";

.no_type_element_preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  //background: #F5F6FF;
  svg {
    width: 55px;
    height: 55px;
    //opacity: 0.5;
    path {
      stroke: #ece2e2;
    }
  }
}
.preview_frame {
  width: 100%;
  height: 130px;
  overflow: hidden;
  &.for_expand_view {
    height: 100%;
  }
}
.preview_scale {
  transform: scale(0.123) translate(-343%, -350%);
  width: 640px;
  height: 1260px;
  overflow: hidden;
  pointer-events: none;

  &.for_expand_view {
    transform: scale(0.6) translate(-33%, -33%);
  }
}

.image_container {
  width: 100%;
  height: 100%;
}

.image_preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #F1F1F0;
}

.pdf_preview_container {
  transform: scale(0.58) translate(-38.5%, -40.4%);
  width: 171px;
  //height: 1260px;
  overflow: hidden;
  pointer-events: none;
}

.ms_doc_preview {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f6ff;
}

.text_limiter {
  @include ellipsis;
  max-width: 240px;
  &_error {
    color: #596080;
  }
}

.duration_container {
  display: flex;
  align-items: center;
  & svg {
    margin-right: 4px;
  }
}
.broken_image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  svg {
    //width:67px;
    //height:67px;
    //opacity: 0.5;
    //path {
    //  stroke: #ECE2E2;
    //  fill:#ECE2E2;
    //}
    rect {
      fill: transparent;
    }
  }
  &.toc_video {
    background-color: transparent;
  }
}
.video_preview {
  width: 100%;
  height: 100%;
}

.uploading_loader {
  background-color: #f2f3ff;
  position: relative;
  width: 100%;
  height: 100%;
}
.uploading_container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  & svg {
    transform: translate(-50%, -50%);
  }
}
.uploading_text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  font-size: 10px;
}
.uploading_image {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
}
.circle_cancel_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg:hover {
    & path {
      stroke: #596080;
    }
  }
}
.embed_component_preview {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: beige;
  display: flex;
  align-items: center;
  justify-content: center;
  &.embed_for_toc {
    position: relative;
  }
}
.big_icon {
  width: 24px;
  height: 24px;
}
.google_drive_icon {
  width: 32px;
  height: 32px;
}

.toc_image {
  width: 154px;
  height: 100px;
  border-radius: 12px;
}

.for_toc {
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
  max-width: 154px;
}