.wrapper {
  position: absolute;
  height: 0;
  width: 100%;
  /*display: none;*/
}

.handler .place {
  position: absolute;
  height: 4px;
  top: 8px;
  width: 254px;
  /*right: -12px;*/
  /*background: #8697F0;*/
  /*border-radius: 3px;*/
}

.handler {
  top: -17px;
  position: absolute;
  height: 64px;
  z-index: 10;
  width: 100%;
  overflow-x: hidden;
}

.smartViewShort {
  height: 30px;
  width: 30px;
  overflow: hidden;
}

.is_last {
  top: -15px;
  height: 35px;
}

.is_first {
  top: -15px;
  height: 40px;
}

.smartViewShort svg {
  width: 412px;
  height: 20px;
}
.smartViewLong svg {
  width: 740px;
}

.handler .wrapper {
  position: absolute;
  height: 10px;
  width: 100%;
  /*display: none;*/
}
