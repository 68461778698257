.label {
  color: #484343;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  margin: 9px 0 15px;
  &.marginTop {
    margin-top: 8px;
  }
}

.text {
  font-size: 14px;
  line-height: 18px;
  color: #596080;
  width: 442px;
  padding-top: 7px;
}
