.component2 {
  border-radius: 3px;
  margin-left: -3px;
  border: 1px dashed #596080;
}
.component {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 3px;
  width: 700px;
}

.wrapper_component2 {
  /*padding-left: 152px;*/
}
.component2_small {
  margin-left: -6px;
}
.separating_line {
  height: 100%;
  border-left: 1px solid #d7ddf7;
  margin-left: 11px;
}

.wrapper_component_balanser {
  /*margin-right: 110px;*/
  display: flex;

  /*max-width: 887px;*/
}

.wrapper_component:hover .add_line {
  visibility: visible;
}
.wrapper_component_in_library:hover .add_line {
  visibility: visible;
}

.add_line {
  visibility: hidden;
  position: absolute;
  left: 133px;
  right: 0;
  top: -12px;
  bottom: -12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
}
.wrapper_component_small .add_line.add_line {
  visibility: hidden;
}

.wrapper_component_small .rectangle.rectangle {
  visibility: hidden;
}

.add_line.add_line_in_library {
  left: 44px;
}
.img_wrapper:hover {
  background: #e8ebfa;
  /*opacity: 0.8;*/
}
.img_wrapper img {
  width: 16px;
  height: 16px;
}
.img_wrapper {
  padding: 4px 4px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper_component:hover .rectangle {
  opacity: 1;
}

.wrapper_component_in_library:hover .rectangle {
  opacity: 1;
}

.rectangle {
  opacity: 0;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  padding: 4px;
  position: absolute;
  left: -60px;
}

.rectangle:hover {
  background: #e8ebfa;
  opacity: 1;
}
.rectangle:hover .dots_active svg path {
  stroke: #596080;
}

.rectangle_active {
  background: #e8ebfa;
  opacity: 1;
}

.dots {
  width: 24px;
  height: 24px;
}

.dots:hover {
  opacity: 1;
}
.dots_active {
  opacity: 1;
}
.dots_active svg {
  opacity: 1;
}

.wrapper_component_small .add_line.add_line {
  visibility: hidden;
}

.wrapper_component_small .rectangle.rectangle {
  visibility: hidden;
}

.wrapper_component_small.wrapper_component_small {
  pointer-events: none;
  padding-left: 44px;
}
.wrapper_component {
  font-family: "RobotoFlex", sans-serif;
  position: relative;
  max-width: 888px;
  padding-left: 156px;
  /*padding-right: 30px;*/
}
.wrapper_component_view_mode {
  padding-left: 0px;
  margin-left: 0px;
}

.component_view_mode {
  margin-left: 0px;
  border-left: none;
}
.component_view_mode:hover {
  background-color: transparent;
}

.wrapper_component_in_library {
  font-family: "RobotoFlex", sans-serif;
  position: relative;
  max-width: 888px;
  padding-left: 69px;
  /*padding-right: 30px;*/
}

.wrapper_component_balanser {
  /*margin-right: 110px;*/
  display: flex;

  /*max-width: 887px;*/
}
.wrapper_component:hover .add_line {
  visibility: visible;
}
.wrapper_component_in_library:hover .add_line {
  visibility: visible;
}

.hiddenBlockWrapperBg {
  background: #e8ebfa;
  border-radius: 6px;
}
.hiddenWrapper {
  width: 100%;
}
.hiddenContainer.visible {
  visibility: visible;
}

.hiddenContainer:hover {
  background: #e8ebfa;
}

.hiddenContainer {
  position: absolute;
  right: -32px;
  width: 32px;
  height: 32px;
  visibility: hidden;
  background: transparent;
  cursor: pointer;
  border: 0 solid transparent;
  border-radius: 6px;
  /*top: -4px;*/
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.hiddenContainer .imageContainer svg {
  width: 20px;
  height: 20px;
}
.hiddenContainer .imageContainer svg path {
  stroke: #596080;
}

.addOpacity {
  opacity: 0.3;
  width: 100%;
}

.addOpacity.addOpacity:hover {
  opacity: 0.6;
}
