@import "./src/_mixins";

.leftMenu {
  margin-top: 21px;
  width: 271px;
  border-right: 1px solid rgba(29, 29, 29, 0.08);;
}

.views_title {
  display: flex;
  align-items: center;

  //margin-bottom: 23px;
}

.views_icon {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}
.views_text {
  color: #484343;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
}

.search {
  padding: 24px 14px 20px 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search.channels {
  padding-top: 23px;
}

.search_input_wrapper:hover {
  border: 1px solid #a3aacc;
}
.search_input_wrapper {
  height: 32px;
  width: 192px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fafbff;
  display: flex;
  align-items: center;
}

.search_input_search_icon {
  padding: 8px 7px 8px 8px;
}

.search_input {
  background: #fafbff;
  font-size: 14px;
  line-height: 21px;
  width: 129px;
  border: 0;
}
.search_input::placeholder {
  color: #a3aacc;
}

.search_input_tag {
  background: #fafbff;
  font-size: 14px;
  line-height: 21px;
  width: 169px;
  border: 0;
}
.search_input_tag::placeholder {
  color: #a3aacc;
}

.search_input_search_icon {
  padding: 8px 7px 8px 8px;
}

.clear_search {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
}

.clear_search:hover {
  background: #e8ebfa;
}

.plus_wrapper {
  /*padding-left: 15px;*/
  width: 32px;
  height: 32px;
  padding: 6px;
  cursor: pointer;
  border-radius: 4px;
}

.plus_wrapper:hover {
  /*margin-left: auto;*/
  background: #dde2f9;
}
.left_welcome_wrapper {
  padding-left: 21px;
  padding-right: 4px;
  & .text {
    width: 200px;
    font-size: 14px;
    line-height: 21px;
    color: #242633;
    @include ellipsis;
    &:hover {
      background: #dde2f9;
    }
  }
  & .number {
    width: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #a3aacc;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .row {
    position: relative;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 17px;
    &:hover {
      background: #dde2f9;
      & .itemMenu {
        background: red;
      }
    }
    border-radius: 6px;
    cursor: pointer;
    &.active {
      background: #dde2f9;
    }
  }
}
