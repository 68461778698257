/*multiSelectPanel section*/
.multiSelectPanel {
  justify-content: flex-start;
  position: absolute;
  display: flex;
  justify-content: space-around;
  height: 48px;
  width: 256px;
  padding-left: 5px;
  left: 50%;
  bottom: 28px;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background-color: rgba(83, 75, 79, 1);
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 105;
  color: #fff;
}

.libraryPanel {
  width: 324px;
}

.makerDivider{
  width: 18px;
  height: 1px;
  transform: rotate(-90deg);
  flex-shrink: 0;
  opacity: 0.2;
  background: #FFF;
  margin-top: 24px;
  margin-left: 8px;
}
.withSharedOption {
  width: 331px;
}
.makerPanel{
  width: 375px;
}
.hide_me_plx {
  opacity: 0;
}

.playlistPanel {
  justify-content: flex-start;
  width: 255px;
}

.multiSelectPanel_item {
  margin: 4px 0px;
  border-radius: 6px;
  background-color: rgba(83, 75, 79, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 41px;
}

.multiSelectPanel_item_playlist {
  justify-content: flex-start;
  padding-left: 5px;
}

.multiSelectPanel_item_add_tag {
  justify-content: flex-start;
  margin-left: 13px;
  padding-top: 2px;
}

/*.multiSelectPanel_right_border {*/
/*    border-right: 1px solid #1F2855;*/
/*}*/
/*.multiSelectPanel_left_border {*/
/*    border-left: 1px solid #1F2855;*/
/*}*/

.multiSelectPanel_active {
  /*background: #505D9F;*/
}

.multiSelectPanel_item_selected {
  justify-content: center;
  padding-left: 6px;
  border: none;
  margin-right: 0;
  cursor: default;
}

.multiSelectPanel_item.multiSelectPanel_item_selected:hover {
  background: none;
}

.multiSelectPanel_item_favorite {
  justify-content: flex-start;
  margin-left: 19px;
}

.multiSelectPanel_item_share {
  justify-content: flex-start;
  margin-left: 10px;
}
.playlistPanel .multiSelectPanel_item_share {
  padding-top: 1px;
  margin-left: 14px;
}

.multiSelectPanel_item_favorite:active .image_wrapper svg {
  width: 21px;
  height: 21px;
}

.multiSelectPanel_item_favorite_active.multiSelectPanel_item_favorite_active
  .image_wrapper
  svg.multiSel
  path {
  stroke: transparent;
  fill: #ffc72c;
}

.multiSelectPanel_item_favorite_active:active .image_wrapper svg.multiSel path {
  stroke: #fff;
  fill: none;
}

.multiSelectPanel_item_selected_text {
  font-family: RobotoFlex;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  min-width: 112px;
  margin-left: 7px;
  color: #fff;
  white-space: nowrap;
}
.playlistPanel .multiSelectPanel_item_favorite {
  margin-left: 28px;
}
.playlistPanel .multiSelectPanel_item_selected_text {
  margin-left: 5px;
}
.multiSelectPanel_item_text {
  font-weight: normal;
  white-space: nowrap;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.multiSelectPanel_item svg.multiSel path {
  stroke: #fff;
}

.multiSelectPanel_item_playlist {
  justify-content: flex-start;
  border: none;
  padding-left: 5px;
  border-radius: 6px;
  margin-left: 4px;
  padding-top: 2px;
}

.multiSelectPanel_item_trash {
  justify-content: flex-start;
  border: none;
  padding-left: 5px;
  border-radius: 6px;
  margin-left: 10px;
  padding-top: 4px;
}

.multiSelectPanel_item_trash:hover {
  /*background-color: #FFE1E8;*/
  /*color: #C21C44;*/
}

.multiSelectPanel_item_trash:hover svg.multiSel path {
  /*stroke: #C21C44;*/
}

.library_selected {
  width: 130px;
  justify-content: center;
  padding-left: 0;
  margin-right: 12px;
  margin-left: -1px;
}

.library_favorite {
  justify-content: center;
  /*width: 90px;*/
  padding-left: 0;
}

.library_privacy {
  justify-content: flex-start;
  width: 135px;
  padding-left: 9px;
}

.library_move_collection {
  justify-content: flex-start;

  padding-left: 1px;
}

.library_trash {
}

/*moveToFolderMenu section*/

.moveFolder__scroll_wrapper {
}

.ps_scrollHeight {
  max-height: 180px;
}

.moveFolder__menu {
  display: block;
  position: absolute;
  width: 240px;
  max-height: 244px;
  left: 15px;
  bottom: 40px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  z-index: 400;
  line-height: 16px;
  padding-bottom: 12px;
}

.moveFolder__search_wrapper_input {
  border: 1px solid #d7ddf7;
  color: #a3aacc;
  width: 208px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.moveFolder__search_wrapper_search_icon svg.multiSel path {
  stroke: #a3aacc;
}

.moveFolder__search_img_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-left: 2px;
  padding: 8px 4px 8px 5px;
  cursor: default;
}

.moveFolder__search_input {
  border: none;
  background: none;
  width: 100%;
  display: block;
  resize: none;
  padding: 0 0 0 0;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.moveFolder__search_input::placeholder {
  color: #a3aacc;
}

.moveFolder__input {
  border: none;
  background: none;
  width: 100%;
  display: block;
  resize: none;
  padding: 0 0 0 20px;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #111111;
}

.clear_search {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  margin-right: 7px;
  height: 24px;
  width: 24px;
}

.clear_search:hover {
  background: #e8ebfa;
}

.moveFolder__input_section {
  display: flex;
  padding: 16px 0 8px 16px;
  position: relative;
}

.moveFolder__folders_section {
  padding: 0 0 0 17px;
}

.moveFolder__folders_padding {
  padding: 5px 0 5px 17px;
}

.moveFolder__new_folder_item {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  background: #fff;
}

/*.moveFolder__new_folder_item svg path {*/
/*    stroke: #596080;*/
/*}*/

.moveFolder__hide_new_folder {
  display: none;
}

.moveFolder__new_folder_item:hover {
  background: #e8ebfa;
}

.image_wrapper {
  display: flex;
  width: 21px;
  height: 21px;
  /*margin-right: 7px;*/
  stroke: #596080;
}

.multiSelectPanel_item .image_wrapper svg.multiSel path {
  stroke: #fff;
}

.multiSelectPanel_item .image_wrapper svg g {
  opacity: 1;
}

.moveFolder__folder_item .image_wrapper svg.multiSel path {
  stroke: #596080;
}

.moveFolder__new_folder_item .image_wrapper {
  width: 15px;
  height: 15px;
  margin: 0;
}

.moveFolder__new_folder_item .image_wrapper svg.multiSel path {
  stroke: #0026e5;
}

.moveFolder__new_folder_text {
  white-space: nowrap;
  font-family: "RobotoFlex", sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  padding-left: 9px;
  color: #0026e5;
}

.moveFolder__folders_title_text {
  white-space: nowrap;
  font-family: "RobotoFlex", sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  color: #a3aacc;
}

.moveFolder__folders_found {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  line-height: 18px;
  color: #a3aacc;
  text-transform: uppercase;
  margin-top: 2px;
  padding: 0 0 0 17px;
  margin-bottom: 5px;
}

.no_found {
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  cursor: auto;
  white-space: nowrap;
  font-family: "RobotoFlex", sans-serif;
  color: #a3aacc;
}

.moveFolder__folder_item {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.moveFolder__folder_item:hover {
  background: #e8ebfa;
}

.end {
  height: 9px;
}

.moveFolder__folder_item_text {
  margin-left: 3px;
  white-space: nowrap;
  font-family: "RobotoFlex", sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #242633;
}

.moveFolder__inactive_folder {
  color: #a3aacc;
  cursor: default;
  pointer-events: none;
}

.moveFolder__inactive_folder.moveFolder__folder_item:hover {
  background: white;
}

.moveFolder__inactive_folder svg.multiSel path {
  stroke: #a2a7b2;
}

.moveFolder__count_page {
  position: absolute;
  right: 12px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  color: #a2a7b2;
  padding-top: 1px;
  padding-right: 3px;
}

.multiSelectPanel__item_disable:hover {
  background: none;
}

.multiSelectPanel__item_disable {
  cursor: no-drop;
}

.multiSelectPanel__item_disable.multiSelectPanel__item_disable.multiSelectPanel__item_disable
  div
  svg
  path {
  stroke: #a2a7b2;
}

.multiSelectPanel__item_disable.multiSelectPanel__item_disable.multiSelectPanel__item_disable
  div
  svg
  path:hover {
  stroke: #a2a7b2;
}

.image_wrapper.svg_inactive svg.multiSel path {
  stroke: #a2a7b2;
}

.multiSelectPanel__inactive {
  color: #a2a7b2;
}

svg.favorite path {
  fill: transparent;
}

svg.multiSel {
  width: 16px;
  height: 19px;
}

.multiSelectPanel_item_playlist svg.multiSel {
  width: 18px;
  height: 18px;
}

svg.move_trash {
  width: 15px;
  height: 17px;
}

.multiSelectPanel_item_trash .image_wrapper {
  /*margin-right: 3px;*/
}
.multiSelectPanel_item_add_tag svg {
  width: 18px;
  height: 19px;
}
.multiSelectPanel_item.multiSelectPanel_item:hover svg path {
  stroke: rgba(255, 199, 44, 1);
}

.multiSelectPanel_item.multiSelectPanel_item_active {
  stroke: rgba(255, 199, 44, 1);
}

.libraryPanel .multiSelectPanel_item_favorite {
  margin-left: 16px;
  padding-top: 2px;
}

.controls_wrapper {
  display: flex;
}