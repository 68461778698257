@import "./src/_mixins";

@keyframes fadeInRow {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 32px;
  }
}

.table_row,
.header_table_row {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  height: 32px;
  font-family: "RobotoFlex", sans-serif;
}
.table_row {
  padding-right: 14px;
  animation: fadeInRow 0.5s;
}
.row_justification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header_table_row {
  height: 32px;
  margin-bottom: 12px;
  .name {
    padding-left: 5px;
  }
}

.table_row:hover {
  .line {
    visibility: hidden;
  }
}

.cell {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 15%;
  &.first {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  &.last {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  position: relative;
  height: 32px;
  &.selected {
    background-color: #FFEEBF;
  }
  &.name {
    flex: 32%;
  }
  &.function {
    flex: 12%;
  }
  &.organization {
    flex: 12%;
  }
  &.invited {
    flex: 14%;
  }
  &.role {
    flex: 10%;
  }
  &.status {
    flex: 10%;
  }
  &.gear,
  &.more {
    flex: 5%;
    justify-content: flex-end;
    overflow: visible;
  }
  &.more.disabled svg * {
    stroke: lightgrey;
  }
  &.gear {
    padding-right: 10px;
  }
  &.in_body {
    & .contact_hovered_place{
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    &.name {
      flex: 32.5%;
      &:hover {
        z-index: 5;
        overflow: visible;
      }
      & .is_in_contacts{
        overflow: visible;
        max-width: 100%;
        &:hover {
          .contact_popup-wrapper {
            display: flex;
          }
        }
      }
    }
    &.function {
      flex: 12.5%;
    }
  
    &.invited {
      flex: 14.5%;
    }
  
    &.role {
      flex: 10.5%;
    }
  
    &.status {
      flex: 11%;
    }
    &.gear,
    &.more {
      flex: 3%;
    }
  }
}
.header_table_row .cell.role {
  min-width: 111px;
  margin-left: 4px;
}

.table_row .cell.role {
  min-width: 111px;
  z-index: 1;
  overflow: initial;
  &.selected {
    z-index: 2;
  }
}

.check_cell {
  width: 55px;
  visibility: hidden;
  &.selected {
    visibility: visible;
  }
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  position: relative;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_row:hover .check_cell {
  visibility: visible;
}

.favorite_cell {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.isFavorite {
    svg path {
      fill: #ff8a00;
    }
  }
}

.more_cell {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover:not(.disabled) {
    background-color: #FFDF85;
    & svg.more_svg path {
      stroke: #242633;
    }
  }
  &.selected {
    background-color: #FFDF85;
    & svg.more_svg path {
      stroke: #242633;
    }
  }
}

.table_row:hover .favorite_cell,
.table_row:hover .cell {
  background-color: #FFEEBF;
}

.name_text {
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  margin-right: 5px;
  &.name_field {
    font-weight: 500;
    max-width: 345px;
  }
  @include ellipsis; 
}

.avatar_container {
  position: relative;
  margin-right: 9px;
  margin-left: 8px;
  width: 30px;
}

.header_text {
  @include ellipsis;
}

.contact_popup-wrapper {
  display: none;
}

.popup-wrapper {
  border-radius: 12px;
  border: 0.6px solid #ECE2E2;
  background: var(--White, #FFF);
  box-shadow: 1px 1px 6px 0px rgba(122, 105, 105, 0.25);
  width: 280px;
  height: 218px;
  position: absolute;
  z-index: 3;
  left: 110px;
  top: -17px;
  padding: 16px;
  cursor: default;

  &_header {
    justify-content: left;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 34px;
  
    p {
      margin: 0 20px 0 12px;
      color: #484343;
      font-family: RobotoFlex, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      width: 158px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .username {
      margin-left: 8px;
    }
    
    &_svg-wrapper {
      border-radius: 28px;
      width: 28px;
      height: 28px;
      position: relative;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        background: #FFEEBF;
      }
    }
  }

  &_description_item,
  &_body_item {
    display: flex;
    align-items: center;

    h4 {
      color: #484343;
      font-family: RobotoFlex, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin: 0;
    }

    p {
      color: #585858;
      text-align: right;
      font-family: RobotoFlex, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      max-width: 196px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
  }

  &_description {
    margin: 19px 0 12px;

    &_item {
      margin-bottom: 7px;

      h4 {
        width: 48px;
      }

      p {
        margin: 0;
      }
    }
  }

  &_body {
    border-radius: 6px;
    background: rgba(147, 147, 147, 0.08);
    padding: 6px 10px;
    margin-top: 12px;

    &_item {
      justify-content: space-between;
      height: 19px;
      
      p {
        margin: 0;
      }
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  opacity: 0.08;
  background: #1D1D1D;
}

.contact_avatar_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    border: 1px solid white;
    border-radius: 50%;
  }
}
.contact_avatar_wrapper_placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  & svg{
    border: 1px solid white;
    border-radius: 50%;
  }
  &.is_big {
    > svg {
      width: 38px;
      height: 38px;
    }
  }
 
}
