.wrapper {
  background: #ffffff;
  border: 1px solid #d7ddf7;
  &.isExpanded {
    width: 100%;
  }
  &.isDescriptionMargin.isDescriptionMargin {
    margin: 5px 0 16px;
  }
  width: 300px;
  margin: 8px 0 8px 16px;
  border-radius: 6px;
  padding: 5px 5px 4px 8px;
  &.areaWrapper {
    max-height: 140px;
    min-height: 60px;
    overflow-x: hidden;
  }
  &.noScroll {
    overflow-y: hidden;
  }
  &:focus-within {
    border: 1px solid #8697f0;
  }
  &.readOnly {
    &:focus-within {
      border: 1px solid #d7ddf7;
    }
  }
  & textarea {
    height: auto;
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    overflow: hidden;
    padding: 0;
    margin-bottom: 13px;
    resize: none;
    min-height: 21px;
    border: none;
    background: none;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: #242633;
  }
}
textarea.in_italic {
  font-style: italic;
}
textarea.inactiveText {
  color: #596080;
  opacity: 0.8;
}
