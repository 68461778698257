@import "./src/_mixins";
.fadein_animation {
  animation: fadein_card_show 0.6s;
  -moz-animation: fadein_card_show 0.6s; /* Firefox */
  -webkit-animation: fadein_card_show 0.6s; /* Safari and Chrome */
  -o-animation: fadein_card_show 0.6s;
}

@keyframes fadein_card_show {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}
@keyframes rotating {
  //from {
  //  -ms-transform: rotate(0deg);
  //  -moz-transform: rotate(0deg);
  //  -webkit-transform: rotate(0deg);
  //  -o-transform: rotate(0deg);
  //  transform: rotate(0deg);
  //}
  //to {
  //  -ms-transform: rotate(360deg);
  //  -moz-transform: rotate(360deg);
  //  -webkit-transform: rotate(360deg);
  //  -o-transform: rotate(360deg);
  //  transform: rotate(360deg);
  //}
}
//@keyframes turn_chevron_down {
//  0% {
//    transform: rotate(0deg);
//  }
//
//  100% {
//    transform: rotate(90deg);
//  }
//}
//@keyframes turn_chevron_up {
//  0% {
//    transform: rotate(90deg);
//  }
//
//  100% {
//    transform: initial;
//  }
//}
@keyframes slide_matrix_down {
  0% {
    height: 0;
  }

  100% {
    height: 150px;
  }
}
@keyframes slide_matrix_up {
  0% {
    height: 150px;
  }

  100% {
    height: 0;
  }
}
.row_wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 48px;
  //animation: rotating 3s linear infinite;
  &.zActive {
    z-index: 3;
  }
  &.hoverZActive {
    z-index: 2;
  }
  //&:not(.header) {
  //  animation: fade_in_list 0.3s;
  //}
  &.selected {
    .row_container {
      background-color: #d7ddf7;
    }
  }
  &.hovered {
    .row_container {
      background-color: #e8ebfa;
    }
    .checkbox_container {
      visibility: visible;
    }
  }
  &:not(.header):hover {
    .row_container {
      background-color: #e8ebfa;
    }
  }
  &:hover {
    .checkbox_container {
      visibility: visible;
    }
  }
  &.header {
    min-height: 52px;
    .first_container,
    .item_container,
    .last_container {
      color: #596080;
    }
    .last_container {
      .action_icon:not(.favorite) {
        & svg.row_icon path {
          stroke: #596080;
        }
      }
    }
  }
}
@keyframes fade_in_list_Playlist {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 32px;
  }
}
@keyframes fade_in_list {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 48px;
  }
}
.checkbox_container {
  width: 32px;
  padding: 12px 6px;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  align-items: center;
  &.active {
    visibility: visible;
  }
}
.row_container {
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  border-radius: 8px;
  animation: rotating 2s linear infinite;
}
.first_container {
  margin: 12px;
  display: flex;
  align-items: center;
  .first_icon {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first_item {
    width: 200px;
    min-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.header {
      display: flex;
      align-items: center;
    }
    &.icon_placeholder {
      margin-right: 36px;
    }
    input {
      background: #ffffff;
      border: 1px solid #c7cad1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      width: 100%;
      z-index: auto;
      overflow-wrap: break-word;
      resize: none;
      height: 24px;
    }
  }
}
@keyframes slow_disable {
  80% {
    width: 0;
  }
  100% {
    display: none;
  }
}
@media (max-width: 1900px) {
  .item_container.collapsible .tags_cell,
  .item_container.collapsible .size_cell,
  .item_container.collapsible .privacy_cell,
  .item_container.collapsible .channel_cell,
  .item_container.collapsible .created_cell,
  .item_container.collapsible .type_cell {
    width: 0;
  }
}

@media (max-width: 1900px) {
  .hidden_item_container.show .created_cell,
  .hidden_item_container.show .modified_cell {
    width: 107px;
  }
}
.hidden_item_container {
  display: flex;
  align-items: center;
  .created_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    align-items: center;
    text-transform: capitalize;
    width: 0;
    white-space: nowrap;
    display: flex;
  }
  .modified_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    align-items: center;
    text-transform: capitalize;
    width: 0;
    white-space: nowrap;
    display: flex;
  }
}
.item_container {
  animation: rotating 1s linear infinite;
  display: flex;
  align-items: center;
  .type_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    width: 80px;
  }
  .channel_cell {
    &.collapsible {
      overflow: hidden;
    }
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
  }
  .dashboard_cell {
    position: relative;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 28px;
  }
  .progress_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 100px;
  }
  .availability_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 140px;
  }
  .user_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #596080;
  }
  .last_modified_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 100px;
    &.collapsible {
      overflow: hidden;
    }
  }
  .created_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 100px;
    &.collapsible {
      overflow: hidden;
    }
  }
  .comments_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 72px;
  }
  .icon {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  .created_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    align-items: center;
    text-transform: capitalize;
    width: 100px;
    white-space: nowrap;
    display: flex;
    &.collapsible {
      overflow: hidden;
    }
  }
  .modified_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    align-items: center;
    text-transform: capitalize;
    width: 100px;
    white-space: nowrap;
    display: flex;
  }

  .privacy_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }
  .size_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }
  .tags_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    width: 130px;
    display: flex;
    align-items: center;
    &.header {
    }
    .tags_container {
      display: flex;
      width: fit-content;
      overflow: hidden;
    }
    .tag_placeholder {
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      padding: 2px 8px;
      background: #e8ebfa;
      border-radius: 15px;
      margin: 2px 8px;
    }
  }
}
.last_container {
  margin: 8px 8px 8px 12px;
  display: flex;
  height: 32px;
  width: 72px;
  justify-content: flex-end;
  position: relative;
  &.collapsible {
    width: 32px;
  }
  &.collapsible .actions_cell {
    width: 32px;
  }
  &.collapsible .action_icon.favorite {
    display: none;
  }
  .icon {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  .header_actions_cell {
    display: flex;
    align-items: center;
    width: 32px;
    height: 32px;
    justify-content: center;
  }

  .actions_cell {
    display: flex;
    align-items: center;
    width: 72px;
    justify-content: space-between;
    position: relative;
  }
  .action_icon {
    border-radius: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.active:not(.favorite) {
      background-color: #d7ddf7;
    }
    &:hover {
      background-color: #d7ddf7;
    }
    &.favorite svg.row_icon path {
      stroke: #596080;
    }
    &.favorite.active {
      & svg.row_icon path {
        fill: #596080;
      }
    }
    & svg.row_icon path {
      stroke: #596080;
    }
    &.active:not(.favorite) {
      & svg.row_icon path {
        stroke: #242633;
      }
    }
    &:not(.favorite):hover {
      & svg.row_icon path {
        stroke: #242633;
      }
    }
    &.lock {
      background-color: initial;
      &:hover {
        background-color: initial;
      }
    }
    &.lock.active {
      background-color: initial;
      &:hover {
        background-color: initial;
      }
      & svg.row_icon path {
        stroke: #0026e5;
      }
    }
  }
}

.empty_more_placeholder {
  width: 32px;
}

.tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  height: 22px;
  padding: 2px 2px;
  margin: 0 4px;
  min-width: 60px;
  width: fit-content;
  max-width: 127px;
  white-space: nowrap;
}
.tagText {
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  min-width: 60px;
  width: fit-content;
  max-width: 127px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagYellow {
  background: #ffe5a0;
}
.tagGreen {
  background: #d1f3c1;
}
.tagRed {
  background: #ec1832;
}
.tagPink {
  background: #caa0ff;
}
.tagPlaceholder {
  font-size: 14px;
  line-height: 21px;
  padding: 2px 8px;
  background: #e8ebfa;
  border-radius: 15px;
  margin: 2px 8px;
}

.tagsContainer {
  display: flex;
  width: fit-content;
  overflow: hidden;
}

.tag_item_wrapper {
  margin: 0 2px;
}
.playlist_header,
.library_header {
  .first_container,
  .last_container,
  .item_container {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #596080;
  }
  .user_cell {
    overflow: initial;
    justify-content: initial;
  }
  .row_wrapper {
    height: 32px;
  }
  .checkbox_container {
    visibility: visible;
    width: 62px;
    &.no_authors {
      width: 32px;
    }
  }
  .row_container {
    width: 100%;
  }
  .first_container {
    margin-left: 58px;
    width: 210px;
    margin-right: 2px;
    padding-left: 17px;
  }
  .header_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #596080;
  }
  .last_container {
    width: 140px;
  }
}
.library_header {
  .first_container {
    padding-left: 113px;
    margin-right: 3px;
    width: 294px;
    margin-left: 0;
  }
  .last_container {
    margin-right: 7px;
    &.collapsible {
      width: 32px;
    }
  }
  & .last_modified_cell {
    width: 100px;
  }

  .header_actions_cell.collapsible {
    width: 32px;
  }
}

.playlist,
.library {
  .first_container,
  .last_container,
  .item_container {
  }
  & .last_modified_cell {
    width: 100px;
  }
  &:not(.header) {
    //animation: fade_in_list_Playlist 0.3s;
  }

  .title_text {
    padding-left: 7px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &.row_wrapper {
    height: 32px;
    margin-top: 4px;
    margin-bottom: 5px;
  }

  .row_container {
    width: 100%;
    &.isHide {
      opacity: 0.4;
    }
  }
  .progress_cell {
    justify-content: center;
  }
  .title_text {
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #242633;
  }
  .first_container {
    margin-right: 2px;
    margin-left: 0;
  }
  .last_container {
    display: flex;
    width: 140px;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    margin: 0 8px 0 12px;
    &.collapsible {
      justify-content: flex-end;
      width: 32px;
    }
  }
  .white_wrapper {
    height: 32px;
    background-color: #f6f6f5;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .row_container:hover {
    .white_wrapper {
      //background-color: #E8EBFA;
    }
  }
  .white_wrapper.hovered {
    //background-color: #E8EBFA;
  }
  .dashboard_column_item {
    width: 84px;
    height: 24px;
    background: #c9b5a6;
    border-radius: 6px;
    padding: 3px;
    z-index: 1;
    font-size: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    line-height: 18px;
    color: #596080;
  }
  .checkbox_container {
    visibility: visible;
    width: 62px;
    &.isShared {
      width: 32px;
    }
    &.no_authors {
      width: 32px;
    }
    svg {
      //animation: turn_chevron_up 0.4s;
      //width: 8px;
      //height: 8px;
      //path {
      //  stroke: #596080;
      //}
    }
    &.down svg {
      //animation: turn_chevron_down 0.4s;
      //transform: rotate(90deg);
    }

    .chevron_icon {
      margin-right: 13px;
    }
  }

  .image_wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .image {
    position: relative;
    width: 56px;
    height: 28px;
    overflow-x: hidden;
    object-fit: cover;
  }
  .image_def {
    position: relative;
    width: 56px;
    height: 28px;
    overflow-x: hidden;
  }
  .action_icon {
    overflow: visible;
    &.inactive svg path {
      stroke: lightgrey;
    }
    &.inactive:hover {
      background-color: initial;
    }
    &.isHide {
      & svg path {
        stroke: #8f8f8f;
        fill: #919191;
      }
    }
  }
}
.library,
.library_header {
  & .item_container.hide {
    display: none;
  }
}

.library .image_wrapper.not_page {
  overflow: hidden;
  width: 58px;
  height: 27px;
  display: block;
  border: 1px solid #d7ddf7;

  &.isHoverPreview {
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
  }
  &.link_type,
  &.img_type {
    position: relative;
  }
}

.first_icon_wrapper {
  width: 58px;
  height: 27px;
  overflow: hidden;
  border: 1px solid #d7ddf7;
  border-radius: 4px;
  &.isHoverPreview {
    width: 172px;
    height: 258px;
    border: 1px solid transparent;
  }
}
.library .first_icon.page {
  border-radius: 4px;
}
.image_wrapper.page.views {
  transform: scale(0.077) translate(-482%, -598%);
}
.library .image_wrapper.page,
.image_wrapper.page {
  overflow: initial;
  transform: scale(0.077) translate(12%, -598%);
  &.isHoverPreview {
    transform: scale(0.3) translate(9%, -114%);
    &.component_type {
      transform: scale(0.3) translate(9%, -106%);
    }
  }
}
.image_wrapper.page.views.isHoverPreview {
  transform: scale(0.3) translate(-116%, -114%);
}
.library .title_text {
  padding-left: 18px;
}

.preview_wrapper {
  position: relative;
  width: 640px;
  height: 1260px;
  pointer-events: none;
}
.w_title {
  overflow: hidden;
  padding-top: 7px;
  resize: none;
  min-height: 40px;
  border: none;
  background: none;
  line-height: 53px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  color: #242633;
}
.w_description {
  padding-top: 8px;
  font-size: 14px;
  color: #242633;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  line-height: 21px;
}
.space_separator2 {
  height: 31px;
}

.library .checkbox_container {
  padding: 6px 6px 6px 6px;
  width: 37px;
  padding-right: 11px;
  & svg {
    width: 20px;
    height: 20px;
    animation: none;
  }
}
.common_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #505d9f;
  @include ellipsis;
}
.avatar_img {
  width: 18px;
  height: 18px;
}
.avatar_img svg {
  width: 18px;
  height: 18px;
}
.playlist_comments_container {
  padding: 3px;
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #505d9f;
  top: 180px;
  left: 214px;
  line-height: 14px;
  overflow: hidden;
  justify-content: space-between;
}

.list_comments_container svg {
  margin-right: 8px;
}

.list_comments_container svg path {
  stroke: #505d9f;
}

.hightlight_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 34px;
  width: 100%;
  z-index: 100;
  display: none;
}

.highlighted {
  animation: toolTipFadein 1s;
  display: block;
}

@keyframes toolTipFadein {
  0%,
  100% {
    box-shadow: 0 0 0 0 rgba(13, 38, 229, 0);
  }
  50% {
    box-shadow: 0 0 5px 5px rgba(13, 38, 229, 0.2);
  }
  25%,
  75% {
    box-shadow: 0 0 7px 7px rgba(13, 38, 229, 0.3);
  }
}

.relative {
  position: relative;
}

.playlist .row_container .white_wrapper .last_container.float_right {
  justify-content: flex-end;
}
.common_text_ellipsis {
  line-height: 14px;
  height: 22px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #596080;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.row_container.selected {
  border: 1px solid blue;
  .white_wrapper {
    border-radius: 8px;
  }
}
