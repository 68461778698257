.page {
  width: 170px;
  position: relative;
  z-index: auto;
  height: 170px;
  margin-bottom: 29px;
  border-radius: 8px;
}

.page_big {
  height: 250px;
  margin-bottom: 29px;
  position: relative;
}

.page_isUPV {
  margin: 0 29px 39px 0;
}

.page:hover .preview {
  box-shadow: 1px 1px 10px rgba(122, 105, 105, 0.15);
}

@keyframes fadein_card_show {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.fadein_animation {
  animation: fadein_card_show 0.6s;
  -moz-animation: fadein_card_show 0.6s; /* Firefox */
  -webkit-animation: fadein_card_show 0.6s; /* Safari and Chrome */
  -o-animation: fadein_card_show 0.6s;
}

.page.isSideBar {
  width: 162px;
  height: 162px;
}

.page.isPlaylistBuilder {
  width: 162px;
  height: 162px;
  margin: 0 8px 20px 0;
  animation: none;
}

.page_blurred {
  opacity: 0.5;
}

.preview:hover {
  /*border: 1px solid #0D26E5;*/
}

.preview.opened {
  border: 1px solid #ffc72c;
}

.preview {
  width: 172px;
  height: 172px;
  cursor: pointer;
  overflow: hidden;
  z-index: auto;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.1);
  border-radius: 8px;
}

.previewPage {
  height: 252px;
}

.page.selected {
  /*border: 2px solid #ECE2E2;*/
}

.preview.isSideBar {
  width: 162px;
  height: 162px;
}

.preview.isPlaylistBuilder {
  width: 162px;
  height: 162px;
}

.no_preview_placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f6ff;
  border-radius: 8px;
}

.no_preview_placeholder_builder {
  width: 100%;
  height: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f6ff;
}

.preview.selected_active:hover {
  border-color: #ffc72c;
}

.selected_active {
  /*border-color: #FFC72C;*/
  border: 2px solid #ffc72c;
}

.selected_active_meta {
  border: 2px solid #ffc72c;
  box-shadow: 1px 1px 10px rgba(122, 105, 105, 0.15);
  border-radius: 8px;
}

.showSelected {
  position: absolute;
}

.page_no_download {
  width: 0;
  opacity: 0;
  margin: 0 18px 20px 0;
}

.no_download {
  display: none;
}

.pending .preview div {
  display: none;
}

.pending .preview {
  background-color: #e8ebfa;
  animation: downLoading 1s infinite ease-in-out;
}

@keyframes downLoading {
  from {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  to {
    opacity: 0.2;
  }
}

.text {
  line-height: 21px;
  padding-top: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #484343;
}

.text.isPlaylistBuilder {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #242633;
}

.textBuilderWrapper {
  height: 52px;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-left: 1px;
}

.textBuilder {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #242633;
  width: 161px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  margin-top: 8px;
  /* padding-top: 6px; */
  background: #fff;
  height: 36px;
}

.builder_title {
  width: 158px;
}

.failed_name {
  position: absolute;
  bottom: -21px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  padding-top: 1px;
  width: 147px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  overflow: hidden;
  padding-top: 7px;
  resize: none;
  min-height: 40px;
  border: none;
  background: none;
  line-height: 53px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  color: #242633;
}

.description {
  padding-top: 8px;
  font-size: 14px;
  color: #242633;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  line-height: 21px;
}

.display_none {
  display: none;
}

.preview .wrapper {
  transform: scale(0.22) translate(-172%, -175%);
  /*width: 1000px;*/
}

.hasMark {
  margin-top: 24px;
}

.wrapper {
  width: 700px;
  height: 1260px;
  overflow: hidden;
  visibility: hidden;
  animation: fadein_card_show33 0.7s;
}

.wrapper_pro {
  visibility: hidden;
  animation: css 0s 0.8s forwards;

  overflow: hidden;
  height: 240px;
  width: 160px;
}

@keyframes css {
  to {
    visibility: visible;
  }
}

.wrapper div {
  opacity: 1;
  animation: css 0s 0.4s forwards;
}

@keyframes fadein_card_show33 {
  0%,
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.space_separator2 {
  height: 31px;
}

.wrapper_component {
  padding-left: 182px;
  padding-right: 175px;
  /*padding-left: 50px;*/
  font-family: "RobotoFlex", sans-serif;
}

.page.hoverLikeEffect .actionsWrap {
  display: block;
}

.page:hover .actionsWrap {
  display: block;
}

.page:hover .selected_active_meta .actionsWrap {
  display: none;
  z-index: 1;
}

.page.hoverLikeEffect .editActionsPanel {
  visibility: visible;
}

.page:hover .editActionsPanel {
  visibility: visible;
}

.page:hover .selected_active_meta .editActionsPanel {
  visibility: hidden;
}

.page:hover .selected_active_meta .itemMenu.favoriteIcon.favorite {
  background: transparent;
}

.page:hover .itemMenu.favoriteIcon.favorite {
  background: #ffffff;
}

.loaderWrap {
  position: absolute;
  right: 42px;
  top: 38px;
}

.close_wrapper:hover {
  background: #d7ddf7;
}

.close_wrapper {
  position: absolute;
  right: -14px;
  top: 0;
  padding: 4px;
  border-radius: 4px;
  height: 27px;
  visibility: hidden;
}

.page.hoverLikeEffect .close_wrapper,
.page:hover .close_wrapper {
  visibility: visible;
}

.close_wrapper svg {
  width: 20px;
  height: 20px;
}

.circle_wrapper {
  position: absolute;
  right: 109px;
  top: 59px;
}

.close_wrapper_page_panel {
  right: 0;
  top: 0;
}

.panel_progress_container {
  overflow: visible;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel_progress_item {
  width: 24px;
  height: 24px;
}

.editActionsPanel {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 2;
  border-radius: 8px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  display: block;
}

.hidden {
  visibility: hidden;
}

.miniPage_wrapper {
  position: relative;
  z-index: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.editable {
  display: flex;
  justify-content: space-between;
}

.input {
  padding-left: 6px;
  min-height: 26px;
  background: #ffffff;
  border: 1px solid #c7cad1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 144px;
  z-index: auto;
  overflow-wrap: break-word;
  resize: none;
}

.more_active {
  background-color: #e8ebfa;
}

.menuLeftBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 24px;
  height: 24px;
  margin: 8px 8px 8px 8px;
}

.menuRightBlock {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 54px;
  height: 24px;
  margin: 7px 7px 8px 8px;
}

.itemMenu {
  position: relative;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: auto;
  width: 22px;
  height: 22px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.itemMenu svg path {
  /*stroke: #596080;*/
}

.itemMenu:hover {
  /*background-color: #E8EBFA;*/
}

.itemMenu_active {
  position: relative;
  background-color: #e8ebfa;
  visibility: visible;
  z-index: auto;
}

.hover_icon {
  width: 16px;
  height: 16px;
}

.selectedIcon {
  visibility: visible;
}

.selected_checkbox {
  background: #ffc72c;
  border-radius: 4px;
}

.hover_icon.notSelectedIcon {
  visibility: hidden;
  border: none;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.checkboxItem {
  margin: 10px;
  z-index: 2;
  top: 0;
  left: 0;
  position: absolute;
  box-shadow: initial;
}

.favorite svg path {
  stroke: none;
  fill: rgb(255, 199, 44);
}

.favorite {
  visibility: visible;
}

.favorite:hover svg path {
  stroke: #ff8a00;
}

.folderContainer {
  cursor: pointer;
  position: absolute;
  width: 170px;
  height: 166px;
  z-index: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.folderContainer.isSideBar {
  width: 162px;
  height: 162px;
}

.folderContainer.isPlaylistBuilder {
  width: 162px;
  height: 162px;
}

.used_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  position: absolute;
  width: 46px;
  height: 24px;
  right: 9px;
  top: 7px;
  background: rgba(240, 196, 76, 0.2);
  border: 1px solid #f0c44c;
  box-sizing: border-box;
  border-radius: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #242633;
}

.folderIcon {
  position: relative;
  width: 24px;
  height: 24px;
  padding: 4px;
  z-index: 1;
  align-self: flex-end;
}

.folderBuffer {
  display: flex;
  justify-content: flex-end;
}

.folderBufferFail {
  position: absolute;
  top: 5px;
  right: 20px;
}

.folderIcon svg {
  width: 100%;
  height: 100%;
}

.outerTableWrapper {
  display: flex;
  min-width: 982px;
  max-width: 100%;
  flex-wrap: wrap;
}

/* ======= Columns widths =======*/
.checkboxWidth {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}

.nameWidth {
  width: 234px;
  min-width: 100px;
  max-width: 360px;
}

.folderWidth {
  width: 100%;
  min-width: 167px;
  max-width: 217px;
}

.collectionWidth {
  width: 90px;
  max-width: 120px;
  min-width: 90px;
}

.typeWidth {
  width: auto;
  min-width: 80px;
  max-width: 80px;
}

.statusWidth {
  width: auto;
  min-width: 43px;
  max-width: 43px;
}

.tagsWidth {
  width: auto;
  min-width: 203px;
  max-width: 203px;
}

.privacyWidth {
  width: 100%;
  min-width: 80px;
  max-width: 110px;
}

.sizeWidth {
  width: 100%;
  min-width: 100px;
  max-width: 100px;
}

.modifiedWidth {
  width: auto;
  min-width: 105px;
}

.createdWidth {
  width: auto;
  min-width: 105px;
}

.iconsWidth {
  width: auto;
  min-width: 185px;
  max-width: 185px;
}

.iconsWidth svg path {
  stroke: #596080;
}

/*=======*/

.tableBodySection {
  width: 100%;
  display: block;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.tableBodyRow {
  animation: fadein_row_show 0.5s;
  -moz-animation: fadein_row_show 0.5s; /* Firefox */
  -webkit-animation: fadein_row_show 0.5s; /* Safari and Chrome */
  -o-animation: fadein_row_show 0.5s;

  display: flex;
  width: 100%;
  height: 48px;
}

@keyframes fadein_row_show {
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 48px;
  }
}

@-moz-keyframes fadein_row_show {
  /* Firefox */
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 48px;
  }
}

@-webkit-keyframes fadein_row_show {
  /* Safari and Chrome */
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 48px;
  }
}

@-o-keyframes fadein_row_show {
  /* Opera */
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 48px;
  }
}

.tableBodyRowCheckbox {
  display: flex;
  align-items: center;
  width: 20px;
  height: 48px;
  margin-right: 11px;
  cursor: pointer;
  visibility: hidden;
}

.tableBodyRowCheckbox_active {
  visibility: visible;
}

.tableBodyRowContent {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  height: 48px;
  cursor: pointer;
  width: calc(100% - 28px);
}

.tableBodyRow:hover .tableBodyRowSettingsImage {
  visibility: visible;
}

.tableBodyRow:hover .tableBodyRowFavImage {
  visibility: visible;
}

.tableBodyRow:hover .tableBodyRowCheckbox {
  visibility: visible;
}

.tableBodyRow:hover .tableBodyRowContent {
  background: #e8ebfa;
}

.tableBodyRowContent:hover .tableBodyRowFavImage {
  visibility: visible;
}

.tableBodyRowContent:active {
  background: #d7ddf7;
}

.tableBodyIconsSettings {
  width: 50px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableBodyIconsFavorite {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableBodyRowContentActive {
  background: #d7ddf7;
}

.tableBodyCell {
  display: flex;
  align-items: center;
  height: 48px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
}

.tableBodyCell:last-child {
  margin-right: 0;
}

.tableBodyTagsCell {
  display: flex;
  justify-content: center;
}

.tableBodyRowCellName {
  display: flex;
  justify-content: center;
}

.tableBodyPrivacyCell {
  display: flex;
  align-items: center;
}

.tableBodySizeCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableBodySettingsCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tableBodySettingsCellActive.tableBodySettingsCellActive {
  visibility: visible;
}

.tableBodyStatusCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableBodyNameCell {
  display: inline-block;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 90%;
  max-width: 220px;
  min-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableBodyFolderCell {
  width: 100%;
  max-width: 120px;
  min-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 2px;
}

.tableBodyNameMiniPage {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 4px;
  margin: 0 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableBodyRowSettingsImage {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: #e8ebfa;
  border-radius: 6px;
  position: relative;
  display: flex;
  visibility: hidden;
}

.tableBodyRowSettingsImage:hover {
  background: #d7ddf7;
}

.tableBodyFavoriteCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableBodyRowFavImage {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;

  cursor: pointer;
  display: flex;
  visibility: hidden;
}

.tableBodyRowFavImage:hover {
  background: #d7ddf7;
}

.tableBodyFavImageActive {
  visibility: visible;
}

.tableBodyFavImageActive svg path {
  stroke: #ff8a00;
  fill: #ff8a00;
}

.input {
  padding-left: 6px;
  min-height: 26px;
  background: #ffffff;
  border: 1px solid #c7cad1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 147px;
  z-index: auto;
  overflow-wrap: break-word;
  resize: none;
}

.tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  height: 22px;
  padding: 2px 2px;
  margin: 0 4px;
  min-width: 60px;
  width: fit-content;
  max-width: 127px;
  white-space: nowrap;
}

.tagText {
  text-align: center;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  min-width: 60px;
  width: fit-content;
  max-width: 127px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagYellow {
  background: #ffe5a0;
}

.tagGreen {
  background: #d1f3c1;
}

.tagRed {
  background: #ec1832;
}

.tagPink {
  background: #caa0ff;
}

.tagPlaceholder {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  padding: 2px 8px;
  background: #e8ebfa;
  border-radius: 15px;
  margin: 2px 8px;
}

.tagsContainer {
  display: flex;
  width: fit-content;
  overflow: hidden;
}

.typeIconContainer {
  width: 100%;
}

.typeIconContainer.isPlaylistBuilder svg {
  margin-left: 15px;
}

.doc_tag {
  background: #4c58c2;
}

.link_tag {
  background: #2f71f4;
  height: 16px;
  display: flex;
  justify-content: center;
}

.doc_tag:after {
  content: "doc";
}

.video_tag {
  background: black;
}

.video_tag:after {
  content: "video";
}

.xls_tag {
  background: #2cb56a;
}

.xls_tag:after {
  content: "xls";
}

.pdf_tag {
  background: #cd574d;
}

.pdf_tag:after {
  content: "pdf";
}

.comp_tag {
  background: #0a9531;
}

.comp_tag:after {
  content: "component";
}

.img_tag {
  background: #cd574d;
}

.img_tag:after {
  content: "image";
}

.sideBar_loaded_component {
  background: #f5f6ff;
  position: relative;
  height: 100%;
}

.upload_preview {
  /*position: absolute;*/
  /*top: 76px;*/
  /*left: 26px;*/
  display: none;
}

.sideBar_loaded_component svg path {
  stroke: #596080;
}

.pending_color svg path {
  stroke: #a3aacc;
}

.sideBar_container.page {
  margin: 0 9px 39px 0;
  width: 162px;
  height: 162px;
  animation: fadein_card_show_side_bar 0.5s;
  -moz-animation: fadein_card_show_side_bar 0.5s; /* Firefox */
  -webkit-animation: fadein_card_show_side_bar 0.5s; /* Safari and Chrome */
  -o-animation: fadein_card_show_side_bar 0.5s;
}

.sideBar_container.page.isPlaylistBuilder {
  width: 162px;
  height: 162px;
}

@keyframes fadein_card_show_side_bar {
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 36px;
    opacity: 0.1;
  }

  60% {
    width: 72px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 139px;
  }
}

@-moz-keyframes fadein_card_show_side_bar {
  /* Firefox */
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 36px;
    opacity: 0.1;
  }

  60% {
    width: 72px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 139px;
  }
}

@-webkit-keyframes fadein_card_show_side_bar {
  /* Safari and Chrome */
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 36px;
    opacity: 0.1;
  }

  60% {
    width: 72px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 139px;
  }
}

@-o-keyframes fadein_card_show_side_bar {
  /* Opera */
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 36px;
    opacity: 0.1;
  }

  60% {
    width: 72px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 139px;
  }
}

.sideBar_container .preview {
  width: 162px;
  height: 162px;
}

.sideBar_container .preview.isPlaylistBuilder {
  width: 162px;
  height: 162px;
}

.sideBar_container .folderContainer {
  width: 160px;
  height: 158px;
}

.sideBar_container .folderContainer.isPlaylistBuilder {
  width: 160px;
  height: 158px;
}

.sideBar_container .editActionsPanel {
  width: 160px;
  height: 160px;
}

.image_preview {
  position: absolute;
  top: 0;
  width: 170px;
}

.image_preview_correction_meta {
  width: 168px;
}

.pdf_preview {
  width: 170px;
  height: 100%;
  overflow: hidden;
  max-height: 249px;
}

.become_tiny {
  width: 168.5px !important;
}

.image_preview img {
  top: 0;
  left: 0;
  border-radius: 4px;
  position: absolute;
  height: 100%;
  width: 100%;
}

.image_preview.isPlaylistBuilder img {
  top: 0;
  left: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  height: 160px;
  width: 160px;
}

.audio_preview {
  position: absolute;
  top: 1px;
  left: 0;
  height: 100%;
  width: 100%;
}

.broken_image_container {
  width: 100%;
  height: 168px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.broken_image_container svg {
  z-index: 1;
  width: 44px;
  height: auto;
}

.broken_image_container svg path {
  fill: white;
  stroke: #ece2e2;
}

.page:hover .broken_image_container svg path {
  fill: #ffeebf;
  stroke: #ffc72c;
}

.page .selected_active_meta .broken_image_container svg path {
  fill: #ffeebf;
  stroke: #ffc72c;
}

.broken_image_container .icon_background {
  background: #596080;
  border-radius: 5px;
  width: 32px;
  height: 32px;
  position: absolute;
}

.broken_image_container_metaData_sideBar {
  width: 100%;
  height: 172px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f6ff;
}

.broken_image_container_sideBar {
  width: 160px;
  height: 160px;
}

.broken_image_container_sideBar_playlist {
  width: 160px;
  height: 160px;
}

.broken_image_container_listView {
  width: 56px;
  height: 26px;
}

.broken_image_container_listView svg {
  width: 16px;
  height: 16px;
}

.broken_image_container_isHoverPreview {
  width: 100%;
  height: 100%;
}

.broken_image_container_isHoverPreview svg {
  width: 32px;
  height: 32px;
}

.broken_image_container_isPagePdf {
}

.circle_loader_wrapper {
  z-index: 15;
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.circle_loader {
  position: relative;
  width: 60px;
  height: 60px;
  background: #f5f6ff;
  text-indent: -9999em;
  border-radius: 50%;
  border-top: 1px solid #0026e5;
  border-right: 1px solid #0026e5;
  border-bottom: 1px solid #d7ddf7;
  border-left: 1px solid #0026e5;
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite ease;
  animation: load8 1.1s infinite ease;
}

.circle_loader_text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  font-size: 10px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    /*border-radius: 50%;*/
  }
  25% {
    /*-webkit-transform: rotate(180deg);*/
    /*transform: rotate(180deg);*/
    /*border-radius: 20%;*/
  }
  50% {
    /*-webkit-transform: rotate(60deg);*/
    /*transform: rotate(60deg);*/
    /*border-radius: 50%;*/
  }
  75% {
    /*-webkit-transform: rotate(50deg);*/
    /*transform: rotate(50deg);*/
    /*border-radius: 20%;*/
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    /*border-radius: 50%;*/
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    /*border-radius: 50%;*/
  }
  25% {
    /*-webkit-transform: rotate(180deg);*/
    /*transform: rotate(180deg);*/
    /*border-radius: 20%;*/
  }
  50% {
    /*-webkit-transform: rotate(60deg);*/
    /*transform: rotate(60deg);*/
    /*border-radius: 50%;*/
  }
  75% {
    /*-webkit-transform: rotate(50deg);*/
    /*transform: rotate(50deg);*/
    /*border-radius: 20%;*/
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    /*border-radius: 50%;*/
  }
}

.success_upload_container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.success_upload {
  z-index: 20;
  background: none;
  border-radius: 50%;
  padding: 17px;
  width: 56px;
  height: 56px;
  background: #fff;
  visibility: hidden;
  transition: visibility 1s linear;
}

.show {
  visibility: visible;
}

.success_upload svg {
  width: 24px;
  height: 24px;
}

.fail_upload {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 17px 17px 17px;
}

.fail_upload .text {
  margin-top: 4px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  width: 92px;
  height: 36px;
  line-height: 18px;
  text-align: center;
  color: #242633;
  white-space: normal;
}

.fail_upload svg {
  width: 20px;
  height: 20px;
}

.fail_upload .close_wrapper {
  height: 27px;
  cursor: pointer;
}

.fail_upload .error svg path {
  stroke: #b30e00;
}

.image_container {
  width: 170px;
  height: 170px;
  left: -7px;
  top: -7px;
  position: absolute;
  overflow: hidden;
}

.image_container_components {
  /*top: -1px;*/
  width: 171px;
  height: 172px;
  overflow: hidden;
  border-radius: 8px;
  display: grid;
  align-items: center;
}

.image_container_components_pdfAsPage {
  /* width: 172px;
    height: 256px; */

  width: 168px;
  height: 248px;
}

.image_container_components_pdfAsPage_for_description {
  margin: 0 auto;
  width: 169px;
  border: 1px solid #d7ddf7;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  height: 256px;
}

.image_container_components_pdfAsPage_sideBar {
  width: 160px;
  height: 233px;
}

.image_container_components.isSideBar {
  width: 160px;
  height: 160px;
  left: 0;
  top: 0;
}

.image_container_140 {
  width: 140px;
  height: 140px;
  position: absolute;
  overflow: hidden;
}

.image_container_450 {
  width: 451px;
  height: 284px;
  border-radius: 8px;
}

.image_container_parent_width {
  width: 100%;
  height: 284px;
  border-radius: 8px;
}

.image_container_161 {
  position: absolute;
  width: 160px;
  border-radius: 8px;
}

.image_container_161_pdf {
  top: 7px;
  position: absolute;
  width: 160px;
  border-radius: 8px;
}

.image_container_170 {
  width: 170px;
  height: 170px;
  position: absolute;
  overflow: hidden;
  border-radius: 8px;
}

.image_container_canvas {
  width: 164px;
  height: 114px;
  border-radius: 8px;
}

.image_container_canvas_image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  position: relative;
}

.image_container_ListViewLibrary {
  width: 100%;
  height: auto;
  border-radius: 8px;
  position: relative;
}

.image_container_ListView {
  width: 30px;
  height: 30px;
}

.image_container_ListViewPreview {
  width: 100%;
  height: auto;
}

.image_container_pdf {
  height: auto;
  min-height: 100px;
}

.image_container_isHoverPreview {
  display: flex;
}

.image_450.image_450 {
  width: 500px;
  height: 281px;
  object-fit: contain;
  padding-left: 9px;
}

.image_centered.image_centered {
  width: 100%;
  height: 281px;
  object-fit: contain;
  padding-left: 9px;
}

.prev_image {
  position: absolute;
  height: 137px;
  width: 145px;
  object-fit: scale-down;
  overflow-x: hidden;
}

.prev_image_canvas {
  top: initial;
  left: initial;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  border-radius: 8px 0 0 8px;
  object-fit: cover;
}

.prev_image_canvas_element {
  height: auto;
  width: 100%;
  object-fit: initial;
  overflow-x: hidden;
  position: relative;
  left: initial;
  border-radius: 8px;
  top: initial;
}

.image_ListViewLibrary {
  left: 0;
  top: 0;
  position: absolute;
  width: 58px;
  object-fit: scale-down;
  overflow-x: hidden;
  height: auto;
}

.isHoverPreview {
  position: relative;
  left: 0;
  top: 0;
}

.isImgPreview {
  width: 82px;
  height: 83px;
  top: 28px;
}

.isLibDescription {
  object-fit: unset;
  width: 170px;
  height: 170px;
  top: 0;
  position: relative;
}

.prev_image_pdf {
  position: absolute;
  width: 145px;
  height: 137px;
}

.image_container_450 .prev_image_pdf {
  width: 451px;
  height: 254px;
}

.google_embed {
  position: absolute;
  overflow: hidden;
  height: 168px;
  width: 168px;
  background-color: beige;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}

.google_embed iframe {
  transform: scale(0.24) translate(-178.4%, -166.8%);
  overflow: hidden;
  width: 1200px;
  height: 980px;
}

.google_embed_sideBar {
  height: 160px;
  width: 160px;
}

.embed_component {
  position: absolute;
  overflow: hidden;
  height: 172px;
  width: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}

.selected_active_meta .embed_component {
  height: 168px;
  width: 168px;
  top: 2px;
}

.embed_component.sideBar {
  height: 160px;
  width: 160px;
}

.embed_meta {
  position: relative;
  height: 270px;
  width: 100%;
}

.embed_component svg {
  width: 32px;
  height: 32px;
}

.sideBar_container .overlay {
  width: 162px;
  height: 162px;
}

.overlay {
  position: absolute;
  z-index: 1;
  border-radius: 7px;
  left: 0px;
  top: 0px;
  width: 172px;
  height: 171px;
}

.overlayedPage {
  height: 250px;
}

.page.hoverLikeEffect .overlay,
.page:hover .overlay {
  background-color: #ffeebf;
  opacity: 0.7;
  border: 2px solid #ffc72c;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}

.page_big .overlay {
  height: 251px;
}

.page:hover .selected_active_meta .overlay {
  border: none;
}

.page:hover .preview {
  /*border: 2px solid #FFC72C*/
}

.overlay.sideBar {
  width: 160px;
  height: 160px;
}

.actionsWrap {
  display: none;
}

.actionsWrap.active {
  display: block;
}

.actionsWrap.active .overlay {
  background: rgba(255, 238, 191, 0.65);
}

.overlay.unfavorite svg path {
  fill: transparent;
}

.usedItem {
  position: absolute;
  top: 170px;
  left: 170px;
  z-index: 10;
}

.usedItem_page {
  position: absolute;
  top: 248px;
  left: 169px;
  z-index: 40;
}

.usedItem.overlayUsed {
  z-index: 39;
}

.page:hover .usedItem.overlayUsed2 {
  z-index: 1;
}

.page:hover .selected_active_meta .usedItem.overlayUsed2 {
  visibility: hidden;
  z-index: 1;
}

.usedItem.sideBar {
  top: 162px;
  left: 161px;
}

.moreOption {
  position: absolute;
  top: 0;
  left: 127px;
  margin: 10px;
  box-shadow: initial;
}

.itemMenu.moreOption.sideBar {
  left: 120px;
}

.itemMenu.moreOption svg.hover_icon path {
  stroke: #484343;
}

.itemMenu_active.moreOption {
  position: absolute;
  background: #ffc72c;
}

.favoriteIcon.sideBar {
  bottom: -191px;
}

.itemMenu.favoriteIcon:hover svg path {
  stroke: #ffc72c;
  fill: rgba(255, 199, 44, 0.6);
}

.unfavorite.favoriteIcon svg path {
  fill: transparent;
  stroke: #484343;
}

.unfavorite.favoriteIcon:hover svg path {
  stroke: #484343;
  fill: transparent;
}

.itemMenu.checkboxItem svg path {
  stroke: #343434;
}

.itemMenu.checkboxItem.overlayUsed .hover_icon path {
  stroke: #343434;
}

.itemMenu.checkboxItem {
  box-shadow: initial;
  background-color: white;
}

.itemMenu.checkboxItem.active {
  background: #ffc72c;
  box-shadow: initial;
}

.leftOffset {
  left: 1px;
}

.favoriteIcon {
  position: absolute;
  bottom: -4px;
  left: -2px;
  margin: 10px;
  box-shadow: initial;
}

.favoriteIcon_page {
  bottom: 0px;
}

.favoriteIcon svg {
  margin-top: -1px;
}

.favoriteIcon.sideBar {
  bottom: -191px;
}

.itemMenu.favoriteIcon {
  padding-top: 2px;
}

.itemMenu.notHovered {
  padding-top: 6px;
}

.itemMenu.favoriteIcon.favorite {
  background: transparent;
}

.overlayUsedOffset {
  left: 169px;
}

.overlayOpenPage {
  position: absolute;
  right: 69px;
  top: 110px;
}

.overlayOpenComponent {
  position: absolute;
  right: 69px;
  top: 69px;
}

.label_socket {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
}

.label_socket_page {
  bottom: 10px;
}

.stand_alone_wrapper {
  transform: scale(0.225) translate(-166%, -171%);
}

.wrapper_alt {
  width: 700px;
  height: 1260px;
  overflow: hidden;
  animation: fadein_card_show33 0.7s;
  pointer-events: none;
}

.ViewPageRow {
  width: 250px;
  transform: scale(0.225) translate(-166%, -172.5%);
}

.ViewPageRowHover {
  width: 360px;
  transform: scale(0.825) translate(0%, 39.8%);
}

.ViewList {
  width: 200px;
  transform: scale(0.125) translate(-344%, -348.5%);
}

.ComponentViewListPreview {
  transform: scale(0.22) translate(-172%, -175%);
  overflow: initial;
  height: 1024px;
}

.PageViewListPreview {
  transform: scale(0.22) translate(0%, 0%);
  overflow: initial;
  height: 1024px;
}

.ForUPVPreview {
  width: 340px;
  transform: scale(0.225) translate(-166%, -172.5%);
}

.isLibDescription {
  height: 1260px;
  transform: scale(0.18) translate(-225%, -225.5%);
  width: 883px;
}

.isExpandedView {
  /*width: 586px;*/
  height: unset;
  /*max-height: 1260px;*/
}

.title_alt {
  overflow: hidden;
  padding-top: 7px;
  resize: none;
  min-height: 40px;
  border: none;
  background: none;
  line-height: 53px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  color: #242633;
  width:700px;
}

.bubble {
  height: 22px;
  width: 38px;
  background: #FB4633;
  border-radius: 10px ;
   color: white;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.tableHeadArrowActive {
  margin-left: 8px;
  visibility: visible;
}

.tableHeadArrowActive path {
  stroke: #596080;
}

.tableHeadArrow {
  visibility: hidden;
}

.tableHeadArrow path {
  stroke: #a3aacc;
}

.tableHeadCell:hover .tableHeadArrow {
  visibility: visible;
}

.shared_socket {
  position: absolute;
  top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}
