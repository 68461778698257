@import "./src/_mixins";

.wrapper {
  position: absolute;
  width: 100%;
  &.isCreateLink {
    width: 418px;
    right: -8px;
    & .container {
      width: 100%;
      max-width: none;
    }
  }
}
.container {
  position: relative;
  //min-width: 365px;
  max-width: 451px;
  background-color: white;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 1;
  padding-bottom: 8px;
  margin-top: 1px;
  &.createLibrary {
    min-width: 235px;
    max-width: 235px;
  }
}
.hint {
  user-select: none;
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  line-height: 21px;
  color: #7a696980;
  text-transform: uppercase;
  padding: 13px 9px 1px 9px;
  &.emptyHint {
    padding: 13px 9px 0 9px;
  }
}
.tags {
  background-color: white;
  max-height: 157px;
  padding-bottom: 8px;
  margin-bottom: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}
.tags::-webkit-scrollbar {
  width: 2px;
  background-color: #ffffff;
}
.tags::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 2px;
  background-color: #e2e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.tags::-webkit-scrollbar-track-piece {
  display: none;
  background-color: #ffffff;
}

.tags::-webkit-scrollbar-track {
  border-radius: 10px;
}
.create {
  margin-top: 5px;
  & p {
    margin-left: 8px;
    margin-right: 8px;
    color: #484343;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }
}
.line {
  margin: 0;
  height: 1px;
  width: 100%;
  background-color: #e8ebfa;
}
.listItem {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #ffeebf;
  }
  &:hover .item_actions {
    visibility: visible;
  }
  &.active {
    background-color: #ffeebf;
    & .item_actions {
      visibility: visible;
    }
  }
  & .clickableSave {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
  }
}
.last_drag_wrapper {
  position: relative;
}
.dropLine {
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  z-index: 0;
  background-color: #0026e5;
}
.dropZone {
  position: absolute;
  top: -16px;
  width: 100%;
  height: 32px;
  z-index: 2;
  &.first {
    top: -46px;
    width: 100%;
    height: 62px;
  }
  &.last {
    top: -16px;
    width: 100%;
    height: 64px;
  }
}

.icon_container {
  border-radius: 6px;
  margin-left: 2px;
  margin-right: 2px;
  width: 32px;
  height: 32px;
  padding: 4px 4px;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.item_tag_container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
}
.item_actions {
  position: relative;
  border-radius: 4px;
  margin-left: 2px;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  padding: 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  &:hover {
    background-color: #ffdf85;
  }
  &:hover .more > svg path {
    stroke: #596080;
  }
}
//.more

.foundHashTags {
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  padding-left: 8px;
  padding-top: 5px;
  width: 451px;
  height: 30px;
  cursor: pointer;
  & .text {
    max-width: 410px;
    @include ellipsis;
  }
  &:hover {
    background: #e8ebfa;
  }
}
