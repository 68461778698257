.drop_wrapper {
  position: absolute;
  padding: 8px 0;
  width: 240px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 30; // header usage
}
