.draggable-block-menu {
  border-radius: 4px;
  cursor: grab;
  opacity: 0;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform;
}

.draggable-block-menu .icon {
  width: 17px;
  height: 18px;
  border-radius: 4px;
  background-image: url(./draggable-block-menu.svg);
  &:hover{
    background-color: #FFEEBF;
  }
}


.draggable-block-menu:active {
  cursor: grabbing;
}

.draggable-block-menu:hover {
  background-color: #efefef;
}

.draggable-block-target-line {
  pointer-events: none;
  background: #7B00CF;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  will-change: transform;
}
