@import "./src/_mixins";

.column_header {
  margin-left: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 30px;
  border-radius: 6px;
  background-color: red;
  text-align: center;
  padding: 0 5px 0 15px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #596080;
  position: relative;
  &.isNotStatic {
    cursor: pointer;
  }
  &.notSeen:before {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    top: -4px;
    right: 7px;
    content: "";
    background-color: red;
  }
  &.stroke p {
    text-shadow:
      -0.5px -0.5px 0 #000,
      0.5px -0.5px 0 #000,
      -0.5px 0.5px 0 #000,
      0.5px 0.5px 0 #000;
  }
}
.selago {
  background-color: #f0f1fe;
}
.logan {
  background-color: #a3aacc;
}
.comet {
  background-color: #596080;
}
.charade {
  background-color: #242633;
}

.pampas {
  background-color: #f1ebe8;
}
.moon_mist {
  background-color: #e2e0d2;
}
.soft_amber {
  background-color: #d4c2b7;
}
.corvette {
  background-color: #fbd5a8;
}

.banana_mania {
  background-color: #fce7bb;
}
.skeptic {
  background-color: #c3e4d9;
}
.hawkes_blue {
  background-color: #d2e4fb;
}
.french_lilac {
  background-color: #e9cbec;
}

.vanilla_ice {
  background-color: #f5e0e9;
}
.cinderella {
  background-color: #fee1df;
}
.tan_hide {
  background-color: #fba76a;
}
.ronchi {
  background-color: #f0c44c;
}

.inputHeader {
  border: none;
  background-color: inherit;
  font-size: 16px;
}

.counterInRow {
  position: absolute;
  right: 0;
  width: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #939393;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hover_icon {
  width: 16px;
  height: 16px;
}

.itemMenu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  //z-index: 32;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  &.isHidden {
    visibility: hidden;
    &.itemMenu_active {
      visibility: visible;
    }
    &:hover {
      visibility: visible;
    }
  }
  &.active {
    background-color: #e8ebfa;
  }
}
.highlightedColumn {
}

@keyframes toolTipFadein {
  0%,
  100% {
    background-color: inherit;
  }
  50% {
    background-color: #FFEEBF;
  }
  25%,
  75% {
    background-color: #fff;
  }
}

.hoverHandler {
  margin: 0 6px;
  border-radius: 6px;
}
.hoverHandler:hover {
  & .isHidden {
    visibility: visible;
  }
  //& .counterInRow{
  //  visibility: hidden;
  //}
}
.itemMenu svg path {
  stroke: #596080;
}

.itemMenu:hover {
  background-color: #fff;
}
.itemMenu.canManageContent {
  visibility: hidden;
}
.itemMenu_active {
  position: relative;
  background-color: #e8ebfa;
  visibility: visible;
  z-index: 32;
}

.tag {
  user-select: none;
  padding: 0 8px 0 8px;
  max-width: 141px;
  width: fit-content;
  height: 24px;
  background-color: #e8ebfa;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &.ffffff {
    border: 1px solid #d7ddf7;
  }

  & .text {
    max-width: 110px;
    width: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #202124;
    @include ellipsis;
    padding: 0;
  }

  & .icon.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 3px;
    padding: 3px;
  }

  &.initial {
    background-color: white;
    border: 1px solid #d7ddf7;

    &:hover {
      background-color: #dde1f5;
    }
  }
}

.textHeader {
  max-width: 170px;
  color: #596080;
  @include ellipsis;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
}
