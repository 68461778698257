@import "../../mixins";

.side_bar_wrapper {
  width: 343px;
  background-color: #FFFFFF;

  .title {
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #242633;
    padding-left: 40px;
    padding-top: 34px;
    letter-spacing: -0.9px;
    padding-bottom: 19px;
    cursor: default;
  }
  .label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    letter-spacing: 0.09em;
    color: #242633;
    text-transform: uppercase;
    cursor: default;
    padding-left: 18px;
    margin-bottom: 10px;
    & svg {
      margin-right: 10px;
    }
    &.workspace svg {
      margin-right: 8px;
    }
  }
  .selector {
    width: 263px;
    height: 40px;
    cursor: pointer;
    background-color: #FFFFFF;
    position: relative;
    display: flex; 
    align-items: center;
    padding: 1px 9px 0 16px;
    margin: 0 0 0 40px;

    &-wrapper {
      background-color: #FFFFFF;
      position: relative;
      z-index: 5;
      padding-bottom: 12px;
    }

    &:hover {
      border-radius: 10px;
      background: #FFEEBF;
    }
    .text {
      font-size: 16px;
      line-height: 21px;
      color: #484343;
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      flex: 1;

      &.isHeader {
          color: #585858;
      }
      
      &.disabled {
        color: lightgrey;
      }
    }
    &.active {
      background-color: #FFC72C;
      border-radius: 10px;
      .text {
        color: #484343;
      }
    }
    &.last {
      margin-bottom: 16px;
    }
    &.disabled:hover {
      background-color: #FFFFFF;
    }
  }
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  background: #f9faff;
}

.header {
  width: 100%;
  display: flex;
  padding: 24px 0 12px 0;
  align-items: center;
  .title {
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    padding-left: 26px;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .search_icon_wrapper:hover {
    background: #dde2f9;
  }

  .search_icon_wrapper {
    height: 32px;
    width: 32px;
    padding: 6px;
    margin-left: 10px;
    border-radius: 6px;
    cursor: pointer;
  }

  .search_icon {
    width: 20px;
    cursor: pointer;
  }
}

.navigation_wrapper {
  margin-left: 26px;
  margin-bottom: 36px;
}

.button_group {
  position: relative;
  display: flex;
}

.with_icon_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes show_message {
  0% {
    height: 0;
  }

  100% {
    height: 32px;
  }
}
@keyframes show_message_big {
  0% {
    height: 0;
  }

  100% {
    height: auto;
  }
}
.form_informer {
  margin-top: 1px;
}
.form_item {
  position: relative;
  transition: height ease-in-out 0.3s;
  padding: 6px 32px 6px 8px;
  max-width: 458px;
  border: 1px solid;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #242633;
  cursor: pointer;
  overflow: hidden;
  animation: show_message 0.3s;

  &.notification.show {
    animation: show_message_big 0.3s;
  }

  .form_icon {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.error .form_icon {
    @include icon_with_color(#c21c44);
  }
  &.success .form_icon {
    @include icon_with_color(#117e0f);
  }
  &.notification .form_icon {
    @include icon_with_color(#ff8a00);
  }

  &.error {
    border-color: #c21c44;
    background-color: #fed8e2;
  }
  &.success {
    border-color: #117e0f;
    background-color: #d1edd1;
  }
  &.notification {
    border-color: #ff8a00;
    background-color: #fad1a4;
  }
  & .bold {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  }
  & .inline {
    display: inline-block;
    font-size: 14px;
    line-height: 21px;
    color: #242633;
  }
  & .notification_title {
    margin-bottom: 8px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    display: inline-block;
  }
}

.anyListIsHidden {
  height: 0;
  transition: 0.5s ease-in-out height;
}

.profileIsVisible {
  height: 104px ;
  transition: 0.5s ease-in-out height;
}

.preferencesIsVisible {
  height: 156px;
  transition: 0.5s ease-in-out height;
}

.submenu-items {
  padding-left: 16px;
  position: relative;
  z-index: 3;

  .selector {
    width: 247px;
    height: 40px;
    padding-left: 12px;

    &-wrapper {
      padding-top: 1px;
      position: relative;
      z-index: 2;
    }
    .text {
      padding-left: 4px;
    }
  }
}

.chevron {
  width: 26px;
  height: 20px;
  transform: rotate(90deg);

  &.chevronOpen {
      transform: rotate(180deg);
  }
}
