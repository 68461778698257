.wrapper_create_component {
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 105;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  font-style: normal;
  font-weight: 400;

  //background-color: red;
  .create_component {
    width: 650px;
    height: 584px;
    position: relative;
    //top: calc(50vh - 325px);
    margin: auto;
    background: #ffffff;
    border: 0.6px solid #ece2e2;
    box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    .line_first {
      margin-top: 19px;
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #d7ddf7;
    }
    .header {
      width: 100%;
      padding: 16px;
      padding-bottom: 0px;
      //display: flex;
      //flex-direction: column;
      justify-content: space-between;
      height: 49px;
      & .title {
        height: 20px;
        line-height: 14px;
        width: 178px;
        white-space: nowrap;
        margin-top: 17px;
        font-weight: 500;
        font-size: 16px;
        color: #242633;
      }
      .close_svg_active {
        background-color: #d7ddf7;
        border-radius: 4px;
      }
      svg:hover {
        cursor: pointer;
        background-color: #d7ddf7;
        border-radius: 4px;
      }
    }
    .more_option {
      transition: height 0.5s ease;
      height: 0;
      overflow: hidden;
      &.option_open {
        height: 91px;
      }
    }
    .option {
      width: 100%;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 32px;
      margin-top: 10px;
      .option_blocks {
        display: flex;
      }
      .more_settings_button {
        font-size: 14px;
        height: 31px;
        border-radius: 6px;
        width: 135px;
        cursor: pointer;
        margin-top: 32px;
        display: flex;
        padding-top: 1px;
        align-items: center;
        svg {
          margin-left: 5px;
          margin-right: 9px;
        }
        &:hover {
          background: #e8ebfa;
        }
        &.active {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }
    .block {
      width: 254px;
      display: flex;
      flex-direction: column;
      margin-right: 17px;
      .title_collection {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #242633;
        padding-left: 2px;
        margin-top: 2px;
      }
      p {
        margin-bottom: 5px;
      }
      .block_wrapper_input {
        border: 1px solid #d7ddf7;
        box-sizing: border-box;
        width: 254px;
        height: 32px;
        border-radius: 6px;
        border: 1px solid #ece2e2;
        border-radius: 6px;
        width: 340px;
        height: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        padding: 5px 8px 6px 5px;
        &.block_wrapper_input_dropdown {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          & svg {
            margin-top: 3px;
          }
        }
        &:hover {
          border: 1px solid #a3aacc;
        }
        input {
          color: #484343;
          border: none;
          font-size: 14px;
          width: 238px;
        }
      }
      .block_wrapper_input_big.block_wrapper_input_big {
        height: 52px;
        cursor: pointer;
      }
      p {
        font-size: 12px;
      }
    }
    .wrapper_create {
      width: 100%;
      height: 100%;
      position: relative;
      //left: -40px;
      //background-color: red;
      max-height: 467px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-top: 30px;
      &::-webkit-scrollbar {
        width: 8px;
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: 8px;
        background-color: #e2e1e1;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      }
      &::-webkit-scrollbar-track-piece {
        display: none;
        background-color: #ffffff;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      .add_block {
        margin-top: 24px;
        display: flex;
        line-height: 24px;
        color: #0026e5;
        font-size: 12px;
        width: 114px;
        height: 32px;
        align-items: center;
        cursor: pointer;
        position: relative;
        margin-left: 160px;
        border-radius: 18px;
        .add_block_icon {
          padding: 8px;
        }
        &:hover {
          background: #e8ebfa;
        }
      }
    }

    .wrapper_submit {
      display: flex;
      justify-content: space-between;
      padding: 8px 12px;
      height: 76px;
      .button_group {
        display: flex;
        justify-content: flex-end;
      }
      .tags_group {
        display: flex;
        align-items: center;
        padding: 11px 24px;
        width: 618px;
        .tags_input {
          width: 100%;
        }
      }
      .button {
        width: 65px;
        height: 32px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-right: 6px;
        margin-top: 12px;
        justify-content: center;
        cursor: pointer;
        &.create {
          border: 1px solid #0026e5;
          color: #0026e5;
          width: 70px;
        }
        &:hover {
          background: #e8ebfa;
        }
      }
    }
  }
}

.modal_close_warning {
  position: absolute;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 247px;
  height: 150px;
  padding: 24px 24px 24px 16px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  color: #242633;
  cursor: auto;
  top: 40px;
  z-index: 2;
  &_header {
    font-weight: 500;
    font-size: 16px;
  }
  &_text {
    padding-top: 9px;
  }
  &_button_group {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    div {
      border-radius: 6px;
      padding: 8px 12px;
      cursor: pointer;
    }
    .button_close {
      color: #c21c44;
      border: 1px solid #c21c44;
    }
    .button_cancel {
      color: #596080;
    }
  }
}
