.header_underlay {
  background-color: white;
}
.container {
  position: absolute;
  top: 0;
  height: 56px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d7ddf7;
  z-index: 104;
  width: 100%;
  background: #f2f3ff;
  &.forFiles {
    width: max-content;
    left: 0;
  }
  &.zIndex_top {
    z-index: 100;
  }
  &.forEditPage {
    border-bottom: none;
    background: none;
  }
}

.left_navigation {
  font-size: 14px;
  min-width: 448px;
  max-width: 448px;
  height: 32px;
  margin: 12px 21px 12px 0;
  display: flex;
  align-items: center;
  &.isViewLegacy {
    position: absolute;
    left: 0;
  }
  &.pushTop {
    margin-top: 18px;
  }
  & .arrow {
    margin-right: 2px;
  }
  & .playlist {
    width: 16px;
    margin-right: 5px;
    & svg {
      fill: #d7ddf7;
    }
  }
  & .text {
    line-height: 32px;
  }
  & .channel {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }

  & .button_container {
    border-radius: 6px;
    padding-left: 4.5px;
    padding-right: 4.5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 100px;
    &:hover {
      background-color: #d7ddf7;
      svg {
        & path,
        & circle,
        & polygon,
        & rect,
        & g {
          stroke: #242633;
        }
      }
    }
  }
}

.middle_status {
  height: 32px;
  margin: 12px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_navigation {
  height: 32px;
  min-width: 448px;
  max-width: 448px;
  margin: 12px 16px 12px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & .button_container {
    position: relative;
    border-radius: 6px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.disabled {
      color: grey;
      cursor: no-drop;
      & svg path {
        stroke: grey;
      }
    }
    & .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 6px;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      &:hover {
        background-color: #d7ddf7;
        & svg path {
          stroke: #242633;
        }
      }
      &.active {
        background-color: #d7ddf7;
        & svg path {
          stroke: #242633;
        }
      }
      &.border {
        border: 1px solid #d7ddf7;
      }
    }
  }
}
.published_button {
  width: 69px;
  height: 32px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  margin-left: 16px;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #a3aacc;
  justify-content: center;
}

.isPublish1_wrap {
  overflow: hidden;
  position: relative;
  height: 32px;
  border-radius: 16px;
  transition-duration: 0.6s;
  display: flex;
  justify-content: center;
  width: 67px;
  font-size: 12px;

  & .default {
    position: absolute;
    transition-duration: 0.4s;
    transition-property: top, background-color;
    background-color: #f2f3ff;
    width: 67px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;

    & .flex {
      height: 32px;
      line-height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background-color: #f2f3ff;

      & svg {
        margin-right: 9px;
        width: 16px;
        height: 16px;
      }
      &.wait_text {
        border: 1px solid #a3aacc;
        color: #596080;
        background-color: #f2f3ff;
        &.isPage {
          background-color: white;
        }
      }
      &.create_text {
        border: 1px solid #0026e5;
        color: #0026e5;
        background-color: #f2f3ff;
        &.isPage {
          background-color: white;
        }
      }
      &.publish_text {
        border: 1px solid #43af41;
        color: #43af41;
        background-color: #f2f3ff;
        &.isPage {
          background-color: transparent;
        }
      }
      &.draft_text {
        border: 1px solid #a3aacc;
        color: #242633;
        background-color: #f2f3ff;
        &.isPage {
          background-color: white;
        }
      }
      &.edit_text {
        border: 1px solid #ff8a00;
        color: #ff8a00;
        background-color: #f2f3ff;
        &.isPage {
          background-color: transparent;
        }
      }
    }
    &.draft {
      transition-duration: 0.6s;
      top: 0;
    }
    &.publish {
      transition-duration: 0.6s;
      background-color: #e2fae2;
      top: -64px;
    }
    &.wait.wait {
      top: -32px;
      background-color: #f4f5f6;
    }
    &.edit {
      transition-duration: 0.6s;
      background-color: #e2fae2;
      top: -96px;
    }
  }
}

.relative_parent {
  position: relative;
}

.chevron_button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e8ebfa;
  }
  &:active {
    background-color: #d7ddf7;
  }

  &.disabled {
    background-color: initial;
    &:hover {
      background-color: initial;
    }
    svg path {
      stroke: transparent;
      fill: lightgrey;
    }
    cursor: default;
  }
}
.chevron_button.right {
  margin-left: 6px;
  svg {
    transform: rotate(180deg);
  }
}

.vertical_separator {
  background-color: #d7ddf7;
  height: 100%;
  width: 1px;
  margin-left: 16px;
}
