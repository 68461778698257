.wrapper {
  flex-shrink: 0;
  flex-grow: 1;
}

.wrapper2 {
  position: relative;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.info {
  padding: 31px 0 5px 24px;
  max-width: 690px;
  margin: 0 auto;
}

.space_separator {
  height: 32px;
  width: 10px;
}

.content {
  max-width: 700px;
  padding-bottom: 90px;
  margin: 0 auto;
}

.invisibleCanEditDiv {
  position: absolute;
  top: 0;
  left: 0;
  //background-color: rgba(120, 200, 240, 0.6);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.preventSelection {
  user-select: none;
  pointer-events: none;
}

.input_title {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  min-height: 40px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  /*line-height: 48px;*/
  padding: 27px 0 0 0;
  color: #242633;
}

.input_description {
  display: block;
  width: 100%;
  padding-top: 8px;
  max-width: 100%;
  min-width: 100%;
  resize: none;
  border: none;
  overflow: auto;
  height: auto;
  font-size: 14px;
  color: #242633;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  line-height: 21px;
}
