.main {
  background-color: #f9faff;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  width: 266px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo_wrapper {
  width: 40px;
  height: 60px;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #242633;
  display: inline-block;
  margin-bottom: 19px;
  width: 164px;
  align-self: initial;
  &.password {
    width: 184px;
  }
}

.input_wrapper {
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #d7ddf7;
  border-radius: 6px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  & input {
    border: none;
    width: 95%;
    height: 95%;
  }
}

.button_wrapper {
}

.button_group_wrapper.button_group_wrapper {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  & > div {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
