.svg_wrapper{
  width: 27px;
  height: 27px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 6px;
  &:hover {
    border-color: #ECCE8344;
  }
  &.isDisabled{
    background: none;
    opacity:0.5;
    cursor:not-allowed;

  }
  &.isNotViewed{
    svg {
      opacity: 0.15;
    }
  }
}

.option_shell{
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  //padding: 9px 14px 0 0;
  gap: 11px;
  align-items: center;
}