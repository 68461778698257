.recent_wrapper_grid {
  padding-top: 2px;
  height: 100%;
  width: 100%;
  /*z-index: 100;*/
}
.recent_wrapper_list {
  padding-top: 2px;
  height: 100%;
  min-width: 982px;
  max-width: 1160px;
  margin: 0 auto;
}
.recent_grid_row_container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 40px 0;
  z-index: 100;
  scroll-snap-type: inline;
  scroll-behavior: smooth;
  gap: 40px;
}


  @media (min-width: 900px) {
    .recent_grid_row_container {
      width: 508px;
      margin: 0 auto;
    }
  }

  @media (min-width: 1024px) {
    .recent_grid_row_container {
     width: 688px;
    }  
  }

  @media (min-width: 1300px) {
    .recent_grid_row_container {
      width: 871px;
    }
  }

  @media (min-width: 1430px) {
    .recent_grid_row_container {
      width: 1054px;
    }
  }

  @media (min-width: 1600px) {
    .recent_grid_row_container {
      width: 1240px;
    }
  }

  @media (min-width: 1850px) {
    .recent_grid_row_container {
      width: 1417px;
    }
  }

  @media (min-width: 2050px) {
    .recent_grid_row_container {
      width: 1600px;
    }
  }

.recent_list_row_container.recent_list_row_container {
  overflow-x: visible;
  margin: 0 0 40px -5px;
}
.row_grid_spacing {
  padding-left: 0;
}
.row_list_spacing {
  padding-left: 0;
}
.title_grid_spacing {
}
.title_list_spacing.title_list_spacing {
  margin: 24px 0 16px 33px;
}
.recent_title_wrapper {
  margin: 0 0 24px 0;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #596080;
}

.recent_list_view_block {
  display: block;
}
.fullParentWidth {
  width: 100%;
}
.item_wrapper {
  display: flex;
  flex-direction: row;
}
.emptyRecent {
  display: flex;
  flex-direction: column;
  margin: 35vh auto 0 auto;
  align-items: center;
}
.empty {
  width: 371px;
  display: flex;
  flex-direction: column;
  margin: 35vh auto 0 auto;
  align-items: center;
  position: relative;
  left: -40px;
}
.emptySmartView {
  width: 371px;
  display: flex;
  flex-direction: column;
  margin: calc(35vh - 70px) auto 0 auto;
  align-items: center;
  position: relative;
  left: -40px;
}
.emptyRecent img {
  width: 48px;
  height: 58.67px;
}

.emptyRecent_mainText {
  margin-top: 18px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #242633;
  text-align: center;
}

.emptyRecent_infoText {
  width: 380px;
  margin-top: 8px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  text-align: center;
}

.relative {
  position: relative;
}
.clockIcon {
  width: 48px;
  height: 48px;
}
.clockIcon path {
  stroke: #596080;
}
