.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 12px;
  margin: 6px 0;
  border-radius: 6px;
  &.hover:hover {
    background: #c5ceff;
  }

  &.heightDouble {
    height: 24px;
  }
}
.line {
  width: 100%;
  margin: 5px 0;
  &.single {
    background: #d7ddf7;
    height: 1px;
  }
  &.bold {
    background: #d7ddf7;
    height: 3px;
  }
  &.dotted {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 76%,
      #d7ddf7 0%
    );
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
    height: 2px;
    width: 705px;
  }
}

.settingsWrapper {
  position: absolute;
  justify-content: space-around;
  display: flex;
  width: 136px;
  height: 40px;
  top: -54px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 6px;
  .buttonWrapper {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #d7ddf7;
    }
    &.active {
      background: #d7ddf7;
    }
  }
}
