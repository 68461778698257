.playlist_card {
  width: 300px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #c7cad1;
  border-radius: 12px;
  margin: 10px;
  cursor: pointer;
}

@keyframes fadein_card_show {
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 10px;
    opacity: 0.1;
  }

  60% {
    width: 200px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 300px;
  }
}
@-moz-keyframes fadein_card_show {
  /* Firefox */
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 36px;
    opacity: 0.1;
  }

  60% {
    width: 72px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 147px;
  }
}
@-webkit-keyframes fadein_card_show {
  /* Safari and Chrome */
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 36px;
    opacity: 0.1;
  }

  60% {
    width: 72px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 147px;
  }
}
@-o-keyframes fadein_card_show {
  /* Opera */
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 36px;
    opacity: 0.1;
  }

  60% {
    width: 72px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 147px;
  }
}
.pending {
  background-color: #e8ebfa;
  animation: downLoading 1s infinite ease-in-out;
}

@keyframes downLoading {
  from {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  to {
    opacity: 0.2;
  }
}

.pending div {
  display: none;
}

.block_playlist_card {
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.block_playlist_card2 {
  display: flex;
  flex-wrap: wrap;
  max-width: 960px;
  margin: auto;
}

.wrap_image {
  min-height: 136px;
  position: relative;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  overflow: hidden;
}

.image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-height: 136px;
  object-fit: cover;
  overflow-x: hidden;
}
.image_crop {
  position: absolute;
  width: 350px;
  object-fit: cover;
  overflow-x: hidden;
}

.info {
  display: flex;
  padding: 8px 16px 0 16px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 9px 16px 0 16px;
  color: #242633;
}

.avatar {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  align-items: center;
}

.clock {
  width: 16px;
  margin-right: 5px;
  opacity: 0.5;
}

.author {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #5f646d;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.time {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  display: flex;
  font-size: 14px;
  line-height: 14px;
  color: #a2a7b2;

  align-items: center;
}
