.footer__section {
  width: 108px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  height: 60px;
  z-index: 100;
  .footer__item {
    margin-left: 0;
    background: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    &:hover {
      background: rgb(255, 238, 191);
    }
  }
}


.item {
  display: flex;
  width: 28px;
  height: 28px;
  cursor: pointer;
  position: relative;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 6px;
  background: #e8ebfa;

  & svg path {
    stroke: #596080;
    fill: #fff;
  }
  &.active svg path {
    stroke: #596080;
    fill: #dde2f9;
  }

  &.active {
    background: #dde2f9;
  }

  .disabled {
    cursor: no-drop;
    background: none;
  }

  :hover .disabled {
    background: none;
    cursor: no-drop;
  }

  :hover.disabled svg path {
    stroke: #a2a7b2;
    fill: #fff;
  }

  .disabled svg path {
    stroke: #a2a7b2;
  }
  :hover.disabled svg {
    background: none;
  }
}