.wrapper {
  width: 100%;
  height: auto;
  margin-top: 7px;

  &.leftBallancer {
    position: relative;
    left: -10px;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  justify-content: space-between;
  width: 360px;
}

.filter_left {
  height: 56px;
  padding: 12px 5px;
}

.filters_button {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 5px 8px 5px 6px;
  width: 83px;
  border-radius: 6px;
  cursor: pointer;

  &.active {
    background: #e8ebfa;

    & svg path {
      stroke: #242633;
    }
  }

  & svg {
    margin-right: 8px;
  }

  & svg path {
    stroke: #596080;
  }

  &:hover {
    background: #e8ebfa;

    & svg path {
      stroke: #242633;
    }
  }
}

.filter_text {
  font-size: 14px;
  line-height: 21px;
  color: #242633;
}

.save_right {
  height: 56px;
  padding: 12px 2px;
  grid-area: right;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.save_button {
  display: flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
  justify-content: center;
  border: 1px solid #404d8c;
  border-radius: 8px;
  text-transform: uppercase;
  width: 128px;

  &.active {
    background: #e8ebfa;

    & svg path {
      stroke: #242633;
    }
  }

  & svg {
    margin-right: 8px;
  }

  & svg path {
    stroke: #596080;
  }

  &:hover {
    background: #e8ebfa;

    & svg path {
      stroke: #242633;
    }
  }

  &.active {
    background: #e8ebfa;

    & svg path {
      stroke: #242633;
    }
  }
}

.save_option {
  position: absolute;
  width: 321px;
  height: 158px;
  top: 45px;
  right: 0;
  padding: 24px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  // ManyPlaylist content overlaps DynamicCollection Create menu issue
  z-index: 102;

  &.isLibrary {
    top: 40px;
    right: 0;
    z-index: 103;
  }

  &_changes {
    display: flex;
  }

  &_text {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #596080;
    padding-bottom: 4px;
  }

  &_select_option {
    width: 208px;
    height: 184px;
    position: absolute;
    top: 55px;
    left: 0;
    box-shadow:
      0 1px 4px rgba(0, 0, 0, 0.25),
      0 4px 16px rgba(0, 0, 0, 0.15);
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 14px 7px 11px 7px;

    & .select_item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1px 8px;
      height: 32px;
      width: 32px;

      &:hover {
        background-image: url("../../images/icons/rectangle_32.svg");
      }

      &.active {
        background-image: url("../../images/icons/rectangle_32.svg");
      }
    }

    &.colors {
      height: auto;
      padding-left: 13px;
      width: 200px;
    }
  }

  &_icon {
    width: 81px;
    position: relative;

    &_selectors {
      width: 65px;
      height: 32px;
      border-radius: 6px;
      border: 1px solid #d7ddf7;
      display: flex;

      & .item {
        width: 34px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background: #e8ebfa;
        }

        &.active {
          background: #e8ebfa;
        }
      }

      & .separator {
        background: #d7ddf7;
        width: 1px;
      }
    }
  }

  &_input {
    background: #ffffff;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    height: 32px;
    width: 192px;
    font-size: 14px;
    line-height: 21px;
    color: #242633;
    padding-left: 8px;
  }

  &_confirm {
    display: flex;
    justify-content: end;
    padding-top: 25px;

    &_cancel {
      width: 68px;
      height: 32px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #596080;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      cursor: pointer;
      margin-right: 6px;

      &:hover {
        background-color: #e8ebfa;
      }
    }

    &_create {
      position: absolute;
      width: 65px;
      height: 32px;
      border: 1px solid #0026e5;
      border-radius: 6px;
      color: #0026e5;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.save_text {
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #404d8c;
  font-size: 12px;
}

.icon_save_smart {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.tags {
  padding: 0;
  transition-property: height, padding-top, padding-bottom;
  transition: ease-in-out 0.3s;
  height: 0;
  overflow: hidden;

  &.active {
    height: fit-content;
    position: relative;
    padding-bottom: 11px;
    overflow: initial;
  }

  &.isFullScreen {
    display: flex;
    justify-content: space-between;
  }
}

.filters {
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-left: 5px;
  z-index: 102;
}

.filters.collapsed_large {
  display: none;
}

.filters.collapsed_small {
  display: flex;

  & .icon svg {
    margin-right: 8px;
  }
}

@media (min-width: 1600px) {
  .filters.collapsed_small {
    display: none;
  }
  .filters.collapsed_large {
    display: flex;
  }
}

.filters_option {
  height: 32px;
  border-radius: 6px;
  position: relative;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  line-height: 21px;
  padding: 0 10px;
  margin-right: 8px;
  display: flex;
  cursor: pointer;
  color: #a3aacc;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;

  &.hoverable:hover {
    background: #e8ebfa;

    & .icon svg path {
      stroke: #242633;
    }
  }

  &.active.active {
    background: #e8ebfa;

    & .icon path {
      stroke: #242633;
    }

    .filters_option_text {
      color: #242633;
    }
  }

  &.selected {
    background: #e8ebfa;

    & .icon path {
      stroke: #0026e5;
    }
  }

  &.file_type {
    margin-right: 3px;
  }
}

.filters_option svg {
}

.filters_option_text {
  color: #596080;
  margin-right: 4px;

  &.blue {
    color: #0026e5;

    & .icon svg path {
      stroke: #0026e5;
    }
  }
}

.filters_option_text_active {
  max-width: 113px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0026e5;
}

.drop_list_wrapper {
  position: absolute;
  top: 143px;
  left: 212px;
  padding: 0;
  z-index: 33;
  overflow: hidden;
  transition-property: height, padding-left;
  transition: ease-in-out 0.3s;
  width: 280px;
  max-height: 482px;
  padding-top: 17px;
  overflow-y: auto;

  //&::-webkit-scrollbar {
  //  width: 4px;
  //}
  //
  //&::-webkit-scrollbar-track {
  //  background: none;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  background: #ECE2E2;
  //}
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;

  &.active {
    padding: 16px 0;
    height: fit-content;
  }

  &.is_types {
    left: 382px;
    width: 143px;
    height: 323px;
  }
}

.drop_list_container {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #484343;
}

.drop_list_title {
  padding-left: 16px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 12px;
  /* or 100% */

  position: relative;
  z-index: 10;

  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #d3c8c8;
}

.drop_list_main {
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 3px;
}

.drop_list_main::-webkit-scrollbar {
  display: none;
  width: 4px;
}

.drop_list_main::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 4px;
  background-color: #e2e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.drop_list_main::-webkit-scrollbar-track-piece {
  display: none;
}

.drop_list_main::-webkit-scrollbar-track {
  border-radius: 8px;
}

.list_item_container {
  display: flex;
  align-items: center;
  margin: 1px 0;
  //padding: 5px 0 5px 18px;
  padding: 0 0 0 18px;
  cursor: pointer;
  height: 30px;

  &:hover {
    background: #ffeebf;
  }
}

.list_item_checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: #484343;
    }
  }
}

.list_item_title {
  width: 122px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.datepicker_button {
  height: 32px;
  display: flex;
  justify-content: flex-start;

  &.right {
    justify-content: flex-end;
  }

  align-items: center;
  border-radius: 6px;

  &:hover {
    //background: #FFEEBF;
  }

  svg {
    path {
      stroke: #534b4f;
    }
  }
}

.date_range_wrapper {
  position: absolute;
  height: 305px;
  width: 319px;
  overflow: hidden;
  top: 146px;
  left: 278px;
  padding: 0;
  background: #ffffff;
  z-index: 33;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  transition-property: height, padding;
  transition: ease-in-out 0.3s;
  padding-top: 16px;

  &.active {
    height: 305px;
  }
}

.date_range_container {
  ////padding-top: 10px;
  //margin-top: 5px;
}

.list_item_no_tags {
  color: #a3aacc;
  margin-left: 18px;
}

.slide_option_wrapper {
  display: flex;
  align-items: center;
}

.slide_option {
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #596080;
  margin-right: 17px;

  &.active {
    font-style: normal;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    color: #242633;
  }
}

.slide_separator {
  position: relative;
  background: #e4e7f7;
  height: 1px;
  width: 100%;
  margin: 10px auto;
  margin-bottom: 17px;
}

.edit_collection_options {
  display: flex;
  flex-wrap: wrap;
  width: 192px;
}

.edit_collection_item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 15px;
  margin-right: 16px;
  margin-bottom: 7px;

  &:hover {
    background: #e8ebfa;
  }

  &.active {
    background: #e8ebfa;

    & svg path {
      stroke: #242633;
    }
  }
}

.slider {
  position: absolute;
  background-color: #0026e5;
  height: 1px;
  transition-property: left, width;
  transition: ease-in-out 0.3s;

  &[data-option="icon"] {
    left: 0;
    width: 35px;
  }

  &[data-option="color"] {
    left: 50px;
    width: 45px;
  }
}

.confirm_button_Smart_view {
  display: flex;
}

.sort_wrapper {
  display: flex;
  justify-content: end;
}
