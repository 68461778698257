.createLink_wrapper {
  position: absolute;
  z-index: 3;
  top: 44px;
  left: -95px;
}

.createLink_container {
  width: 480px;
  height: 122px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-top: 22px;
  padding-left: 24px;

  .title_container {
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #242633;
    margin-bottom: 24px;
  }
  .input_wrapper {
    display: flex;
    flex-direction: row;

    .input_container {
      background: #ffffff;
      border: 1px solid #d7ddf7;
      border-radius: 6px;
      width: 375px;
      height: 32px;
      padding: 5px 32px 6px 8px;
      margin-right: 10px;
      input {
        border: none;
        width: 100%;
        height: 21px;
        font-size: 14px;
        &::placeholder {
          color: #a3aacc;
        }
      }

      &.errorBorder {
        border: 1px solid #b30e00;
      }
    }

    .save_button {
      cursor: pointer;
      height: 32px;
      width: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.05em;
      color: #0026e5;
      border: 1px solid #0026e5;
      border-radius: 6px;
      &:active,
      &:hover {
        background: #e8ebfa;
      }
    }
  }

  .error_message_container {
    position: absolute;
    height: 22px;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #b30e00;
    margin-top: 5px;
  }
}
.view_shell {
  display: flex;
  justify-content: flex-end;
}

.view {
  cursor: pointer;
  width: 32px;
  height: 32px;
  //padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.6px solid #ece2e2;
  background: #ffffff;
  border-radius: 6px;
  & svg path {
    stroke: #484343;
    //fill:#484343
  }
  &.disable {
    cursor: no-drop;
    opacity: 0.4;
    &:hover {
      background: none;
    }
  }
  &.low_mode {
    width: 28px;
    height: 28px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  &.sticky_mode_right {
    border-radius: 0 6px 6px 0;

  }
  &.sticky_mode_left {
    border-radius: 6px 0 0 6px;
  }

  &:hover {
    color: #484343;
    border: 0.6px solid #ffc72c;
    background-color: #ffeebf;
    z-index: 1;
  }
  &.active {
    color: #484343;
    z-index: 1;
    border: 0.6px solid #ffc72c;
    background-color: #ffeebf;
  }
}
