.input_title {
  height: auto;
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  padding: 0;
  margin-bottom: 13px;
  resize: none;
  min-height: 40px;
  border: none;
  background: none;
  line-height: 53px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  color: #242633;
}

.input_title::placeholder {
  color: #a3aacc;
}
.testStart.testStart {
  background-color: red;
}
.testEnd.testEnd {
  background-color: blue;
}
.has {
  background-color: salmon;
}
.counter {
  opacity: 0;
  position: absolute;
  right: 28px;
  top: 30px;
  font-size: 12px;
  font-family: "RobotoFlex", sans-serif;
}

.counter_grey {
  color: #a3aacc;
}

.counter_red {
  color: #c21c44;
}

.counter_yellow {
  color: #ed9716;
}

.counter_visible {
  opacity: 1;
}

.description {
  display: block;
  width: 100%;
  padding: 8px 0 1px 0;
  margin-bottom: 5px;
  max-width: 100%;
  min-width: 100%;
  resize: none;
  border: none;
  overflow: auto;
  height: auto;
  font-size: 14px;
  color: #242633;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  line-height: 21px;
}

.description::placeholder {
  color: #a3aacc;
}

.display_none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.edit_content {
  /*padding-left: 5px;*/
  max-width: 970px;
  margin: 0 auto;
}

.chevron {
  padding-left: 15px;
}

.wrapperSelect {
  height: 100%;
}
.wrapper {
  /*min-height: calc(100vh - 130px);*/
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrong_create {
  background: #ffeef2;
  border-radius: 6px;
  margin: auto;
  width: fit-content;
  font-size: 14px;
  color: #c21c44;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 9px;
}
.wrong_create svg {
  margin-right: 5px;
}
.info_block_add {
  display: flex;
  margin-right: 13px;
  padding: 6px 8px 6px 0;
  color: #596080;
}

.info_block_add:hover {
  background: #e8ebfa;
  border-radius: 18px;
}

.info_block_hide {
  display: flex;
  cursor: pointer;
  padding: 6px 8px 6px 0;
  color: #596080;
}

.info_block_hide div {
  /*padding-right: 8px;*/
}

.info_block_hide:hover {
  background: #e8ebfa;
  border-radius: 18px;
}

.info_block_add img {
  padding: 0 8px 0;
}

.info_block_hide img {
  padding: 0 8px 0;
}

.info_block {
  opacity: 0;
  display: flex;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.info {
  padding: 0 0 0 68px;
  max-width: 708px;
  margin: 0 auto 0 auto;
  position: relative;
}

.info_wrapper {
  width: 700px;
}

.info_wrapper:hover .info_block {
  opacity: 1;
}

.selection_border {
  background: rgba(0, 162, 255, 0.4);
  position: fixed;
  z-index: 99;
}

.input_description {
  border: none;
  background: none;
  width: 100%;
  display: block;
  resize: none;
  padding: 35px 0 0 0;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-size: 20px;
}

.add_image {
  opacity: 0;
  display: flex;
  width: fit-content;
  cursor: pointer;
}

.add_image:hover {
  background: #b5b6ba;
}

.add_image__text {
  padding-left: 5px;
}

.add_image__icon {
  width: 15px;
}

.title:hover .add_image {
  opacity: 0.2;
}

.image {
  width: 100%;
}

.provisional_button {
  margin-top: 100px;
}

.add_block {
  margin-top: 24px;
  display: flex;
  line-height: 24px;
  color: #534b4f;
  font-size: 12px;
  width: 114px;
  height: 32px;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-left: 72px;
}
.add_block svg path {
  stroke: rgba(83, 75, 79, 0.45);
}
.add_block svg {
  margin-right: 7px;
  margin-left: 10px;
}
.space_separator3 {
  height: 230px;
  width: 100px;
}

.add_block:hover {
  background: #ffeebf;
  border-radius: 18px;
}

.add_block__on_end {
  margin-top: 10px;
}

.add_block_icon {
  padding: 8px;
}
.wrapper_add_block {
  width: 880px;
  z-index: 1000;
  height: 300px;
}

.dragHandler {
  position: absolute;
  width: 1000px;
  height: 50px;
  /*padding-left: 180px;*/
  z-index: 100;
}
.dragHandler_last {
  position: absolute;
  width: 1000px;
  height: 40px;
  padding-left: 160px;
  z-index: 100;
}

.dragLine {
  margin-top: 10px;
  position: absolute;
  background: #8697f0;
  width: 694px;
  height: 4px;
  border-radius: 36px;
  z-index: 100;
  margin-left: 160px;
}
.dragLineLast {
  margin-top: 10px;
  position: absolute;
  background: #8697f0;
  width: 644px;
  height: 4px;
  border-radius: 36px;
  z-index: 100;
}
.dragLine_exist {
  position: absolute;
  width: 694px;
  height: 4px;
  border-radius: 36px;
  z-index: 100;
  margin-left: 160px;
}
.dragLine_new {
  background: #8697f0;
}

.dragHandler_wrapper {
  position: relative;
  top: -10px;
  z-index: 100;
}

.dashed {
  position: absolute;
  top: 0;
  left: 150px;
  right: 20px;
  bottom: 0;
  z-index: -1;
}

.dashed_first.dashed_first {
  /*position: relative;*/
  border-top: 1px dashed #596080;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.dashed_last.dashed_last {
  /*position: relative;*/
  border-bottom: 1px dashed #596080;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.relative {
  position: relative;
}
.create_component {
  scale: 0.8;
  left: -80px;
  z-index: 1;
}
.z_index_up {
  z-index: 100;
}
.test {
  height: 100px;
}
.warehouse_block {
  position: absolute;
  top: 0;
  left: 150px;
  right: 20px;
  bottom: 0;
  z-index: -1;
}
.dashed_middle.dashed_middle {
  /*position: relative;*/
  border-left: 1px dashed #596080;
  border-right: 1px dashed #596080;
}

.component_label {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 850px;
  overflow: hidden;
  top: -6px;
  width: 12px;
  height: 12px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: green;
  border-radius: 10px;
  background: #596080;
  z-index: 1;
}

.component_label span {
  display: none;
}

.component_label.active span {
  display: inline;
}

.component_label.active {
  padding-left: 6px;
  padding-right: 6px;
  width: 87px;
  height: 18px;
  left: 770px;
  top: -18px;
  color: #ffffff;
}

.green {
  background: green;
}

.dragLine_wrap {
  /*position: relative;*/
  position: sticky;
  height: 0;
}
.dragLineSvg {
  position: absolute;
  top: -7px;
  width: 743px;
  /* margin-left: 21px; */
  left: 40px;
}
.dragLineSvg_isLargeRightBar.dragLineSvg_isLargeRightBar {
  left: 0;
  top: -15px;
}

.top_handler {
  height: 24px;
  width: 800px;
}
