.button_container {
  height: 32px;
  width: fit-content;
  background-color: transparent;
  padding: 4px 12px 4px 6px;
  display: flex;
  align-items: center;
  border: 1px solid #0026e5;
  cursor: pointer;
  border-radius: 6px;
  &.delete {
    border: 0.6px solid #d32f2f;
    filter: drop-shadow(0px 1px 4px rgba(122, 105, 105, 0.1));
    border-radius: 6px;
  }
  &:hover {
    background-color: #e8ebfa;
  }
  &.delete:hover {
    background-color: #ffe1e8;
  }
  &.fullParentWidth {
    width: 100%;
    justify-content: center;
  }
  &.widthFit {
    width: fit-content;
  }
  &.isBlue {
    border: 1px solid #0026e5;
    color: #0026e5;
  }
  &.isDark {
    border: 1px solid #596080;
    color: #596080;
  }
  &.isNoPointer {
    cursor: initial;
  }
  &.isComputerTab {
    height: 28px;
  }
  &.isSignIn {
    border-radius: 12px;
    background: #4744A0;
    border: none;

    &:hover {
        border: 1px solid #FFC72C;
        .button_text {
          color: #FFC72C;
        }
      }
  }
  &.isContinueWith {
    border-radius: 12px;
    border: 1px solid #ECE2E2;
    background: #FFF;
    
    &:hover {
      border: 1px solid #FFC72C;
      background: #FFEEBF;
    }
  }
}
.icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  &.forceSvgStyles {
    margin-right: 7px;
  }
  &.forceSvgStyles.delete svg path {
    stroke: #c21c44;
  }
  &.forceSvgStyles svg path {
    stroke: #0026e5;
    fill: transparent;
  }
}

.button_text {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #0026e5;
  &.no_line_break {
    white-space: nowrap;
  }
  &.delete {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #d32f2f;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &.isSignIn {
    color: #FFF;
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
  }
}

.playlist_button_container {
  height: 32px;
  width: auto;
  background-color: transparent;
  display: flex;
  align-items: center;
  border: 1px solid #0026e5;
  cursor: pointer;
  border-radius: 6px;
  padding: 4px 6px;
  &:hover {
    background-color: #e8ebfa;
  }

  &.blue {
    background-color: #0026e5;
    &:hover {
      background-color: #0000a8;
      border-color: #0000a8;
    }
  }
  &.isDisabled {
    cursor: no-drop;
    opacity: 0.5;
    &:hover {
      background-color: initial;
      &.blue {
        &:hover {
          background-color: #0026e5;
          border-color: #0026e5;
        }
      }
    }
  }
}
.playlist_button_text {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #0026e5;
  white-space: nowrap;
  & svg path {
    stroke: #0026e5;
  }
  &.blue {
    color: #ffffff;
    & svg path {
      stroke: white;
    }
  }
}

.playlist_button_wrapper {
  position: relative;
  .buttonInit {
    display: flex;
    background: #d5dcfd;
  }
  .buttonShow {
    display: none;
  }
  &:hover .buttonInit {
    display: none;
  }
  &:hover .buttonShow {
    display: flex;
  }
}
