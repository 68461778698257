.wrapper_change_component {
  position: relative;
  right: 5px;
  display: flex;
  top: -2px;
  height: 24px;
}

.change_component_text.change_component_text {
  padding-right: 8px;
}
.change_component {
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  font-family: "RobotoFlex", sans-serif;
  /*opacity: 0;*/
  height: 32px;
  margin-left: 4px;
  color: #a3aacc;
}
.change_component_disable {
  cursor: no-drop;
  opacity: 0.5;
}
.change_component_disable.change_component_disable:hover {
  cursor: no-drop;
  background: none;
}
.change_component:hover svg path {
  stroke: #596080;
}
.change_component:hover {
  background: #e8ebfa;
  border-radius: 4px;
  color: #596080;

  /*opacity: 0;*/
}
.change_component_active {
  background: #e8ebfa;
  border-radius: 4px;
  color: #596080;
}

.change_component_form svg {
  margin-right: 7px;
  /*width: 20px;*/
  /*height: 20px;*/
}

.change_component_active {
  background: #e8ebfa;
  border-radius: 4px;
}
.separate {
  margin-top: 4px;
  margin-right: 4px;
  margin-left: 8px;
  width: 1px;
  background: #d7ddf7;
  height: 24px;
}
