.component {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 3px;
  /*margin-left: 3px;*/
  width: 700px;
}
.builder_component {
  width: 100%;
}

.wrapper_component_small.wrapper_component_small {
  padding-left: 40px;
  pointer-events: none;
}
.wrapper_component {
  font-family: "RobotoFlex", sans-serif;
  position: relative;
  max-width: 888px;
  padding-left: 156px;
  /*padding-right: 30px;*/
}
.wrapper_component.wrapper_component_in_maker {
  max-width: 100%;
}
.wrapper_component.wrapper_component_in_maker .DraftEditor-editorContainer {
  background: unset;
}
.wrapper_component_in_library {
  font-family: "RobotoFlex", sans-serif;
  position: relative;
  max-width: 888px;
  padding-left: 69px;
  /*padding-right: 30px;*/
}

.wrapper_component_balancer {
  /*margin-right: 110px;*/
  display: flex;
  width: 877px;
  /*max-width: 887px;*/
}
.wrapper_component_in_builder {
  padding: 0;
  width: 100%;
  border: 1px solid transparent;
}
.builder_text_element {
  width: 100%;
  height: 100%;
  min-height: 24px;
  border-radius: 8px;
  background-color: inherit;
  padding: 4px 6px;
}
.builder_text_element.upv_text_element {
  padding: 0;
}
.builder_text_element:focus-within {
  background-color: #fcfdff;
}
.builder_text_element:has(.wrapper_component_in_maker):focus-within {
  background-color: unset;
}
.wrapper_component:hover .add_line {
  visibility: visible;
}
.wrapper_component_in_library:hover .add_line {
  visibility: visible;
}
.separating_line {
  height: 100%;
  border-left: 1px solid #d7ddf7;
  margin-left: 11px;
}
.img_wrapper:hover {
  background: #e8ebfa;
  /*opacity: 0.8;*/
}
.img_wrapper img {
  width: 16px;
  height: 16px;
}
.img_wrapper {
  padding: 4px 4px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_line {
  visibility: hidden;
  position: absolute;
  left: 133px;
  right: 0;
  top: -12px;
  bottom: -12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
}
.wrapper_component_small .add_line.add_line {
  visibility: hidden;
}

.wrapper_component_small .rectangle.rectangle {
  visibility: hidden;
}

.add_line.add_line_in_library {
  left: 44px;
}
.k {
  display: flex;
  flex-wrap: wrap;
  top: 40px;
  position: absolute;
  width: 136px;
  height: 96px;
  visibility: hidden;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.wk {
  height: 40px;
  width: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wa:hover .a {
  visibility: visible;
}
.wa:hover {
  background-color: #e8ebfa;
}
.a {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40px;
  position: absolute;
  width: 106px;
  height: 40px;
  visibility: hidden;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.wa {
  height: 40px;
  width: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wk:hover .k {
  visibility: visible;
}
.wk:hover {
  background-color: #e8ebfa;
}
.z {
  display: flex;
  flex-wrap: wrap;
  top: 40px;
  position: absolute;
  width: 136px;
  height: 96px;
  visibility: hidden;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.wz {
  height: 40px;
  width: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wz:hover {
  background-color: #e8ebfa;
}

.wz:hover .z {
  visibility: visible;
}
.hidden {
  visibility: hidden;
  display: none;
}

.showToolBar {
  z-index: 1000;
  animation: fadein 0.15s;
  -moz-animation: fadein 0.15s; /* Firefox */
  -webkit-animation: fadein 0.15s; /* Safari and Chrome */
  -o-animation: fadein 0.15s;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bar_button_active {
  cursor: pointer;
  height: 40px;
  width: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bar_turn_into {
  cursor: pointer;
  height: 40px;
  width: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bar_turn_into:hover {
  background-image: url("../../images/icons/rectangle_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.bar_turn_into_active {
  background-image: url("../../images/icons/rectangle_hover.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.bar_turn_into svg path {
  stroke: #242633;
}
.bar_turn_into:hover svg path {
  stroke: #0026e5;
}
.bar_turn_into_active svg path {
  stroke: #0026e5;
}

.bar_button_disable {
  cursor: no-drop;
  height: 40px;
  width: 32px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  width: 1px;
  height: 40px;
  background: #e4e7f7;
  opacity: 0.5;
  flex: none;
}

.bar_button_active:hover {
  background-color: #e8ebfa;
}
.bar_button_pressed {
  background-color: #e8ebfa;
}

.component_selected {
  background-color: #c5ceff;
}

.wrapper_component:hover .add_component {
  opacity: 1;
}
.wrapper_component_in_library:hover .add_component {
  opacity: 1;
}

.add_component:hover {
  background-image: url("../../images/icons/rectangle_hover.svg");
}

.add_component_open {
  background-image: url("../../images/icons/rectangle.svg");
  opacity: 1 !important;
}
.add_component_open:hover {
  background-image: url("../../images/icons/rectangle.svg");
}

.wrapper_component:hover .rectangle {
  opacity: 1;
}

.wrapper_component_in_library:hover .rectangle {
  opacity: 1;
}

.Section {
}

.wrapper {
  flex-grow: 1;
  position: relative;
  margin-left: 17px;
}
.wrapper_drag {
  flex-grow: 1;
  position: relative;
}

.add_component {
  /*position: absolute;*/
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /*top: 8px;*/
  /*left: -59px;*/
  font-size: 30px;
  color: lightgray;
  cursor: pointer;
  user-select: none;
  opacity: 0;
}

.count {
  border-radius: 8px;
  height: 40px;
  line-height: 30px;
  position: absolute;
  left: 50px;
  top: 14px;
  padding: 0 16px 0 12px;
  background: #ffffff;
  border: 1px solid #8697f0;
  box-sizing: border-box;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
  display: flex;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.1em;
  align-items: center;
  justify-content: space-around;
}

.count svg {
  margin-right: 8px;
}

.add_component:hover {
  color: gray;
}

.rectangle {
  opacity: 0;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  padding: 4px;
  position: relative;
  top: 4px;
}

.rectangle:hover {
  background: #e8ebfa;
  opacity: 1;
}
.rectangle:hover svg path {
  stroke: #596080;
}

.rectangle_active {
  background: #e8ebfa;
  opacity: 1;
}

.dots {
  width: 24px;
  height: 24px;
}

.dots:hover {
  opacity: 1;
}

.dots_active {
  opacity: 1;
}

.import_page {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
  border-bottom: 1px solid rgba(55, 53, 47, 0.16);
  cursor: pointer;
  width: fit-content;
}
.import_page_icon {
  margin-top: 5px;
  height: 21px;
}

.input_section {
  border: none;
  background: none;
  width: 100%;
  display: block;
  resize: none;
  padding: 0;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-size: 24px;
}

.section {
  display: flex;
}

.LinkPageList {
  padding-left: 20px;
}

.link1 {
  width: 269px;
  height: 48px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 75px;
  position: absolute;
  z-index: 10;
  visibility: hidden;
}
.link_input {
  background: #ffffff;
  width: 200px;
  height: 32px;
  padding-left: 8px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 8px;
}
.link_apply {
  font-size: 12px;
  line-height: 12px;
  width: 47px;
  height: 40px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  color: #0026e5;
  cursor: pointer;
}
.linkNotVisible {
  position: absolute;
  opacity: 0;
}
.showLink {
  overflow: visible;
  position: absolute;
  left: -50px;
  top: 30px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 241px;
  height: 56px;
  z-index: 100;
  display: flex;
  align-items: center;
  color: #2f71f4;
  justify-content: space-between;
  padding: 0 15px 0 12px;
}

.showLink a {
  text-align: left;
}

.test {
  position: relative;
  max-width: 697px;
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*overflow-wrap: initial;*/
}
.test1 {
  margin: 0;
  position: absolute;
  left: -36px;
  text-align: right;
  width: 29px;
  z-index: 0;
}
.test2 {
  margin: 0;
  position: absolute;
  left: -32px;
  text-align: right;
  width: 10px;
  z-index: 0;
}
.test2::before {
  position: absolute;
  content: "•";
}

.showLink_text {
  padding-left: 5px;
  width: 155px;
  font-size: 14px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.showLink svg:last-child {
  margin-left: 10px;
}

.link_svg {
  cursor: pointer;
}
.link_svg path {
  stroke: #596080;
}

.loader {
  position: absolute;
  left: -29px;
  top: 2px;
  text-indent: -9999em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  border-top: 1px solid #0026e5;
  border-right: 1px solid #0026e5;
  border-bottom: 1px solid #d7ddf7;
  border-left: 1px solid #0026e5;
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  z-index: -1;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hiddenContainer {
  position: absolute;
  right: -32px;
  width: 32px;
  height: 32px;
  visibility: hidden;
  background: transparent;
  cursor: pointer;
  border: 0 solid transparent;
  border-radius: 6px;
  /*top: -4px;*/
}
.hiddenContainer:hover {
  background: #e8ebfa;
}
.hiddenContainer.visible {
  visibility: visible;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.hiddenContainer .imageContainer svg {
  width: 20px;
  height: 20px;
}
.hiddenContainer .imageContainer svg path {
  stroke: #596080;
}

.addOpacity {
  opacity: 0.3;
  width: 100%;
}

.addOpacity.addOpacity:hover {
  opacity: 0.6;
}

.hiddenBlockWrapperBg {
  background: #e8ebfa;
  border-radius: 6px;
}

.hiddenWrapper {
  width: 100%;
}

.dndPlaceholderWrapper {
  position: absolute;
  width: 174px;
  height: 44px;
}
.upv_card_view .builder_component {
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 4px;
}
.upv_card_view .not_owned_margins {
  margin: 6px 0;
}
.upv_card_view .builder_component::-webkit-scrollbar {
  display: none;
}
.upv_card_view:focus-within {
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 0px 1px 4px rgba(122, 105, 105, 0.1);
  border-radius: 12px;
}
.upv_card_view:hover {
  /*border: 0.6px solid #FFC72C;*/

  box-shadow: 0px 1px 4px rgba(122, 105, 105, 0.1);
  border-radius: 12px;
  background: #ffffff;
}
.upv_card_view.selected {
  border-radius: 12px;
  border: 1px solid #FFC72C;
  background: rgba(255, 238, 191, 0.20);
}