.wrapper {
}

.wrapper2 {
  min-height: calc(100vh - 1px);
  background-color: #fafaff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.absolute_header_icon {
  margin-right: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
}
.absolute_header_icon:hover {
  background-color: #e8ebfa;
}

.flex {
  display: flex;
  width: 330px;
  height: 32px;
  justify-content: flex-end;
}
.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}
.search_input {
  background-color: white;
  line-height: 21px;
  width: 190px;
  border: 0;
  font-size: 14px;
}

.search_input::placeholder {
  color: #a3aacc;
}

.search_input_wrapper {
  margin-left: 8px;
  height: 40px;
  width: 260px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  display: flex;
  align-items: center;
}
.clear_search {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  margin-right: 7px;
}
.clear_search:hover {
  background: #e8ebfa;
}

.multiSelectPanelWrapper {
  height: 1px;
  bottom: 0;
  left: 55px;
  position: sticky;
  z-index: 100;
}

.new_page {
  width: 101px;
}
.new_page svg {
  margin-right: 5px;
}
.new_page svg path {
  stroke: white;
}

.upload_file {
  position: relative;
  font-family: "RobotoFlex", sans-serif;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  height: 32px;
  color: #0026e5;
  padding: 0 14px 0 5px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
}
.upload_file svg path {
  stroke: #0026e5;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 70px 12px 26px;
  margin-bottom: 12px;
  /*margin-top: 5px;*/
}

.header.rows {
  padding: 20px 43px 12px 44px;
}
.orders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 68px 20px 26px;
}
.orders.rows {
  padding: 0 37px 20px 43px;
}
.cards_content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 26px;
}
.rows_content {
  margin-right: 82px;
  margin-left: 30px;
}
/*@media (min-width: 1600px) {*/
/*    .header {*/
/*        padding: 20px 70px 12px 76px;*/
/*    }*/
/*    .orders {*/
/*        padding: 0 68px 20px 76px;*/
/*    }*/
/*    .cards_content {*/
/*        padding: 0px 48px 20px 76px;*/
/*    }*/
/*    .rows_content {*/
/*        padding: 9px 74px 20px 42px;*/
/*    }*/
/*}*/
/*@media (max-width: 1600px) {*/
/*    .header {*/
/*        padding: 20px 60px 12px 54px;*/
/*    }*/
/*    .orders {*/
/*        padding: 0 60px 20px 54px;*/
/*    }*/
/*    .cards_content {*/
/*        padding: 4px 16px 20px 17px;*/
/*    }*/
/*    .rows_content {*/
/*        padding: 9px 70px 20px 9px;*/
/*    }*/
/*}*/
/*@media (max-width: 1500px) {*/
/*    .header {*/
/*        padding: 20px 60px 12px 54px;*/
/*    }*/
/*    .orders {*/
/*        padding: 0 60px 20px 54px;*/
/*    }*/
/*    .cards_content {*/
/*        padding: 4px 16px 20px 17px;*/
/*    }*/
/*    .rows_content {*/
/*        padding: 9px 70px 20px 9px;*/
/*    }*/
/*}*/
.noResults {
  position: relative;
  margin-top: 194px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 72px - 60px);
}
.folderTitle {
  display: block;
  margin-left: 6px;
  margin-right: 8px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
  text-overflow: ellipsis;
  max-width: 478px;
  white-space: nowrap;
  overflow: hidden;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 24px;
  text-overflow: ellipsis;
  max-width: 478px;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
}

.component_title {
  margin-left: 4px;
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid white;
  box-sizing: border-box;
  padding-left: 3px;
  padding-right: 5px;
  border-radius: 6px;
}
.component_title:hover {
  position: relative;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  padding-left: 3px;
  padding-right: 5px;
  cursor: text;
}

.hide {
  visibility: hidden;
  position: absolute;
}

.folderTitle:hover {
  margin-left: 0;
  margin-right: 2px;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: text;
}

.title_input_wrapper {
  width: fit-content;
  min-width: 50px;
  max-width: 478px;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
}

.component_title_input_wrapper {
  width: 100%;
}

.input {
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 9px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #8697f0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input::placeholder,
.input::-webkit-input-placeholder,
.input:-moz-placeholder,
.input::-moz-placeholder,
.input:-ms-input-placeholder {
  color: #a3aacc;
}

.title_or_search {
  display: flex;
  align-items: center;
  height: 24px;
}

.search_icon_wrapper:hover {
  background: #dde2f9;
}
.search_icon_wrapper {
  height: 32px;
  width: 32px;
  padding: 6px;
  margin-left: 10px;
  border-radius: 6px;
}

.search_icon {
  width: 20px;
  cursor: pointer;
}

.search_icon2 {
  cursor: pointer;
  padding: 8px 8px 8px 7px;
  width: 35px;
}
.folder_icon {
  margin-right: 8px;
  width: 20px;
}

.folder_icon.favorite {
  margin-top: -3px;
}
.folder_icon path {
  stroke: #596080;
  fill: #dde1f5;
}
.page {
  width: 171px;
  height: 280px;
  margin: 0 19px 20px 0;
}
.emptyFavorite_container {
  margin: 194px auto 0;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 72px - 60px);
}
.emptyFavorite_icon_container {
  width: 72px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
}
.emptyFavorite {
  margin-top: 194px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.emptyFavorite svg path {
  stroke: #596080;
}
.emptyFavorite .star {
  top: 38px;
  position: absolute;
  left: 15px;
}
/*.emptyFavorite .star svg path {*/
/*    stroke: #202124;*/
/*}*/
.emptyFavorite img {
  width: 48px;
  height: 58.67px;
}

.emptyFavorite_mainText {
  margin: 13px auto 0;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #242633;
  text-align: center;
  width: 350px;
}

.emptyFavorite_infoText {
  margin-top: 8px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  text-align: center;
  width: 500px;
}

.preview:hover {
  border: 1px solid #0d26e5;
}
.preview {
  width: 171px;
  height: 250px;
  background-color: #fdf8f8;
  border: 1px solid #d7ddf7;
  border-radius: 8px;
  cursor: pointer;
}

.filters {
  display: flex;
  font-size: 14px;
}
.filters_option {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-right: 21px;
  display: flex;
  cursor: pointer;
  color: #a3aacc;
  white-space: nowrap;
}
.checkbox_wrapper {
  margin-left: 7px;
  width: 17px;
  height: 17px;
}
.filters_option img {
  /*height: 20px;*/
}
.filters_option_text {
  /*padding-left: 2px;*/
  color: #596080;
  margin-right: 4px;
}

.filters_no_folder_text {
  padding-left: 2px;
  color: #596080;
  width: 99px;
}
.filters_no_folder_text__active {
  padding-left: 2px;
  color: #0026e5;
  font-weight: 500;
  width: 99px;
}
.in_folder_toggler:hover {
  background: #dde2f9;
}
.in_folder_toggler:hover .in_folder_toggler_img {
  background: #dde2f9;
}
.in_folder_toggler:hover .in_folder_toggler_knob {
  background: #dde2f9;
}
.in_folder_toggler {
  width: fit-content;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 31px;
  padding: 0 11px 0 6px;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
}
.in_folder_toggler_text {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
}
.in_folder_toggler_img {
  margin-right: 9px;
  position: relative;
  display: flex;
  align-items: center;
  width: 24px;
  height: 12px;
  background: #f5f6ff;
  border: 1px solid #bbc1e1;
  border-radius: 12px;
  cursor: pointer;
  -webkit-transition-property: border;
  -moz-transition-property: border;
  -o-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  left: -1px;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.in_folder_toggler_img_right {
  border: 1px solid #0026e5;
}
.in_folder_toggler_knob {
  position: absolute;
  width: 12px;
  height: 12px;
  background: #f5f6ff;
  border: 1px solid #bbc1e1;
  border-radius: 50%;
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  left: -1px;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.in_folder_knob_left {
  left: -1px;
}
.in_folder_knob_right {
  border: 1px solid #0026e5;
  left: 11px;
}
.sort_and_view {
  display: flex;
  justify-content: flex-end;
  /*width: 400px;*/
  align-items: center;
  font-size: 14px;
}
.sort_wrapper {
  width: auto;
  margin-right: 17px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
}
.sort {
  height: 31px;
  /*margin-right: 17px;*/
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 8px 0 6px;
  cursor: pointer;
  position: relative;
  width: fit-content;
  white-space: nowrap;
}
.sort img {
  width: 20px;
  margin-right: 8px;
}
.sort:hover {
  background: #e8ebfa;
  border-radius: 7px;
}
.sort_active {
  background: #e8ebfa;
  border-radius: 7px;
}
.view {
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 6px;
}

.view:hover {
  background: #dde2f9;
}
.checkbox {
  margin: 2px 5px 1px 1px;
}

@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .checkbox {
      margin: 3px 6px 1px 1px;
      -webkit-transform: scale(1.2);
    }
  }
}
.create_new_page {
  border: 1px solid #a3aacc;
  box-sizing: border-box;
  border-radius: 8px;
  width: 170px;
  height: 250px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create_new_page:hover {
  border: 1px solid #0d26e5;
}

.create_new_page.isSideBar {
  width: 160px;
  height: 233px;
}
.create_new_page.isSlide {
  width: 210px;
  height: 158px;
}
.create_new_page.isComponents {
  width: 172px;
  height: 172px;
}
.create_new_page.isComponents.isSideBar {
  width: 162px;
  height: 162px;
}
.create_new_page.pdfPage {
  padding-top: 75px;
  justify-content: initial;
}
.create_new_playlist {
  width: 280px;
  height: 275px;
}
.create_new_page:hover {
  background: #e8ebfa;
}
.create_new_page.isSlide:hover {
  background: unset;
  cursor: no-drop;
}
.create_new_playlist {
  margin-bottom: 16px;
  margin-right: 16px;
  margin-top: 1px;
  border: 1px solid #a3aacc;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 275px;
  justify-content: center;
  align-items: center;
}
.create_new_playlist:hover {
  border: 1px solid #0d26e5;
}
.image_create_new_playlist {
  width: 72px;
  height: 72px;
}
.create_new_page_list {
  background: white;
}
.create_new_playlist svg path {
  stroke: #a3aacc;
}
.create_new_playlist:hover svg path {
  stroke: #0d26e5;
}
.create_new_playlist:hover {
  background: #e8ebfa;
}

.icon_page_40 {
  width: 40px;
  height: 40px;
}
.icon_page_40 path {
  /*stroke:#596080;*/
}
.icon_page_40.fat path {
  stroke-width: 1.7px;
}

.icon_page_60 {
  width: 60px;
  height: 60px;
}
.icon_page_60 path {
  /*stroke:#596080;*/
}
.icon_page_60.fat path {
  stroke-width: 1.7px;
}

.icon_page_68 {
  width: 68px;
  height: 68px;
}
.icon_page_68 path {
  /*stroke:#596080;*/
}
.icon_page_68.fat path {
  stroke-width: 1.7px;
}
.new_playlist_icon polygon {
  stroke-width: 1;
  fill: white;
  stroke: #a3aacc;
}
.create_new_playlist:hover .new_playlist_icon polygon {
  fill: #e8ebfa;
  stroke: #0d26e5;
}
.icon_page_20 {
  width: 20px;
  height: 20px;
}
.fat {
  margin-right: 2px;
  margin-bottom: 2px;
}
.icon_page_20.fat {
  margin-top: 2px;
}
.icon_page_20.fat path {
  stroke-width: 1.7px;
}
.font16 {
  font-weight: 500;
  font-size: 16px;
  color: #a3aacc;
}
.create_new_page:hover .font16 {
  color: #0d26e5;
}
.icon_new_page {
  width: 110px;
  margin-top: 8px;
}

.icon_new_recipe {
  width: 13px;
  margin-right: 4px;
}
.add_new_playlist {
  font-size: 12px;
  margin-top: 8px;
  color: #a3aacc;
  padding-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create_new_playlist svg path {
  stroke: #a3aacc;
}
.create_new_playlist:hover .add_new_playlist {
  color: #0d26e5;
}
.create_new_playlist:hover svg path {
  stroke: #0d26e5;
}
.add_new_page {
  margin-top: 6px;
  color: #a3aacc;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_new_page.component {
  margin-top: 3px;
}
.add_new_page.pdfPage {
  margin-top: 0;
}
.add_new_page svg path {
  stroke: #a3aacc;
}
.create_new_page svg path {
  stroke: #a3aacc;
}
.create_new_page:hover svg path {
  stroke: #0d26e5;
}
.new_page_text {
  width: fit-content;
  white-space: nowrap;
  font-size: 13px;
  margin-left: 3px;
}
.create_new_page:hover .new_page_text,
.create_new_playlist:hover .new_page_text {
  color: #0d26e5;
}
.page_unmount {
  width: 171px;

  height: 280px;
  margin: 0 18px 20px 0;
  animation: fadein 1.5s;
  -moz-animation: fadein 1.5s; /* Firefox */
  -webkit-animation: fadein 1.5s; /* Safari and Chrome */
  -o-animation: fadein 1.5s;
}
.playlists_balancer {
  width: 776px;
  padding-left: 15px;
}
/*.page:hover{*/
/*    background-color: red;*/
/*}*/

@keyframes fadein {
  0% {
    opacity: 1;
    width: 171px;
  }
  30% {
    width: 160px;
    opacity: 0.3;
    margin: 0;
  }

  60% {
    width: 60px;
    opacity: 0.1;
    margin: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    margin: 0;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  0% {
    opacity: 1;
    width: 171px;
  }
  30% {
    width: 160px;
    opacity: 0.3;
    margin: 0;
  }

  60% {
    width: 60px;
    opacity: 0.1;
    margin: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    margin: 0;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  0% {
    opacity: 1;
    width: 171px;
  }
  30% {
    width: 160px;
    opacity: 0.3;
    margin: 0;
  }

  60% {
    width: 60px;
    opacity: 0.1;
    margin: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    margin: 0;
  }
}
@-o-keyframes fadein {
  /* Opera */
  0% {
    opacity: 1;
    width: 171px;
  }
  30% {
    width: 160px;
    opacity: 0.3;
    margin: 0;
  }

  60% {
    width: 60px;
    opacity: 0.1;
    margin: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    margin: 0;
  }
}

.selection_border {
  background: rgba(0, 162, 255, 0.4);
  position: fixed;
  z-index: 199;
}

.folderTitleInputWrapper {
  margin-right: 2px;
}
.folderTitleInputWrapper input {
  width: 100%;
}

.component_title.disabled {
  pointer-events: none;
}
.metaSideBar {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.flexStart {
  justify-content: flex-start;
}
.emptyCentered {
  margin-left: auto;
  margin-right: auto;
  margin-top: 194px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
