.mainWindow {
  min-height: 100vh;
  width: 100%;
  background-color: #fafaff;
  padding: 0 60px 10px 60px;
  height: 100vh;
  overflow: auto;
}

.page {
  display: flex;
  overflow: hidden;

  &_wrapper {
    display: flex;
    flex-direction: column;
  }

  &_title_wrapper {
    display: flex;
    align-items: center;
    color: #484343;
    font-family: RobotoFlex, sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding-left: 31px;
    padding-top: 16px;

    svg {
      height: 19px;
      width: 19px;
    }

    h3 {
      margin-left: 9px;
      text-align: center;
    }
  }
}

.loader_wrapper {
  width: 100%;
  height: calc(100vh - 159px);
  overflow: hidden;
}

.menu_icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.addColumn {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  align-items: center;
  height: 183px;
  width: 15px;
  margin-right: 3px;
  justify-content: space-between;
  opacity: 0;
  cursor: pointer;
}

.hoverHandler {
  display: flex;
  &.isHide {
    width: 0;
    opacity: 0;
    visibility: hidden;
    & .column {
      display: none;
    }
  }

  &:hover {
    & .addColumn {
      opacity: 1;
    }
  }
}
.group_cardsWithCounters {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  gap: 24px;
  margin: 20px 0;
}

.group_cardsWithStatistic {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  gap: 24px;

  & .cardWithStatistic {
    padding: 16px;
    width: 692px;
    height: 257px;
    background: #fcfdff;
    border-radius: 16px;
    //margin: 24px;
    & .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #1f2855;
    }
  }
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;

  &.disable_scroll {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  &.many_playlists {
    height: calc(100vh - 64px);
  }
}

.scroll::-webkit-scrollbar {
  width: 8px;
  background-color: #fafaff;
}

.scroll::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 8px;
  background-color: #e2e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.scroll::-webkit-scrollbar-track-piece {
  display: none;
  background-color: #fafaff;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.content {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  overflow-x: hidden;
}

.card_content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 200px;
  width: 670px;
}
.row {
  display: flex;
}

.header {
  & .text {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    line-height: 30px;
  }
  margin: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.header_content {
  display: flex;
  width: 100%;

  & .header_content_item {
    font-weight: 400;
    font-size: 14px;
    color: #596080;
  }
}

.content_item {
  font-weight: 400;
  font-size: 16px;
  color: #242633;
  margin-top: 5px;
}

.content_item_order {
  &:nth-child(1) {
    width: 322px;
  }

  &:nth-child(2) {
    width: 198px;
  }

  &:nth-child(3) {
    text-align: right;
    width: 140px;
  }
}
.wrap_column {
  display: flex;
}

.column_group {
  display: flex;
  flex-direction: row;
  max-width: calc(100vw - 571px);
  overflow-y: hidden;
  padding-top: 23px;
  padding-left: 16px;
  width: 100%;
  height: calc(100vh - 69px);

  &::-webkit-scrollbar {
    //width: 8px;
    height: 8px;
    background-color: #fafaff;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #fafaff;
  }

  .column_separator {
    //width:10px;
    transition: width 0.3s;
    height: 100%;
    flex-shrink: 0;
    //transition-timing-function: cubic-bezier(0.0, 0.19, 0.07, 1.23);
    //width: 100%;
    &.column_drag_hover {
      width: 70px;
    }
  }
}

.column {
  width: 269px;
  flex-shrink: 0;
  //margin:  6px 10px;

  &.column_hover {
    //border: 1px solid rgba(255, 0, 41, 0.25);
    // box-shadow: 0 0 6px rgba(255, 0, 41, 0.48);
  }

  & .column_item_group {
    height: calc(100vh - 128px);
    //height: calc(100vh - 208px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    //&::-webkit-scrollbar-track{
    //background: #F5F6FF;
    //background-color: #be3b3b;
    //}

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fafaff;
    }
    &::-webkit-scrollbar-thumb {
      display: block;
      border-radius: 8px;
      background-color: #e2e1e1;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track-piece {
      display: none;
      background-color: #fafaff;
    }

    & .column_item {
      height: 120px;
      border: 1px solid rgba(150, 135, 138, 0.19);
      background-color: white;
      border-radius: 8px;
      //margin: 10px 0 ;
      &.draggable_item {
        opacity: 0.5;
      }
    }

    & .card {
      margin-top: 15px;

      svg {
        margin-left: 5px;
        max-width: 250px;
      }
    }
    & .separator {
      height: 0;
      transition: height 0.3s;

      //transition-timing-function: cubic-bezier(0.0, 0.19, 0.07, 1.23);
      width: 100%;
      &.card_hover {
        height: 1px;
        margin: 10px 0;
        background-color: red;
      }
    }
  }
}

.welcome_content_wrapper,
.online_content_wrapper {
  width: 100%;
  .title {
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    color: #000000;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .hint_wrapper {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .hint_container {
    width: fit-content;
    background-color: #e8ebfa;
    padding: 0 16px;
    border-radius: 6px;
    height: 32px;
    border: 1px solid #d7ddf7;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    font-family: "RobotoFlex", sans-serif;
    cursor: pointer;
    color: #596080;
    transition: all ease-in-out 0.15s;
    &:hover {
      background-color: #d7ddf7;
      color: #242633;
    }
  }
}

.welcome_content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &.list_view {
    flex-direction: column;
  }
}

.online_content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &.list_view {
    flex-direction: column;
  }
}

.gridViewIcon {
  width: 32px;
  margin: 0 10px;
}

.zActive {
  z-index: 110;
}
