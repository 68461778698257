.wrapper2_sideBar {
  background: #fff;
  display: flex;
  height: calc(100vh - 63px);
  flex-direction: column;
  width: auto;
  min-width: 443px;
}

.playlists_balancer {
  width: 100%;
}
.sideBarLib__content {
  padding: 0 0 0 0;
  background: #fff;
}
.sideBarLib__content.empty {
  padding-left: 16px;
}
.isEdit {
  padding-bottom: 40px;
}
.sideBar_block {
  z-index: 101;
  position: relative;
  right: 0;
  height: 100vh;
  background: #fff;
  width: 0;
  transition: width 0.3s cubic-bezier(0.19, 0.45, 0.8, 0.43);
  visibility: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
}

.sideBar_block2 {
  width: 534px;
  visibility: visible;
}
.sideBar_hidden {
  display: none;
}
.sideBar_block_expanded {
  width: 100%;
  min-width: calc(100vw - 472px);
}
.sideBar_483 {
  width: 483px;
}
.sideBar_scroll_separator {
  height: 1px;
  width: 100%;
  background: #e8ebfa;
}

.sideBar_scroll_parent {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 163px);
  &.editPage {
    height: calc(100vh - 163px);
  }
}
.sideBar_scroll_parent.playlists {
  height: calc(100vh - 112px);
}
.sideBar_scroll_parent.playlists_with_confirm {
  height: calc(100vh - 172px);
}
.sideBar_scroll_parent::-webkit-scrollbar {
  width: 4px;
  background-color: #ffffff;
}
.sideBar_scroll_parent::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 4px;
  background-color: #e2e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.sideBar_scroll_parent::-webkit-scrollbar-track-piece {
  display: none;
  background-color: #ffffff;
}

.sideBar_scroll_parent::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sideBarLib__header_container {
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  padding-left: 13px;
  padding-right: 16px;
  background: #ffffff;
}

.sideBarLib__header_tabs_container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    align-items: center;
    //width: clamp(330px, 40%, 600px);

    .tab {
      position: relative;
      padding: 5px 6px 6px 7px;
      margin-right: 10px;
      cursor: pointer;
      border-radius: 4px;
      width: fit-content;
      height: 32px;
      display: flex;
      justify-content: left;
      align-items: center;
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      white-space: nowrap;
      &:hover {
        background: #e8ebfa;
      }
      &.active {
        background: #e8ebfa;
      }
    }
    .lib_tab {
      min-width: 62px;
    }
  }

  .expand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    position: relative;
    padding: 5px 6px 6px 7px;
    margin-right: 6px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background: #e8ebfa;
    }

    &.active {
      background: #e8ebfa;
    }
  }
  .plus_button {
    position: relative;
    font-family: "RobotoFlex", sans-serif;
    border: 1px solid #0026e5;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #0026e5;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: #0026e5;
      }
    }

    &.active {
      background: #e8ebfa;
    }
  }
  .close_button {
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #0026e5;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
  }
}

.sideBarLib__orders {
  margin-bottom: 20px;
}
.cards_content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100%;
  //padding: 4px 46px 20px 77px;
}
.rows_content {
  padding: 9px 40px 20px 0;
}

.emptyNoResults_sideBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 4.6vh auto 0 auto;
}
.emptyNoResults_sideBar img {
  width: 64px;
  height: 64px;
}
.emptyNoResults_sideBar .text_container img {
  width: 24px;
  height: 24px;
}

.emptyNoResults_sideBar_height_compensation {
  margin: 7.7vh auto 0 auto;
}

.emptyNoResults_mainText_sideBar {
  margin-top: 23px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #242633;
  text-align: center;
}

.confirm {
  position: absolute;
  bottom: 0;
  width: 534px;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  padding-top: 11px;
  padding-right: 16px;
  border-top: #d7ddf7 solid 1px;
  background: #fff;
  &_cancel {
    width: 68px;
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #596080;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 6px;
    &:hover {
      background-color: #e8ebfa;
    }
  }
  &_create {
    height: 32px;
    //border: 1px solid #0026E5;
    border-radius: 6px;
    color: #0026e5;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
//
.emptyNoResults_infoText_sideBar {
  margin-top: 8px;
  margin-bottom: 23px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  text-align: center;
}
.addFile_wrapper_sideBar {
  position: relative;
}
.multiSelectPanelWrapper_sideBar {
  height: 1px;
  bottom: 0;
  left: 55px;
  position: sticky;
  z-index: 100;
}
.none {
  display: none;
}

.metaSideBar {
  position: absolute;
  top: 0;
  right: -6px;
  min-height: 100%;
  width: 100%;
  z-index: 103;
  background-color: white;
  &.isPlaylist {
    z-index: 110;
  }
  &.off {
    width: 0;
  }
}
