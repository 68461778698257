.addImage {
  width: 696px;
  min-height: 120px;
  background: #f5f6ff;
  border-radius: 12px;
  display: flex;
}

.circle_loader_wrapper {
  z-index: 15;
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.circle_loader {
  position: relative;
  width: 60px;
  height: 60px;
  //background: #F5F6FF;
  /*top: 38px;*/
  /*left: 46px;*/
  text-indent: -9999em;
  border-radius: 50%;
  border-top: 1px solid #0026e5;
  border-right: 1px solid #0026e5;
  border-bottom: 1px solid #d7ddf7;
  border-left: 1px solid #0026e5;
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite ease;
  animation: load8 1.1s infinite ease;
  /*animation:  1.1s infinite linear;*/
}
.uploading_text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  font-size: 10px;
}
.menu_option {
  padding-left: 9px;
  display: flex;
  justify-content: space-between;
  color: #596080;
  width: calc(100% - 104px);
  .name {
    padding-top: 39px;
    font-size: 16px;
  }
  .description {
    padding-top: 5px;
    font-size: 12px;
  }
  & .cancel {
    height: 120px;
    width: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    & .cancel_image_wrapper {
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      &:hover {
        background-color: #e8ebfa;
      }
    }
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    /*border-radius: 50%;*/
  }
  25% {
    /*-webkit-transform: rotate(180deg);*/
    /*transform: rotate(180deg);*/
    /*border-radius: 20%;*/
  }
  50% {
    /*-webkit-transform: rotate(60deg);*/
    /*transform: rotate(60deg);*/
    /*border-radius: 50%;*/
  }
  75% {
    /*-webkit-transform: rotate(50deg);*/
    /*transform: rotate(50deg);*/
    /*border-radius: 20%;*/
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    /*border-radius: 50%;*/
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    /*border-radius: 50%;*/
  }
  25% {
    /*-webkit-transform: rotate(180deg);*/
    /*transform: rotate(180deg);*/
    /*border-radius: 20%;*/
  }
  50% {
    /*-webkit-transform: rotate(60deg);*/
    /*transform: rotate(60deg);*/
    /*border-radius: 50%;*/
  }
  75% {
    /*-webkit-transform: rotate(50deg);*/
    /*transform: rotate(50deg);*/
    /*border-radius: 20%;*/
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    /*border-radius: 50%;*/
  }
}

.imageWrapper {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.buttonsBar {
  display: flex;
  padding-top: 13px;
  .tab {
    padding: 0 6px;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    margin-right: 4px;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #596080;

    &:hover {
      background: #e8ebfa;
    }
    &.active {
      font-style: normal;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 12px;

      /* identical to box height, or 100% */
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      /* UI/UI 1 */
      color: #242633;
    }
  }

  .tab_computer {
    width: 85px;
  }
}

.navigation_slider {
  position: relative;

  .long_line {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -4px;
    background: #e4e7f7;
  }

  .underline_computer {
    transition-property: width, left;
    position: relative;
    height: 2px;
    left: 0;
    width: 85px;
    bottom: -3px;
    background: #0026e5;
    transition-duration: 0.4s;
  }

  .underline_embed {
    transition-property: width, left;
    position: relative;
    height: 2px;
    left: 89px;
    width: 86px;
    bottom: -4px;
    background: #0026e5;
    transition-duration: 0.4s;
  }
  .underline_google {
    transition-property: width, left;
    position: relative;
    height: 2px;
    left: 190px;
    width: 114px;
    bottom: -4px;
    background: #0026e5;
    transition-duration: 0.4s;
  }
  .underline_dropbox {
    transition-property: width, left;
    position: relative;
    height: 2px;
    left: 319px;
    width: 83px;
    bottom: -4px;
    background: #0026e5;
    transition-duration: 0.4s;
  }
}

.menu {
  width: 80%;
}

.file_picker {
  position: relative;
  font-family: "RobotoFlex", sans-serif;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 101px;
  height: 32px;
  color: #0026e5;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  margin-right: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  &:hover {
    background: #dde2f9;
  }
}

.option {
  display: flex;
  align-items: center;
  min-height: 75px;
}

.google_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 560px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.embed_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 432px;
  padding-top: 7px;

  .embed_input_wrapper {
    display: flex;
    align-items: center;
    width: 364px;
    height: 32px;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    padding-left: 5px;
    background: rgba(248, 248, 248, 0.84);

    .embed_input {
      width: 332px;
      height: 21px;
      border: none;
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      background: rgba(248, 248, 248, 0.84);

      &::placeholder {
        color: #a3aacc;
      }
    }
  }

  .embed_submit {
    position: relative;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: "RobotoFlex", sans-serif;
    border: 1px solid #0026e5;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 32px;
    color: #0026e5;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
    padding: 0 7px;
  }
}

.icon_margin {
  margin-right: 2px;
}
