//* {
//    box-sizing: border-box;
//}
.custom_row_placeholder.custom_row_placeholder.custom_row_placeholder {
  padding: 0.25rem 0 0.25rem 0;
}
.table_in_row_view {
  * {
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    font-size: 14px;
  }

  table,
  .divTable {
    //width: fit-content;
  }

  .tr {
    display: flex;
  }

  tr,
  .tr {
    width: fit-content;
    height: 30px;
  }

  th,
  .th,
  td,
  .td {
    //box-shadow: inset 0 0 0 1px lightgray;
    padding: 0.25rem 0.25rem 0.25rem 0;
  }

  th,
  .th {
    padding: 2px 4px 2px 0;
    position: relative;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    text-align: center;
    height: 30px;
  }

  td,
  .td {
    height: 30px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;

    //img {
    //    width: 30px;
    //    height: 30px;
    //    object-fit: cover;
    //    position: relative;
    //    top: 2px;
    //}
  }

  tr {
    th {
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #585858;
    }

    th:nth-child(1) {
      width: 40px;
    }

    th:nth-child(2) {
      width: 40px;
    }

    th:last-child {
      text-align: right;
    }

    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #585858;
    }

    td:nth-child(3) {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #343434;
      & div {
        width: 100%;
        height: 100%;
      }
      & span {
        height: 100%;
      }
    }
  }

  .fui-TableHeaderCell__aside {
    div {
      width: 24px;
    }
  }

  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }

  tbody {
    tr:not(.custom_row) td:first-child {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      padding-left: 10px;
    }

    tr td:nth-child(2) {
      padding-left: 0;
    }

    tr:has(td div div span div.disrupt_opacity) {
      background-color: #ffeebf;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;

      td {
        opacity: 0.5;
      }

      td:last-child {
        opacity: 1;

        .actions_svg_wrapper {
          opacity: 0;
        }

        .actions_svg_wrapper-favorite {
          opacity: 0.5;
        }
      }
    }
    tr:has(td div div span .hovered_by_meta) {
      border: 2px solid #ffc72c;
      box-shadow: 0 0 12px rgba(255, 199, 44, 0.75);
    }

    tr:has(td div div span div.hide_opacity) {
      background-color: transparent;

      td {
        opacity: 0.2;
      }

      td:last-child {
        opacity: 1;

        .actions_svg_wrapper {
          opacity: 0.2;
        }

        .hide_opacity {
          opacity: 1;
        }
      }
    }

    tr td:last-child {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }

    tr:not(.custom_row):hover {
      cursor: pointer;
      background: #ffeebf;
      border-radius: 8px;

      .actions_fluent {
        .actions_svg_wrapper {
          visibility: visible;
        }
      }
    }

    tr {
      height: 32px;
    }

    tr td {
      height: 32px;
    }
  }

  thead {
    tr {
      th {
        border-bottom: 1px rgba(29, 29, 29, 0.08) solid;

        button {
          font-weight: 500;

          .custom_header {
            width: 100%;
            padding-left: 10px;
          }

          .in_recent_mode {
            display: none;
          }
        }
      }

      th:hover {
        button {
          .svg_arrow_wrapper {
            visibility: visible;
          }

          background-color: #ffeebf;
          border-radius: 6px;
        }
      }

      border-bottom: 1px rgba(29, 29, 29, 0.08) solid;
    }

    tr:has(th button .custom_header .in_recent_mode) {
      button {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
.table_in_row_view.disable_headers {
  thead {
    visibility: hidden;
  }
}
.table_in_row_view.make_before_great_again {
  tbody:before {
    line-height: 48px;
  }
}
.no_resize div{
  resize: none;
  cursor: default;
}
.no-resize th {
  cursor: default;
}
.table_for_lib.disable_headers {
  thead {
    visibility: hidden;
  }
}
.table_for_lib.make_before_great_again {
  tbody:before {
    line-height: 48px;
  }
}

@keyframes blinking_touch {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #ffe9ad;
  }
  100% {
    background-color: #ffffff;
  }
}
.table_in_row_view_upv {

  .blink_it.blink_it.blink_it {
    animation: blinking_touch 1s;
    animation-iteration-count: 2;
  }
  .selectedItem{
    background-color: #ffeebf;
  }
  * {
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    font-size: 14px;
  }

  table,
  .divTable {
    transition: all ease-in-out 0.4s;
    width: calc(100vw - 580px);
    max-width: 1020px;
  }
  @media screen and (max-width: 1400px) {
    table,
    .divTable {
      width: calc(100vw - 405px);
    }
  }
  .tr {
    display: flex;
  }

  tr,
  .tr {
    width: fit-content;
    height: 30px;
  }

  th,
  .th,
  td,
  .td {
    //box-shadow: inset 0 0 0 1px lightgray;
    padding: 0.25rem 0.25rem 0.25rem 0;
  }

  th,
  .th {
    padding: 2px 4px 2px 8px;
    position: relative;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    text-align: center;
    height: 30px;
  }

  td,
  .td {
    height: 30px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  tr:not(.custom_row) {
    th {
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #585858;
    }

    th:nth-child(1) {
      padding-left: 26px;
    }

    th:nth-child(2) {
      //width: 40px;
    }

    th:last-child {
      text-align: right;
    }

    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #585858;
    }

    td:nth-child(3) {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #343434;
    }
  }

  .fui-TableHeaderCell__aside {
    div {
      width: 24px;
    }
  }

  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }

  tbody {
    tr {
      .actions_fluent {
        visibility: hidden;
      }
    }
    tr:not(.custom_row) td:first-child {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      padding-left: 12px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      color: #585858;
    }

    tr td:nth-child(2) {
      font-size: 14px;
      font-weight: 500;
      color: #585858;
    }

    tr td:last-child {
      //padding-left: 10px;
      //div:first-of-type{
      //    display: flex;
      //    justify-content: flex-end;
      //    width: 100%;
      //}
    }
    tr:not(.custom_row) td:last-child > div:first-of-type {
      //padding-left: 10px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    tr:has(td div div span div.disrupt_opacity) {
      background-color: #ffeebf;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;

      td {
        opacity: 0.5;
      }

      td:last-child {
        opacity: 1;

        .actions_svg_wrapper {
          opacity: 0;
        }

        .actions_svg_wrapper-favorite {
          opacity: 0.5;
        }
      }
    }

    tr:has(td div div span div.hide_opacity) {
      background-color: transparent;

      td {
        opacity: 0.2;
      }

      td:last-child {
        opacity: 1;

        .actions_svg_wrapper {
          opacity: 0.2;
        }

        .hide_opacity {
          opacity: 1;
        }
      }
    }

    tr td:last-child {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }

    tr.backlight {
      background: #ffeebf;
      border-radius: 8px;
    }
    tr:not(.custom_row):hover {
      cursor: pointer;
      background: #ffeebf;
      border-radius: 8px;

      .actions_fluent {
        visibility: visible;
      }
      .index_svg {
        visibility: visible;
      }
      .index_wrapper {
        display: none;
      }
    }

    tr {
      height: 32px;
    }

    tr td {
      height: 32px;
    }
  }
  tr:hover {
    .table_six_dots {
      display: flex;
    }
    .table_delete_icon {
      display: flex;
    }
  }

  thead {
    tr {
      th {
        border-bottom: 1px rgba(29, 29, 29, 0.08) solid;
      }
      th th {
        padding_left: 10px;
        border-bottom: 1px rgba(29, 29, 29, 0.08) solid;

        button {
          font-weight: 500;

          .custom_header {
            width: 100%;
            //padding-left: 10px;
          }

          .in_recent_mode {
            display: none;
          }
        }
      }

      th:hover {
        background-color: #ffeebf;
        border-radius: 6px;
        button {
          .svg_arrow_wrapper {
            visibility: visible;
          }
        }
      }

      border-bottom: 1px rgba(29, 29, 29, 0.08) solid;
    }

    tr:has(th button .custom_header .in_recent_mode) {
      button {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
.table_in_row_view_upv.large_expand {
  table,
  .divTable {
    width: calc(100vw - 700px);
    max-width: 1100px;
    position: relative;
    left: -7px;
  }
}


.cursed_browser.cursed_browser.cursed_browser.cursed_browser.cursed_browser {
  width: unset;
}

.cell-wrapper {
  span {
    overflow: hidden;
  }
}

.title_row{
  display: flex;
  & .actions_fluent{
    visibility: visible;

  }
}

.table_in_row_view.is_recent {
  .fui-TableHeaderCell__aside {
    div {
      pointer-events: none;
    }
  }
}