@import "./src/_mixins";

.empty {
  font-size: 14px;
  line-height: 21px;
}
.single_item {
  width: 91px;
  height: 32px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #d7ddf7;
  }
  & .text {
    @include ellipsis;
    max-width: 84px;
  }
}
.drop_scroll {
  position: relative;
  min-height: 32px;
  max-height: 208px;
  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
    right: -4px;
  }
  &::-webkit-scrollbar-thumb {
    position: absolute;
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.13);
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
}

.drop_list_wrapper {
  position: relative;
}
.drop_list_container {
  position: absolute;
  left: -7px;
  width: 240px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.list_item {
  position: relative;
  right: 0;
  padding-left: 16px;
  padding-right: 4px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 21px;
  color: #596080;
  //height: 32px;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 500;
    font-size: 14px;
    color: #596080;
    margin-top: 6px;
    margin-bottom: 4px;
  }

  .check_icon {
    visibility: hidden;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  &:hover {
    background: #e8ebfa;
    & .title {
      color: #242633;
    }
    & .description {
      color: #596080;
    }

    & svg path {
      stroke: #242633;
    }
  }
  & .text {
    @include ellipsis;
    text-align: center;
    max-width: 192px;
  }
  & .icon {
    margin: 4px 8px 4px 0;
    min-width: 16px;
  }

  &.active {
    & .check_icon {
      visibility: visible;
    }
    & .title {
      color: #0026e5;
    }
    background: #d7ddf7;
  }
}

.drop_list_button {
  position: relative;
  max-width: 111px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  padding: 0 4px;
  &.active {
    background: #d7ddf7;
  }
  &:hover {
    background: #d7ddf7;
  }
  &.blocked {
    color: grey;
  }
  &.viewOnly {
    background: initial;
  }
  &.viewOnly:hover {
    background: initial;
  }

  & svg {
    margin-left: 5px;
  }
}

.drop_list_invitedUser_hint {
  position: absolute;
  bottom: calc(100% - 10px);
  left: 100%;
  height: 4px;
  width: 4px;
  color: red;
  background: red;
  border-radius: 2px;
  font-size: 9px;
  transition: all ease-out 0.25s;

  display: flex;
  align-items: center;
  justify-content: center;
  @include ellipsis();

  &:hover {
    border-radius: 2px;
    width: 80px;
    height: 12px;
    color: white;
  }
}
