@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}
.wrapper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 101;
}
.container {
  width: 117px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  background: white;
  border: 0.6px solid #ece2e2;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 0px #7a69691a;

  &.disabled {
    border: 0.6px solid #ECE2E2;
    opacity: 0.3;
    cursor: not-allowed;
  }
}
.display_item {
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: not-allowed;
  padding: 2px 2px 2px 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: initial;
  width: 100px;
}
.item {
  height: 24px;
  width: 158px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: not-allowed;
  padding: 2px 2px 2px 9px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #596080;
  &.active {
    background-color: #e8ebfa;
    color: initial;

    &:hover {
      background-color: #d7ddf7;
    }
  }
  &.disabled {
    color: lightgrey;
    cursor: not-allowed;
    &:hover {
      background-color: initial;
    }
    &.active {
      background-color: #e8ebfa;
      color: lightgrey;

      &:hover {
        background-color: #e8ebfa;
      }
    }
  }
}
.chevron_container {
  width: 32px;
  height: 32px;
  padding: 4px;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out all 0.4s;
  & .svg path {
    stroke: #596080;
  }

  &.active {
    transform: rotate(180deg);
  }
}
.drop_down_wrapper {
  background-color: rgb(245, 246, 255);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  position: absolute;
  transition: ease-in-out all 0.4s;
  overflow: hidden;
  &.active {
    border: 1px solid #d7ddf7;
    padding-bottom: 10px;
    padding-top: 20px;
  }
}
.content {
  display: block;
  flex-direction: column;
}

.input_wrapper {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #d7ddf7;
  padding: 2px 6px;
  margin: 5px;
  width: 148px;
  overflow: hidden;
  position: relative;
  background-color: white;

  & input {
    border: none;
    background-color: inherit;
  }
}

.close_icon {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 1px;
  top: 1px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  &:hover {
    background-color: #e8ebfa;
  }
}
.scroll {
  height: calc(100% - 28px);
}
