@import "./src/_mixins";

.wrapper {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
}
.icon_container {
  padding-bottom: 4px;
}
.header_wrapper {
  height: 64px;
  display: grid;
  //grid-template-columns: 33% 33% 33%;
  justify-content: center;
  padding: 16px 24px 16px 26px;
  border-bottom: 1px solid #d7ddf7;

  &.pushTop {
    padding-top: 0;
  }

  & .middle_wrapper {
    //min-width: 300px;
    width: max-content;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    //left: 50%;
    //top: 32px;
    //transform: translate(-50%, -50%);
    //max-width: 344px;
    z-index: 1;
    background: white;

    & .middle_section {
      min-width: 344px;
      height: 31px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      & svg path {
        stroke: #596080;
      }

      & .text {
        font-size: 12px;
        line-height: 12px;
        margin-left: 8px;
        letter-spacing: 0.05em;
        white-space: nowrap;
      }

      & .favorite {
        &:hover svg path {
          stroke: #ff8a00;
        }

        &.active {
          color: #ff8a00;

          & svg path {
            stroke: #ff8a00;
            fill: #ff8a00;
          }
        }
      }

      & .item {
        cursor: pointer;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        height: 32px;
        padding: 9px 7px;
        border-radius: 8px;

        &:hover {
          background: #e8ebfa;
        }

        &.active {
          //background: #E8EBFA;
        }
        &.disabled {
          color: #a2a7b2;
          background: none;
          pointer-events: none;
          & svg path {
            stroke: #a2a7b2;
          }
        }

        & .visit_link {
          svg {
            width: 22px;
            height: 22px;
          }
        }
      }

      & .more {
        position: relative;

        &.tooltip > div > div {
          display: flex;
          align-items: center;
        }

        & > div {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        &.active {
          background-color: #e8ebfa;
        }
      }
    }
  }
  .side_sections {
    //min-width: 300px;
    //width: 100%;
    width: max-content;
    background-color: white;
    min-height: 32px;
    position: relative;
    & .text {
      //width: calc(100% - 36px);
      width: max-content;
      @include ellipsis;
      margin-left: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      letter-spacing: initial;
      display: flex;
      align-items: center;
      height: 32px;
      &.inputActive {
        //margin-left: 28px;
        padding-top: 4px;
      }
    }
    &.title:first-child > div:first-child > div {
      width: 20px;
      height: 24px;
    }
    &.title:first-child > div:first-child {
      bottom: 4px;
      left: 0;
    }
    &.title {
      display: flex;
      align-items: center;
      & input {
        padding-left: 6px;
        padding-top: 0;
        /*margin-top: 8px;*/
        min-height: 26px;
        background: #ffffff;
        border: 1px solid #c7cad1;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        width: 100%;
        z-index: auto;
        overflow-wrap: break-word;
        resize: none;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        letter-spacing: 0.1px;
        line-height: 21px;
        color: #242633;
      }
      input::placeholder,
      input::-webkit-input-placeholder,
      input:-moz-placeholder,
      input::-moz-placeholder,
      input:-ms-input-placeholder {
        color: #a3aacc;
      }
    }

    &.right {
      display: flex;
      justify-content: flex-end;
    }

    &.isAbsolute {
      position: absolute;
      right: 40px;
      top: 16px;
    }

    & .close {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      background-color: white;
      border: 1px solid #d7ddf7;
      &:hover,
      &.active {
        background: #e8ebfa;
      }
    }
  }
}
//==
.content_wrapper {
  height: calc(100vh - 64px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &.isImagePlayer {
    height: auto;
    width: auto;
  }
  &.isPlayerHeight {
    //height: initial;
    height: calc(100vh - 125px);
    //height: inherit;
  }
  &:hover{
    .controls_container {
      opacity: 1;
    }
  }
}
.content_wrapper.isExpandHeight {
  height: auto;
}
.content_wrapper.isWhiteGallery {
    height: auto;
}



.side_controls_container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 10;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}
.content_side_controls {
  width: 100px;
  height: 400px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover .container {
    background: #e8ebfa;
  }
  & .container {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  &.disabled {
    cursor: no-drop;
    opacity: 0.3;

    &:hover .container {
      background: inherit;
    }
  }
}
.content_main_section {
  //overflow-y: auto;
  //overflow-x: auto;
  min-height: 200px;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.25s;
  & .pdf_canvas {
    ::selection{
      background: #ffc72c;
      color: #0e0e0e;
    }
  }
}
.content_main_section::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #ffffff;
}
.content_main_section::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 8px;
  background-color: #e2e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.content_main_section::-webkit-scrollbar-track-piece {
  display: none;
  background-color: #ffffff;
}

.content_main_section::-webkit-scrollbar-track {
  border-radius: 10px;
}

.content_main_section.isPlayerTOCCardView {
  transform: scale(0.135) translate(1%, -327%);
}
.content_main_section.isPlayerTOCRowView {
  transform: scale(0.0455) translate(-1%, -1053%);
}
.content_main_section.isPlayerPreview {
  transform: scale(0.097) translate(0%, 11%);
}
.content_main_section.noAlignment {
  align-items: start;
}
.content_main_section.isWhiteGallery {
  height: auto;
  max-height: calc(100vh - 64px);
}
.content_main_section.isWhiteGallery.isPdf {
  height: auto;
  max-height: initial;
}
.isPlayerPadding {
  //margin-top: 20px;
  //margin-bottom: 60px;
}

.content_bottom_controls {
  position: absolute;
  width: auto;
  height: 40px;
  z-index: 1;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: flex;
  color: #596080;
  & svg path {
    stroke: #596080;
  }

  & .item {
    &.tooltip {
      padding: 0;
      width: 100%;
      display: flex;
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      & > div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          //border-radius: 6px;
        }
      }
      &.side {
        width: auto;
      }
    }

    //border-radius: inherit;
    cursor: pointer;
    display: flex;
    padding: 6px;
    margin: 0;
    height: 100%;
    background-color: white;
    border-radius: 6px;
    &.disabled {
      cursor: no-drop;
      opacity: 0.3;
      &:hover {
        background-color: inherit;
      }
    }
    &.active,
    &:hover,
    &:hover .grow {
      background-color: #e8ebfa;
    }
    &.grow {
      height: 100%;
      flex-grow: 1;
      padding: 13px 13px;
    }
    &.percent {
      margin: 8px 9px;
    }
    &.pages.pages.pages {
      padding: 8px 9px;
    }
    &.text {
      height: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      &.bold {
        font-weight: 900;
        font-family: "Roboto", sans-serif;
      }
      & .slash {
        margin: 0 4px;
      }
    }
  }
  & .separator {
    z-index: 2;
    width: 1px;
    height: 100%;
    margin: 0 2px;
    background-color: #e8ebfa;
  }
  & .section {
    border-radius: inherit;
    min-width: 128px;
    display: flex;
    align-items: center;
  }
  & .wrap {
    padding: 3px;
    &.last {
      padding-right: 6px;
    }
    &.first {
      padding-left: 6px;
    }
  }
}
.fullScreenImage {
  display: flex;
  margin: auto;
  transition:
    height,
    ease 0.4s;
  justify-content: center;
  align-items: center;
  & img {
    height: 100%;
    //width: 100%;
  }
  & img.svg_pic {
    //height: 100%;
    //width: 100%;
  }
  &.isPlayer {
    margin: initial;
  }
  &.isPlayerTOCPreview {
    margin: initial;
  }
}

.preview_pic_Player {
  height: auto;
  width: 114px;
}
img.preview_pic_TOCPlayer {
  width: auto;
  height: 113px;
  object-fit: cover;
  transition: all ease-out 0.25s;
}
img.preview_pic_TOCPlayer.row {
  width: auto;
  height: 48px;
  object-fit: cover;
}
.content_image_section {
  overflow-y: auto;
  overflow-x: auto;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;

  &.isPlayer {
    height: initial;
  }
  //background-color: crimson;
}

.archive,
.msWord {
  width: 35%;
  margin: 190px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.isPlayer {
    margin: initial;
    width: 400px;
    height: 400px;
    justify-content: center;
  }

  & .icon_wrapper {
    margin-bottom: 29px;
  }
  & .title_wrapper {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #242633;
    margin-bottom: 40px;
  }
  & .description_wrapper {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #596080;
    margin-bottom: 24px;
  }
  & .actions_wrapper {
  }
}

.library_component_with_blocks {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 200px;
  flex-direction: column;

  &.isPlayer {
    padding-left: 100px;
    padding-right: 100px;
    background: #ffffff;
    min-width: 900px;
    min-height: 900px;
    //max-height: 900px;
  }
  &.isPlayerPreview {
    transform: scale(0.3);
    background: #ffffff;
    padding-left: 100px;
    padding-right: 100px;
  }
  &.isPlayerTOCPreview {
    transform: scale(0.3) translate(0%, -117%);
    background: #ffffff;
    padding-left: 100px;
    padding-right: 100px;
    min-width: 560px;
    min-height: auto;
    /* top: 0; */
    padding-top: initial;
    padding-bottom: initial;
  }
  &.isPlayerTOCPreview.row {
    transform: scale(0.095) translate(1%, -477%);
    background: #ffffff;
    padding-left: 100px;
    padding-right: 100px;
    min-width: 560px;
    min-height: auto;
    /* top: 0; */
    padding-top: initial;
    padding-bottom: initial;
  }
}
.with_blocks_title {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  min-height: 40px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  /*line-height: 48px;*/
  padding: 27px 0 0 0;
  color: #242633;
  margin-bottom: 13px;
}
.with_blocks_title.isPlayer {
  min-height: 80px;
}
.with_blocks_description {
  display: block;
  width: 100%;
  padding-top: 8px;
  max-width: 100%;
  min-width: 100%;
  resize: none;
  border: none;
  overflow: auto;
  height: auto;
  font-size: 14px;
  color: #242633;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  line-height: 21px;
}
.no_pointer_events {
  pointer-events: none;
}
.embed_preview {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
}
.embed_preview iframe {
  padding: 0 15px 8px 9px;
  width: 102%;
  height: 102%;
  top: -10px;
  left: -10px;
}

.embed_preview {
  &.isPlayerPreview {
    transform: scale(0.33) translate(0%, -106%);
  }
  &.isPlayer {
    width: calc(100vw - 200px);
  }
  &.isPlayerTOCPreviewRow {
    transform: scale(0.33) translate(0%, -106%);
  }
  &.isPlayerTOCPreviewCard {
    transform: scale(0.33) translate(0%, -106%);
  }
}

.google_embed_preview {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
}

.google_embed_preview iframe {
  padding: 0 15px 8px 9px;
  width: 102%;
  height: 102%;
  top: -10px;
  left: -10px;
}

.archive {
  &.isPlayer {
  }
  &.isPlayerPreview {
    transform: scale(0.2) translate(0%, 0%);
    width: 400px;
    margin: initial;
  }
  &.isPlayerTOCPreviewCard {
    transform: scale(0.2) translate(0%, -180%);
    width: 400px;
    margin: initial;
  }
  &.isPlayerTOCPreviewRow {
    transform: scale(0.15) translate(0%, -284%);
    width: 400px;
    margin: initial;
  }
}

.isPlayerArchive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hideWithoutHover {
 & .controls_container {
    //visibility: hidden;
   transition: opacity 0.2s ease-in-out;
   opacity: 0;
  }
}
.controls_container {
  bottom: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 130px;
  height: 40px;
  background: #534B4F;
  color:#FFFFFF;
  border-radius: 8px;
  z-index: 1;
  &.forUPV {
    bottom: 100px;
  }
}
.info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 4px;
}
.icon {
  border-radius: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg path {
    stroke: #d7ddf7;
  }
  &:hover {
    background-color: #e8ebfa;
    & svg path {
      stroke: #596080;
    }
  }
}
