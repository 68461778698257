.users_count {
  color: #939393;
  margin-left: 5px;
  &.hide {
    opacity: 0;
    width: 14px;
  }
  &.active {
    color: #484343;
  }
  &.to_be_shown_on_hover {
    position: absolute;
    right: 26px;
    width: 24px;
    height: 24px;
    top: 4px;
    svg {
      width: 17px;
      position: relative;
      top: -5px;
    }
    border-radius: 4px;
    &:hover {
      background: #ffdf85;
    }
  }
  &.disabled {
    color: lightgrey;
    cursor: not-allowed;
    &:hover {
      background-color: initial;
    }
  }
  &.drop_down_down {
    position: absolute;
    width: 150px;
    height: 152px;
    background: #ffffff;
    border: 0.6px solid #ece2e2;
    box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
    border-radius: 12px;
    padding-top: 16px;
    z-index: 2;
    &.drop_dow_down_option {
      padding-left: 16px;
      height: 30px;
      min-height: 30px;
      &:hover {
        background: #ffeebf;
        border-color: #ece2e2;
      }
    }
  }
}

.nav_bar {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  height: 20px;
  position: relative;
  z-index: 2;
  &.customTopPadding {
    padding-top: 15px;
  }
}
.nav_bar.isDescriptionOpened {
  @media (max-width: 1730px) {
    padding-left: 32px;

  }
}

.nav_item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  height: 36px;
  box-sizing: content-box;
  cursor: pointer;
  .to_be_shown_on_hover {
    display: none;
  }
  &:hover {
    .to_be_hide_on_hover {
      display: none;
    }
    .to_be_shown_on_hover {
      display: block;
    }
  }
  &.is_passive {
    .to_be_hide_on_hover {
      display: none;
    }
    .to_be_shown_on_hover.to_be_shown_on_hover {
      display: block;
    }
  }

  &.disabled {
    color: lightgrey;
    cursor: not-allowed;
    &:hover {
      background-color: initial;
    }
  }

  &.first {
    margin-right: 16px;
  }
}

.nav_slider {
  position: relative;
  padding-top: 5px;
  z-index: 1;
}

.nav_underlay {
  position: absolute;
  width: 99.5%;
  height: 1px;
  bottom: -1px;
  background: #e4e7f7;
}
.all_separator {
  position: absolute;
  right: -8.5px;
  top: 0;
  width: 1px;
  height: 22px;
  background-color: #e4e7f7;
}


.isNavVisible {
  display: flex;
}

@media (max-width: 1430px) {
  .isNavVisible {
    display: none;
  }
}

@media (max-width: 1550px) {
  .isNavVisible.isDescriptionOpened {
    display: none;
  }
}
