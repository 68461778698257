.blink_it {
  animation: blinking_touch 1s;
  animation-iteration-count: 2;
}

@keyframes blinking_touch {
  0% {
    background-color: #ffffff;
  }

  50% {
    background-color: #ffe9ad;
  }

  100% {
    background-color: #ffffff;
  }
}