@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin icon_with_color($color) {
  & path {
    stroke: #596080;
  }

  @if $color {
    & {
      path,
      rect,
      polygon {
        stroke: $color;
      }
    }
  }
}

@mixin btn_icon($idle_color, $hover_color, $is_no_background) {
  & {
    svg path {
      stroke: #596080;
    }
  }

  &.active {
    svg {
      path,
      rect,
      polygon {
        stroke: #242633;
      }
    }
  }
  &:hover {
    svg {
      path,
      rect,
      polygon {
        stroke: #242633;
      }
    }
  }

  @if $idle_color {
    & {
      svg {
        path,
        rect,
        polygon {
          stroke: $idle_color;
        }
      }
    }
  }
  @if $hover_color {
    &.active {
      svg {
        path,
        rect,
        polygon {
          stroke: #242633;
        }
      }
    }
    &:hover {
      svg {
        path,
        rect,
        polygon {
          stroke: #242633;
        }
      }
    }
  }

  @if $is_no_background {
    svg {
      path,
      rect,
      polygon {
        fill: white;
      }
    }
  }
}

@mixin scroll_bar($width) {
  &::-webkit-scrollbar {
    width: 4px;
    @if $width {
      width: $width;
    }
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    @if $width {
      border-radius: $width;
    }
    background-color: #dbdbdb;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    @if $width {
      border-radius: $width;
    }
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
}
