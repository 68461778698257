mux-player {
  /* Hide all controls at once */
  //--controls: none;
  //--play-button: none;
  //--seek-live-button: none;
  //--seek-backward-button: none;
  //--seek-forward-button: none;
  //--mute-button: none;
  //--captions-button: none;
  //--airplay-button: none;
  //--pip-button: none;
  //--fullscreen-button: none;
  //--cast-button: none;
  //--playback-rate-button: none;
  //--volume-range: none;
  //--time-range: none;
  //--time-display: none;
  //--duration-display: none;

  /* Target a specific section by prefixing the CSS var with (top|center|bottom) */
  //--center-controls: none;
  //--bottom-play-button: none;
  & video {
    max-height: calc(100vh - 200px);
    display: flex;
  }
}
.turn_off_all {
  /* Hide all controls at once */
  --controls: none;
  --play-button: none;
  --seek-live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --mute-button: none;
  --captions-button: none;
  --airplay-button: none;
  --pip-button: none;
  --fullscreen-button: none;
  --cast-button: none;
  --playback-rate-button: none;
  --volume-range: none;
  --time-range: none;
  --time-display: none;
  --duration-display: none;
}

.mux_expand_view {
  --seek-live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --mute-button: none;
  --captions-button: none;
  --airplay-button: none;
  --pip-button: none;
  //--fullscreen-button: none;
  --cast-button: none;
  --playback-rate-button: none;
  --volume-range: none;
  //--time-range: none;
  --time-display: none;
  //--duration-display: none;
  --bottom-play-button: none;
}
.white_gallery_video {
 height: auto;
  width:  calc(100vw - 40px);
}
.custom_audio_controls {
  width: 100%;
  height: 107px;
  margin-left: 62px;
  background-color: transparent;
  media-control-bar {
    background-color: transparent;
  }
  media-play-button {
    background-color: transparent;
    margin-bottom: 25px;
  }
  media-time-range {
    background-color: transparent;
    padding-top: 24px;
    margin-left: 20px;
    ////--media-primary-color:red !important;
    //--media-secondary-color:pink !important;
    //--media-preview-thumbnail-background:blue !important;
    //--media-preview-time-background: green !important;
    //--media-control-background:violet !important;
    //--media-control-hover-background:black !important;
    --media-range-thumb-background: transparent;
    --media-range-bar-color: #ffc72c;
    --media-range-track-height: 20px;
    --media-range-track-background: #e6e6e6;
    --media-range-track-width: 386px;
  }
}

.video_custom_controls {
  width: 100%;
  //max-height: 609px;
  //--media-object-fit: cover;
  --media-object-position: center;
  height: 100%;
  media-control-bar {
    background-color: transparent;
  }
  media-play-button {
    background-color: transparent;
    width: 100%;
    height: 77%;
  }
  media-time-range {
    --media-range-thumb-background: transparent;
    --media-range-bar-color: #ffc72c;
    --media-range-track-background: #e6e6e6;
    background-color: transparent;
  }
  video {
    max-height: 609px;
  }
}

.video_custom_controls * {
  max-height: inherit;
}
.svg_to_be_colored {
  rect {
    stroke: grey;
  }
}

.custom_media_btn {
  svg {
    width: 48px;
    height: 48px;
  }
}
