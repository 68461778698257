.close_button {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: #0026e5;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  & svg {
    width: 12px;
    height: 12px;
  }
  &:hover {
    background: #e8ebfa;
  }

  &.active {
    background: #e8ebfa;
  }
}
