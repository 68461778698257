
.editor-input:focus-within  .textPlaceHolder::before {
  content: "Press ‘/’ for commands ";
  position: absolute;
  color: #B9B9B9;
}
.PlaygroundEditorTheme__table  .textPlaceHolder::before {
display: none;
}
.PlaygroundEditorTheme__table.PlaygroundEditorTheme__table {
  width: inherit;
}