.dropdown {
  position: absolute;
  width: 320px;
  max-height: 452px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 5;
  left: 510px;
  top: 30px;
}

.dropdown_block {
  max-height: 452px;
  font-size: 14px;
  font-weight: 400;
}

.dropdown__item {
  cursor: pointer;
  position: relative;
  align-items: center;
  height: 53px;
  display: flex;
  padding-left: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown__item_image {
  padding-right: 19px;
}

.dropdown__item:not(:last-child):after {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  content: "";
  border-top: 1px solid #c7cad1;
}

.dropdown__item:hover {
  background: #e8ebfa;
}
