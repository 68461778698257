.editMenu {
  position: absolute;
  width: 240px;
  left: 0;
  top: 23px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  /*z-index: 10;*/
  z-index: 101;
  line-height: 16px;
  animation: fadein_row 0.1s;
}

@keyframes fadein_row {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.loaderWrapper {
  width: 100%;
  height: 130px;
  padding-top: 17px;
  z-index: 15;
  overflow: hidden;
}
.loader_wrapper_trash {
  width: 100%;
  height: 91px;
  z-index: 15;
  overflow: hidden;
  padding-top: 10.5px;
}
.link_page_menu {
  position: absolute;
  right: -156px;
  top: 49px;
  width: 240px;
  /*height: 183px;*/
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 10;
}

.chapter_add_menu {
  position: absolute;
  width: 160px;
  right: 13px;
  top: 38px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 10;
}

.editMenuPage {
  position: absolute;
  width: 240px;
  height: fit-content;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 10;
}

.tablePositioning {
  position: fixed;
}

.editMenuFolder {
  position: fixed;
  width: 240px;
  height: fit-content;
  left: 271px;
  margin-top: 18px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 130;
}

.editMenuChapter {
  position: absolute;
  width: 240px;
  right: -165px;
  top: 38px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 10;
}

.editMenu__item_delete {
  display: flex;
  padding: 5px 0 5px 15px;
  cursor: pointer;
  position: relative;
  align-items: center;
}
.editMenu__item_delete svg path {
  stroke: #b30e00;
  fill: white;
}
.editMenu__item_delete.editMenu__item_delete.editMenu__item_delete svg path {
  stroke: #b30e00;
}

.editMenu__item_delete:hover {
  background: #FFEEBF;
}
.editMenu__item_delete:hover svg path {
  fill: #ffe1e8;
}

.editMenu__item {
  display: flex;
  padding: 5px 14px 5px 7px;
  cursor: pointer;
  position: relative;
  align-items: center;
  height: 30px;
}

.editMenu__item svg {
  /* width: 33px; */
  height: auto;
}

.in_trash__item {
  display: flex;
  padding: 5px 0 5px 19px;
  position: relative;
  align-items: center;
}

.no_found {
  color: #a3aacc;
  padding: 6px 0 10px 19px;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  cursor: auto;
}

.editMenu__input {
  display: flex;
  padding: 6px 0 10px 17px;
  position: relative;
}

.image_wrapper {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.image_wrapper svg {
  width: 16px;
  height: 16px;
}

.editMenu__item .favorite.favorite svg path {
  stroke: #ff8a00;
  fill: #ff8a00;
}
.editMenu__item:hover .favorite.favorite svg path {
  stroke: #ff8a00;
  fill: #ff8a00;
}

.editMenu__item .image_wrapper svg path {
  stroke: #596080;
  /*fill: white;*/
}

.editMenu__item .image_wrapper {
  padding-left: 1px;
}

.in_trash__item .image_wrapper svg path {
  stroke: #596080;
}

/*.image_wrapper svg {*/
/*    stroke: red;*/
/*}*/

.editMenu__item:hover .image_wrapper svg path {
  stroke: #242633;
  /*fill: #E8EBFA;*/
}

.scroll_wrapper {
  height: 189px;
}

.editMenu__item_first {
  margin-top: 7px;
}

.end {
  height: 9px;
}

.count_page {
  position: absolute;
  right: 12px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #a2a7b2;
  padding-top: 1px;
  padding-right: 3px;
}

.option_in_trash {
  position: absolute;
  display: flex;
  right: 12px;
  padding-top: 1px;
  padding-right: 3px;
}

.move_page {
  display: block !important;
  visibility: hidden;
  position: absolute;
  width: 250px !important;
  top: 0 !important;
  left: -245px !important;
  z-index: 10;
  line-height: 16px;
  padding-bottom: 10px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
}

.undo_wrapper {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.undo_wrapper:hover {
  background-image: url("../../images/icons/rectangle_undo.svg");
}

.undo_wrapper:hover svg path {
  stroke: #596080;
}

.delete_icon_wrapper {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete_icon_wrapper:hover {
  background-image: url("../../images/icons/rectangle_delete.svg");
}

.icon_wrapper_disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.icon_wrapper_disabled:hover {
  cursor: not-allowed;
}
.undo_wrapper.icon_wrapper_disabled:hover,
.delete_icon_wrapper.icon_wrapper_disabled:hover {
  background-image: url("../../images/icons/rectangle_undo.svg");
}

.undo_wrapper.icon_wrapper_disabled:hover svg path,
.delete_icon_wrapper.icon_wrapper_disabled:hover svg path {
  stroke: grey;
}

.delete_icon_wrapper:hover svg path {
  stroke: #c21c44;
}

.trashMenu {
  top: -30px;
  line-height: 16px;
  position: fixed;
  width: 240px;
  height: fit-content;
  left: 225px;
  margin-top: 18px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 107;
}

.editMenu__move_to:hover:not(.editMenu__item_disable) .move_page {
  visibility: visible;
}

.move_page_active {
  visibility: visible;
}

.editMenu__item_last {
  margin-bottom: 9px;
}

.editMenu_active_icon {
  position: absolute;
  right: 10px;
}

.editMenu__item:hover {
  background: #ffeebf;
}
.editMenu__item_active {
  /*background: #E8EBFA;*/
}
.editMenu__item_freezed {
  color: #d3c8c8 !important;
  cursor: default !important;
}
.editMenu__item_freezed:hover {
  background: none;
}
.share_menu_toggler {
  position: relative;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field {
  width: 75%;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c7cad1;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin: 14px 7px 2px 24px;
}
.field .input {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  padding: 0 0 0 6px;
}
.field .input::placeholder {
  color: #a3aacc;
}
.invite {
  display: flex;
  justify-content: space-between;
}

.search_wrapper_input {
  border: 1px solid #d7ddf7;
  color: #a3aacc;
  width: 208px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search_wrapper_input_search_icon {
  min-width: 32px;
  height: 32px;
  padding: 8px 5px 8px 6px;
}
.invisibility {
  position: absolute;
  /*top: 1px;*/
  color: white;
  border: white;
  z-index: -11;
  cursor: default;
  top: -200px;
  /*height: 0;*/
  opacity: 0.01;
}
.input {
  border: none;
  background: none;
  width: 90%;
  display: block;
  resize: none;
  padding: 0 0 0 20px;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  color: #111111;
}

.search_input {
  border: none;
  background: none;
  width: 100%;
  display: block;
  resize: none;
  padding: 2px 0 0 0;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
}

.search_input_search_icon {
  padding: 8px 7px 8px 8px;
}

.clear_search {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  margin-right: 4px;
  height: 24px;
  width: 24px;
}
.clear_search:hover {
  background: #e8ebfa;
}

.search_input::placeholder {
  color: #a3aacc;
}

.field_icon_box {
  /*width: 84px;*/
  height: 56px;
  border-left: 1px solid #c7cad1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.field_icon_box {
  padding: 0 18px;
}

.share_menu__user {
  display: flex;
  justify-content: space-between;
  padding: 14px 40px 0 31px;
}

.share_menu__user_info_block {
  display: flex;
}

.share_menu__select_role_block {
  display: flex;
  align-items: center;
  cursor: no-drop;
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  font-size: 14px;
  color: #596080;
}

.share_menu__select_role_block_in_input {
  width: 108px;
  cursor: pointer;
}

.share_menu__select_role_block img {
  /* padding-left: 6px; */
  /* height: 8px; */
  position: relative;
  top: 2px;
  opacity: 0.6;
  padding-bottom: 3px;
  height: 8px;
  width: 8px;
  margin-left: 5px;
}

.share_menu__option {
  display: flex;
  justify-content: space-between;
  font-family: "RobotoFlex", serif;
  padding: 22px 27px 1px 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  color: #202124;
}

.share_menu__user_info {
  padding-left: 10px;
  font-family: "RobotoFlex", serif;
  font-size: 14px;
  line-height: 14px;
  justify-content: space-between;
  display: flex;
  flex-flow: column;
}

.share_menu__user_name {
  color: #111111;
}

.share_menu__user_email {
  color: #7e8592;
}

.toggle {
  width: 40px;
  height: 24px;
  border-radius: 16px;
  background: #c7cad1;
  -webkit-transition: background-color 0.4s ease;
  -moz-transition: background-color 0.4s ease;
  -o-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
  cursor: pointer;
}

.toggle_active {
  background: #0da6f2;
}

.toggle_icon {
  padding: 2px;
  height: 24px;

  -webkit-transition: padding 0.4s ease;
  -moz-transition: padding 0.4s ease;
  -o-transition: padding 0.4s ease;
  transition: padding 0.4s ease;
}

.toggle_icon_active {
  padding-left: 18px;
}

.createMenu {
  width: 140px;
  position: fixed;
  left: 200px;
  /*top: 20px;*/
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 10;
}

.createMenu__item {
  display: flex;
  padding: 16px 0 8px 20px;
  cursor: pointer;
}

.createMenu__item:hover {
  background: #e8ebfa;
}

.createMenu__item_disable {
  cursor: no-drop;
}

.editMenu__item_disable:hover {
  background: none;
}

.editMenu__item_disable {
  cursor: no-drop;
}

.editMenu__item_disable.grey {
  color: grey;
}
.editMenu__item_disable.grey .image_wrapper svg path,
.editMenu__item_disable.grey svg path {
  stroke: grey;
}
.editMenu__item_disable.grey:hover svg path {
  stroke: grey;
}
.editMenu__item_disable.grey:hover .image_wrapper {
  color: grey;
}
.editMenu__item_disable.grey .editMenu__text {
  color: grey;
}

.editMenu__item_inactive {
  background-color: #fff;
}

.editMenu__clock {
  width: 16px;
}

.editMenu__turn_info:hover .editMenu__turn_block {
  visibility: visible;
}

.editMenu__item_disable.editMenu__turn_info:hover .editMenu__turn_block {
  display: none;

}

.editMenu__turn_block {
  visibility: hidden;
}

.editMenu__text {
  white-space: nowrap;
  font-style: normal;
  width: 144px;
  color: #484343;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: RobotoFlex, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}

.editMenu__text.playlists {
  width: 184px;
}
.editMenu__text.long_text {
  width: 240px;
}

.editMenu__item .image_wrapper.svg_inactive svg path {
  stroke: #a2a7b2;
}

/*.svg_inactive, .svg_inactive:hover {*/
/*    stroke: #A2A7B2;*/
/*}*/

.editMenu__inactive {
  color: #a2a7b2;
}

.text_inactive {
  color: #a2a7b2;
}

.editMenu__inactive:hover {
  /*background: #FFF;*/
}

.hot_key {
  position: absolute;
  right: 17px;
  color: #a2a7b2;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 1px;
}

.icon {
  position: absolute;
  right: 10px;
}

/*.icon_inactive {*/
/*    color: ;*/
/*}*/

.addMenu__text {
  padding-left: 14px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  letter-spacing: normal;
}

.editMenu__noActive {
  cursor: no-drop;
}

.editMenu__active:hover .addMenu__text {
  color: blue;
}

.editMenu__delete {
  color: #b30e00;
}
.editMenu__delete svg path {
  stroke: #b30e00;
}

.editMenu__separator {
  border-bottom: 1px solid #c7cad1;
  /*padding-top: 18px;*/
}

.share_menu__separator {
  border-bottom: 1px solid #c7cad1;
  padding-top: 26px;
  margin-bottom: 18px;
}
.stolb {
  padding-right: 8px;
}

.stolb svg path {
  stroke: #596080;
}
.text {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  padding-left: 14px;
  padding-top: 4px;
}

.dropdown_order {
  display: flex;
  padding-left: 8px;
}
.dropdown_create {
  padding-top: 8px;
  padding-bottom: 8px;
  opacity: 1;
  position: absolute;
  width: 710px;
  /*height: 176px;*/
  /*height: 346px;*/
  background: #ffffff;
  border-radius: 8px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 5;
  font-size: 14px;
  color: #242633;
  font-weight: 400;
  right: -7px;
  top: 35px;
}
.dropdown_parse_option {
  padding-top: 8px;
  padding-bottom: 8px;
  opacity: 1;
  position: absolute;
  width: 240px;

  background: #ffffff;
  border-radius: 8px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 5;
  font-size: 14px;
  color: #242633;
  font-weight: 400;
  left: 0;
  top: 35px;
}

.dropdown_turn_info {
  padding-top: 8px;
  padding-bottom: 8px;
  opacity: 1;
  position: absolute;
  width: 164px;
  /*height: 346px;*/
  background: #ffffff;
  border-radius: 8px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 5;
  font-size: 14px;
  color: #242633;
  font-weight: 400;
  left: 240px;
  top: -36px;
}
.isPlaylist.isPlaylist {
  top: 0;
}
.isUpv.isUpv {
  left: 193px;
  z-index: 125;
}

.dropdown__item div {
  padding-left: 8px;
}
/*.dropdown__item:first-child {*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    align-items: center;*/
/*    height: 30px;*/
/*    display: flex;*/
/*    padding-left: 19px;*/
/*}*/
.dropdown_create__item {
  cursor: pointer;
  position: relative;
  align-items: center;
  display: flex;
  line-height: 17px;
  width: 226px;
  height: 32px;
  border-radius: 6px;
  padding-left: 8px;
}
.dropdown__item {
  cursor: pointer;
  position: relative;
  align-items: center;
  height: 30px;
  display: flex;
  padding-left: 17px;
  line-height: 17px;
}

.dropdown_create__item svg {
  margin-right: 9px;
  width: 20px;
  height: 20px;
}
.dropdown__item {
  padding-left: 8px;
}

.dropdown__item_blocked {
  padding-left: 8px;
}

.dropdown_create__item_blocked {
  cursor: no-drop;
}

.dropdown_create__item_blocked.dropdown_create__item_blocked:hover {
  background: none;
}

.dropdown__item_blocked {
  cursor: no-drop;
  position: relative;
  align-items: center;
  height: 30px;
  display: flex;
  padding-left: 17px;
  line-height: 17px;
}

.dropdown__item_image {
  position: absolute;
}

.image_wrapper_create svg path {
  stroke: #596080;
}

.editMenu__item svg path {
  stroke: #596080;
}
.dropdown__item svg path {
  stroke: #596080;
}
.editMenu__item:hover svg path {
  stroke: #242633;
}

.dropdown_create__item:hover {
  background: #ffeebf;
}
.dropdown__item:hover {
  background: #ffeebf;
}

.dropdown__item:hover svg path {
  stroke: #242633;
}
.sort_menu {
  position: absolute;
  background: #ffffff;
  box-shadow: 1px 1px 6px 0 #7a696940;
  border: 0.6px solid #ece2e2;
  border-radius: 12px;
  width: 150px;
  padding-bottom: 12px;
  top: 40px;
  z-index: 200;
  right: 4px;
  cursor: default;
}

.sort_menu_title {
  color: #d3c8c8;
  font-size: 12px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-align: left;

  padding: 17px 10px 8px 16px;
}
.sort_menu .editMenu__item {
  /*padding: 5px 0 5px 0px;*/
}

.editMenu__item_preferences {
  font-size: 14px;
  color: #596080;
  width: 100%;
  padding-left: 0;
}

.collOption_move_page {
  display: block;
  position: absolute;
  width: 258px;
  height: 240px;
  left: 0;
  top: -15px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 10;
  line-height: 16px;
}

.collOption__scroll_wrapper {
  overflow-y: auto;
  height: 184px;
}

.collOption__item {
  display: flex;
  padding: 8px 8px 8px 8px;
  cursor: pointer;
}

.collOption__input {
  display: flex;
  padding: 6px 9px 10px 9px;
  position: relative;
}

.collOption__item:hover {
  background: #e8ebfa;
}

.collOption__item svg path {
  stroke: #596080;
}

.collOption__item:hover svg path {
  stroke: #242633;
}

.sort_menu .collOption__item {
  padding: 5px 0 5px 3px;
}

.collOption__item_disable:hover {
  background: none;
}

.collOption__item_disable {
  cursor: no-drop;
}

.collOption__item_inactive {
  background-color: #fff;
}

.collOption__clock {
  width: 16px;
}

.collOption__turn_info:hover .collOption__turn_block {
  visibility: visible;
}

.collOption__turn_block {
  visibility: hidden;
}

.collOption__text {
  white-space: nowrap;

  font-family: "RobotoFlex", sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  padding-left: 9px;
  font-style: normal;
  letter-spacing: normal;
  width: 134px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #242633;
}

.collOption__item .image_wrapper.svg_inactive svg path {
  stroke: #a2a7b2;
}

.collOption__noActive {
  cursor: no-drop;
}

.collOption__active:hover .addMenu__text {
  color: blue;
}

.collOption__delete {
  color: #b30e00;
}

.collOption__separator {
  border-bottom: 1px solid #c7cad1;
  /*padding-top: 18px;*/
}
.collOption__item_disable:hover {
  background: none;
}

.collOption__item_disable {
  cursor: no-drop;
}

.collOption__item_inactive {
  background-color: #fff;
}

.collOption__clock {
  width: 16px;
}

.collOption__turn_info:hover .collOption__turn_block {
  visibility: visible;
}

.collOption__turn_block {
  visibility: hidden;
}

.collOption__text {
  width: 214px;
  overflow: hidden;
  white-space: nowrap;
  font-family: "RobotoFlex", sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  padding-left: 9px;
  font-style: normal;
  letter-spacing: normal;

  text-overflow: ellipsis;
  color: #242633;
}

.collOption__item .image_wrapper.svg_inactive svg path {
  stroke: #a2a7b2;
}

.collOption__item_active {
  background: #e8ebfa;
}

.collOption__move_to:hover .collOption_move_page {
  visibility: visible;
}

.collOption_move_page_active {
  visibility: visible;
}

.collOption__search_wrapper_input {
  border: 1px solid #d7ddf7;
  color: #a3aacc;
  width: 100%;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.collOption__count_page {
  position: relative;
  right: 12px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #a2a7b2;
  padding-top: 1px;
  padding-right: 3px;
}
.collOption__buddah_check svg path {
  stroke: #1a0dab;
}

.invite_button {
  margin-top: 13px;
  margin-right: 25px;
  cursor: no-drop;
}

.in_folder_toggler:hover {
  background: #dde2f9;
}
.in_folder_toggler:hover .in_folder_toggler_img {
  background: #dde2f9;
}
.in_folder_toggler:hover .in_folder_toggler_knob {
  background: #dde2f9;
}
.in_folder_toggler {
  margin-top: 14px;
  width: fit-content;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 31px;
  padding: 0 11px 0 6px;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
}
.in_folder_toggler_text {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
}
.in_folder_toggler_img {
  /*margin-right: 9px;*/
  position: relative;
  display: flex;
  align-items: center;
  width: 37px;
  height: 17.5px;

  /*background: #F5F6FF;*/
  border: 1px solid #bbc1e1;
  border-radius: 12px;
  cursor: pointer;
  -webkit-transition-property: border;
  -moz-transition-property: border;
  -o-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  left: -1px;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.in_folder_toggler_img_disable {
  cursor: no-drop;
}
.in_folder_toggler_img_right {
  border: 1px solid #0026e5;
}
.in_folder_toggler_knob {
  position: absolute;
  width: 17.5px;
  height: 17.5px;
  background: #f5f6ff;
  border: 1px solid #bbc1e1;
  border-radius: 50%;
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  left: -1px;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.in_folder_knob_left {
  left: -1px;
}
.in_folder_knob_right {
  border: 1px solid #0026e5;
  left: 18px;
}

.item {
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 6px;
  border: 1px solid #d7ddf7;
  margin-right: 11px;
}
.item:hover {
  background: #e8ebfa;
}
.item.active {
  background: #e8ebfa;
}

.item_wrapper {
  position: relative;
}

.editMenu__item_delete.isDisabled {
  background-color: initial;
  cursor: no-drop;
  opacity: 0.5;
}
.editMenu__item_delete.isDisabled:hover .image_wrapper svg path {
  fill: transparent;
}

.ps_menu_height {
  max-height: 180px;
}
.menu_icon_16 {
  width: 16px;
  height: 16px;
}

.activityStyles {
  width: 118px;
}
.emojiStyles {
  width: 112px;
}
.preferencesStyles {
  width: 183px;
}
.playerHeaderStyles {
  width: 296px;
}

.red_text {
  color: #D32F2F;
}

.optionsHeader {
  text-transform: uppercase;
  font-size: 12px;
  padding-top: 16px;
  padding-left: 16px;
  color: #D3C8C8;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}
