@import "../../../mixins";
$used_layout_spacings: 60% 40%;

.wrapper {
  position: absolute;
  bottom: 4px;
  right: 7px;
  &.isComponent {
    z-index: 1;
  }
}

.container {
  position: absolute;
  // top and bottom is controlled by JS
  width: 270px;
  height: fit-content;
  max-height: 289px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow-y: auto;
  padding: 6px 16px;
  display: grid;
  grid-template-rows: 24px auto;

  .header {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: $used_layout_spacings;

    .title {
      font-size: 14px;
      color: #242633;
      padding-left: 7px;
    }
    .channels {
      display: flex;
      align-items: center;

      &_title {
        font-size: 14px;
        color: #242633;
      }

      .icon {
        width: 20px;
        height: 17px;
        margin-right: 3px;
        top: 2px;
        position: relative;
      }
    }
  }
  .content {
    height: 88px;
    overflow-x: auto;
    @include scroll_bar(4px);

    .used_item_wrapper {
      display: grid;
      grid-template-columns: $used_layout_spacings;
      margin: 8px 0;
    }

    .used_item {
      width: 100%;
      height: 18px;
      display: flex;
      align-items: center;
      cursor: pointer;

      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #596080;
      &_title {
        width: 120px;
        @include ellipsis;
      }
      &.channel {
        padding-left: 2px;
      }
      &:hover {
        background-color: #e8ebfa;
        color: #242633;
      }

      @include btn_icon(#a3aacc, #596080, true);

      .icon {
        width: 16px;
        height: 16px;
        margin: 0 7px;
      }
    }
  }
  &.forCard {
    padding-left: 0px;
    padding-right: 0px;
  }
  &.no_box_shadow {
    box-shadow: initial;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;

    .used_item_wrapper {
      grid-template-columns: $used_layout_spacings;
    }
  }
}

.container::-webkit-scrollbar {
  display: none;
}
.button {
  width: 22px;
  height: 22px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 3px 0px #7a69691a;
  background: #fff;
  border: 0.6px solid #ece2e2;
  cursor: pointer;
  opacity: 0.8;
  :hover{
    background-color: #FFC72C;
  }
  &:hover {
    //background-color: #242633;
    & .icon {
      //background-color: #242633;
    }
  }

  .icon {
    width: 22px;
    height: 22px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #484343;
  }

  &.isShowOnly {
    &:hover {
      background-color: initial;
    }
    box-shadow: initial;
    cursor: default;
  }
}
.isActive {
  background-color: #FFC72C;
}
.no_channels_text {
  padding-left: 11px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  cursor: initial;
}

.spinner_wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
