.resize_wrapper {
  //background-color: #F5F6FF;
  border-radius: 4px;

  &:hover {
    background-color: #f5f6ff;
  }
  .resize {
    max-width: 100%;
    min-width: 150px;
    margin: auto;
    position: relative;
    &.pdf {
      min-width: 383px;
    }
    .resize_handler {
      position: absolute;
      height: 100%;
      top: 0;
      z-index: 100;
      width: 8px;
      //background-color: rgba(255, 0, 0, 0.29);
      cursor: col-resize;
      &.hidden {
        visibility: hidden;
        .svg_wrapper {
          visibility: hidden;
        }
      }
      .svg_wrapper {
        background: #ffffff;
        box-shadow:
          0px 1px 4px rgba(64, 77, 140, 0.12),
          0px 4px 16px rgba(64, 77, 140, 0.03);
        border-radius: 6px;
        position: relative;
        top: calc(50% - 12px);
        padding: 2px;
        width: 24px;
        height: 24px;
        visibility: hidden;
        right: 8px;
      }
      &.left {
        //top: 50%;
        left: -4px;
      }
      &.right {
        right: -4px;

        //top: 50%;
      }
    }
    &:hover {
      .svg_wrapper {
        visibility: visible;
      }
    }
  }
}
.broken_image_container {
  width: 100%;
  height: 137px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f6ff;
}
