.wrapper {
  width: 100%;
}
.wrapper2 {
  min-height: calc(100vh - 1px);
  //background-color: #FAFAFF;
  display: flex;
  flex-direction: column;
}
.cards_content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 6px 20px 26px;

  @media (max-width: 1023.8px) {
    padding-left: 28px;
  }

  @media (min-width: 1023.9px) {
    padding-left: 16px;
  }

  @media (min-width: 1324.9px) {
    padding-left: 12px;
  }

  @media (min-width: 1545.9px) {
    padding-left: 8px;
  }
  @media (max-width: 1600px) {
    padding-bottom: 20px;
  }
}
.rows_content {
  padding: 9px 21px 20px 0px;
}

.empty {
  width: 371px;
  display: flex;
  flex-direction: column;
  margin: calc(18vh + 18px) auto 0 auto;
  align-items: center;

  svg {
    width: 76px;
    height: 76px;
  }
}

.empty_mainText {
  color: #484343;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: #242633;
  margin: 13px auto 0;
  line-height: 12px;
  text-align: center;
  width: 350px;
}
.empty_infoText {
  margin-top: 13px;
  font-weight: 400;
  font-size: 12px;
  color: #939393;
  text-align: center;
  width: 500px;
}
.empty_icon {
}

.wrapper {
  position: relative;
  background-color: #f6f6f5;
  &.row {
    position: absolute;
    width: 100%;
    height: 34px;
  }
}

.drop_zone {
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  width: 149px;
  height: 275px;
  right: 0;
  overflow: hidden;
  &.active {
    display: block;
  }
  &.left {
    right: initial;
    left: 0;
  }
  &.top {
    width: 100%;
    height: 17px;
    left: 0;
  }
  &.bottom {
    width: 100%;
    height: 20px;
    top: initial;
    bottom: -3px;
  }
}
.drop_line {
  background-color: #0026e5;
  visibility: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  right: 7px;
  width: 2px;
  height: 275px;
  &.row_view {
    width: 100%;
    height: 2px;
  }
  &.left {
    left: -9px;
    right: initial;
  }
  &.active {
    visibility: visible;
  }
  &.top {
    top: -2px;
    bottom: initial;
  }
  &.bottom {
    top: initial;
    bottom: -3px;
  }
}

.title {
  display: flex;
  padding: 18px 32px 12px;

  h1 {
    font-size: 18px;
    padding-left: 9px;
    letter-spacing: 1px;
  }
}

.header_icon {
  width: 20px;
  height: 20px;
}