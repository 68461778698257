

.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  height: 15px;
  margin: 10px;
  width: 15px;
  border-radius: 50%;
  &.small{
    height: 7.5px;
    width: 7.5px;
  }
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}


@keyframes dot {
  0% { background-color: grey; opacity: 0.8; transform: scale(1); }
  50% { background-color: grey; opacity: 1; transform: scale(1.3); }
  100% { background-color: grey; opacity: 0.8; transform: scale(1); }
}
