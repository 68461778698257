@import "./src/_mixins";

.block {
  background: white;
  height: 90px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8ebfa;
  z-index: 2;
}

.nav {
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
}

.backToEditButton {
  position: absolute;
  bottom: 46px;
  left: 24px;
}
.back_button_wrapper {
  width: 106px;
  height: 24px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: #505d9f;
  border-radius: 6px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #404d8c;
  }
  &:active {
    background: #1f2855;
  }
}

.back {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #5f646d;
  width: 350px;
  flex-direction: column;
  cursor: pointer;

  &:hover .container {
    background-color: #e8ebfa;
  }
  .container {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: white;
    font-style: normal;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #596080;
    width: 88px;
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    & svg path {
      stroke: #596080;
    }
    & svg {
      margin-right: 6px;
      margin-left: 6px;
    }

    &.finish {
      width: 174px;
    }
  }
  .pageTitle {
    margin-left: 40px;
    width: max-content;
    max-width: 300px;
    @include ellipsis;
    font-size: 12px;
    color: #596080;
  }
}

.forward {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: #5f646d;
  width: 350px;
  flex-direction: column;
  cursor: pointer;
  &:hover .container {
    background-color: #e8ebfa;
  }
  &:hover .finish {
    background-color: #e8ebfa;
  }

  .container {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: white;
    font-style: normal;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #0026e5;
    width: 88px;
    height: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 6px;
    & svg path {
      stroke: #0026e5;
    }
    & svg {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  .pageTitle {
    margin-right: 40px;
    width: max-content;
    max-width: 300px;
    @include ellipsis;
    font-size: 12px;
    color: #596080;
  }
  .finish {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    border-radius: 4px;
  }
}
