.typeIcon {
  position: relative;
  & .underlay {
    & svg path {
      fill: white;
    }
    &.component svg path {
      fill: white;
    }
    &.pdf svg path {
      fill: white;
    }
    &.ms svg path {
      fill: white;
    }
    &.presentation svg path {
      fill: white;
    }
    &.link svg path {
      fill: white;
    }
    &.image svg path {
      fill: white;
    }
    &.media svg path {
      fill: white;
    }
    & svg {
      width: 100%;
      height: 100%;
    }
  }

  & .children_wrapper {
    position: absolute;
    transform: translate(-50%, -45%);
    top: 55%;
    left: 50%;
  }
}
