.label {
  font-weight: 500;
  font-size: 14px;
  color: #242633;
  margin-bottom: 23px;
  &.emojis {
    margin-bottom: 6px;
  }
  &.marginTop {
    margin-top: 8px;
  }
}

.text {
  font-size: 12px;
  line-height: 18px;
  color: #596080;
}

.input_container {
  position: relative;
  margin-top: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  .label {
    font-size: 12px;
    line-height: 18px;
    color: #596080;
  }
  .required {
    margin-right: 2px;
    display: inline;
    font-size: 12px;
    line-height: 18px;
    color: #c21c44;
  }
  .input_border {
    width: 173px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid #d7ddf7;
    background-color: #e8ebfa;
    overflow: hidden;
  }
  .button_wrapper {
    margin-left: 22px;
    height: 32px;
    display: flex;
  }
  .avatar_container {
    position: relative;
    margin-top: 7px;
    display: flex;
  }
  .input {
    padding-left: 5px;
    position: relative;
    border: none;
    height: 32px;
    width: 100%;
  }
}
