@import "../../../../mixins";

.wrapper {
  display: flex;
  position: relative;
}
.selected_country {
  display: flex;
  background-color: #fff;
}
.filter_section {
  padding-left: 10px;
  display: flex;
  align-items: center;
  height: 32px;
}
.flag {
  width: 62px;
  min-width: 47px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 8px;
}
.prefix {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #343434;
  background-color: white;
  display: flex;
  align-items: center;
  padding-left: 5px;
  height: 46px;
  white-space: nowrap;

  &.in_dropdown {
    height: 32px;
  }
}

.underlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  width: 100vw;
  height: 100vh;
}
.drop_list {
  padding-top: 9px;
  padding-bottom: 9px;
  position: fixed;
  width: 253px;
  top: 30px;
  z-index: 103;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
}
.search_hint {
  display: flex;
  font-size: 14px;
  line-height: 21px;
  color: #596080;
  &.hide {
    display: none;
  }
}
.input_wrapper {
  display: none;
  &.show {
    display: flex;
  }
}
.input {
  width: 100%;
  height: 100%;
  border: none;
}
.search_icon {
  position: relative;
  margin-right: 15px;
  margin-left: 6px;
  & path {
    fill: #e8ebfa;
  }
}
.country_item {
  padding-left: 6px;
  height: 32px;
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  color: #596080;
  display: flex;
  align-items: center;
  cursor: pointer;
  &.active {
    background-color: #d7ddf7;
  }
  &:hover {
    background-color: #e8ebfa;
  }
  .flag {
    width: 28px;
    min-width: 28px;
    margin-left: 3px;
    margin-right: 10px;
  }
  .name {
    max-width: 120px;
    @include ellipsis;
  }
  .prefix {
    width: 84px;
    @include ellipsis;
    background-color: inherit;
    color: inherit;
  }
}

.scroll_list {
  overflow-x: auto;
  height: 100px;
  @include scroll_bar(4px);
}

.unknown_flag_icon {
  position: relative;
  height: 20px;
  & .question {
    font-size: 14px;
    line-height: 21px;
    color: #596080;
    position: absolute;
    top: -2px;
    left: 7px;
  }
}

.flag_container {
  position: relative;
  height: 20px;
  margin-left: 7px;
}

.flag_icon_border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 28px;
  height: 20px;
  z-index: 2;
  top: 1px;
  border-radius: 2px;
}
.flag_icon {
  position: relative;
  width: 28px;
  height: 20px;
  z-index: 1;
  border-radius: 2px;
}

.arrow {
  margin-left: 4px;
}