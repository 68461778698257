.formWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkoutForm {
  width: 600px;
  height: fit-content;
  border: #F6F9FC solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

.emailWrapper {
  margin-bottom: 200px;
}

.payElement {
  margin-top: 40px;
}

.price {
  margin-top: 10px;
  font-size: 32px;
}

.paymentWrapper {
  width: 100%;
  height: 100%;
}

.paymentSuccessfulMessage {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}

.submitPay {
  margin-top: 310px;
  padding: 8px 16px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  &:hover {
    background-color: #F6F9FC;
    cursor: pointer;
  }
}