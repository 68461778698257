.checkbox_shell{
  overflow: hidden;
  cursor: pointer;

  .svg_wrapper {
    width: 26px;
    height: 26px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
   & svg {
     width: 18px;
     height: 18px;
     &.yellow_checkbox {
        width: 24px;
        height: 24px;
        path {
          stroke: #F8BA12;
        }
      }
    }
  }
  svg path {
    stroke: rgb(52, 52, 52);
  }

  .index_wrapper {
    min-width: 15px;
    display: flex;
    justify-content: flex-end;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.15;
  }
}