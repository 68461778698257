
.PlaygroundEditorTitleTheme__h1 {
  font-size: 40px;
  margin: 0;
  line-height: 44px;
}

.PlaygroundEditorTitleTheme__h2 {
  font-size: 32px;
  margin: 0;
  line-height: 42px;
}

.PlaygroundEditorTitleTheme__h3 {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
}
.editor-input:focus-within  .titlePlaceHolder::before {
  content: "Write a Title";
  position: absolute;
  color: #C5C5C5;
}