html,
body {
  max-width: 100%;
  overflow-x: hidden;
}


@font-face {
  font-family: 'RobotoFlex';
  src: local('RobotoRegularScript'),
    url('./fonts/Roboto-flex/RobotoFlex.ttf') format('truetype');
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*sans-serif;*/
  font-family: RobotoFlex, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
html,
body {
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.draggable {
  /* just to size it to content */
  display: inline-block;
  /* opaque background is important for performance */
  background: red;
  /* avoid selecting text while dragging */
  user-select: none;
}

/*.public-DraftStyleDefault-ltr 	.align-justify   {*/
/*    text-align: justify;*/
/*}*/
/*.align-justify .public-DraftStyleDefault-ltr{*/
/*    text-align: justify;*/
/*}*/

/*.justify-left  .public-DraftStyleDefault-ltr  {*/
/*    text-align: right;*/
/*}*/

.public-DraftStyleDefault-ltr > span {
  /*display: inline-block;*/
  word-break: break-word;
  min-width: 1px;
}

h1 {
  margin: 0;
  font-size: 1em;
}

h2 {
  margin: 0;
  font-size: 1em;
}

h3 {
  margin: 0;
  font-size: 1em;
}

button {
  font-family: RobotoFlex, sans-serif;
  font-weight: 400;
}

a {
  text-decoration: underline;
  cursor: pointer;
  color: #2f71f4;
  font-family: "RobotoFlex", sans-serif;
  /*font-size: 18px;*/
}

a:hover {
  text-decoration: underline;
}

figure {
  width: 100%;
  margin: 0;
  align-items: center;
}

figure > div > div {
  padding-bottom: 56.25%;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  margin: 0;
  top: 0;
}

figure > div {
  margin-top: 13px;
}

figure > img {
  width: 100%;
  display: block;
  border-radius: 8px;
}

figure ~ div {
  display: none;
}

.public-DraftEditorPlaceholder-root.public-DraftEditorPlaceholder-hasFocus.public-DraftEditorPlaceholder-hasFocus {
  display: block;
}

.public-DraftEditorPlaceholder-root.public-DraftEditorPlaceholder-root {
  display: none;
  color: #a3aacc;
}
.builder_component
  .public-DraftEditorPlaceholder-root.public-DraftEditorPlaceholder-root {
  display: block;
  color: #a3aacc;
}

.public-DraftEditorPlaceholder-root {
  display: flex;
  align-items: center;
  align-content: center;
  white-space: pre-wrap;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.wrapper_component_title .public-DraftEditorPlaceholder-root {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.wrapper_component_callout .public-DraftEditorPlaceholder-root {
  font-size: 14px;
  padding-left: 58px;
  padding-top: 11px;
}

.wrapper_component_embed {
  padding-bottom: 8px;
  padding-top: 8px;
}
.wrapper_component_googleEmbed {
  padding-bottom: 8px;
  padding-top: 8px;
}

.wrapper_component_googleEmbed.google {
  width: 620px;
}

.wrapper_component_ordered-list-item .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 24px;
  /*padding-left: 10px;*/
}
.wrapper_component_ordered-list-item0 .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 24px;
  /*padding-left: 10px;*/
}
.wrapper_component_ordered-list-item1 .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 24px;
  /*padding-left: 10px;*/
}
.wrapper_component_ordered-list-item2 .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 24px;
  /*padding-left: 10px;*/
}
.wrapper_component_unordered-list-item .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 24px;
  padding-left: 12px;
}
.wrapper_component_unordered-list-item0 .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 24px;
  /*padding-left: 12px;*/
}
.wrapper_component_unordered-list-item1 .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 24px;
  /*padding-left: 12px;*/
}
.wrapper_component_unordered-list-item2 .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 24px;
  /*padding-left: 12px;*/
}

.wrapper_component_title2 .public-DraftEditorPlaceholder-root {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.wrapper_component_title3 .public-DraftEditorPlaceholder-root {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 22px;
}

.wrapper_component_text .public-DraftEditorPlaceholder-root {
  /*font-family: "IBM Plex", serif;*/
  font-size: 16px;
  line-height: 24px;
}

*:focus {
  outline: 0;
}

body,
html {
  width: 100%;
  height: 100%;
}

.wrapper_component_callout .DraftEditor-editorContainer {
  font-family: "RobotoFlex", sans-serif;
  background: #f0f1f4;
  line-height: 28px;
  padding: 0 20px 0 56px;
  font-size: 14px;
  color: #242633;
  padding-top: 12px;
}

.wrapper_component_callout .DraftEditor-editorContainer:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.wrapper_component_callout .DraftEditor-editorContainer:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-bottom: 20px;
}

.wrapper_component_callout .DraftEditor-editorContainer::before {
  content: url("./images/icons/callout.svg");
  position: absolute;
  left: 21px;
  top: 17px;
}

.selected_block_create_block .component {
  background-color: #c5ceff;
}

.selected_block .component {
  background-color: #c5ceff;
}

.hasDragBlocks .selected_block .component {
  background-color: #c5ceff;
}

.hasDragBlocks .selected_block .opacityWrapper {
  opacity: 0.5;
}

.wrapper_component_title {
  padding-top: 33px;
  padding-bottom: 8px;
  color: #242633;
}

.wrapper_component_ordered-list-item {
  font-size: 16px;
  line-height: 24px;
}
.wrapper_component_ordered-list-item0 {
  font-size: 16px;
  line-height: 24px;
}
.wrapper_component_ordered-list-item1 {
  font-size: 16px;
  line-height: 24px;
}
.wrapper_component_ordered-list-item2 {
  font-size: 16px;
  line-height: 24px;
}
.wrapper_component_unordered-list-item {
  line-height: 24px;
  font-size: 16px;
}
.wrapper_component_unordered-list-item0 {
  line-height: 24px;
  font-size: 16px;
}
.wrapper_component_unordered-list-item1 {
  line-height: 24px;
  font-size: 16px;
}
.wrapper_component_unordered-list-item2 {
  line-height: 24px;
  font-size: 16px;
}

/*.wrapper_component_bullet  {*/
/*    padding-top: 5px;*/
/*    padding-bottom: 8px;*/
/*    font-size: 18px;*/
/*}*/

.wrapper_component_title2 {
  padding-top: 24px;
  padding-bottom: 4px;
  line-height: 30px;
  color: #242633;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.wrapper_drag_title {
  margin-top: 38px;
  position: relative;
}
.wrapper_drag_title2 {
  margin-top: 29px;
  position: relative;
}
.wrapper_drag_title3 {
  margin-top: 23px;
  position: relative;
}
.wrapper_component_title3 {
  padding-top: 18px;
  padding-bottom: 4px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #242633;
}

.wrapper_component_text {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 16px;
  color: #242633;
  line-height: 24px;
}
.wrapper_component_video {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  color: #242633;
  line-height: 20px;
}
.wrapper_component_page {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  color: #242633;
  line-height: 30px;
}
.wrapper_component_callout {
  padding-top: 8px;
  padding-bottom: 8px;
}

.wrapper_component_image br {
  display: none;
}
.wrapper_component_image {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  color: #242633;
  line-height: 20px;
}
.wrapper_component_page {
  padding-top: 8px;
  padding-bottom: 8px;
}
.wrapper_component_webSite {
  padding-top: 8px;
  padding-bottom: 8px;
}

/*.height_wrapper_component_bullet {*/
/*    height: 14px;*/
/*}*/
.height_wrapper_component_ordered-list-item {
  height: 22px;
}
.height_wrapper_component_ordered-list-item0 {
  height: 22px;
}
.height_wrapper_component_ordered-list-item1 {
  height: 22px;
}
.height_wrapper_component_ordered-list-item2 {
  height: 22px;
}

.height_wrapper_component_unordered-list-item0 {
  height: 22px;
}
.height_wrapper_component_unordered-list-item1 {
  height: 22px;
}
.height_wrapper_component_unordered-list-item2 {
  height: 22px;
}
.height_wrapper_component_unordered-list-item {
  height: 22px;
}

.height_wrapper_component_title {
  height: 36px;
}

.height_wrapper_component_title2 {
  height: 28px;
}

.height_wrapper_component_title3 {
  height: 21px;
}

.height_wrapper_component_text {
  height: 22px;
}

.height_wrapper_component {
  display: flex;
  position: absolute;
  left: -60px;
  top: 1px;
  align-items: center;
  justify-content: space-between;
  width: 55px;
}

.ps__rail-y {
  opacity: 0.6;
}

.DraftEditor-root li::marker {
  margin-left: -1em;
  display: none;
  content: "";
  list-style-type: none !important;
}
.DraftEditor-root ul {
  list-style-type: none !important;
}
.DraftEditor-root  ol {
  list-style-type: none !important;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.DraftEditor-root ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
::-moz-selection {
  background: #ffc72c;
}
::selection {
  background: #ffc72c;
}
.multiDragMod [data-rbd-placeholder-context-id] {
  display: none !important;
}

.DraftEditor-editorContainer.DraftEditor-editorContainer {
  z-index: unset;
}

.draggable_page {
  position: absolute;
  top: -150px;
  padding-left: 44px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  background-image: url("./images/icons/cursor_drag_page.svg");
  background-repeat: no-repeat;
  background-position: bottom 8px left 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  height: 36px;
  background: #ffffff;
  border: 0.6px solid #ffc72c;
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  color: #343434;
  opacity: 0.7;
  /* TODO  */
}

.draggable_multiple::after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  padding-left: 44px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  width: 200px;
  height: 36px;
  background: transparent;
  border: 0.6px solid #ffc72c;
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
  border-radius: 12px;
  opacity: 0.7;
  z-index: 1;
}

.draggable_multiple_counter {
  position: absolute;
  top: -155px;
  left: 190px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #FFC72C;
  border-radius: 50%;
  z-index: 2;
  font-family: "RobotoFlex", sans-serif;
  color: #FFF;
  font-size: 14px;
}
.draggable_item {
  width: 230px;
  height: 45px;
  opacity: 1;
  position: absolute;
  top: -150px;
  background: #ffeebf;
  padding-left: 13px;
  padding-right: 13px;
  border: 1px solid #ffeebf;
  box-sizing: border-box;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  background-repeat: no-repeat;
  background-position: bottom 8px left 10px;
  color: #242633;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1000;
}
.draggable_item.in_toc {
  background: #FFC72C;
  height: 28px;
  border-radius: 0;
  padding: 10px;
  padding-left: 20px;
}
.draggable_item.in_toc > img {
  position: absolute;
  left: 1px;
  top: 15%;
  width: 19px;
  height: 17px;
}
.draggable_tag {
  width: 172px;
  height: 40px;
  position: absolute;
  top: -150px;
  background: #ffffff;
  padding-left: 10px;
  padding-right: 20px;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  display: flex;
  opacity: 0.7;
  align-items: center;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /*background-image: url("./images/icons/cursor_drag_page.svg");*/
  /*background-repeat: no-repeat;*/
  /*background-position: bottom 8px left 10px;*/
  color: #242633;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.draggable_smart_view {
  width: 32px;
  height: 32px;
  opacity: 1;
  position: absolute;
  background: #ffffff;
  padding-top: 1px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.draggable_tag_list_item {
  opacity: 1;
  top: -100px;
  position: absolute;
  background: #ffffff;
  border-radius: 12px;
}
.draggable_smart_view_list_item {
  position: absolute;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  padding: 5px;
}
.draggable_smart_view_list_item div {
  display: flex;
  overflow: hidden;
}
.draggable_smart_view_list_item div div {
  max-width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
}
.draggable_playlist {
  width: 172px;
  height: 40px;
  opacity: 1;
  position: absolute;
  top: -150px;
  background: #ffffff;
  padding-left: 44px;
  padding-right: 20px;
  border: 1px solid #0026e5;
  box-sizing: border-box;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  background-image: url("images/icons/cursor_drag_playlist.svg");
  background-repeat: no-repeat;
  background-position: bottom 8px left 10px;
  color: #242633;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.draggable_page_placeholder {
  opacity: 0.999;
  background: #dde2f9;
  border-radius: 6px;
  border: 1px solid transparent;
}

.to_delete.to_delete {
  height: 280px;
  margin: 0;
  width: 0;
  opacity: 0;
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s; /* Firefox */
  -webkit-animation: fadein 0.5s; /* Safari and Chrome */
  -o-animation: fadein 0.5s;
}

.to_delete.to_delete svg path {
  stroke: #808080 !important;
  fill: #808080 !important;
  opacity: 0.5;
}

.to_delete.to_delete svg {
  opacity: 0.5;
}

@keyframes fadein {
  0% {
    width: 171px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
    margin: 0;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  0% {
    width: 171px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
    margin: 0;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  0% {
    width: 171px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
    margin: 0;
  }
}
@-o-keyframes fadein {
  /* Opera */
  0% {
    width: 171px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
    margin: 0;
  }
}

.component_ordered-list-item0.component_ordered-list-item0 {
  padding-left: 29px;
}
.component_ordered-list-item1.component_ordered-list-item1 {
  padding-left: 61px;
}

.component_ordered-list-item2.component_ordered-list-item2 li {
  padding-left: 24px;
}
.component_ordered-list-item1.component_ordered-list-item1 li {
  padding-left: 4px;
}
.component_ordered-list-item2.component_ordered-list-item2
  .public-DraftEditorPlaceholder-root {
  padding-left: 24px;
}

.component_ordered-list-item2.component_ordered-list-item2 {
  padding-left: 91px;
}

.component_unordered-list-item0.component_unordered-list-item0 {
  padding-left: 29px;
}

.component_unordered-list-item1.component_unordered-list-item1 {
  padding-left: 64px;
}

.component_unordered-list-item2.component_unordered-list-item2 {
  padding-left: 115px;
}

.wrapper_component_ordered-list-item0_preview {
  padding-left: 29px;
  position: relative;
}
.wrapper_component_ordered-list-item1_preview {
  padding-left: 64px;
}

.wrapper_component_ordered-list-item2_preview {
  padding-left: 91px;
}

.wrapper_component_unordered-list-item0_preview {
  padding-left: 29px;
}

.wrapper_component_unordered-list-item1_preview {
  padding-left: 64px;
}

.wrapper_component_unordered-list-item2_preview {
  padding-left: 115px;
}

.wrapper_component_ordered-list-item0_preview div:first-of-type {
  position: relative;
}
.wrapper_component_ordered-list-item1_preview div:first-of-type {
  position: relative;
}

.wrapper_component_ordered-list-item2_preview div:first-of-type {
  position: relative;
}

.wrapper_component_unordered-list-item0_preview div:first-of-type {
  position: relative;
}

.wrapper_component_unordered-list-item1_preview div:first-of-type {
  position: relative;
}

.wrapper_component_unordered-list-item2_preview div:first-of-type {
  position: relative;
}

.showLink {
  visibility: hidden;
}

.wrapper2_sideBar .ps {
  overflow-x: visible !important;
}

.playlistElement {
  border: 1px;
}
.selected_blockPlaylist .playlistElement {
  border: 1px solid #0026e5;
}

.dragCard, .drag_toc_preview {
  position: absolute;
}
.dragCardNested > div:first-child {
  border-color: transparent;
  background-color: #FFEEBF;
}

.drag_toc_preview > div:first-child {
  border-color: transparent;
  background-color: #FFC72C;
}
.dragCard.in_toc > div > div:first-child {
  padding: 5px 15px 5px 0;
}
.dragCard .draggable_item_icon {
  position: absolute;
  left: 3px;
  top: 55px;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
}
.dragColumn {
  position: absolute;
  height: 550px;
  overflow: hidden;
}

.colors_inversion .text {
  color: white !important;
}

.colors_inversion .text span {
  color: white !important;
}
.selectedByArrow .highlightedPlace{
  background-color: #ffeebf;
  border: 0.6px solid  #ece2e2;
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
}

