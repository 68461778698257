.search_icon2 {
  cursor: pointer;
  padding: 8px 8px 8px 7px;
}

.search_input_wrapper {
  margin-left: 8px;
  margin-bottom: 16px;
  height: 32px;
  //width: 100%;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
  &.fullscreen {
    margin-bottom: 0;
    width: 500px;
  }
}

.search_input {
  background-color: white;
  line-height: 21px;
  width: 100%;
  border: 0;
  font-size: 14px;
  &.isPlaylists {
    width: 100%;
  }
}
.search_input::placeholder {
  color: #a3aacc;
}

.clear_search {
  position: absolute;
  right: 9px;
  cursor: pointer;
  padding: 2px;
  margin-right: 7px;
  border-radius: 50%;
}
.clear_search:hover {
  background: #e8ebfa;
}
