@import "./src/_mixins";

.folder_wrap{

  height: 140px;
  width: 140px;
  & svg {
    position: absolute;
    left: 0;
  }
}
.folder_wrap_small{
  height: 30px;
  & svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
  }
}
.row_wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  //animation: rotating 3s linear infinite;
  height: 32px;
  margin-top: 4px;
  margin-bottom: 5px;
  &.zActive {
    z-index: 2;
  }

  &.selected {
    .row_container {
      background-color: #d7ddf7;
    }
  }
  &.hovered {
    .row_container {
      background-color: #e8ebfa;
    }
    .checkbox_container {
      visibility: visible;
    }
  }
  &:not(.header):hover {
    .row_container {
      background-color: #e8ebfa;
    }
  }
  &:hover {
    .checkbox_container {
      visibility: visible;
    }
  }
  &.header {
    min-height: 32px;
    margin-top: 10px;
    .first_container,
    .item_container,
    .last_container {
      color: #596080;
    }
    .last_container {
      .action_icon:not(.favorite) {
        & svg.row_icon path {
          stroke: #596080;
        }
      }
    }
  }
}

.row_container {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  border-radius: 8px;
  animation: rotating 2s linear infinite;
  width: 100%;
  &.isHide {
    opacity: 0.4;
  }
}
.first_container {
  display: flex;
  align-items: center;
  margin-right: 2px;
  margin-left: 0;
  .first_icon {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first_item {
    width: 150px;
    min-width: 130px;
    @include ellipsis;
    &.header {
      display: flex;
      align-items: center;
    }
    &.icon_placeholder {
      margin-right: 36px;
    }
  }
}
@keyframes slow_disable {
  80% {
    width: 0;
  }
  100% {
    display: none;
  }
}

.item_container {
  animation: rotating 1s linear infinite;
  display: flex;
  align-items: center;
  .authors_cell {
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 120px;
    max-width: 150px;
    min-width: 120px;
  }
  .type_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    width: 80px;
  }
  .channel_cell {
    &.collapsible {
      overflow: hidden;
    }
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 150px;
    max-width: 200px;
    min-width: 130px;
  }
  .dashboard_cell {
    position: relative;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 28px;
  }
  .progress_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: center;
  }
  .availability_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 140px;
  }
  .user_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #596080;
    &.isShared {
      width: 32px;
    }
  }
  .last_modified_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 144px;
    &.collapsible {
      overflow: hidden;
    }
  }

  .comments_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    width: 72px;
  }
  &.views_cell {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 72px;
  }
  .icon {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.last_container {
  position: relative;
  display: flex;
  width: 90px;
  min-width: 90px;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  margin: 0 8px 0 12px;
  &.collapsible {
    justify-content: flex-end;
    width: 32px;
  }

  &.collapsible {
    width: 32px;
  }
  &.collapsible .actions_cell {
    width: 32px;
  }
  &.collapsible .action_icon.favorite {
    display: none;
  }
  .icon {
    overflow: hidden;
    transition: width ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  .header_actions_cell {
    display: flex;
    align-items: center;
    width: 32px;
    height: 32px;
    justify-content: center;
  }

  .actions_cell {
    display: flex;
    align-items: center;
    width: 72px;
    justify-content: space-between;
    position: relative;
  }
  .views_cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    position: relative;
  }
  .action_icon {
    border-radius: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.active:not(.favorite) {
      background-color: #d7ddf7;
    }
    &:hover {
      background-color: #d7ddf7;
    }
    &.favorite svg.row_icon path {
      stroke: #ff8a00;
    }
    &.favorite.active {
      & svg.row_icon path {
        fill: #ff8a00;
      }
    }
    & svg.row_icon path {
      stroke: #596080;
    }
    &.active:not(.favorite) {
      & svg.row_icon path {
        stroke: #242633;
      }
    }
    &:not(.favorite):hover {
      & svg.row_icon path {
        stroke: #242633;
      }
    }
    &.lock {
      background-color: initial;
      &:hover {
        background-color: initial;
      }
    }
    &.lock.active {
      background-color: initial;
      &:hover {
        background-color: initial;
      }
      & svg.row_icon path {
        stroke: #0026e5;
      }
    }
  }
}

.white_wrapper {
  height: 32px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.row_container:hover {
  .white_wrapper {
    background-color: #e8ebfa;
  }
}
.white_wrapper.hovered {
  background-color: #e8ebfa;
}

.header {
  .first_container,
  .last_container,
  .item_container {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #596080;
  }
  .user_cell {
    overflow: initial;
    justify-content: initial;
  }
  .row_wrapper {
    height: 32px;
  }
  .checkbox_container {
    visibility: visible;
    width: 62px;
    &.no_authors {
      width: 32px;
    }
  }
  .row_container {
    width: 100%;
    gap: 10px;
  }
  .first_container {
    margin-right: 8px;
    min-width: 210px;
  }
  .header_text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #596080;
  }
  .last_container {
    width: 90px;
    justify-content: flex-end;
  }
  .last_modified_cell {
    width: 144px;
    white-space: nowrap;
  }
  .channel_cell {
    width: 150px;
    max-width: 200px;
    min-width: 130px;
  }
}

.image_wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.image {
  position: relative;
  width: 30px;
  height: 30px;
  overflow-x: hidden;
  object-fit: cover;
}
.image_def {
  position: relative;
  width: 30px;
  height: 30px;
  overflow-x: hidden;
}
.action_icon {
  overflow: visible;
  &.inactive svg path {
    stroke: lightgrey;
  }
  &.inactive:hover {
    background-color: initial;
  }
  &.isHide {
    & svg path {
      stroke: #8f8f8f;
      fill: #919191;
    }
  }
}

.common_text_ellipsis,
.last_modified_cell.common_text_ellipsis {
  height: 22px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #596080;
  display: block;
  @include ellipsis;
  line-height: 21px;
}
.avatar_img {
  width: 18px;
  height: 18px;
}
.avatar_img svg {
  width: 18px;
  height: 18px;
}

.hightlight_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 34px;
  width: 100%;
  z-index: 100;
  display: none;
}

.highlighted {
  animation: toolTipFadein 1s;
  display: block;
}

@keyframes toolTipFadein {
  0%,
  100% {
    box-shadow: 0 0 0 0 rgba(13, 38, 229, 0);
  }
  50% {
    box-shadow: 0 0 5px 5px rgba(13, 38, 229, 0.2);
  }
  25%,
  75% {
    box-shadow: 0 0 7px 7px rgba(13, 38, 229, 0.3);
  }
}

.relative {
  position: relative;
}

.action_icon.active {
  overflow: visible;
}

@keyframes fade_in_list_Playlist {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 32px;
  }
}

.animate_in {
  animation: fade_in_list_Playlist 0.3s;
}

.preview {
  width: 56px;
  height: 28px;
}
