.highlighted.highlighted {
  animation: toolTipFadein 1s;
}

.highlightedDrag.highlightedDrag {
  box-shadow: 0 0 1px 1px rgb(255, 238, 191);
}

@keyframes toolTipFadein {
  0%,
  100% {
    box-shadow: 0 0 1px 1px rgba(255, 238, 191, 0);
  }
  50% {
    box-shadow: 0 0 1px 1px rgba(255, 238, 191, 0.2);
  }
  25%,
  75% {
    box-shadow: 0 0 1px 1px rgb(255, 238, 191,);
  }
  0% {
    margin-left: 18px;
  }
  50%,
  100% {
    margin-left: 13px;
  }
}
.cardInDashboard {
  position: relative;
  background: #ffffff;
  border: 1px solid #e2e6ff;
  width: 250px;
  height: 86px;
  padding-left: 9px;
  border-radius: 10px;
  margin-left: 13px;
  padding-right: 11px;
  cursor: pointer;
  z-index: 25;
}
.cardInDashboard:hover {
  border-color: transparent;
  background-color: #FFEEBF;
}

.cardInDashboard.isOwn {
  border-left: 8px solid #63D471;
  padding-left: 11px;
}
.cardInDashboard.isNotOwn {
  border-left: 8px solid #63D4714D;
}
.cardInDashboard.notPerm {
}

.cardInDashboard.notSeen:before {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 6px;
  right: 7px;
  content: "";
  background-color: red;
}

.image_wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.image {
  position: relative;
  width: 30px;
  height: 30px;
  overflow-x: hidden;
  object-fit: cover;
}