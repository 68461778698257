.floating-text-format-popup {
  display: flex;
  background: #fff;
  padding: 4px 10px;
  vertical-align: middle;
  position: absolute;
  font-size: 14px;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
  height: 32px;
  will-change: transform;
}

.floating-text-format-popup button.popup-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 6px;
  padding: 1px;
  cursor: pointer;
  vertical-align: middle;
}

.floating-text-format-popup button.popup-item:disabled {
  cursor: not-allowed;
}

.floating-text-format-popup button.popup-item.spaced {
  width: 26px;
  height: 26px;
  margin: 0 3px;
  padding: 3px 5px;
}

.floating-text-format-popup button.popup-item i.format {
  background-size: contain;
  height: 16px;
  width: 16px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
}

.floating-text-format-popup button.popup-item:disabled i.format {
  opacity: 0.2;
}

.floating-text-format-popup button.popup-item.active {
  background-color: #FFC72C
}

.floating-text-format-popup button.popup-item.active i {
  opacity: 1;
}

.floating-text-format-popup .popup-item:hover:not([disabled]) {
  background-color: #FFEEBF;
}

.floating-text-format-popup select.popup-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.floating-text-format-popup select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.floating-text-format-popup .popup-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.floating-text-format-popup .popup-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.floating-text-format-popup i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.floating-text-format-popup i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

.floating-text-format-popup .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.title {
  color: #484343;
}

.title1 {
  font-size: 46px;
}

.title2 {
  font-size: 32px;
}

.title3 {
  font-size: 24px;
}

.dropdown-item-title {
  width: 191px;
  height: 211px;
}

.item-title.item-title.item-title{
  height: 54px;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 17px;
  border-radius: 0;
}

.item-title.item-title.item-title:hover{
  background: #FFEEBF;
}

.annotation{
  color: #D3C8C8;
  font-size: 12px;
  padding-top: 12px;
  padding-left: 16px;
  padding-bottom: 8px;
  text-transform: uppercase;
}
