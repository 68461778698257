.page {
  border-color: blue;
  margin: 0 18px 20px 0;
  animation: fadein_card_show 0.5s;
  -moz-animation: fadein_card_show 0.5s; /* Firefox */
  -webkit-animation: fadein_card_show 0.5s; /* Safari and Chrome */
  -o-animation: fadein_card_show 0.5s;
  position: relative;
  z-index: 1;
}

@keyframes fadein_card_show {
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 60px;
    opacity: 0.1;
  }

  60% {
    width: 160px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 171px;
  }
}
@-moz-keyframes fadein_card_show {
  /* Firefox */
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 60px;
    opacity: 0.1;
  }

  60% {
    width: 120px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 171px;
  }
}
@-webkit-keyframes fadein_card_show {
  /* Safari and Chrome */
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 60px;
    opacity: 0.1;
  }

  60% {
    width: 120px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 171px;
  }
}
@-o-keyframes fadein_card_show {
  /* Opera */
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: 60px;
    opacity: 0.1;
  }

  60% {
    width: 120px;
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    width: 171px;
  }
}
.page_blurred {
  opacity: 0.5;
}

.preview:hover {
  border: 1px solid #0d26e5;
}
.preview {
  width: 280px;
  height: 275px;
  background-color: white;
  border: 1px solid #d7ddf7;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  z-index: 25;
}

.preview.selected_active:hover {
  border-color: #0026e5;
}

.selected_active {
  border-color: #0026e5;
}

.showSelected {
  position: absolute;
}

.page_no_download {
  width: 0;
  opacity: 0;
  margin: 0 18px 20px 0;
  display: none;
}

.text {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  padding-top: 8px;
  width: 167px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
  font-size: 14px;
  color: #242633;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
}

.draft {
  background: #ffffff;
  border: 1px solid #d7ddf7;
  width: fit-content;
  color: #596080;
  font-size: 12px;
  line-height: 18px;
  padding: 2px 8px;
  border-radius: 16px;
  margin-left: 12px;
  margin-top: 4px;
}

.publish_wrapper {
  height: 25px;
}
.publish {
  background: #ffffff;
  border: 1px solid #43af41;
  width: fit-content;
  color: #43af41;
  font-size: 12px;
  margin-left: 11px;
  margin-top: 7px;
  line-height: 18px;
  padding: 2px 13px;
  height: 24px;
  border-radius: 16px;
}

.meta_wrapper {
  width: 100%;
  padding-top: 6px;
}

.meta_status {
  width: 100%;
  padding-left: 15px;
}
.common_text {
  line-height: 14px;
  height: 22px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #596080;
  display: flex;
}
.icon_and_text {
  display: flex;
  padding: 2px 0 4px 0;
  align-items: center;
}
.icon_and_text.last {
  padding-bottom: 1px;
}

.icon_and_text svg path {
  stroke: #596080;
}

.svg_wrap {
  width: 20px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.meta_info {
  padding-top: 2px;
  padding-left: 15px;
  padding-right: 15px;
  height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.price_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 30px;
  color: #242633;
}
.time_container {
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max(100px, max-content);
  max-width: 200px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #a3aacc;
}

.time_container svg {
  margin-right: 10px;
}

.description {
  padding-top: 2px;
  font-size: 14px;
  color: #242633;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
}
.display_none {
  display: none;
}

.image_wrapper2 {
  height: 141px;
  position: relative;
  overflow: hidden;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
}
.content {
  padding: 5px 11px 4px 11px;
}
.content_text {
  /*margin-bottom: 9px;*/
}
.image_def {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 280px;

  max-height: 124px;
  object-fit: cover;
  overflow-x: hidden;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
.image {
  /*safari fix*/
  min-height: 100%;
  position: absolute;
  width: 280px;
  object-fit: cover;
  overflow-x: hidden;
}
.wrapper_pro {
  visibility: hidden;
  animation: css 0s 0.8s forwards;
  overflow: hidden;
  width: 280px;
  height: 275px;
}
.content_wrapper {
  /*max-height: 227px;*/
  /*min-height: 227px*/
}
.wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  /*width: 280px;*/
  /*height: 100%;*/
  /*height: 1260px;*/
  overflow: hidden;
  animation: fadein_card_show33 0.7s;
  position: relative;
}

@keyframes css {
  to {
    visibility: visible;
  }
}

.wrapper div {
  opacity: 1;
}
@keyframes fadein_card_show33 {
  0%,
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.space_separator2 {
  height: 31px;
}

.wrapper_component {
  padding-left: 182px;
  padding-right: 175px;
  /*padding-left: 50px;*/
  font-family: "RobotoFlex", sans-serif;
}

.page:hover .selected_active_meta .editActionsPanel {
  visibility: hidden;
}

.page:hover .editActionsPanel {
  visibility: visible;
}

.editActionsPanel {
  position: absolute;
  /*display: none;*/
  visibility: hidden;
  width: 280px;
  z-index: 30;
  /*-webkit-transition: 1s ease 0.5s;*/
  /*background-color: #C5D2FF;*/
}
.editActionsPanel:hover {
  display: block;
}
.editActionsPanel.TOC_active {
  z-index: 31;
}

.miniPage_wrapper {
  position: relative;
  z-index: 31;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.editable {
  display: flex;
  justify-content: space-between;
}

.more_active {
  background-color: #e8ebfa;
}

.menuLeftBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 24px;
  height: 24px;
  margin: 15px;
}

.menuRightBlock {
  position: relative;
  z-index: 30;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 86px;

  height: 24px;
  margin: 15px;
}
.menuRightBlock_small.menuRightBlock_small {
  width: 56px;
}

.menuRightBlock_tiny.menuRightBlock_tiny {
  width: 24px;
}

.itemMenu {
  position: relative;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 32;
  width: 24px;
  height: 24px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.itemMenu svg path {
  stroke: #596080;
}

.hideIcon.isHideActive svg path {
  stroke: #8f8f8f;
  fill: #919191;
}
.itemMenu:hover {
  background-color: #e8ebfa;
}
.itemMenu_active {
  position: relative;
  background-color: #e8ebfa;
  visibility: visible;
  z-index: 31;
}
.hover_icon {
  width: 20px;
  height: 20px;
}

.selectedIcon {
  visibility: visible;
  margin: 15px;
}

.hover_icon.notSelectedIcon {
  visibility: hidden;
  border: none;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.checkboxItem {
  margin: 15px;
  z-index: 10;
}

.favorite svg path {
  stroke: #ff8a00;
  fill: #ff8a00;
}

.favorite {
  visibility: visible;
}

.favoriteIcon svg {
  margin-top: -3px;
}

.favorite:hover svg path {
  stroke: #ff8a00;
}

.isChannelMiniPlaylist {
  visibility: hidden;
}

.folderContainerSmall.folderContainerSmall {
  height: 100px;
}
.folderContainer {
  position: absolute;
  width: 280px;
  height: 275px;
  z-index: 29;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.folderIcon {
  /*width: 20px;*/
  /*height: 20px;*/
  margin: 8px;
  z-index: 30;
  align-self: flex-end;
}

.outerTableWrapper {
  display: flex;
  min-width: 982px;
  max-width: 100%;
  flex-wrap: wrap;
}

.tHRow {
  padding: 0 0 6px 11px;

  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #596080;
}

.tableHeaderContent {
  padding: 0 0 6px 20px;
  margin-left: 9px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #596080;
}

.tableHeadArrow {
  margin-left: 2px;
  visibility: hidden;
}

.tableHeadArrow path {
  stroke: #a3aacc;
}

.tableHeadCell:hover .tableHeadArrow {
  visibility: visible;
}

.tableHeadArrowActive {
  margin-left: 2px;
  visibility: visible;
}

.tableHeadArrowActive path {
  stroke: #596080;
}

.tableHeadCell {
  /*outline: 1px solid blue;*/
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*padding-left: 10px;*/
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.tableHeadNameCell {
  padding-left: 4px;
}
.tableHeaderFolderCell {
  width: 100%;
  min-width: 167px;
}

.tableHeadTagsCell {
  justify-content: flex-start;
  padding-left: 8px;
}

.tableLibraryHeadTagsCell {
  padding-left: 0;
}

.tableHeadCell:last-child {
  margin-right: 0;
}

.tableHeadRowCheckbox {
  margin-right: 6px;
}

.tableHeadIconsFavorite {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableHeadIconsSettings {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableLibraryHeadIconsSettings {
  width: 50px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: auto;
}

.tableHeaderSizeCell {
  display: flex;
  justify-content: center;
  padding-left: 0;
}

/* ======= Columns widths =======*/
.checkboxWidth {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}

.nameWidth {
  width: 220px;
  min-width: 220px;
  max-width: 360px;
}
.libraryNameWidth {
  /* width: 259px; */
  min-width: 240px;
  max-width: 360px;
}

.folderWidth {
  width: 167px;
  min-width: 167px;
  max-width: 305px;
}
.collectionWidth {
  width: 120px;
  max-width: 120px;
  min-width: 100px;
}
.typeWidth {
  width: auto;
  min-width: 80px;
  max-width: 80px;
}

.statusWidth {
  width: auto;
  min-width: 73px;
  max-width: 73px;
}

.tagsWidth {
  width: auto;
  min-width: 203px;
  max-width: 203px;
}

.privacyWidth {
  width: 100%;
  min-width: 80px;
  max-width: 80px;
}
.sizeWidth {
  width: 118px;
  min-width: 100px;
  max-width: 118px;
}

.modifiedWidth {
  width: auto;
  min-width: 105px;
}

.createdWidth {
  width: auto;
  min-width: 105px;
}

.iconsWidth {
  width: auto;
  min-width: 85px;
  max-width: 85px;
}

.iconsLibraryWidth {
  justify-content: flex-end;
  width: auto;
  min-width: 140px;
  max-width: 185px;
}

.iconsWidth svg path,
.iconsLibraryWidth svg path {
  stroke: #596080;
}
/*=======*/

.tableHeaderStatusCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableBodySection {
  width: 100%;
  display: block;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.tableBodyRow {
  animation: fadein_row_show 0.5s;
  -moz-animation: fadein_row_show 0.5s; /* Firefox */
  -webkit-animation: fadein_row_show 0.5s; /* Safari and Chrome */
  -o-animation: fadein_row_show 0.5s;

  display: flex;
  width: 100%;
  height: 48px;
}

.tableHeaderPrivacyCell {
  padding-left: 0;
}

.tableHeaderTypeCell {
  padding-left: 0;
}

.tableHeaderCollectionCell {
  padding-left: 10px;
  display: flex;
}

@keyframes fadein_row_show {
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 48px;
  }
}
@-moz-keyframes fadein_row_show {
  /* Firefox */
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 48px;
  }
}
@-webkit-keyframes fadein_row_show {
  /* Safari and Chrome */
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 48px;
  }
}
@-o-keyframes fadein_row_show {
  /* Opera */
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 48px;
  }
}

.tableBodyRowCheckbox {
  display: flex;
  align-items: center;
  width: 20px;
  height: 48px;
  margin-right: 8px;
  visibility: hidden;
  cursor: pointer;
}

.tableBodyRowCheckbox_active {
  visibility: visible;
}

.tableBodyRowContent {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  height: 48px;
  cursor: pointer;
  width: calc(100% - 28px);
}

.tableBodyRow:hover .tableBodyRowSettingsImage {
  visibility: visible;
}

.tableBodyRow:hover .tableBodyRowFavImage {
  visibility: visible;
}

.tableBodyRow:hover .tableBodyRowCheckbox {
  visibility: visible;
}

.tableBodyRow:hover .tableBodyRowContent {
  background: #e8ebfa;
}

.tableBodyRowContent:hover .tableBodyRowFavImage {
  visibility: visible;
}

.tableBodyRowContent:active {
  background: #d7ddf7;
}

.tBRowContentActive {
  background: #d7ddf7;
}

.tableBodyCell {
  /*outline: 1px solid red;*/
  display: flex;
  align-items: center;
  height: 48px;
  /*margin-right: 40px;*/
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
}

.tableBodyCell:last-child {
  margin-right: 0;
}

.tableBodyTagsCell {
  display: flex;
  justify-content: center;
}

.tableBodySettingsCell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableBodyTypeCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableBodySettingsCellActive.tableBodySettingsCellActive {
  visibility: visible;
}

.tableBodyStatusCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableBodyNameCell {
  display: inline-block;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: fit-content;
  max-width: 168px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableBodyFolderCell {
  width: fit-content;
  max-width: 167px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 2px;
}

.tableBodyNameMiniPage {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 4px;
  margin: 0 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableBodyRowSettingsImage {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: #e8ebfa;
  border-radius: 6px;
  position: relative;
  display: flex;
  visibility: hidden;
}

.tableBodyRowSettingsImage:hover {
  background: #d7ddf7;
}

.tableBodyFavoriteCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableBodyRowFavImage {
  justify-content: center;
  align-items: center;
  width: 20px;
  height: auto;
  cursor: pointer;
  display: flex;
  visibility: hidden;
}

.tableBodyFavImageActive {
  visibility: visible;
}

.tableBodyFavImageActive svg path {
  stroke: #ff8a00;
  fill: #ff8a00;
}

.input {
  width: 346px;
  height: 48px;
  min-height: 26px;
  z-index: 118;
  background: #ffffff;
  border: 1px solid #8697f0;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 28px;
  position: relative;
  left: -7px;
}

.tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  height: 22px;
  padding: 2px 2px;
  margin: 0 4px;
  min-width: 60px;
  width: fit-content;
  max-width: 127px;
  white-space: nowrap;
}
.tagText {
  text-align: center;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  min-width: 60px;
  width: fit-content;
  max-width: 127px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagYellow {
  background: #ffe5a0;
}
.tagGreen {
  background: #d1f3c1;
}
.tagRed {
  background: #ec1832;
}
.tagPink {
  background: #caa0ff;
}
.tagPlaceholder {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  padding: 2px 8px;
  background: #e8ebfa;
  border-radius: 15px;
  margin: 2px 8px;
}

.tagsContainer {
  display: flex;
  width: fit-content;
  overflow: hidden;
}

.category_wrapper,
.lock_wrapper,
.playlist_read_wrapper,
.playlist_comments_wrapper,
.dashboard_column_wrapper {
  position: relative;
  width: 0;
  height: 0;
}

.lock_container {
  padding: 3px;
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  top: 205px;
  left: 243px;
  overflow: hidden;
}

.lock_container svg path {
  stroke: #0026e5;
}

.playlist_read_container {
  padding: 3px;
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #505d9f;
  top: 211px;
  left: 198px;
  line-height: 14px;
  overflow: hidden;
  justify-content: space-between;
}

.playlist_read_container svg {
  margin-right: 8px;
}

.playlist_read_container svg path {
  stroke: #505d9f;
}

.playlist_comments_container {
  padding: 3px;
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #505d9f;
  top: 180px;
  left: 214px;
  line-height: 14px;
  overflow: hidden;
  justify-content: space-between;
}

.playlist_comments_container svg {
  margin-right: 8px;
}

.playlist_comments_container svg path {
  stroke: #505d9f;
}

.dashboard_column_container {
  width: 84px;
  height: 24px;
  background: #c9b5a6;
  border-radius: 6px;
  padding: 3px;
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #505d9f;
  top: 238px;
  left: 182px;
  line-height: 14px;
  overflow: hidden;
  justify-content: center;
}

.selector_wrapper {
  width: 70px;
  height: 16px;
  border-radius: 6px;
  padding: 1px;
  position: absolute;
  /*z-index: 1;*/
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #505d9f;
  top: 246px;
  left: 196px;
  line-height: 14px;
  justify-content: center;
  z-index: 30;
}
.selector_wrapper.inPlaylists {
  border: 1px solid #d7ddf7;
  left: 155px;
}
.selector_wrapper.inPlaylists:hover {
  border: 1px solid #96a6ec;
}

.selector_wrapper.row_view {
  border: 1px solid #96a6ec;
  position: relative;
  top: initial;
  left: initial;
}
.dashboardSvg {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 241px;
  left: 248px;
}

.chevronRowSvg {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 3px;
}
.chevronDownSvg {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 247px;
  left: 205px;
  z-index: 30;
}

.dashboard_column_container svg {
  margin-right: 8px;
}

.dashboard_column_container svg path {
  stroke: #505d9f;
}

.category_label {
  z-index: 1;
  position: absolute;
  width: auto;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 6px;
  height: 24px;
  left: 15px;
  top: 87px;
  background: #ffffff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #404d8c;
}

/*@media (min-width: 1900px) {*/
/*!*body*!*/
/*    .tableBodyCell {*/
/*        !*margin-right: 48px;*!*/
/*    }*/

/*    .tableBodyNameCell {*/
/*        !*width: 320px;*!*/
/*        !*max-width: 100%;*!*/
/*    }*/
/*    .tableBodyNameMiniPage {*/
/*        margin: 0 10px 0 0;*/
/*    }*/
/*    .tableBodyFolderCell {*/
/*        width: 305px;*/
/*        max-width: 100%;*/
/*    }*/
/*    .tableBodyStatusCell {*/

/*    }*/
/*!*header*!*/

/*    .tableHeadCell {*/
/*        margin-right: 48px;*/
/*    }*/

/*    .tableHeadNameCell {*/
/*        !*width: 100%;*!*/
/*        !*min-width: 360px;*!*/
/*        !*max-width: 360px;*!*/
/*    }*/
/*    .tableHeaderFolderCell {*/
/*        width: 100%;*/
/*        min-width: 305px;;*/
/*        max-width: 305px;*/
/*    }*/
/*    .tableHeaderCollectionCell {*/
/*        !*width: 100%;*!*/
/*        !*min-width: 215px;*!*/
/*        !*max-width: 215px;*!*/
/*    }*/
/*    .tableHeaderTypeCell {*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*        !*min-width: 80px;*!*/
/*        !*max-width: 80px;*!*/
/*    }*/
/*    !*.tableHeaderSizeWCell {*!*/
/*    !*    width: 100%;*!*/
/*    !*    min-width: 50px;*!*/
/*    !*    max-width: 80px;*!*/
/*    !*}*!*/
/*    !*.tableHeaderPrivacyCell {*!*/
/*    !*    width: 100%;*!*/
/*    !*    min-width: 50px;*!*/
/*    !*    max-width: 80px;*!*/
/*    !*}*!*/

/*}*/

/*@media (max-width: 1599px) {*/
/*    .tableBodyCell {*/
/*        margin-right: 24px;*/
/*    }*/
/*    .tableHeadCell {*/
/*        margin-right: 24px;*/
/*    }*/
/*}*/
/*@media (max-width: 1450px) {*/
/*    .tableBodyCell {*/
/*        margin-right: 14px;*/
/*    }*/
/*    .tableHeadCell {*/
/*        margin-right: 14px;*/
/*    }*/
/*}*/
/*@media (max-width: 1400px) {*/
/*    .tableBodyCell {*/
/*        margin-right: 10px;*/
/*    }*/
/*    .tableHeadCell {*/
/*        margin-right: 10px;*/
/*    }*/
/*}*/
/*@media (max-width: 1370px) {*/
/*    .tableBodyCell {*/
/*        margin-right: 8px;*/
/*    }*/
/*    .tableHeadCell {*/
/*        margin-right: 8px;*/
/*    }*/
/*}*/

.pending.pending .preview {
  background-color: #e8ebfa;
  animation: downLoading 1s infinite ease-in-out;
}
.isHide {
  opacity: 0.4;
}
.pending.pending .preview div {
  display: none;
}

@keyframes downLoading {
  from {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  to {
    opacity: 0.2;
  }
}

.ownerPlate {
  overflow: hidden;
  text-overflow: ellipsis;
  justify-self: flex-end;
  align-self: flex-end;
  margin-top: 9px;
  margin-left: 11px;
  font-size: 12px;
  color: #505d9f;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 23px;
}

.ownerPlate .avatar {
  width: 18px;
  height: 18px;
  margin-right: 11px;
}
.ownerPlate .avatar svg {
  width: 18px;
  height: 18px;
}

.ownerPlate.miniPage {
  right: 8px;
}
.overlay {
  background-color: #d7ddf7aa;
  position: absolute;
  z-index: 2;
  left: 1px;
  top: 1px;
  width: 278px;
  height: 273px;
  border-radius: 4px;
  display: none;
}
.overlay.active {
  display: block;
}

.tocButton {
  position: absolute;
  z-index: 1;
  top: 105px;
  left: 240px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.44);
  border-radius: 4px;
}
.tocButton_active {
  visibility: visible;
}
.tocWrapper {
  z-index: 31;
  position: relative;
  visibility: hidden;
  top: 13px;
  left: 260px;
}
.tocWrapper_active {
  visibility: visible;
}

.dashboard_column_container.selago {
  background-color: #f0f1fe;
}
.dashboard_column_container.logan {
  background-color: #a3aacc;
}
.dashboard_column_container.comet {
  background-color: #596080;
}
.dashboard_column_container.charade {
  background-color: #242633;
}

.dashboard_column_container.pampas {
  background-color: #f1ebe8;
}
.dashboard_column_container.moon_mist {
  background-color: #e2e0d2;
}
.dashboard_column_container.soft_amber {
  background-color: #d4c2b7;
}
.dashboard_column_container.corvette {
  background-color: #fbd5a8;
}

.dashboard_column_container.banana_mania {
  background-color: #fce7bb;
}
.dashboard_column_container.skeptic {
  background-color: #c3e4d9;
}
.dashboard_column_container.hawkes_blue {
  background-color: #d2e4fb;
}
.dashboard_column_container.french_lilac {
  background-color: #e9cbec;
}

.dashboard_column_container.vanilla_ice {
  background-color: #f5e0e9;
}
.dashboard_column_container.cinderella {
  background-color: #fee1df;
}
.dashboard_column_container.tan_hide {
  background-color: #fba76a;
}
.dashboard_column_container.ronchi {
  background-color: #f0c44c;
}

.page.zActive {
  z-index: 2;
}

.hightlight_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 275px;
  width: 280px;
}

.highlighted {
  animation: toolTipFadein 1s infinite;
}

@keyframes toolTipFadein {
  0%,
  100% {
    box-shadow: 0 0 0 0 rgba(13, 38, 229, 0);
  }
  50% {
    box-shadow: 0 0 5px 5px rgba(13, 38, 229, 0.2);
  }
  25%,
  75% {
    box-shadow: 0 0 7px 7px rgba(13, 38, 229, 0.3);
  }
}
