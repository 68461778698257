.add_page_wrapper {
  margin-right: 18px;
  cursor: pointer;
}
.add_page_wrapper:hover svg,
.add_page_wrapper:hover svg rect {
  fill: #e4e7f7;
}
.recent_wrapper_grid {
  padding-top: 2px;
  height: 100%;
  width: 100%;
  /*z-index: 100;*/
}
.recent_wrapper_list {
  padding-top: 2px;
  height: 100%;
  min-width: 982px;
  max-width: 1160px;
  margin: 0 auto;
}
.recent_grid_row_container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 40px 0;
  z-index: 100;
  scroll-snap-type: inline;
  scroll-behavior: smooth;
}
.recent_list_row_container.recent_list_row_container {
  overflow-x: visible;
  margin: 0 0 40px -5px;
}
.row_grid_spacing {
  padding-left: 0;
}
.row_list_spacing {
  padding-left: 0;
  z-index: 102;
  position: relative;
}
.title_grid_spacing {
}
.title_list_spacing.title_list_spacing {
  margin: 24px 0 16px 57px;
}
.recent_title_wrapper {
  margin: 0 0 24px 0;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #596080;
}

.recent_list_view_block {
  display: block;
}
.fullParentWidth {
  width: 100%;
}

.emptyRecent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 194px;
  align-items: center;
  min-height: calc(100vh - 72px - 60px);
}
.emptyRecent img {
  width: 48px;
  height: 58.67px;
}

.emptyRecent_mainText {
  margin-top: 18px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #242633;
  text-align: center;
}

.emptyRecent_infoText {
  margin-top: 8px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  text-align: center;
}
