.pdf_wrapper {
  position: relative;
  width: 172px;
  margin: 0 auto;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
}

.icon_wrapper {
  position: absolute;
  bottom: 9px;
  left: 11px;
}

.icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #f7e1df;
  border-radius: 4px;
  width: 24px;
  height: 29px;
  border-top-right-radius: 10px;
  &.image {
    background: #d8e3fa;
  }
  & svg {
    margin-top: 5px;
  }
}
.pdf_canvas_margin {
  width: 156px;
  margin: 8px;
  height: 227px;
}

.isPlayerPreview {
  transform: scale(0.7) translate(0%, 30%);
}
.isPlayerTOCPreviewCard {
  transform: scale(0.9) translate(0%, -7%);
}
.isPlayerTOCPreviewRow {
  transform: scale(0.33) translate(0%, -106%);
}
