@import "./src/_mixins";

.wrapper {
  margin-top: 5px;
  margin-bottom: 5px;
  &:hover .controls_container {
    visibility: visible;
  }
  &.noYMargins {
    margin-top: 0;
    margin-bottom: 0;
  }
  .pdfImagePreview{
    width:100%;
    height: auto;

  }

}

.attachment.wrapper {
  position: relative;

  margin-left: 2px;
  &.preview {
    width: 100%;
  }

  & .container {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px 15px;
    width: 100%;
    min-height: 58px;
    border-radius: 12px;
    border: 1px solid #d7ddf7;
    &:hover {
      background-color: #e8ebfa;
    }
  }

  & .icon {
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }
  & .text {
    font-size: 16px;
    line-height: 24px;
    max-width: 180px;
    margin-right: 5px;
    @include ellipsis;
  }
  & .size_text {
    font-size: 12px;
    line-height: 18px;
    color: #596080;
  }
}
.preview {
  position: relative;
  &.canvas {
    position: relative;
  }
  &.builder {
    position: absolute;
  }
  & .controls_container {
    visibility: hidden;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 130px;
    height: 40px;
    background: #f5f6ff;
    border-radius: 8px;
    z-index: 1;
  }
  & .info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0 4px;
  }
  & .icon {
    border-radius: 8px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg path {
      stroke: #d7ddf7;
    }
    &:hover {
      background-color: #e8ebfa;
      & svg path {
        stroke: #596080;
      }
    }
  }
}

.loader_container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  border-radius: 8px;
  background-color: #dde2f9;
  & .message {
    color: #596080;
    font-size: 14px;
    height: 100%;
    padding: 10px;
  }
  background: radial-gradient(
      farthest-corner at top,
      #ffffff 0%,
      #e8ebfa 4%,
      #dde2f9 12.25%,
      #dde2f9 31.25%,
      #e8ebfa 50%
    )
    top right/200% 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  animation: colorChange 1s infinite alternate;
}

@keyframes colorChange {
  to {
    background-position: top left;
  }
}

.is_lib_description.is_lib_description.is_lib_description.is_lib_description {
  transform: translate(-10%, -10%);
  width: 125%;
}
