.preview {
  &.builder {
    position: absolute;
  }
  .react-pdf__Document {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .react-pdf__Page__canvas {
    width: 100%;
    max-width: 100%;
    height: auto !important;
    border-radius: 6px;
  }

  .react-pdf__Page__svg {
    width: 100%;
  }

  .react-pdf__Page {
    width: 100%;
    ::selection{
      background: #ffc72c;
      color: #0e0e0e;
    }
    svg,
    canvas {
      width: 100%;
    }
  }

  .react-pdf__Page__textContent {
    width: 100% !important;
  }
}

.fullScreen {
  & .react-pdf__Document {
  }

  & .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  & .react-pdf__Page__svg {
  }

  & .react-pdf__Page {
  }

  & .react-pdf__Page__textContent {
  }
}
