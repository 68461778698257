@import "../../../mixins";

// will be removed
//.library_element {
//  position: relative;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  cursor: pointer;
//  margin: 0 auto;
//  &.viewMode {
//    margin: 0 auto;
//  }
//  max-width: 856px;
//  margin-left: 82px;
//  &.display {
//    height: auto;
//  }
//}

.six_dot_balancer {
  .rising_button {
    display: none;
  }
  .six_dot_wrap {
    visibility: visible;
  }
  &.hoverable:hover .six_dot_wrap .six_dot_stripe {
    z-index: 1000;
    display: block;
  }
  &.hoverable:hover .six_dot_icon {
    display: flex;
  }
  &.hoverable:hover .six_dot_placeholder {
    display: none;
  }
  &.hoverable:hover .six_dot_placeholder.not_hidden {
    display: flex;
  }
}
.six_dot_balancer:hover {
  .rising_button {
    display: block;
  }
  .rising_button_flex {
    display: flex;
  }
}

.text_element {
  margin-left: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  &.hoverable:hover.notEdit.notEdit .text_container.text_container {
    background-color: inherit;
  }
  &.hoverable:hover.notEdit.notEdit {
    cursor: default;
  }
  &.viewMode {
    margin: 0 auto;
  }
  width: 460px;
  &.hoverable:hover .six_dot_wrap {
    visibility: visible;
  }
  &.hoverable:hover .text_container:not(.text_container:focus-within) {
    background-color: #e8ebfa;
  }
  &.dragginOver {
    background-color: #e8ebfa;
    & .text_container {
      background-color: #d7ddf7;
    }
  }
}

.text_container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 24px;
  border-radius: 8px;
  padding: 4px;
  &.notEdit {
    pointer-events: none;
  }
  & .line_wrapper {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    position: absolute;
    height: 16px;
    bottom: 0;
    left: 0;
    & .line {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #c5ceff;
    }
  }
  // TODO CHECK Use

  &:focus-within {
    cursor: text;
  }
  &.selected {
    border: 1px solid #0026e5;
    padding: 0;
  }
}

.selected_block {
  & .text_container {
    border: 1px solid #0026e5;
    padding: 3px;
  }
  & .empty_element {
    border: 1px solid #0026e5;
  }
  & .container {
    border-color: #0000a8;
  }
  & .selected {
    border-color: #0000a8;
  }
  & .upload_container {
    border: 1px solid #0026e5;
  }
}

.text_element_display {
  font-size: 16px;
  line-height: 24px;
  margin: 8px 4px;
  color: #242633;
}

.pdf_canvas_placeholder {
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: #dde2f9;
  & .message {
    color: #596080;
    font-size: 14px;
    height: 100%;
    padding: 10px;
  }
  background: radial-gradient(
      farthest-corner at top,
      #ffffff 0%,
      #e8ebfa 4%,
      #dde2f9 12.25%,
      #dde2f9 31.25%,
      #e8ebfa 50%
    )
    top right/200% 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  animation: colorChange 1s infinite alternate;
  &.no_anim {
    background: #999999;
    color: #242633;
  }
}

@keyframes colorChange {
  to {
    background-position: top left;
  }
}

.page_canvas_element,
.pdf_canvas_element,
.link_canvas_element,
.embed_canvas_element,
.ms_canvas_element,
.slides_canvas_element,
.image_canvas_element,
.other_canvas_element {
  visibility: visible;
  cursor: pointer;
  white-space: initial;
  &:hover .canvas_actions {
    visibility: visible;
  }
  &.blink_it {
    animation: blinking_touch 1s;
  }
}

.view_builder_collapsed {
  &:hover .canvas_actions {
    visibility: visible;
    z-index: 1;
    cursor: pointer;
    right: 33px;
    top: 8px;
  }
  &.textElement {
    * {
      pointer-events: none;
    }
  }
}

@keyframes blinking_touch {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #e8ebfa;
  }
  100% {
    background-color: #ffffff;
  }
}

.pdf_canvas_element.hidden {
  visibility: hidden;
}

.pdf_canvas_element {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 40px;
  cursor: pointer;
  &:hover .canvas_actions {
    visibility: visible;
  }
}

.canvas_actions {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  visibility: hidden;

  & .item {
    margin-left: 16px;
    background: #fff;
    box-shadow:
      0 2px 7px rgba(0, 0, 0, 0.04),
      0 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: 40px;
    &:hover {
      background: #dde0f4;
      & svg {
        fill: #dde0f4;
      }
    }
  }
  & .save {
    //display: flex;
    padding: 10px;
    & svg {
      margin-right: 5px;
    }
  }
  & .download {
    padding: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    & svg path {
      stroke: #596080;
    }
  }
}

.pdf_canvas_container {
  position: relative;
}

.image_canvas_element {
  position: relative;
  margin: 40px auto 0;
  height: auto;
  background: #e8ebfa;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}

.link_canvas_element {
  position: relative;
  margin: 40px auto 0;
  max-width: 700px;
  height: 114px;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
}
.link_canvas_image {
  width: 164px;
}
.link_canvas_type_icon {
  position: absolute;
  bottom: 12px;
  left: 16px;
}
.slides_canvas_element {
  position: relative;
  margin: 40px auto 0;
  max-width: 700px;
  height: 114px;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}
.slides_canvas_image {
  width: 164px;
  background-color: #f7ebdc;
}
.slides_canvas_type_icon {
  position: absolute;
  bottom: 12px;
  left: 16px;
}
.ms_canvas_element {
  position: relative;
  margin: 40px auto 0;
  max-width: 700px;
  height: 114px;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
}
.ms_canvas_image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 164px;
  background-color: #e8ebfa;
}
.ms_canvas_type_icon {
  position: absolute;
  bottom: 12px;
  left: 16px;
}
.link_canvas_text_section {
  padding: 16px 48px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.link_canvas_title {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #242633;
  margin-bottom: 8px;
  @include ellipsis;
  max-width: 470px;
}
.link_canvas_description {
  font-size: 14px;
  line-height: 14px;
  color: #596080;
  margin-bottom: 12px;
  @include ellipsis;
  line-break: anywhere;
  max-width: 470px;
}
.link_canvas_link {
  font-size: 14px;
  color: #a3aacc;
  @include ellipsis;
  max-width: 470px;
}

.text_canvas_element {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 40px auto 0;
  padding: 20px;
  &.collapsed {
    width: 516px;
  }
  * {
    pointer-events: none;
  }
}

.text_canvas_text {
  font-style: normal;
  width: 100%;
}
.text_canvas_line {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6px;
  background-color: #c5ceff;
}

.page_canvas_element {
  //padding: 16px 0;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-top: 40px;
  padding: 64px 16px 92px;
}
.blink_it.blink_it.blink_it {
  animation: blinking_touch 1s;
}

@keyframes blinking_touch {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #e8ebfa;
  }
  100% {
    background-color: #ffffff;
  }
}

.page_canvas_container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}
.page_canvas_description {
  font-size: 14px;
  line-height: 21px;
  color: #202124;
  text-wrap: normal;
  word-break: break-all;
  max-width: 730px;
  padding: 8px 0 1px 0;
  margin-bottom: 5px;
  resize: none;
  border: none;
  overflow: auto;
  height: auto;
  font-family: "RobotoFlex", sans-serif;
}

.page_canvas_no_pointer_events {
  //pointer-events: none;
}

.page_canvas_title {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  line-height: 53px;
  color: #242633;
  margin-bottom: 8px;
}

.empty_element_wrapper {
  position: relative;
  margin: 0 auto;
  width: 856px;
  min-height: 50px;
}

.empty_element {
  position: relative;
  margin-left: 2px;
  width: 100%;
  height: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D7DDF7FF' stroke-width='2' stroke-dasharray='0.5%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  &.notEdit {
    cursor: default;
  }
  &.hoverable:hover .empty_add_icon {
    display: none;
  }
  &.hoverable:hover .empty_add_icon.notEdit {
    display: flex;
  }
  &.hoverable:hover .empty_menu {
    //display: flex;
  }
  &.hoverable:hover .empty_menu.notEdit {
    pointer-events: none;
  }
  &.hoverable:hover .six_dot_wrap {
    visibility: visible;
  }
  &.dragginOver {
    background-color: #e8ebfa;
    & .empty_add_icon {
      background-color: #d7ddf7;
    }
  }
  &.selected {
    border: 1px solid #0026e5;
  }
}

.empty_add_icon {
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  background-color: #e8ebfa;
  border-radius: 8px;
}

.empty_menu {
  display: none;
  width: 100%;
  height: 100%;
  background-color: #e8ebfa;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.center_menu {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.empty_menu_item {
  width: auto;
  height: 24px;
  padding: 4px 6.5px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #596080;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  & svg {
    margin-right: 5px;
  }
  &:hover {
    background-color: #d7ddf7;
  }
}

.six_dot_wrap {
  visibility: hidden;
  position: absolute;
  display: flex;
  left: -51px;
  width: 50px;
  height: calc(100% + 16px);
  &.isCompressView  {
    height: calc(100% + 2px);
  }
  &.on {
    visibility: visible;
  }
}

.six_dot_empty_element_shift {
  left: -53px;
}
.six_dot_positioning {
  left: 25px;
  height: 100%;
  position: relative;
}

.six_dot_icon {
  height: 24px;
  width: 24px;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  position: absolute;
  background: #d7ddf7;
  left: -15px;
  top: 50%;
  z-index: 31;
  transform: translate(0%, -50%);

  &.on {
    background-color: #d7ddf7;
  }
}

.otherOwner {
  display: block;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translate(0%, -50%);
  & > div {
    top: 0;
  }
}

.six_dot_stripe {
  z-index: 1000;

  position: absolute;
  height: 100%;
  display: none;
  flex-direction: column;
  top: 50%;
  left: -116px;
  align-items: center;
  background-color: #e8ebfa;
  & .line {
    background-color: #d7ddf7;
    width: 2px;
    height: 100%;
  }
  & .plus {
    padding: 4px 4px;
    width: 24px;
    height: 24px;
    z-index: 1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    &.bottom {
      top: initial;
      bottom: -12px;
    }
    &:hover {
      background: rgba(126, 122, 133, 0.04);
    }
    & svg path {
      stroke: #0026e5;
    }
  }
}

.other_canvas_element {
  position: relative;
  margin: 40px auto 0;
  max-width: 700px;
  height: 114px;
  background: white;
  box-shadow: 0 4.82849px 9.65697px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
}

.other_canvas_image {
}
.other_canvas_type_icon {
  position: absolute;
  bottom: 12px;
  left: 16px;
}
.other_canvas_text_section {
}
.other_canvas_title {
}
.other_canvas_description {
}
.other_canvas_link {
}

.other_canvas_image_placeholder {
  width: 161px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8ebfa;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.noIconPlaceholder {
  width: 28px;
  height: 34px;
}
.slidesIcon {
  height: 34px;
}

.upload_element {
  background: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 2px;

  &.viewMode {
    margin: 0 auto;
  }
  width: 460px;
  &.hoverable {
    visibility: visible;
  }
  &.hoverable:hover .six_dot_wrap .plus {
    visibility: visible;
  }
  &.hoverable.active .six_dot_wrap .plus {
    visibility: visible;
  }
  &.hoverable:hover .upload_container {
    background-color: #fff;
  }
  &.dragginOver {
    background-color: #e8ebfa;
    & .upload_container {
      background-color: #d7ddf7;
    }
  }
}

.upload_container {
  border: 1px solid #d7ddf7;
  border-radius: 8px;
  display: flex;
  min-height: 90px;
  width: 461px;
  cursor: pointer;
  &.isComputerTab {
    min-height: 0;
    height: 69px;
  }
  &.noEdit.noEdit {
    cursor: default;
    pointer-events: none;
  }
  & .icon_left {
    margin-left: 3px;
    width: 96px;
    min-height: 88px;
    height: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    & svg path {
      stroke: #596080;
    }
  }
  & .main_right {
    width: 290px;
    margin-left: 4px;
    & .slider {
    }
    & .button {
    }
  }
}

.invisible_input {
  display: none;
}

.six_dot_permanent_line {
  display: flex;
  .line {
    width: 2px;
    background-color: #e8ebfa;
    position: absolute;
    &.regular {
      height: 100%;
    }
    &.first {
      height: 50%;
      transform: translate(0%, 100%);
    }
    &.last {
      height: 50%;
    }
    &.no_line {
      display: none;
    }
  }
}
.six_dot_item_number {
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #e8ebfa;
  border-radius: 6px;
  color: #596080;
}

.six_dot_placeholder {
  height: 32px;
  width: 32px;
  left: -15px;
  background: #e8ebfa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(0%, -50%);
  &.selected {
    background: #c5ceff;
  }
  &.selected .six_dot_item_number {
    color: #0026e5;
  }
}

.six_dot_balancer.active .six_dot_icon {
  display: flex;
}
.six_dot_dropMenu_wrapper {
  position: absolute;
  top: 50%;
  z-index: 11;
}

.container {
  position: relative;
  background: #ffffff;
  width: 100%;
  border: 1px solid #d7ddf7;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 96px minmax(100px, 350px) 64px;
  height: 90px;
  overflow: hidden;
  cursor: pointer;

  &.blink_it {
    animation: blinking_touch 1s;
  }

  @keyframes blinking_touch {
    0% {
      background-color: #ffffff;
    }
    50% {
      background-color: #e8ebfa;
    }
    100% {
      background-color: #ffffff;
    }
  }
  &:hover .middle,
  &:hover .icon_right {
    background-color: #f0f1fe;
  }

  &.big {
    height: 140px;
  }
  &.selected {
    border-color: #0000a8;
  }
}

.image_left {
  position: relative;
  overflow: hidden;
}
.middle {
  display: grid;
  grid-template-rows: 50px 1fr;
  &.big {
    grid-template-rows: 87px 1fr;
  }
  & .text {
    padding: 16px;
  }
  & .hint {
    padding-left: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #a3aacc;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    & svg path {
      stroke: #a3aacc;
    }
  }

  .title {
    max-width: 280px;
    @include ellipsis;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    max-height: 40px;
    line-height: 20px;
    color: #242633;
    word-break: break-all;
  }
  .description {
    display: flex;
    @include ellipsis;
    max-width: 90%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #596080;
    &.small {
      display: none;
    }
  }
}
.icon_right {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 88px;
  width: 64px;
  align-self: center;

  & svg.withHover:hover {
    & path {
      stroke: #596080;
    }
  }
}
.can_edit_wrapper {
  position: absolute;
  right: -33px;
  top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter {
  opacity: 0;
  position: absolute;
  right: 0;
  top: -14px;
  font-size: 12px;
  font-family: "RobotoFlex", sans-serif;
}

.counter_grey {
  color: #a3aacc;
}

.counter_red {
  color: #c21c44;
}

.counter_yellow {
  color: #ed9716;
}

.counter_visible {
  opacity: 1;
}

.embed_canvas_element {
  height: 450px;
  position: relative;
  margin-bottom: 40px;
}

.embed_preview {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #fff;
  & iframe {
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: 470px;
    padding: 10px;
  }
}

.number_plate {
  height: 32px;
  width: 32px;
  left: -64px;
  background: #e8ebfa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 16px;
  transform: translate(0%, -50%);
  & .number {
    font-style: normal;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #e8ebfa;
    border-radius: 6px;
    color: #596080;
  }
}
