$border-color: #ece2e2;
.share_menu__heading_block {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 10px;
  /*height: 44px ;*/
  /*justify-content: space-between;*/
  /*width: 170px;*/
  /*align-content: flex-end;*/
}

.share_menu__heading_block_bottom{
  margin-bottom: -7px;
}
.share_menu__available {
  margin-top: 10px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #939393;
}

.share_menu__access_code {
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #939393;
  margin-top: -2px;
}

.share_menu__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
}

.optional_text {
  display: flex;
  align-items: center;
  height: 34px;
  margin-left: 10px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #242633;
}
.share_menu__heading_description {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #596080;
  margin-top: 5px;
  margin-bottom: 9px;
}

.line {
  height: 0.5px;
  width: 100%;
  background-color: $border-color;
  border: none;
}
.inputWithSelectBorder {
  border-color: red !important;
}

.radio_selector {
  display: flex;
  padding-right: 20px;
}
.radioButton {
  :first-child {
    stroke: #cbcbcb;
  }
  :last-child {
    fill: none;
    stroke: none;
  }
  & .selected {
    fill: #534b4f;
    stroke: #534b4f;
  }
}

.selected {
  :last-child {
    stroke: #534b4f;
    fill: #534b4f;
  }
}

.option {
  display: flex;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
  &:hover:not(.option_disabled) .optional_text {
    text-shadow: 1px 1px 1px #e8ebfa;
  }
  &:hover:not(.option_disabled) {
    svg path {
      fill: #e8ebfa;
    }
  }
}

.option_disabled {
  opacity: 0.4;
  &:hover {
    cursor: not-allowed;
  }
}

.name_date_picker {
  width: 72px;
  height: 24px;
  background: #8697f0;
  border-radius: 6px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

.datePicker {
  display: flex;
  background: #f5f6ff;
  border: 1px solid #d7ddf7;
  border-radius: 6px;
  width: 232px;
  height: 32px;
  align-items: center;
  margin-left: 24px;
}

.small_text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #242633;
  display: flex;
  align-items: center;
  margin-left: 24px;
}
.date_text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #242633;
  display: flex;
  align-items: center;
}
.toggler_container {
  margin-top: 5px;
  width: 28px;
  height: 13px;
}

.shareInput {
  background: #ffffff;
  border: 1px solid $border-color;
  border-radius: 6px;
  height: 32px;
  width: 100%;
  margin-top: 14px;
  padding-left: 10px;
  &::placeholder {
    color: rgba(122, 105, 105, 0.5);
  }
}

.copyInput {
  border: 1px solid $border-color;
  border-radius: 6px;
  height: 32px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-right: 20px;
}

.copyInput_small {
  margin-top: 5px;
}

.share_menu {
  width: 399px;

  position: absolute;
  //right: -67px;
  //top: 18px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  z-index: 10;
  padding: 18px 0;
  cursor: auto;

  &.isPlaylist {
    right: 43px;
    top: 49px;
    min-height: 355px;
    width: 498px;
  }

  &.isModify{
    width: 212px;
    height:unset;
    min-height: unset;
    max-height: 317px;
    padding-left:20px;
  }

  &.isForSettings{
    right: 364px;
  }
}

.share_menu.share_maker_option {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 103;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.share_menu__heading {
  font-family: "RobotoFlex", serif;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;

  padding: 0 19px 0 19px;
  justify-content: space-between;
  color: #242633;
}

.channelsInput {
  height: 86px;
}

.wrap_copy_button {
  cursor: context-menu;
}

.button {
  height: 32px;
  border: none;
  color: white;
  background-color: #534b4f;
  border-radius: 6px;
  padding: 10px;
  font-family: "RobotoFlex", serif;
  font-size: 12px;
  letter-spacing: 0.6px;
  font-style: normal;
  font-weight: 500;

  user-select: none;
  cursor: pointer;
}

.copy_button {
  position: absolute;
  top: 37px;
  right: -2px;
  display: flex;
  align-items: center;
  width: 74px;
  height: 24px;
  border-radius: 6px;
  cursor: pointer;
  font-family: "RobotoFlex", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  color: #534b4f;
  background-color: #ffffff;
  border: 0.6px solid #ece2e2;
  :first-child {
    margin-right: 10px;
  }
}
.copy_button:hover {
  color: #484343;
  border: 0.6px solid #ffc72c;
  background-color: #ffeebf;
  svg {
    path {
      stroke: #484343;
    }
  }
}
.copy_button:active {
  background: rgba(202, 202, 203, 0.25);
}

.share_menu__access {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
}

.button_group_copy {
  position: relative;
  margin-bottom: 16px;
}

.button_group {
  margin-top: 20px;
  margin-right: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button:nth-child(2) {
    margin-left: 21px;
    margin-right: 1px;
  }
}

.isModify{
  margin-top:unset;
}
.cancel {
  background: white;
  width: 71px;
  height: 32px;
  border: 0.6px solid #534b4f;
  border-radius: 6px;
  margin-right: 16px;
  cursor: pointer;
  color: #534b4f;
  font-family: "RobotoFlex", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.cancel:hover {
  background-color: #e8ebfa;
}

.separator {
  background-color: #1d1d1d;
  opacity: 0.08;
  height: 1px;
  margin-top: 6px;
  margin-bottom: 8px;
}

.input_user_name {
  width: 359px;
}

.project_link {
  margin-top: 5px;
  margin-bottom: 7px;
}
.placeholder {
  margin-top: 5px;
  margin-bottom: 7px;
}


.availability_m_top {
  margin-top: 2px;
}
