.button_wrapper {
  display: flex;
  position: absolute;
  right: -26px;
  justify-content: space-between;
  user-select: none;
  top: 22px;
}
.button_wrapper.isExpandedView {
  right: -100px;
}
.isUpv.isUpv {
  position: static;
  & .approve_button {
    margin: 0;
    font-size: 11px;
    height: 24px;
    border-radius: 6px;
    padding: 3px 7px;
  }
  & .clicked.clicked {
    margin: 0;
  }
  & .question_wrapper {
    position: static;
    text-transform: uppercase;
  }
  & .green {
    border: 0.6px solid #43af41;
  }

  & .green {
    border: 0.6px solid #43af41;
  }

  & .darkGreen {
    border: 0.6px solid #3b7669;
  }

  & .orange {
    border: 0.6px solid #db874a;
  }

  & .purple {
    border: 0.6px solid #ae45ca;
  }

  & .darkPurple {
    border: 0.6px solid #6826bc;
  }

  & .blue {
    border: 0.6px solid #FFC72C;
  }
}

.question_wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  &.upvCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 19px;
  }
  &.isListView {
    bottom: 13px;
  }
  &.isPlayer {
    right: 0;
    left: inherit;
  }
}

.button_wrapper.PlayerContent {
  position: relative;
  right: initial;
  width: fit-content;
}
.question_wrapper.PlayerContent {
  position: relative;
}

.button_wrapper {
  & .button_settings {
    height: 42px;
    width: 136px;
    font-size: 12px;
    color: #a3aacc;
    font-family: "RobotoFlex", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: default;

    .svg_wrapper {
      width: 32px;
      height: 32px;
      padding: 6px;
      cursor: pointer;
      border-radius: 6px;
      margin-left: 12px;

      &:hover {
        background: #d7ddf7;
      }

      &.active_item {
        background: #d7ddf7;
      }
    }

    & .settings_menu {
      position: absolute;
      top: -112px;
      left: -257px;
      background: #ffffff;
      box-shadow:
        0 1px 4px rgba(64, 77, 140, 0.15),
        0 4px 16px rgba(64, 77, 140, 0.09);
      border-radius: 8px;
      z-index: 100;
      display: flex;
      width: 284px;
      height: 296px;
      padding: 16px;
      flex-direction: column;
      align-items: center;
      .item_wrapper {
        //margin-top: 16px;
        margin-bottom: 16px;
        width: 252px;
        &_limit {
          width: 279px;
        }
        & .text {
          margin-top: 16px;
          margin-bottom: 3px;
          color: #939393;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          &.active {
            color: #FFC72C;
          }
        }
        & .text:first-child {
          margin-top: 0;
        }

        &:focus-within .input_wrapper {
          border: 1px solid #FFC72C;
        }

        & .field {
          display: flex;
          align-items: center;
          margin-top: 9px;
          margin-left: 9px;
          height: 32px;
          margin-bottom: 6px;
          font-size: 14px;
          line-height: 21px;
          color: #242633;
        }

        & .input_wrapper {
          //margin-top: 4px;
          border-radius: 6px;
          border: 1px solid #ECE2E2;
          background: var(--White, #FFF);
          //width: 375px;
          height: 32px;
          padding: 5px 8px 6px 4px;
          &.input_wrapper_small {
            width: 70px;
            padding-left: 3px;
            position: relative;
            //top: -14px;
            margin-left: 14px;
            input {
              text-align: right;
            }
          }
          input {
            border: none;
            width: 100%;
            height: 21px;
            color: #343434;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            &::placeholder {
              color: #a3aacc;
            }
          }
        }

        & .select_color {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          //margin-right: 17px;

          & .picker_wrapper {
            border: 1px solid #d7ddf7;
            box-sizing: border-box;
            border-radius: 17px;
            width: 32px;
            height: 32px;
            padding: 2px;

            &.active {
              border: 1px solid #FFC72C;
            }

            & .picker {
              width: 26px;
              height: 26px;
              border-radius: 13px;
              cursor: pointer;
              &.green {
                background: #1E9001;
              }

              &.red {
                background: #D33F49;
              }

              &.orange {
                background: #E56E00;
              }

              &.purple {
                background: #9F4C9B;
              }

              &.blue {
                background: #204EC3;
              }

              &.black {
                background: #484343;
              }
            }
          }
        }
      }
    }
  }
}

.approve_button {
  align-self: center;
  padding: 8px 9px;
  border-radius: 16px;
  width: fit-content;
  height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  max-width: 500px;
  overflow: hidden;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-top: 15px;
  margin-left: 2px;
  transition: background-color ease-in-out 0.15s;
  &.upvCard {
    position: relative;
    top: 4px;
    margin-top: 0;
  }
  &.isDisabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  & svg {
    margin-left: -4px;
    margin-right: 6px;
  }

  &.clicked.clicked {
    border: 1px solid #d7ddf7;
    display: flex;
    align-items: center;
    background: white;
    color: #242633;
    margin-top: 15px;
    &:hover {
      background: #e8ebfa;
    }
  }
  &.upvCard.clicked {
    top: 4px;
    margin-top: 0;
    position: relative;
  }
  &.matrix_header {
    height: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  &.green {
    background: rgba(67, 175, 65, 0.15);
    color: #43af41;

    & svg path {
      stroke: #43af41;
    }
    &:hover {
      background: rgba(67, 175, 65, 0.3);
    }
  }

  &.darkGreen {
    background: rgba(59, 118, 105, 0.15);
    color: #3b7669;

    & svg path {
      stroke: #3b7669;
    }
    &:hover {
      background: rgba(59, 118, 105, 0.3);
    }
  }

  &.orange {
    background: rgba(219, 135, 74, 0.15);
    color: #db874a;

    & svg path {
      stroke: #db874a;
    }
    &:hover {
      background: rgba(219, 135, 74, 0.3);
    }
  }

  &.purple {
    background: rgba(174, 69, 202, 0.15);
    color: #ae45ca;

    & svg path {
      stroke: #ae45ca;
    }
    &:hover {
      background: rgba(174, 69, 202, 0.3);
    }
  }

  &.darkPurple {
    background: rgba(104, 38, 188, 0.15);
    color: #6826bc;

    & svg path {
      stroke: #6826bc;
    }
    &:hover {
      background: rgba(104, 38, 188, 0.3);
    }
  }

  &.blue {
    background: rgba(0, 38, 229, 0.15);
    color: #FFC72C;

    & svg path {
      stroke: #FFC72C;
    }
    &:hover {
      background: rgba(0, 38, 229, 0.3);
    }
  }

  & svg {
    width: 20px;
    height: 20px;
  }
}

.edit_button {
  position: absolute;
  left: 110%;
  top: 19px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity ease-in-out 0.15s;
  opacity: 0;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: #e8ebfa;
  }
  &.active {
    opacity: 1;
    background-color: #d7ddf7;
  }
  &.upvCard {
    top: 9px;
    left: 5px;
    position: relative;
  }
}

.question_wrapper:hover .edit_button {
  opacity: 1;
}
