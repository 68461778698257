@import "../../../../mixins";

@keyframes fade_in_list {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 32px;
  }
}

.activity_main {
  height: 100%;
  padding: 27px 40px;

  .title {
    margin-top: 43px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    color: #242633;
  }
}

.activity_table {
  padding: 14px 12px 4px 8px;

  .header {
    // This selectors are for cosmetic styles only!
    // List view may display wrong if any spacing styles added here.
  }
  .row_wrapper {
    // This selectors are for cosmetic styles only!
    // List view may display wrong if any spacing styles added here.
    cursor: default;
    border-radius: 6px;
    &:hover {
      background-color: #FFEEBF;
    }
    &.active {
      background-color: #FFEEBF;
    }
  }
  .row {
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    &:not(.header) {
      animation: fade_in_list 0.3s;
    }
  }
  .cell {
    font-weight: 400;
    font-size: 14px;
    color: #242633;
    @include ellipsis;
    &.first {
      padding-left: 8px;
    }
    &.last {
      padding-right: 4px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.cell {
  position: relative;
  padding-top: 7px;

  &.channelName {
    flex: 37%;
  }
  &.userRole {
    flex: 29%;
  }
  &.authorName {
    flex: 40%;
  }
  &.actions {
    flex: 8%;
  }
}

.header.row {
  height: 64px;
}

.cell.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  overflow: visible;
}

.header .cell {
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: #596080;
  cursor: pointer;
}

.actions .more {
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 6px;
  &:hover {
    background-color: #FFC72C;
  }
  &.active {
    background-color: #FFC72C;
  }

  &.pin {
    svg path {
      stroke: #484343;
    }
  }
}
