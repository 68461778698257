.label {
  color: #484343;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  margin-bottom: 22px;
  margin-top: 10px;
  &.marginTop12 {
    margin-top: 12px;
  }
}

.text {
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  margin-bottom: 19px;
    p {
    font-size: 14px;
    color: #585858;
  }
}
