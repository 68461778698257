//* {
//    box-sizing: border-box;
//}

.table_for_lib {
  * {
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    font-size: 14px;
  }

  table,
  .divTable {
    //width: fit-content;
  }

  .tr {
    display: flex;
  }

  tr,
  .tr {
    width: fit-content;
    height: 30px;
  }

  th,
  .th,
  td,
  .td {
    //box-shadow: inset 0 0 0 1px lightgray;
    padding: 0.25rem 0.25rem 0.25rem 8px;
  }

  th,
  .th {
    padding: 2px 4px 2px 8px;
    position: relative;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    text-align: center;
    height: 30px;
  }

  td,
  .td {
    height: 30px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  tr {
    th {
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #585858;
    }

    th:nth-child(1) {
      width: 40px;
    }

    th:nth-child(2) {
      width: 40px;
    }

    th:last-child {
      text-align: right;
    }

    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #585858;
    }

    td:nth-child(3) {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #343434;
    }
  }

  .fui-TableHeaderCell__aside {
    div {
      width: 24px;
    }
  }

  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }

  tbody {
    tr td:first-child {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      padding-left: 10px;
    }

    tr td:nth-child(2) {
      padding-left: 0px;
    }

    tr td {
      padding-left: 18px;
    }
    tr td:nth-child(3) {
      padding-left: 5px;
    }
    tr:has(td div div span div.disrupt_opacity) {
      background-color: #ffeebf;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;

      td {
        opacity: 0.5;
      }

      td:last-child {
        opacity: 1;

        .actions_svg_wrapper {
          opacity: 0;
        }

        .actions_svg_wrapper-favorite {
          opacity: 0.5;
        }
      }
    }

    tr:has(td div div span div.hide_opacity) {
      background-color: transparent;

      td {
        opacity: 0.2;
      }

      td:last-child {
        opacity: 1;

        .actions_svg_wrapper {
          opacity: 0.2;
        }

        .hide_opacity {
          opacity: 1;
        }
      }
    }

    tr td:last-child {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      span {
        display: flex;
        justify-content: flex-end;
      }
    }

    tr:hover {
      cursor: pointer;
      background: #ffeebf;
      border-radius: 8px;

      .actions_fluent {
        .actions_svg_wrapper {
          visibility: visible;
        }
      }
    }

    tr {
      height: 32px;
    }

    tr td {
      height: 32px;
    }
  }

  thead {
    tr {
      th {
        border-bottom: 1px rgba(29, 29, 29, 0.08) solid;

        button {
          font-weight: 500;

          .custom_header {
            width: 100%;
            padding-left: 10px;
          }

          .in_recent_mode {
            display: none;
          }
        }
      }

      th:hover {
        button {
          .svg_arrow_wrapper {
            visibility: visible;
          }

          background-color: #ffeebf;
          border-radius: 6px;
        }
      }

      border-bottom: 1px rgba(29, 29, 29, 0.08) solid;
    }

    tr:has(th button .custom_header .in_recent_mode) {
      button {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
.table_in_row_view.disable_headers {
  thead {
    visibility: hidden;
  }
}
.table_in_row_view.make_before_great_again {
  tbody:before {
    line-height: 48px;
  }
}
.no_resize div{
  resize: none;
  cursor: default;
}
.no-resize th {
  cursor: default;
}
.table_for_lib.disable_headers {
  thead {
    visibility: hidden;
  }
}
.table_for_lib.make_before_great_again {
  tbody:before {
    line-height: 48px;
  }
}
.cell-wrapper {
  span {
    overflow: hidden;
  }
}