:root {
  --button-radius: 4px;
  --button-row-gap: 8px;
  --button-paddings: 16px;
  --button-height: 32px;
  --border-default: #A2A0A7;

  // COLORS SECTION
  // Grayscale
  --Grayscale_1: #FCFCFD;
  --Grayscale_2: #F7F7F8;
  --Grayscale_3: #F2F2F3;
  --Grayscale_4: #CBCACE;
  --Grayscale_5: #B7B5BA;
  --Grayscale_6: #A2A0A7;
  --Grayscale_7: #8D8A93;
  --Grayscale_10: #514E55;
  --Grayscale_11: #323135;
  --Grayscale_12: #232225;
  --Grayscale_hover_alpha_1: #7E7A850A;
  --Grayscale_hover_alpha_2: #6F6C7514;

// Brand
  --Brand_1: #FEFDFA;
  --Brand_2: #FDF6E2;
  --Brand_3: #F8EAC9;
  --Brand_4: #F3DDA5;
  --Brand_5: #ECCE83;
  --Brand_6: #E7C15F;
  --Brand_7: #FFC72C;
  --Brand_8: #F8BA12;
  --Brand_9: #DDA20E;
  --Brand_hover_alpha_1: #E6AC190F;
  --Brand_hover_alpha_2: #CA971614;
// Accents Orange
  --Accents_Orange_1: #FEFDFA;
  --Accents_Orange_2: #FDF2E2;
  --Accents_Orange_3: #F8E5C9;
  --Accents_Orange_4: #F3D4A5;
  --Accents_Orange_5: #ECC283;
  --Accents_Orange_6: #E7B15F;
  --Accents_Orange_7: #DD9F40;
  --Accents_Orange_9: #A97323;
  --Accents_Orange_10: #845C1F;
  --Accents_Orange_hover_alpha_2: #CA821614;
  --Accents_Orange_hover_alpha_3: #A56A1229;

  // Accents Red
  --Accents_Red_1: #FEFBFA;
  --Accents_Red_2: #FDE6E2;
  --Accents_Red_3: #F8CFC9;
  --Accents_Red_4: #F3B0A5;
  --Accents_Red_5: #EC9183;
  --Accents_Red_6: #E7715F;
  --Accents_Red_7: #DD5540;
  --Accents_Red_8: #D23119;
  --Accents_Red_9: #A93523;
  --Accents_Red_10: #842C1F;
  --Accents_Red_hover_alpha_2: #CA2E1614;
  --Accents_Red_hover_alpha_3: #A5261229;

  // Accents Green
  --Accents_Green_1: #FEFBFA;
  --Accents_Green_2: #E2FDE8;
  --Accents_Green_3: #C9F8CC;
  --Accents_Green_4: #A5F3B7;
  --Accents_Green_5: #83EC9B;
  --Accents_Green_6: #5FE77F;
  --Accents_Green_7: #40DD65;
  --Accents_Green_9: #23A942;
  --Accents_Green_10: #1F8437;
  --Accents_Green_hover_alpha_2: #16CA2214;
  --Accents_Green_hover_alpha_3: #12A51C29;

  // Default
  --white: #FFF;
  --black: #000;

  // SCHEME
  // Background Scheme Default
  --Sheme_bg_disabled: #F7F7F8;
  --Sheme_bg_subtle: #F2F2F3;
  --Sheme_bg_muted: #A2A0A7;
  --Sheme_bg_default: #323135;
  --Sheme_bg_hover: #514E55;
  --Sheme_bg_hover_alpha_1: #7E7A850A;
  --Sheme_bg_emphasized: #232225;

  // Foreground Scheme Default
  --Sheme_fg_disabled: #CBCACE;
  --Sheme_fg_subtle: #8D8A93;
  --Sheme_fg_muted: #514E55;
  --Sheme_fg_default: #232225;
  --Sheme_fg_inverted: #FCFCFD;

  // Border Scheme Default
  --Sheme_border_default: #A2A0A7;
  --Sheme_border_muted: #B7B5BA;
  --Sheme_border_subtle: #CBCACE;
  --Sheme_border_disabled: #F2F2F3;
  --Sheme_border_focus: #6F6C7514;

  // Modes
  // Background Scheme Neutral
  --Neutral_Scheme_bg_disabled: var(--Grayscale_2);
  --Neutral_Scheme_bg_subtle: var(--Grayscale_3);
  --Neutral_Scheme_bg_muted: var(--Grayscale_6);
  --Neutral_Scheme_bg_default: var(--Grayscale_11);
  --Neutral_Scheme_bg_hover: var(--Grayscale_10);
  --Neutral_Scheme_bg_hover_alpha_1: var(--Grayscale_hover_alpha_1);
  --Neutral_Scheme_bg_emphasized: var(--Grayscale_12);

  // Foreground Scheme Neutral
  --Neutral_Scheme_fg_disabled: var(--Grayscale_4);
  --Neutral_Scheme_fg_subtle: var(--Grayscale_7);
  --Neutral_Scheme_fg_muted: var(--Grayscale_10);
  --Neutral_Scheme_fg_default: var(--Grayscale_12);
  --Neutral_Scheme_fg_inverted: var(--Grayscale_1);

  // Border Scheme Neutral
  --Neutral_Scheme_border_default: var(--Grayscale_3);
  --Neutral_Scheme_border_muted: var(--Grayscale_4);
  --Neutral_Scheme_border_subtle: var(--Grayscale_5);
  --Neutral_Scheme_border_disabled: var(--Grayscale_6);
  --Neutral_Scheme_border_focus: var(--Grayscale_hover_alpha_2);

  // Background Scheme Brand
  --Brand_Scheme_bg_disabled: var(--Brand_2);
  --Brand_Scheme_bg_subtle: var(--Brand_3);
  --Brand_Scheme_bg_muted: var(--Brand_5);
  --Brand_Scheme_bg_default: var(--Brand_7);
  --Brand_Scheme_bg_hover: var(--Brand_6);
  --Brand_Scheme_bg_hover_alpha_1: var(--Brand_hover_alpha_1);
  --Brand_Scheme_bg_emphasized: var(--Brand_8);
  
  // Foreground Scheme Brand
  --Brand_Scheme_fg_disabled: var(--Brand_3);
  --Brand_Scheme_fg_subtle: var(--Brand_4);
  --Brand_Scheme_fg_muted: var(--Brand_6);
  --Brand_Scheme_fg_default: var(--Brand_8);
  --Brand_Scheme_fg_inverted: var(--Brand_1);
  
  // Border Scheme Brand
  --Brand_Scheme_border_default: var(--Brand_2);
  --Brand_Scheme_border_muted: var(--Brand_3);
  --Brand_Scheme_border_subtle: var(--Brand_4);
  --Brand_Scheme_border_disabled: var(--Brand_5);
  --Brand_Scheme_border_focus: var(--Brand_hover_alpha_2);

  // Background Scheme Warning
  --Warning_Scheme_bg_disabled: var(--Accents_Orange_2);
  --Warning_Scheme_bg_subtle: var(--Accents_Orange_3);
  --Warning_Scheme_bg_muted: var(--Accents_Orange_5);
  --Warning_Scheme_bg_default: var(--Accents_Orange_7);
  --Warning_Scheme_bg_hover: var(--Accents_Orange_6);
  --Warning_Scheme_bg_hover_alpha_1: var(--Accents_Orange_hover_alpha_3);
  --Warning_Scheme_bg_emphasized: var(--Accents_Orange_10);
  
  // Foreground Scheme Warning
  --Warning_Scheme_fg_disabled: var(--Accents_Orange_9);
  --Warning_Scheme_fg_subtle: var(--Accents_Orange_6);
  --Warning_Scheme_fg_muted: var(--Accents_Orange_4);
  --Warning_Scheme_fg_default: var(--Accents_Orange_3);
  --Warning_Scheme_fg_inverted: var(--Accents_Orange_1);
  
  // Border Scheme Warning
  --Warning_Scheme_border_default: var(--Accents_Orange_5);
  --Warning_Scheme_border_muted: var(--Accents_Orange_4);
  --Warning_Scheme_border_subtle: var(--Accents_Orange_3);
  --Warning_Scheme_border_disabled: var(--Accents_Orange_2);
  --Warning_Scheme_border_focus: var(--Accents_Orange_hover_alpha_2);

  // Background Scheme Error
  --Error_Scheme_bg_disabled: var(--Accents_Red_2);
  --Error_Scheme_bg_subtle: var(--Accents_Red_3);
  --Error_Scheme_bg_muted: var(--Accents_Red_5);
  --Error_Scheme_bg_default: var(--Accents_Red_7);
  --Error_Scheme_bg_hover: var(--Accents_Red_6);
  --Error_Scheme_bg_hover_alpha_1: var(--Accents_Red_hover_alpha_3);
  --Error_Scheme_bg_emphasized: var(--Accents_Red_10);

  // Foreground Scheme Error
  --Error_Scheme_fg_disabled: var(--Accents_Red_9);
  --Error_Scheme_fg_subtle: var(--Accents_Red_6);
  --Error_Scheme_fg_muted: var(--Accents_Red_4);
  --Error_Scheme_fg_default: var(--Accents_Red_3);
  --Error_Scheme_fg_inverted: var(--Accents_Red_1);

  // Border Scheme Error
  --Error_Scheme_border_default: var(--Accents_Red_5);
  --Error_Scheme_border_muted: var(--Accents_Red_4);
  --Error_Scheme_border_subtle: var(--Accents_Red_3);
  --Error_Scheme_border_disabled: var(--Accents_Red_2);
  --Error_Scheme_border_focus: var(--Accents_Red_hover_alpha_2);

  // Background Scheme Success
  --Success_Scheme_bg_disabled: var(--Accents_Green_2);
  --Success_Scheme_bg_subtle: var(--Accents_Green_3);
  --Success_Scheme_bg_muted: var(--Accents_Green_5);
  --Success_Scheme_bg_default: var(--Accents_Green_7);
  --Success_Scheme_bg_hover: var(--Accents_Green_6);
  --Success_Scheme_bg_hover_alpha_1: var(--Accents_Green_hover_alpha_3);
  --Success_Scheme_bg_emphasized: var(--Accents_Green_10);

  // Foreground Scheme Success
  --Success_Scheme_fg_disabled: var(--Accents_Green_9);
  --Success_Scheme_fg_subtle: var(--Accents_Green_6);
  --Success_Scheme_fg_muted: var(--Accents_Green_4);
  --Success_Scheme_fg_default: var(--Accents_Green_3);
  --Success_Scheme_fg_inverted: var(--Accents_Green_1);

  // Border Scheme Success
  --Success_Scheme_border_default: var(--Accents_Green_5);
  --Success_Scheme_border_muted: var(--Accents_Green_4);
  --Success_Scheme_border_subtle: var(--Accents_Green_3);
  --Success_Scheme_border_disabled: var(--Accents_Green_2);
  --Success_Scheme_border_focus: var(--Accents_Green_hover_alpha_2);

  // Gradients 
  // Background
  --Gradient-bg-disabled: linear-gradient(98deg, #E2FDE8 4.03%, #C9F8CC 102.26%);
  --Gradient-bg-subtle: linear-gradient(98deg, #A5F3B7 4.03%, #83EC9B 102.26%);
  --Gradient-bg-muted: linear-gradient(98deg, #5FE77F 4.03%, #40DD65 102.26%);
  --Gradient-bg-default: linear-gradient(98deg, #19D244 4.03%, #23A942 102.26%);
  --Gradient-bg-hover: linear-gradient(98deg, #40DD65 4.03%, #19D244 102.26%);
  --Gradient-bg-emphasized: linear-gradient(98deg, #23A942 4.03%, #1F8437 102.26%);

  // Foreground
  --Gradient-fg-default: linear-gradient(98deg, #1F8437 4.03%, #165024 102.26%);
  --Gradient-fg-muted: linear-gradient(98deg, #40DD65 4.03%, #19D244 102.26%);
  --Gradient-fg-subtle: linear-gradient(98deg, #5FE77F 4.03%, #40DD65 102.26%);
  --Gradient-fg-disabled: linear-gradient(98deg, #A5F3B7 4.03%, #83EC9B 102.26%);
  --Gradient-fg-Inverted: linear-gradient(98deg, #FAFEFB 4.03%, #E2FDE8 102.26%);
  
  // Border
  --Gradient-border-default: linear-gradient(98deg, #40DD65 4.03%, #19D244 102.26%);
  --Gradient-border-muted: linear-gradient(98deg, #5FE77F 4.03%, #40DD65 102.26%);
  --Gradient-border-subtle: linear-gradient(98deg, #A5F3B7 4.03%, #83EC9B 102.26%);
  --Gradient-border-disabled: linear-gradient(98deg, #C9F8CC 4.03%, #A5F3B7 102.26%);
  --Gradient-border-focus: linear-gradient(98deg, rgba(25, 230, 39, 0.04) 4.03%, rgba(22, 202, 34, 0.08) 102.26%);

  // Fonts
  .text_unified {
    font-family: RobotoFlex, sans-serif;
    &-xs {
      font-size: 12px;
      line-height: 15px;
    }
    &-sm {
      font-size: 14px;
      line-height: 18px;
    }
    &-base {
      font-size: 16px;
      line-height: 24px;
    }
    &-lg {
      font-size: 18px;
      line-height: 27px;
    }
    &-xl {
      font-size: 20px;
      line-height: 30px;
    }
    &-2xl {
      font-size: 24px;
      line-height: 36px;
    }
    &-3xl {
      font-size: 30px;
      line-height: 38px;
    }
    &-4xl {
      font-size: 36px;
      line-height: 45px;
    }
    &-5xl {
      font-size: 48px;
      line-height: 48px;
    }
    &-6xl {
      font-size: 60px;
      line-height: 60px;
    }
    &-7xl {
      font-size: 72px;
      line-height: 72px;
    }
    &-8xl {
      font-size: 96px;
      line-height: 96px;
    }
    &-9xl {
      font-size: 128px;
      line-height: 128px;
    }
  }
  // Box-shadow
  --box_shadow-xs: 0px 2px 4px 2px rgba(23, 25, 28, 0.08);
  --box_shadow-sm: 0px 8px 20px -4px rgba(23, 25, 28, 0.08), 0px 4px 6px 2px rgba(23, 25, 28, 0.08);
  --box_shadow-md: 0px 12px 32px -6px rgba(23, 25, 28, 0.08), 0px 8px 16px 2px  rgba(23, 25, 28, 0.08);
  --box_shadow-lg: 0px 16px 40px -8px rgba(23, 25, 28, 0.08), 0px 12px 24px 4px  rgba(23, 25, 28, 0.08);
  --box_shadow-xl: 0px 20px 60px -10px rgba(23, 25, 28, 0.08),  0px 16px 32px 6px  rgba(23, 25, 28, 0.12);
}