.view_page_wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: calc(100vh - 73px);
}

.view_page_content {
  display: flex;
  &.view_mode {
    margin: 0 auto;
  }
}

.view_page_scroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 64px);
  width: 100%;
  &.isSideBarOpened {
    width: auto;
  }
}
