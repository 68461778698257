.wrapper {
  position: fixed;
  top: 100px;
  z-index: 1;
}
.container {
  cursor: initial;
  z-index: 1;
  position: absolute;
  right: -41px;
  top: 6px;
  width: 208px;
  height: 310px;
  background-color: white;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.section {
}
.title_input {
  display: flex;
  align-items: center;
  padding: 4px 5px 7px 5px;
  cursor: pointer;
  width: 100%;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  &.error {
    border: 1px solid #b30e00;
  }
  input {
    line-height: 16px;
    width: 100%;
    border: none;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #242633;
  }
  &.active {
    border: 1px solid #8697f0;
  }
}
.error_msg_container {
  position: relative;
  top: 5px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #b30e00;
  & .icon svg path {
    stroke: #b30e00;
  }
  & .icon {
    margin-right: 4px;
  }
  &.isLibrarySideBarTags {
    margin-left: 0;
  }
}
.color_picker {
  height: 180px;
  width: 100%;
  margin-bottom: 15px;

  & .label {
    margin-left: 16px;
    font-family: "RobotoFlex", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #a3aacc;
  }
  & .colors {
    margin: 8px 16px;
    display: grid;
    grid-template-columns: repeat(4, 42px);
    grid-template-rows: repeat(4, 34px);
    grid-row-gap: 6px;
    grid-column-gap: 6px;

    & .color_container {
      cursor: pointer;
      width: 32px;
      border-radius: 50%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #e8ebfa;
      }
      &.active {
        background-color: #e8ebfa;
      }
      &.empty:hover {
        background-color: white;
      }
      &.empty {
        cursor: inherit;
      }
      & .color_icon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }
  }
}
.delete_option {
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  cursor: pointer;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #c21c44;
  margin: 8px 0;
  padding-left: 12px;
  .delete_icon_wrapper {
    position: relative;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    & svg path {
      stroke: #b30e00;
      fill: white;
    }
  }
  &:hover {
    background: #ffe1e8;
  }
  &:hover svg path {
    fill: #ffe1e8;
  }
  &.isLibrarySideBarTags {
    padding-left: 5px;
  }
}

.line {
  margin-top: 16px;
  height: 1px;
  width: 100%;
  background-color: #e8ebfa;
}
