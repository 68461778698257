.avatar_wrapper{
  flex: none;
  width: 22px;
  height: 22px;
  img {
    border-radius: 50%;
    width: 22px;
    height: 22px;
  }
  @media (max-width: 900px){
    display: none;
  }

}
.bold_text{
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #484343;
}
.avatar_wrapper_details{
  flex: none;
  img {
    border-radius: 50%;
    width: 36px;
    height: 36px;
  }
}

.selector_place{
  width: 26px;
  height: 38px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex: none;
}

.avatar_and_status{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sender_info{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.recipients_info_tooltip{
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recipients_opened_tooltip{
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  & svg{
    flex: none;
  }
}

.tooltip_wrapper{
  position: relative;

  &:hover{
    .tooltip{
      cursor: default;
      z-index: 20;
      width: 284px;
      box-shadow: 1px 1px 18px 0 rgba(122, 105, 105, 0.25);
      height:auto;
      padding: 16px;
      &.isInvite{
        width:192px;
      }
      &.isAtBottom{
        bottom: 0;
        top: inherit;
      }
    }
    .tooltip_opacity{
      opacity: 1;
    }
  }
}
.channel_info{
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  color: #484343;

  & p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & svg {
    flex: none;
  }
  width: 100%;
}

.recipients_info{
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 83%;

  & p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.opens_info{
  width: 100%;
  & p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.channels_bubble{
  border-radius: 16px;
  background: #FFF4D4;
  margin-left: 8px;
  padding: 5px 8px;
}
.tooltip_opacity.tooltip_opacity{
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip{
  top: -10px;
  left: 30px;
  position: absolute;
  width: 0;
  height: 0;
  z-index: -1;
  border-radius: 12px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  gap: 16px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  transition:all 0.15s ease-in-out;
  max-height: 316px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.13);
  }

}

.channels_tooltip_item_wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.channels_tooltip_item{
  display: flex;
  align-items: center;
  gap: 10px;
  flex:none;
  overflow: hidden;
  & span{
    max-width: 180px;
    overflow: hidden;

    text-overflow: ellipsis;

  }
}

.sender_details{
  display: flex;
  flex-direction: column;
  & .sender_details_email{
    color: #939393;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
  }
}

.role_wrapper{
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #F6F6F5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
}

.hiddenInput {
  display: none;
}
.wrapper_copy_button {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  gap: 15px;
  padding-left: 3px;
}

.copy_button {
  display: flex;
  align-items: center;
  width: 74px;
  height: 24px;
  border-radius: 6px;
  cursor: pointer;
  font-family: "RobotoFlex", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  color: #534b4f;
  background-color: inherit;
  &:hover{
    border: 0.6px solid #FFC72C;
  }
  border: 0.6px solid #ece2e2;
  :first-child {
    margin-right: 10px;
  }
}
.outbox_avatar_wrapper{
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #F6F6F5;
  display: flex;
  justify-content: center;
  align-items: center;
  & img{
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }
}

.outbox_popup_username {
  color: #585858;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.outbox_popup_email {
  color: #939393;
  font-family: "NavigationSlider", sans-serif;
  font-size: 13px;
  font-weight: 400;
}
