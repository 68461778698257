@import "./src/_mixins";

.headingMenu_small.headingMenu_small {
  width: 400px;
}

.headingMenu {
  left: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 11px 5px 11px 9px;

  &.view_playlist {
    border-bottom: 1px solid #d7ddf7;
    justify-content: center;
    align-items: center;
    background-color: #f5f6ff;
    height: 64px;
  }

  &.edit_page {
    border-bottom: 1px solid #d7ddf7;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }

  &.isPage {
    padding: 16px 5px 11px 9px;
    z-index: 104;

    &.isShowLibrary {
      z-index: 1;
    }
  }

  &.forEditPage {
    justify-content: space-around;
  }
}
.headingMenu_small.isPage {
  position: relative;
  left: 0;
  z-index: 100;
}

.view_expand_wrapper {
  position: absolute;
  right: 15px;
  top: 14px;
  .simple_button {
    float: right;
    min-width: 113px;
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
    border-radius: 6px;
    & svg {
      margin-right: 5px;
    }
    & .text {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #596080;
    }
    svg path {
      stroke: #596080;
    }
    &:hover {
      background-color: #e8ebfa;
      .text {
        color: #242633;
      }
      svg path {
        stroke: #242633;
      }
    }
  }
}

.edit_page_title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  display: flex;
  align-items: center;
  padding: 8px 10px;
}

.close_edit {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-right: 11px;
  border: 1px solid #d7ddf7;
  cursor: pointer;
  &:hover {
    background-color: #e8ebfa;
  }
  & svg {
    fill: transparent;
    & path {
      stroke: #0026e5;
    }
  }
}

.back_to_playlists_btn {
  position: absolute;
  left: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #0026e5;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
  }
  & svg path {
    stroke: #0026e5;
  }
  &:hover {
    background: #e8ebfa;
    & svg {
      fill: #e8ebfa;
    }
  }

  &:active {
    background: #e8ebfa;
  }
}

.title {
  display: flex;
  height: 24px;
}
.title_small {
  .breadcrumbs .text {
    max-width: 157px;
    @include ellipsis;

    &.small {
      width: 20px;
    }
  }
}

.draft {
  font-size: 16px;
  line-height: 24px;
  color: #a3aacc;
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translate(-50%, -50%);
  &.playlist_preview {
    background-color: #ffe589;
    border: 1px solid transparent;
    border-radius: 6px;
    height: 32px;
    top: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #242633;
    width: 128px;
    font-size: 12px;
    line-height: 12px;
    & svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
.published {
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.balanceCenterPlaylist {
  padding-right: 335px;
}
.balanceCenterPage {
  padding-right: 100px;
}
.close {
  bottom: 2px;
  position: relative;
  padding-right: 20px;
  cursor: pointer;
}

.navigate {
  display: flex;
  width: 96px;
  justify-content: space-between;
  padding-right: 40px;
}

.navigate__block {
  padding: 0 5px;
  cursor: pointer;
}

.navigate__block_disable {
  opacity: 0.5;
  cursor: no-drop;
}

.headingMenu__container {
  position: relative;

  &.forEditPage {
    position: absolute;
    right: 11px;
    top: 13px;
    z-index: 110;
  }
}

.headingMenu__share_container {
  position: relative;
  top: 14px;
  right: 111px;
}

.headingMenu__options {
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: normal;
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  letter-spacing: 1px;
  min-width: 360px;

  .item {
    display: flex;
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: relative;
    margin-left: 16px;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 6px;
  }

  .library svg path {
    stroke: #596080;
    fill: #fff;
  }

  svg path {
    stroke: #596080;
  }

  .item.library.active {
    background: #e8ebfa;
  }

  .item.share.active,
  .item.view.active,
  .item.settings.active {
    background: #e8ebfa;
  }
  .item.more.active {
    background: #e8ebfa;
  }
  .item.more {
    border: 1px solid #d7ddf7;
  }
  .item:hover {
    background: #e8ebfa;
    & svg path {
      stroke: #242633;
    }
  }
  .item.settings.disabled {
    cursor: not-allowed;
    pointer-events: none;
    & svg path {
      fill: none;
      stroke: #a2a7b2;
    }
  }
  .item:hover svg path {
    fill: #e8ebfa;
  }

  .item.favorite:hover svg path {
    stroke: #ff8a00;
  }

  .item.favorite.active svg path {
    stroke: #ff8a00;
    fill: #ff8a00;
  }

  .item.active svg path {
    stroke: #242633;
    fill: #e8ebfa;
  }

  &.disabled {
    cursor: no-drop;
    background: none;
  }

  &:hover .disabled {
    background: none;
  }

  &:hover.disabled svg path {
    stroke: #a2a7b2;
    fill: #fff;
  }

  &.disabled svg path {
    stroke: #a2a7b2;
  }
  &:hover.disabled svg {
    background: none;
  }
}

.headingMenu__options_item {
  display: flex;
  cursor: pointer;
  position: relative;
  padding-left: 21px;
}

.headingMenu__options_img {
  padding: 0 4px;
  position: relative;
  bottom: 1px;
}

.headingMenu__options_img_heart {
  opacity: 0.7;
  &_active {
    opacity: 1;
  }
  &_active path {
    opacity: 1;
    stroke: #ff8a00 !important ;
    fill: #ff8a00 !important;
  }
}

.parent_title {
  @include ellipsis;
  max-width: 200px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #5f646d;
  cursor: pointer;
}

.current_title {
  @include ellipsis;
  max-width: 300px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #242633;
}

.order {
  color: #5f646d;
  padding-left: 32px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
}
.view_playlist_title {
  display: flex;
  align-items: center;
  margin: 0 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;

  & .text_wrapper {
    user-select: text;
  }
}
.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5.5px 6px;
  border-radius: 6px;
  margin: 0 8px;
  & .icon_title {
    margin-right: 4px;
    flex-shrink: 0;
  }
  & .text {
    display: flex;
    align-items: center;
    @include ellipsis;
    max-width: 150px;
  }
  .favorite_icon path {
    stroke: #596080;
    fill: #dde1f5;
  }
  &:hover {
    background: #e8ebfa;
  }
  &_parent {
    cursor: pointer;
    min-width: 81px;
  }
  &:last-child {
    cursor: default;
    background: none;
  }
  &.active:hover {
    background: #e8ebfa;
  }
  &.active {
    cursor: pointer;
  }
  &.last {
    width: 100px;
  }
}

.separator {
  align-self: center;
}
.separator_parent {
  align-self: center;

  padding-bottom: 3px;
}

.icon24 {
  width: 20px;
  height: 24px;
  margin-right: 10px;
}
.icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.icon path {
  //fill: none
}

.publish {
  text-transform: uppercase;
  border: 1px solid #0026e5;
  border-radius: 6px;
  height: 32px;
  white-space: nowrap;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: #0026e5;
  font-size: 12px;
  //margin-left: 10px;
  margin: 0 10px;
  cursor: pointer;
}
.publish_button_container {
  display: flex;
  justify-content: space-between;
  width: 69px;
  margin: 0 11px 0 16px;
  & div:first-child {
    margin-left: 0;
  }
  &.wide_buttons_block {
    width: 220px;
  }
  &.placeholder {
    margin: 0;
    width: 11px;
  }
}

.back_navigation {
  display: flex;
  align-items: center;
  min-width: 360px;
  & .button_container {
    border-radius: 6px;
    padding-left: 4.5px;
    padding-right: 4.5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 9px;
    &:hover {
      background-color: #d7ddf7;
      & svg path {
        stroke: #242633;
      }
    }
    &.page {
      padding-left: 10.5px;
      height: 32px;
    }
  }
  & .arrow {
    margin-right: 5px;
  }
  & .playlist {
    margin-right: 5px;
    & svg {
      fill: #d7ddf7;
    }
  }
  & .text {
    line-height: 32px;
    &.page {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      max-width: 340px;
      @include ellipsis;
    }
  }
  & .page {
    margin-right: 6px;
  }
}
.middle_container {
  width: 73px;
}

.preview_info_icon {
  background: #ffe589;
  border-radius: 16px;
  height: 24px;
  display: flex;
  align-items: center;
  padding-right: 11px;
  cursor: pointer;
  .text {
    color: #242633;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
  }
  svg {
    margin-left: 8px;
    margin-right: 4px;
  }
  svg path {
    stroke: #242633;
  }
}
.breadcrumbs .icon {
  width: 20px;
  height: 20px;
}
.breadcrumbs_parent {
  @include btn_icon(false, false, false);
}

.status_wrapper {
  margin-right: 24px;
  overflow: hidden;
  position: relative;
  height: 24px;
  border-radius: 16px;
  transition-duration: 0.6s;
  display: flex;
  align-items: center;
  width: 74px;
  font-size: 12px;
  border-color: lightgrey;
  color: lightgrey;
  border: 1px solid;
  & svg path {
    stroke: lightgrey;
  }

  &.saved {
    animation: greenBlink 1s;
    & svg path {
      animation: greenBlink 1s;
    }
  }
  &.saving {
    width: 84px;
  }
}

.container {
  display: flex;
  align-items: center;
}

.check_svg {
  margin-right: 2px;
  width: 24px;
  height: 16px;
}

.spinner_container {
  width: 23px;
  height: 20px;
  margin-right: 2px;
  margin-left: 2px;
  &.saved svg path {
    animation: greenBlink 1s;
  }
}

@keyframes greenBlink {
  0% {
    border-color: green;
    color: green;
    stroke: green;
  }
  100% {
    border-color: lightgrey;
    color: lightgrey;
    stroke: lightgrey;
  }
}

svg.icon.library {
  fill: #d7ddf7;
}

.playlist_icon {
  width: 16px;
  @include btn_icon(false, false, false);
  margin-right: 5px;
}
