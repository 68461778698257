.inactive {
  border-color: #a2a7b2;
  color: #a2a7b2;
  cursor: no-drop;
  .input_item {
    .input_label {
      color: #a2a7b2;
    }
    .input_body {
      border-color: #a2a7b2;
      cursor: no-drop;
      input,
      textarea {
      }
      .input_text {
        width: 200px;
      }
    }
  }
  svg path {
    stroke: #a2a7b2;
  }
}
.section_wrapper {
  cursor: initial;
  position: absolute;
  z-index: 104;
  top: 18px;
  right: 25px;
  padding: 9px 10px 10px 12px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-transform: none;
  letter-spacing: normal;
  margin-bottom: 16px;
  display: none;
  width: 280px;
  &.show {
    display: block;
  }
  .section_header {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    margin-left: 10px;
    .title {
      cursor: default;
      width: 248px;
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #111111;
      text-transform: none;
      letter-spacing: normal;
      margin-top: 14px;
    }
    .controls {
      width: 20px;
      height: 20px;
      display: flex;
      margin-top: 4px;
      margin-right: 3px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 0 solid black;
      border-radius: 4px;
      svg {
        width: 100%;
        height: 100%;
      }
      :hover svg path {
        stroke: #596080;
        fill: #d7ddf7;
      }
      &:hover {
        background: #d7ddf7;
      }
    }
  }
}
.more_settings_control {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  .more_icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .more_text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #242633;
  }
}

.more_settings {
  height: 300px;
  visibility: visible;
  &.hidden {
    height: 0;
    visibility: hidden;
  }
}

.input_item {
  margin-bottom: 16px;
  margin-left: 10px;
  .input_label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 4px;
    color: #596080;
    cursor: default;
  }
  .input_body {
    width: 235px;
    padding: 4px 0 4px 6px;
    border: 1px solid #d7ddf7;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    input {
      width: 209px;
      margin-left: 3px;
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      margin-left: 2px;
      svg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      svg path {
        stroke: #a3aacc;
        fill: #fff;
      }
    }
    input,
    textarea {
      border: none;
      font-family: "RobotoFlex", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #242633;
    }
    textarea {
      resize: none;
      width: 100%;
      height: 100%;
    }
  }
}
.actions_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 9px 13px 6px 2px;
  margin-top: 36px;
  .actions_item {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #596080;
  }

  .action_btn,
  .cancel_btn {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  .action_btn {
    margin-left: 3px;
  }
  .cancel_btn {
    width: 71px;
    padding: 10px;
    margin-right: 15px;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      background-color: #d3d3d39a;
    }
  }

  .action_btn {
    width: 71px;
    padding: 10px;
    margin-right: 15px;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid #0026e5;
    color: #0026e5;
    &:hover {
      background: #e8ebfa;
    }
  }
}

.collection_input_text {
  width: 204px;
  white-space: nowrap;
  font-family: "RobotoFlex", sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  padding-left: 9px;
  font-style: normal;
  letter-spacing: normal;
  text-overflow: ellipsis;
  color: #242633;
}

.collections-wrapper {
  position: relative;
  z-index: 1;
  margin-left: 10px;
}
