.input_wraper {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 15px;
  line-height: 26px;
  padding: 4px 10px;
  position: relative;
  border-radius: 3px;
  box-shadow: rgba(15, 15, 15, 0.1) 0 0 0 1px inset;
  background: rgba(242, 241, 238, 0.6);
  cursor: text;
  margin-top: 4px;
  margin-bottom: 8px;
}

.link {
  color: #1a0dab;
  text-decoration: none;
  cursor: pointer;
  margin-top: 13px;
}

.link:hover {
  text-decoration: underline;
  color: #2418ae;
}

.input {
  border: none;
  background: none;
  height: 44px;
  width: 100%;
  display: block;
  resize: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  padding: 0 0 0 12px;
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: rgba(122, 105, 105, 0.50);
  }
}

.login {
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  background-color: #111B2A;
  position: fixed;
}

.login_bg {
  background-size: cover;
  width: 100%;
  height: 100%;
}

.column {
  width: 521px;
  //height: 636px;
  border-radius: 32px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  box-shadow: 1px 1px 6px 0px rgba(122, 105, 105, 0.10);
  padding: 50px 68px;
  position: relative;
  min-width: 521px;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.column_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 48%;
  z-index: 2;
}

.column_place {
  width: 309px;
  height: 671px;
}

.column_register {
  padding: 50px 68px;
}
.scroll {
  width: 100%;
  height: 100%;
  margin-top: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    margin-right: 2px;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
}

.no_account_block {
  display: flex;
  padding-top: 13px;
  margin-top: 2px;
  margin-bottom: 15px;

  &.is_login {
    margin-bottom: 5px;
  }
}

.header {
  color: #484343;
  font-size: 36px;
  font-weight: 500;
  line-height: 48px;
  margin-top: 12px;

  &.is_login {
    margin-top: 20px;
  }
}

.or_separator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 11px;
  font-size: 14px;
  line-height: 21px;
  color: #585858;
  user-select: none;
  font-weight: 400;

  p {
    width: 10%;
    text-align: center;
    margin: 0;
  }

  &.is_sign_up {
    padding-top: 1px;
    padding-bottom: 0;
  }
}

.separator_line {
  height: 1px;
  width:45%;
  background: #ECE2E2;
}
.text_info {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  margin-bottom: 4px;
}

.text_toggler {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  margin-left: 8px;
}

.text_signup {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #484343;
  margin-right: 5px;
}
.input_tips {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #979797;
  margin-top: 6px;
  text-align: end;
  right: 0;
  top: 55px;
}
.sign_in_button_container {
  margin-top: 29px;
  //&[data-place="signUp"] {
  //  margin-bottom: 46px;
  //}
}

.text_info_link {
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #4744A0;
  font-weight: 500;
  margin: auto 0;
  text-decoration: none;
}
.text_notice_link {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #4744A0;
  margin: auto 0;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &::before {
    content: "";
    margin-left: 4px;
  }
}

.text_notice {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #979797;
  margin-top: 8px;
}

.toggle {
  width: 27px;
  height: 16px;
  border-radius: 16px;
  background: #c7cad1;
  -webkit-transition: background-color 0.4s ease;
  -moz-transition: background-color 0.4s ease;
  -o-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
  margin-right: 10px;
}

.toggle_active {
  background: #0da6f2;
}

.toggle_icon {
  padding: 1px;
  height: 14px;

  -webkit-transition: padding 0.4s ease;
  -moz-transition: padding 0.4s ease;
  -o-transition: padding 0.4s ease;
  transition: padding 0.4s ease;
}

.toggle_icon_active {
  padding-left: 13px;
}

.one_line_names {
  display: flex;
  margin-top: -2px;
}
.name_container {
  width: 100%;
  &[data-side="left"] {
    margin-right: 12px;
  }
  &[data-side="right"] {
    margin-left: 8px;
  }
}

.password_info_block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  flex-direction: column;
  position: relative;
  & .two_cols {
    display: flex;
    justify-content: space-between;
  }
}

.remember_block {
  height: 27px;
  margin-bottom: 57px;
  &.first {
    margin-bottom: 24px;
  }
}

.forgot_password {
  display: flex;
  justify-content: end;
  color: #979797;
  position: absolute;
  right: 67px;
  transform: translateY(-18px);
  font-weight: 400;
  padding-top: 8px;
}

.keep_me_block {
  height: 27px;
  margin-bottom: 57px;
}

.field {
  width: 100%;
  background: #ffffff;
  border: 1px solid #ECE2E2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 0;
  &:focus-within {
    border: 1px solid #FFC72C;
  }
}

.eye_container {
  position: absolute;
  width: 32px;
  right: 2px;
  & svg path {
    stroke: #596080;
  }
}

.field_has_error {
  border: 1px solid #D32F2F;
  background: #FFE8E8;
}

.field_password {
  margin-top: 0;
}

.error_wrapper {
  position: absolute;
}

.field_icon_box {
  width: 56px;
  height: 56px;
  border-right: 1px solid #c7cad1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eye {
  padding-right: 10px;
  cursor: pointer;
}

.eye_active {
  filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
}

.separator {
  position: relative;
  height: 1px;
  background: #e0e2e6;
  width: 121%;
  border-radius: 2px;
  left: -48px;
  margin-top: 14px;
  margin-bottom: 24px;
}

.continue_with {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #484343;
  font-weight: 500;
}

.continue_with svg {
  margin-right: 10px;
  margin-left: 6px;
}

.buttons {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  & > * {
    margin: 12px 0;
    max-width: 182px;
  }
}

.title {
  display: flex;
  position: absolute;
  top: -90px;
}

.title_text {
}

.input_text {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  color: rgba(55, 53, 47, 0.6);
}

.input_error {
  font-size: 14px;
  color: rgb(235, 87, 87);
  text-align: center;
  display: block;
  width: 100%;
}

.input_wraper:focus-within {
  box-shadow:
    rgba(46, 170, 220, 0.7) 0 0 0 1px inset,
    rgba(46, 170, 220, 0.4) 0 0 0 2px !important;
}

.continue_button {
  width: 128px;
  height: 46px;
  background: #ffd042;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 33px;
  cursor: pointer;
  text-transform: uppercase;
}

.continue {
  user-select: none;
  transition: background 20ms ease-in 0s;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: 36px;
  border-radius: 3px;
  color: rgb(235, 87, 87);
  font-size: 14px;
  line-height: 1;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;
  background: rgb(253, 245, 242);
  box-shadow:
    rgba(15, 15, 15, 0.1) 0 1px 2px,
    rgba(235, 87, 87, 0.3) 0 0 0 1px inset;
  margin-top: 26px;
  margin-bottom: 12px;
  width: 100%;
}

.scroll_wrapper {
  height: 100vh;
  background-color: #f0f1fe;
  width: 100%;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    margin-right: 2px;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
}

.scroll_balancer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll_balancer_inside {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.page_body {
  width: 659px;
  padding-top: 52px;
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  min-width: 47%;
  height: 624px;

  &_title {
    color: #FFF;
    font-size: 40px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    line-height: 58px;
    margin: 48px 0 29px;
    z-index: 2;
    max-width: 550px;
  }

  &_text {
    margin: 0 0 7px;
    color: #fff;
    z-index: 2;
  }

  &_image {
    position: relative;
    width: 632px;
    height: 484px;
    right: -27px;
    top: -123px;
    z-index: 1;
  }
}

@media screen and (max-width: 1280.1px) {
  .page_body {
    padding-top: 36px;
  }
}

@media screen and (max-width: 1180px) {
  .page_body {
    padding-top: 14px;
    height: 702px;

    &_title {
      max-width: 370px;
      margin-top: 9px;
    }

    &_image {
      position: absolute;
      right: -19px;
      top: 315px;
    }
  }

  .column_wrapper {
    margin-left: 103px;
  }

  .scroll_balancer {
    gap: 55px;
  }
}

@media screen and (max-width: 992.1px) {
  .column_wrapper {
    margin-left: 0;
    width: 100%;
  }
  .page_body {
    display: none;
  }
}
@media screen and (max-height: 750.1px) {
  .column_register {
   height: 690px;
  }
 
}
