.warning {
  position: absolute;
  left: 340px;
  width: 326px;
  height: 160px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 1000;
  font-family: "RobotoFlex", sans-serif;
  padding: 24px 16px;
  & .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #242633;
  }
  & .text {
    padding-top: 8px;
    line-height: 18px;
    font-size: 12px;
    color: #242633;
  }
  & .button_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 6px;
    letter-spacing: 0.05em;
    & .cancel {
      text-transform: uppercase;
      color: #596080;
      cursor: pointer;
    }
    & .detach {
      height: 32px;
      border: 1px solid #0026e5;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      color: #0026e5;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
