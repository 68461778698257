.react-daterange-picker__wrapper,
.react-date-picker__wrapper {
  background: #ffffff;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  border-radius: 6px;
  width: 232px;
  height: 32px;
  font-size: 14px;
}

.playlistsDatePicker,
.playlistsDatePicker2023 {
  margin-left: 23px;
}

.isModifyUnset {
  margin-left: unset;
}

.modifyZeroLeft.modifyZeroLeft.modifyZeroLeft{
  margin-left: 0;
}

.playlistsDatePicker .react-daterange-picker__wrapper,
.react-date-picker__wrapper {
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
  width: 132px;
  font-size: 14px;
  background: #f5f6ff;
  height: 28px;
}

.playlistsDatePicker2023 .react-daterange-picker__wrapper,
.react-date-picker__wrapper {
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
  width: 132px;
  font-size: 14px;
  background: #ffffff;
  height: 28px;
}
.isModify .react-daterange-picker__wrapper,
.react-date-picker__wrapper {
  font-size: 13px;
}
.isModify{
  width: 170px;
}
.isModify .react-daterange-picker__wrapper,
.react-date-picker__wrapper {
  width: 100px;
}
.react-daterange-picker__inputGroup__input:invalid,
.react-date-picker__inputGroup__input:invalid {
  background: transparent;
}

.playlistsDatePicker.react-date-picker--disabled {
  color: rgba(170, 170, 170, 0.71) !important;
}
.playlistsDatePicker.react-date-picker--disabled input {
  color: rgba(170, 170, 170, 0.71) !important;
}

.react-daterange-picker__calendar-button,
.react-date-picker__calendar-button {
  width: 0;
  display: none;
}

.react-daterange-picker__inputGroup,
.react-date-picker__inputGroup {
  margin-left: 3px;
}

.react-calendar button.react-calendar__navigation__label {
  background-color: transparent;
}

.react-calendar {
  position: absolute;
  top: -25px;
  width: 319px;
  height: 318px;
  background: #ffffff;
  box-shadow:
    0 10px 15px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px;
  border: 1px solid transparent;
}

.playlistsDatePicker2023 .react-calendar {
  border-radius: 12px;
  height: auto;
}

.playlistsDatePicker2023 .react-calendar__navigation__arrow {
  min-width: 32px;
}

.playlistsDatePicker2023 .react-calendar__navigation__label {
  border-radius: 6px;
  margin: 0 10px;
  height: 32px;
}

.react-calendar__tile .react-calendar__month-view__days__day {
  margin-bottom: 10px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ffeebf;
  border-radius: 18px;
}

.react-calendar__navigation {
  height: 32px;
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  margin-top: 5px;
  margin-bottom: 23px;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 7px;
}
.react-calendar__month-view__weekdays__weekday {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #a3aacc;
  border: none;
  padding: 0;
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
}

.react-calendar button {
  margin: 0 0 4px 0;
  /*border-radius: 20px;*/
  min-width: 0;
}

.tileClass {
  width: 41px;
  height: 32px;
  cursor: pointer;
  border: none;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  color: #242633;
  margin: 2px 0;
  border-radius: 6px;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #596080;
}
.react-calendar__navigation__arrow {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  min-width: 32px;
}
.react-calendar__navigation__arrow:hover {
  background-color: #e8ebfa;
}
.react-calendar__navigation__label {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-calendar__navigation__label__labelText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #534b4f;
}
.react-calendar__navigation__label {
}

.hide
  button.react-daterange-picker__clear-button.react-daterange-picker__button,
.hide button.react-date-picker__clear-button.react-date-picker__button {
  display: none;
}

.playlistsDatePicker .react-date-picker__clear-button.react-date-picker__button,
.playlistsDatePicker2023
  .react-date-picker__clear-button.react-date-picker__button {
  display: none;
}

.react-daterange-picker__clear-button.react-daterange-picker__button,
.react-date-picker__clear-button.react-date-picker__button {
  background-color: #e8ebfa;
  border-radius: 4px;
  height: 17px;
  width: 17px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  margin-top: 7px;
}

.react-daterange-picker__clear-button.react-daterange-picker__button {
  margin-top: 0;
}

.react-calendar__tile--hover.react-calendar__tile--hover {
  /*border-radius: 0;*/
  background-color: #ffeebf;
}
.react-calendar__tile--hover {
  border-radius: 0;
}

.react-calendar__tile--hoverStart.react-calendar__tile--hoverStart {
  border-radius: 0;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  background-color: #ffc72c;
  color: white;
}
.react-calendar__tile--hoverEnd.react-calendar__tile--hoverEnd {
  border-radius: 0;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  color: white;
  background-color: #ffc72c;
}

.react-calendar__tile--hoverBothEnds.react-calendar__tile--hoverBothEnds {
  border-radius: 6px;
  background-color: #ffc72c;
}

.react-calendar__tile--now {
  background-color: #ffc72c;
  color: white;
}

.smartview-date-picker_touched .react-calendar__tile--now {
  background-color: white;
  color: #242633;
}
.smartview-date-picker_touched
  .react-calendar__tile--now.react-calendar__tile--hover {
  background-color: #ffeebf;
  color: #242633;
}
.react-calendar__tile--rangeEnd {
  background-color: #ffeebf;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.react-calendar__tile--rangeStart {
  background-color: #ffeebf;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.react-calendar__tile--hasActive {
}

.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range {
  background-color: #ffc72c;
}

.react-calendar__tile--active {
  background-color: #f5f6ff;
  border-radius: 0;
}

.playlistsDatePicker2023
  .react-calendar__tile--active.react-calendar__tile--range {
  background-color: #ffc72c;
}

.react-calendar__tile--rangeStart {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.react-calendar__tile--rangeEnd {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ffc72c;
  color: white;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  width: 0;
  display: none;
}
.react-calendar__navigation__next-button.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button.react-calendar__navigation__prev-button {
  margin-top: -1.5px;
}
.not_touched input {
  color: #a3aacc;
}
.not_touched
  .react-date-picker__inputGroup__leadingZero.react-date-picker__inputGroup__leadingZero,
.not_touched
  .react-daterange-picker__inputGroup__leadingZero.react-daterange-picker__inputGroup__leadingZero {
  color: #a3aacc;
}
.not_touched
  .react-date-picker__inputGroup__divider.react-date-picker__inputGroup__divider,
.not_touched
  .react-daterange-picker__inputGroup__divider.react-daterange-picker__inputGroup__divider {
  color: #a3aacc;
}
.not_touched .react-daterange-picker__range-divider {
  color: #a3aacc;
}

.not_touched .react-daterange-picker__range-divider,
.not_touched .react-date-picker__range-divider,
.not_touched .react-date-picker__inputGroup__divider,
.not_touched .react-daterange-picker__inputGroup__divider {
  color: #a3aacc;
}

/*.global_modal .react-daterange-picker__wrapper, .global_modal .react-date-picker__wrapper {*/
/*    border: none;*/
/*}*/

/*.global_modal .react-daterange-picker__wrapper, .global_modal .react-date-picker__wrapper {*/
/*    width: 302px;*/
/*}*/

.playlistsDatePicker.react-date-picker--disabled input[disabled] {
  color: rgb(212, 212, 212) !important;
  -webkit-text-fill-color: rgb(212, 212, 212);
  opacity: 1;
}
.playlistsDatePicker.react-date-picker--disabled
  input[disabled]::-webkit-datetime-edit-day-field {
  color: rgb(212, 212, 212) !important;
  -webkit-text-fill-color: rgb(212, 212, 212);
  opacity: 1;
}
.react-date-picker--disabled .react-date-picker__inputGroup span {
  color: rgb(212, 212, 212) !important;
}
