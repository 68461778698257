.palette_wrapper {
  position: relative;
  right: 0;
}

.underlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  //to work in leftSideBar
  z-index: 105;
  display: none;
  &.display {
    display: flex;
  }
}
.palette_button {
  position: relative;
  z-index: 2;
}
.palette_container {
  position: absolute;
  // to be on top of leftSideBar
  z-index: 105;
  display: none;
  flex-direction: column;
  &.display {
    display: flex;
  }
  width: 226px;
  padding: 12px 12px 12px 12px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  &.usedWithScroll {
    position: fixed;
  }
  &.usedEmbed {
    position: relative;
    box-shadow: none;
    padding: 0;
  }
}

.colors_section {
  &.is_element_exist {
    margin-top: 10px;
  }
  width: 159px;
  .colors_selector {
    display: flex;
    justify-content: space-between;
    & .background {
      padding: 1px 5px;

      height: 22px;
      text-transform: uppercase;
      font-size: 12px;
      display: flex;
      align-items: center;
      color: #484343;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        background: #ffeebf;
      }
      &.active {
        background: #ffc72c;
      }
    }
    & .text {
      height: 22px;
      text-transform: uppercase;
      padding: 1px 5px;
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      //line-height: 18px;
      align-items: center;
      color: #484343;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        background: #ffeebf;
      }
      &.active {
        background: #ffc72c;
      }
    }
  }
  .default_colors {
    flex-direction: column;
  }
  .custom_colors {
    flex-direction: column;
  }
  & .label {
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;

    display: flex;
    align-items: center;

    color: #596080;
    &.for_smart_modal {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #585858;
      margin-left: 0px;
      margin-bottom: 11px;
    }
  }
  .grid {
    margin-left: 5px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 28px);
    grid-gap: 6px;
  }
  &.usedEmbed {
    width: 171px;
  }
  &.for_smart_modal {
    width: 100%;
  }
  .for_smart_modal_icon {
    margin-left: 0px;
    grid-gap: 11px;
  }
  .for_smart_modal {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    flex-wrap: wrap;
    margin-left: 0px;
    margin-bottom: -4px;
  }
}
.separator {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  width: 100%;
  background: #ffeebf;
}
.controls_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  & .reset_button {
    //font-weight: 500;
    //font-size: 10px;
    //line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #596080;
    width: 24px;
    height: 24px;
    //padding: 1px 5px;
    border: 1px solid #e8ebfa;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: #ffeebf;
    }
  }
  & .icon_wrapper {
    //width: 16px;
    //height: 16px;
    //border-radius: 4px;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //cursor: pointer;
    //padding: 2px;
    //&:hover {
    //  background: #e8ebfa;
    //}
    //&.active {
    //  background: #e8ebfa;
    //}
  }
}
.controls_section.for_smart_modal {
  margin-top: 10px;
  padding-left: 0px;
}
.wrapper {
  z-index: 106;
  position: absolute;
  top: 0;
  right: -258px;
  width: 258px;
  height: 305px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 13px;
}
.label {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #596080;
  height: 18px;
  margin-right: 3px;
}
.value input {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #242633;
  background: #ffffff;
  border: 1px solid #d7ddf7;
  border-radius: 2px;
}

.input_wrapper {
  display: flex;
  justify-content: space-between;
}

.input_container {
  display: flex;
  margin-right: 5px;
  align-items: center;
  &.last {
    margin-right: 0;
  }
}

.button_group {
  display: flex;
  margin-left: 60px;
  margin-top: 15px;
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  height: 24px;
  gap: 10px;
  border-radius: 6px;
  cursor: pointer;

  & .text {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  &.grey {
    width: 51px;
    border: 1px solid #d7ddf7;
    margin-right: 17px;
    & .text {
      color: #0026e5;
    }
    &:hover {
      background-color: #e8ebfa;
      border-color: #e8ebfa;
    }
  }
  &.blue {
    width: 35px;
    background: #0026e5;
    border: 1px solid #0026e5;
    & .text {
      color: #fcfdff;
    }
    &:hover {
      background-color: #021a9f;
    }
  }
}
