.loader,
.loader:after,
.flex_loader,
.flex_loader:after {
  border-radius: 50%;
  width: 9em;
  height: 9em;
}
.loader_mini,
.loader_mini:after {
  border-radius: 50%;
  width: 9em;
  height: 9em;
}

.loader_tini,
.loader_tini:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 15;
  overflow: hidden;
}
.loader-wrapper-page {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 15;
  top: 0;
  left: 0;
  overflow: hidden;
}

@keyframes fadeinOpacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}
.loader-wrapper-page.opacity{
  opacity: 0.2;
  background-color: rgb(241, 241, 240);
  animation: fadeinOpacity 0.7s;
}
.loader-wrapper-page.below{
  z-index: -1;
}
.loader-wrapper-inbox {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 15;
  top: 0;
  left: 0;
}
.loader-wrapper-page.create_component {
  border-radius: 11px;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #484848;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  top: calc(50vh - 147px);
}

.loader-wrapper-page.white_new_library {
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
}
.loader_library_new {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.1);
  border-right: 1.1em solid rgba(0, 0, 0, 0.1);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.1);
  border-left: 1.1em solid #484848;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  top: calc(50vh - 222px);
  border-radius: 50%;
  width: 9em;
  height: 9em;
}
.loader_trash {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #484848;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  top: -60px;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.loader.white {
  border-top: 1.1em solid rgba(0, 0, 0, 0.1);
  border-right: 1.1em solid rgba(0, 0, 0, 0.1);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.1);
}
.flex_loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 15;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #484848;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loader_mini {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #484848;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  top: -60px;
}
.loader_mini.centered {
  top: 0;
}
.loader_mini.playlistinfo {
  top: 10px;
  opacity: 0.4;
}
.loader_tini {
  margin: 6px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid rgba(255, 255, 255, 0.2);
  border-right: 0.2em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.2em solid rgba(255, 255, 255, 0.2);
  border-left: 0.2em solid #484848;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader.menuWrapperCenter {
  left: 35px;
}

.loader.create_component {
  left: 0;
  top: 185px;
}
