@import "./src/_mixins";

.wrapper {
  position: absolute;
  top: 76px;
  left: 12px;
  overflow: hidden;
  background: #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  z-index: 32;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
}

.miniPlaylist {
  position: absolute;
  z-index: 1;
  top: 105px;
  right: 15px;
}

.top_section {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #d7ddf7;
}
.content_section {
  margin-top: 32px;
  max-height: 500px;
  display: block;
  padding-bottom: 10px;
  flex-direction: column;
  padding-top: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &.maxHeight {
    max-height: 163px;
    padding-bottom: 0;
  }
}
.animate_in {
  width: 268px;
  height: auto;
  border-radius: 8px;
  animation-direction: normal;
  animation: expand 1s;
  .icon_container {
    width: 32px;
    height: 32px;
    &:hover {
      background-color: transparent;
    }
  }
}

.animate_out {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  animation: collapse 0.4s;
}

.line {
  height: 1px;
  background-color: #d7ddf7;
  width: 100%;
}

.icon_container {
  min-width: 32px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: #e8ebfa;
  }
}

@keyframes expand {
  0% {
    width: 32px;
    height: 32px;
  }
  50% {
    width: 268px;
    height: 32px;
  }
  100% {
    width: 268px;
    height: auto;
  }
}

@keyframes collapse {
  0% {
    width: 268px;
    height: auto;
  }
  50% {
    width: 268px;
    height: 32px;
  }
  100% {
    width: 32px;
    height: 32px;
  }
}

.title {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #242633;
  width: 140px;
  @include ellipsis;
  line-height: 16px;
}
.noItems {
  align-self: center;
  justify-self: center;
  font-size: 14px;
  color: #242633;
  width: fit-content;
  @include ellipsis;
  line-height: 16px;
  margin: 5px auto;
}

.spinner_wrapper {
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.usedInPage_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
}

.usedInPageButton {
  z-index: 1;
  & .svg_wrap {
    width: 24px;
    height: 24px;
    margin-left: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: rgba(92, 106, 136, 0.17);
    }
  }
  display: flex;
  align-items: center;
  &.active {
    visibility: visible;
    & .svg_wrap {
      background-color: rgba(92, 106, 136, 0.17);
    }
  }
}

.usedInPage {
  //z-index: 31;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  width: 268px;
  height: 0;
  border-radius: 8px;
  &.show {
    transition: height 0.4s ease-in-out;
    border: 1px solid #d7ddf7;
    height: 243px;
    padding-bottom: 10px;
  }
  .inactive {
    transition: initial;
  }
  .top_section {
    border-radius: 8px;
    width: 268px;
  }
  .content_section {
    max-height: 163px;
  }
  .icon_container {
  }
  &.active {
    visibility: visible;
  }
}
