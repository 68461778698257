.addImage {
  background: #fff;
  display: inline-block;
}

.addImagePopover {
  margin-top: 10px;
  background: #fff;
  position: absolute;
  height: 90px;
  width: 560px;
  top: 50%;
  left: calc(50% - 280px);
  border-radius: 2px;
  padding: 10px;
  box-shadow: 0 4px 30px 0 rgba(220, 220, 220, 1);
  z-index: 1000;
}

.addPagePopover {
  margin-top: 10px;
  background: #fff;
  position: absolute;
  min-height: 90px;
  height: fit-content;
  width: 560px;
  top: 50%;
  left: calc(50% - 280px);
  border-radius: 2px;
  padding: 10px;
  box-shadow: 0 4px 30px 0 rgba(220, 220, 220, 1);
  z-index: 1000;
}

.tabs_block {
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  background: #fff;
  height: 25px;
  width: 100%;
  border-bottom: 1px solid #bcbcbc;
}

.tab {
  margin-top: 0;
  height: 25px;
  padding: 0 5px;

  cursor: pointer;
}

.tabs_active {
  border-bottom: 2px solid #282828;
  margin-top: 0;
  height: 25px;
  padding: 0 5px;

  cursor: pointer;
}

.addImageClosedPopover {
  display: none;
}

.addImageButton,
.addImagePressedButton {
  box-sizing: border-box;
  padding: 9px 148px 9px 12px;
  border: 1px solid #ddd;
  color: #888;
  margin: 0;
  cursor: pointer;
  width: 305px;
  /*font-size: 1.5em;*/
  line-height: 1.2em;
}

.addImageButton:focus {
  outline: 0; /* reset for :focus */
}

.addImageButton:hover {
  background: #f3f3f3;
}

.addImageButton:active {
  background: #e6e6e6;
}

.addImagePressedButton {
  background: #ededed;
}

.addImageBottomGradient {
  width: 100%;
  height: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.addImageInput {
  box-sizing: border-box;

  border: 1px solid #ddd;
  cursor: text;
  padding: 4px;
  width: 78%;

  border-radius: 2px;
  margin-bottom: 1em;
  box-shadow: inset 0 1px 8px -3px #ababab;
  background: #fefefe;
}

.addImageConfirmButton {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ddd;
  padding: 0;
  color: #888;
  margin: 0;
  border-radius: 2.1em;
  cursor: pointer;
  height: 2.1em;
  width: 18%;
  font-size: 1em;
  line-height: 2.1em;
  margin-left: 4%;
}

.addImageConfirmButton:focus {
  outline: 0; /* reset for :focus */
}

.addImageConfirmButton:hover {
  background: #f3f3f3;
}

.addImageConfirmButton:active {
  background: #e6e6e6;
}
.loader {
  position: absolute;
  left: -29px;
  top: 2px;
  text-indent: -9999em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  border-top: 1px solid #0026e5;
  border-right: 1px solid #0026e5;
  border-bottom: 1px solid #d7ddf7;
  border-left: 1px solid #0026e5;
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  z-index: -1;
}
.loader {
    position: absolute;
    left: -29px;
    top: 2px;
    text-indent: -9999em;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #FFFFFF;
    border-top: 1px solid #0026E5;
    border-right: 1px solid #0026E5;
    border-bottom: 1px solid #D7DDF7;
    border-left: 1px solid #0026E5;
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    z-index: -1;
}
