@import "./src/_mixins";

.tab_bar {
  left: -60px;
  position: absolute;
  top: 72px;
  transition-property: right, left, background-color;
  transition: ease-in-out 0.3s;
  padding: 8px;
  z-index: 103;
  background: transparent;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  &.active {
    box-shadow:
      -5px 5px 4px 0px rgba(34, 60, 80, 0.06),
      -6px -9px 4px -5px rgba(34, 60, 80, 0.06);
    left: -40px;
    background: #ffffff;
  }
}
.isLibrary {
  display: flex;
  z-index: 100;
  //align-items: center;
  flex-direction: row;
  //margin-top: 3px;
  //margin-right: 4px;
}
.hiddenDynamicCollectionsDropdownWrapper {
  position: relative;
}
.hiddenDynamicCollectionsDropdown_content {
  margin-top: 8px;
  margin-bottom: 8px;
  min-height: 48px;
  max-height: 144px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scroll-snap-type: inline;
  scroll-behavior: smooth;
  //overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #dbdbdb;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
}
.hiddenDynamicCollectionsDropdown {
  position: absolute;
  width: 264px;
  left: -265px;
  top: -130px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.hiddenTagsDropdown {
  position: absolute;
  width: 264px;
  left: -265px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.longItemDynamicCollection {
  min-height: 32px;
  padding-right: 8px;
  padding-left: 8px;
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  color: #242633;
  &:hover {
    background: #e8ebfa;
  }
}
.active {
  background: #e8ebfa;
}
.moreDynamicCollectionsButton {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background: #e8ebfa;
  }
  &.active {
    background-color: #e8ebfa;
  }
}
.longItemDynamicTitle {
  @include ellipsis;
  max-width: 195px;
}
.inner_radius {
  position: absolute;
  top: -11px;
  right: 3px;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border-bottom-right-radius: 14px;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transition-property: background-color;
  transition: ease-in-out 0.3s;
  &.bottom {
    top: initial;
    bottom: -11px;
    transform: rotateX(180deg);
  }
  &.active {
    border-right: 5px solid white;
    border-bottom: 5px solid white;
  }
}
