$animation-time: 0.35s;
$animation-curve: ease-in-out;
.sideBars{
  transition: width ease-in-out 0.35s;
  z-index: 10;
  &.is_collapsed {
    z-index: 15;
  }
}
.open {
  &.settings_sideBar {
    width: 487px;
    visibility: visible;
  }
  &.library_sideBar {
    width: 626px;
    visibility: visible;
    transition: width ease-in-out 0.35s;
  }
  &.expanded.library_sideBar {
    width: 75vw;
    visibility: visible;
  }
  &.settings.library_sideBar {
    width: 360px;
  }
  &.componentMetaData_sideBar {
    width: 360px;
    visibility: visible;
  }
  &.scalable.componentMetaData_sideBar {
    width: 100%;
  }
  &.new_side_bar {
    width: 640px;
  }
}
.new_side_bar.new_side_bar.new_side_bar.new_side_bar {
  position: absolute;
  right: 0;
  height: 100vh;
  transition: ease-in-out width 0.35s;
}
.close {
  &.settings_sideBar {
    width: 0;
    visibility: hidden;
  }
  &.library_sideBar {
    width: 0;
    visibility: hidden;
  }
  .new_side_bar {
    width: 0;
    //visibility: visible;

  }
}

.none {
  display: none;
}

.settings_sideBar {
  width: 0;
  transition: width $animation-curve $animation-time;
}

.library_sideBar {
  position: relative;
  width: 0;
  transition: width $animation-curve $animation-time;
}

.openLow {
  width: 360px;
  transition: all $animation-curve $animation-time;
}
.componentMetaData_sideBar {
  width: 0;
  transition: width $animation-curve $animation-time;
}

.library_sideBar .block {
  position: relative;
  z-index: 101;
  right: 0;
  height: 100vh;
  background: #fff;
  width: 100%;
  transition: width 0.3s cubic-bezier(0.19, 0.45, 0.8, 0.43);
  visibility: visible;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
}
