.main {
  height: calc(100vh - 74px);
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
  max-height: calc(100vh - 60px);

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
.heading_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-right: 33px;
  cursor: no-drop;
}

.heading_search svg {
  margin-right: 10px;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid #d7ddf7;
  background-color: #f5f6ff;
  height: 74px;
}

.back_to_playlists_btn {
  left: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #0026e5;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
  }
  & svg path {
    stroke: #0026e5;
  }
  &:hover {
    background: #e8ebfa;
    & svg {
      fill: #e8ebfa;
    }
  }

  &:active {
    background: #e8ebfa;
  }
}

.heading_left {
  display: flex;
  align-items: center;
  width: 162px;
}

.heading_title {
  font-size: 14px;
}
.heading_center {
  display: flex;
}
.avatar_wrapper {
  margin-right: 9px;
  cursor: default;
}

.heading_right {
  display: flex;
  align-items: center;
  width: 162px;
}
.heading_login {
  border: 1px solid #0026e5;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 7px;
  width: fit-content;
  font-size: 12px;
  color: #0026e5;
  cursor: pointer;
}
.info {
  padding: 31px 0 5px 24px;
  max-width: 690px;
  margin: 0 auto;
}

.space_separator {
  height: 32px;
  width: 10px;
}

.content {
  max-width: 636px;
  margin: 0 auto;
  margin-bottom: 230px;
}

.input_title {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  min-height: 40px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 44px;
  /*line-height: 48px;*/
  padding: 27px 0 0 0;
  color: #242633;
}

.input_description {
  display: block;
  width: 100%;
  padding-top: 8px;
  max-width: 100%;
  min-width: 100%;
  resize: none;
  border: none;
  overflow: auto;
  height: auto;
  font-size: 14px;
  color: #242633;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 400;
  line-height: 21px;
}

.view_expand_wrapper {
  right: 15px;
  top: 14px;
  .simple_button {
    float: right;
    min-width: 113px;
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
    border-radius: 6px;
    & svg {
      margin-right: 5px;
    }
    & .text {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #596080;
    }
    svg path {
      stroke: #596080;
    }
    &:hover {
      background-color: #e8ebfa;
      .text {
        color: #242633;
      }
      svg path {
        stroke: #242633;
      }
    }
  }
}
