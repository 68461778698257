.media {
  display: none;
}

.unordered-list-item > div {
  /*padding-left: 24px;*/
  line-height: 24px;
}

.title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #242633;
}

.title2 {
  font-size: 24px;
  line-height: 30px;
  color: #242633;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.title3 {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #242633;
}

.text {
  /*font-family: "IBM Plex", serif;*/
  font-size: 16px;
  color: #242633;
  line-height: 24px;
}
/*WAT IS IT?*/
/*p {*/
/*  display: inline;*/
/*}*/

a {
  pointer-events: all;
}

.unordered-list-item.unordered-list-item {
  margin-left: 12px;
  font-family: "RobotoFlex", sans-serif;
  color: #242633;
}
.align-right div {
  text-align: right;
}
.align-center div {
  text-align: center;
}

.indent_text_1 {
  padding-left: 31px;
}

.indent_text_2 {
  padding-left: 63px;
}
.align-justify div {
  text-align: justify;
  text-justify: inter-word;
  text-align-last: justify;
  /* for IE9 */
  -ms-text-align-last: justify;
}
.align-justify div:after {
  content: "";
  display: inline-block;
}
.align-left div {
  text-align: left;
}

.bpsgbes {
  display: inline-block;
  line-height: 1px;
}

.b181v2oy {
  /*button?*/
  background: white;
  color: #888;
  font-size: 18px;
  border: 0;
  padding: 0;
  /*padding-top: 5px;*/
  /*vertical-align: center;*/
  height: 24px;
  width: 24px;
  margin: 4px;
}
.tukdd6b :first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.b181v2oy:hover,
.b181v2oy:focus {
  background-image: url("../../images/icons/rectangle.svg");
  outline: 0;
}

.b181v2oy svg {
  /*fill: #888;*/
}

.a9immln {
  background-image: url("../../images/icons/rectangle.svg");
}

/*.a9immln svg path{*/
/*    !*fill: #444;*!*/
/*    stroke: #0026E5*/
/*}*/

.tukdd6b {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
  -ms-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid #ddd;
  padding: 0 10px;
  z-index: 2;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  /*visibility: visible !important;*/
  /*-webkit-transform: translate(-50%) scale(1) !important;*/
  /*-ms-transform: translate(-50%) scale(1) !important;*/
  /*transform: translate(-50%) scale(1) !important;*/
}
pre {
  margin: 0;
  font-family: "RobotoFlex", sans-serif;
}

.tukdd6b:after,
.tukdd6b:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tukdd6b:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}

.tukdd6b:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}

.s1o2cezu {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
}

.public-DraftStyleDefault-ol.public-DraftStyleDefault-ol {
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR.public-DraftStyleDefault-depth0 {
  margin-left: 0;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR.public-DraftStyleDefault-depth0.public-DraftStyleDefault-orderedListItem {
  margin-left: 0;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 44px;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 55px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1 {
  padding-left: 13px;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1 {
  padding-left: 13px;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2 {
  padding-left: 29px;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR.public-DraftStyleDefault-listLTR {
  margin-left: 76px;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR.public-DraftStyleDefault-listLTR.public-DraftStyleDefault-orderedListItem {
  margin-left: 91px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: none !important;
  counter-increment: ol1;
  width: auto !important;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: none !important;
  counter-increment: ol2;
  width: auto !important;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: none !important;
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: none !important;
  counter-increment: ol4;
}
.public-DraftStyleDefault-orderedListItem:before {
  content: none !important;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -27px;
  width: auto;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-listLTR::marker {
  position: absolute;
  content: "";
}
.unordered-list-item.public-DraftStyleDefault-unorderedListItem.unordered-list-item.public-DraftStyleDefault-unorderedListItem {
  list-style-type: none;
}

li::before {
  position: absolute;
  /*content: "•" ;*/
}

div li:not(:first-child) {
  /*padding-top: 9px;*/
}

/*.bpsgbes{display:inline-block;}*/
/*.b181v2oy{background:#fbfbfb;color:#888;font-size:18px;border:0;padding-top:5px;vertical-align:bottom;height:34px;width:36px;}.b181v2oy:hover,.b181v2oy:focus{background:#f3f3f3;outline:0;}.b181v2oy svg{fill:#888;}*/
/*.a9immln{background:#efefef;color:#444;}.a9immln svg{fill:#444;}*/
/*.tukdd6b{left:50%;-webkit-transform:translate(-50%) scale(0);-ms-transform:translate(-50%) scale(0);transform:translate(-50%) scale(0);position:absolute;border:1px solid #ddd;background:#fff;border-radius:2px;box-shadow:0px 1px 3px 0px rgba(220,220,220,1);z-index:2;box-sizing:border-box;}.tukdd6b:after,.tukdd6b:before{top:100%;left:50%;border:solid transparent;content:' ';height:0;width:0;position:absolute;pointer-events:none;}.tukdd6b:after{border-color:rgba(255,255,255,0);border-top-color:#fff;border-width:4px;margin-left:-4px;}.tukdd6b:before{border-color:rgba(221,221,221,0);border-top-color:#ddd;border-width:6px;margin-left:-6px;}*/
/*.s1o2cezu{display:inline-block;border-right:1px solid #ddd;height:24px;margin:0 0.5em;}*/

.embed_website_description {
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.embed_website_description.viewMode {
  max-width: 515px;
}

.embed_website_loading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-top: 27px;
}

.embed_website_h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.embed_website_url {
  width: 500px;
  color: #a3aacc;
  padding-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.loader_image_wrapper {
  width: 100px;
  height: 100px;
  animation: rotation 1s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.preview_image_wrapper {
  padding: 12px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview_image {
  height: 76px;
  width: 76px;
  object-fit: cover;
  border-radius: 6px;
}
.embed_website {
  background: #f5f6ff;
  border-radius: 12px;
  /*width: 696px;*/
  height: 100px;
  display: flex;
  cursor: pointer;
}

.embed_website_viewMode {
  max-width: 636px;
}

.embed_website:hover {
  background: #e8ebfa;
}
.embed_website_text {
  width: 590px;
  height: 70px;
}
.google_embed_website {
  background: #f5f6ff;
  border-radius: 12px;
  width: 696px;
  height: 422px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 1px;
  margin-top: 1px;
}

.google_preview_image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: auto;
}
.google_loader_image_wrapper {
  padding: 12px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google_preview_image {
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  height: 280px;
}

.google_embed_website_text {
  width: 590px;
  height: 70px;
  padding-left: 12px;
}
.google_embed_website_h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 12px;
}
.google_embed_website_description {
  font-size: 12px;
  line-height: 18px;
  color: #596080;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.google_embed_website_url {
  width: 500px;
  color: #a3aacc;
  padding-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.google_embed_website_modified {
  position: relative;
  width: 500px;
  color: #a3aacc;
  padding-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.google_embed_website_modified.minHeight {
  height: 24px;
}

.hiddenImage {
  display: none;
}
.cornerSpinner {
  position: absolute;
  top: 5px;
  left: 11px;
}
