.wrapper {
  padding-top: 6px;
  margin-bottom: 8px;
  &.for_create_block {
    padding-top: 4px;
  }
  & .input_area:focus {
  }

  & .input_area.error {
    border: 1px solid #c21c44;
  }

  &:focus-within .title {
    //color: #8697F0;
  }

  & .title_wrapper{
    display: flex;
    justify-content: space-between;
    & .title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #939393;
      margin-bottom: 7px;
      &.hasSubFieldError{
        padding-top: 1.5px;
      }
      &.required:after {
        //content: '*';
        //color: #C21C44;
        //margin-left: 2px;
      }

      span {
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #939393;
        letter-spacing: 0.05px;
      }
    }
  }
  & .input_area {
    width: 340px;
    height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border-radius: 7px;
    color: #484343;
    &.for_smart_modal {
      width: 311px;
      left: -1px;
      position: relative;
    }
    &.for_create_block {
      width: 300px;
    }
    & .disabled_input {
      height: 23px;
      color: lightgrey;
      cursor: default;
      padding-top: 4px;
      padding-left: 8px;
    }

    & .input {
      border: none;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      width: 100%;
      height: 30px;
      background: transparent;
      padding-left: 8px;
      border: 1px solid #ece2e2;
      border-radius: 6px;

      &:focus {
        border: 1px solid #ffc72c;
        border-radius: 6px;
      }
      &.number {
        padding-left: 0px;
        > :nth-child(3) {
          border: none !important;
          background: transparent !important;
        }
        input {
          height: 31px;
          width: 338px;
          border: none;
          background: transparent;
        }
      }
      &.number::-webkit-outer-spin-button,
      &.number::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.number[type="number"] {
        -moz-appearance: textfield;
      }
    }

    //& .emailToInvite_wrapper, & .emailToAdd_wrapper{
    //
    //  width: fit-content;
    //  height: 24px;
    //  background: #F5F6FF;
    //  border: 1px solid #D7DDF7;
    //  box-sizing: border-box;
    //  border-radius: 24px;
    //  font-size: 14px;
    //  line-height: 21px;
    //  color: #242633;
    //  display: flex;
    //  & .avatar {
    //    position: relative;
    //    top: -1px;
    //    left: -1px;
    //  }
    //  & .email {
    //    color: #596080;
    //    margin-left: 6px ;
    //  }
    //  & .close_wrapper{
    //    width: 22px;
    //    height: 22px;
    //    cursor: pointer;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //  }
    //
    //}
  }
}
